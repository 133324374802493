import React from "react";
import { Form, message } from "antd";

import { FormSection } from "../../molecules";
import { PreRequestFormContext } from "./PreRequestFormContext";
import { bugsnagClient } from "../../../bugsnag";
import {
  PreRequestFormProps,
  PreRequestFormState,
  ICustomChangeEvent
} from "./types";
import {
  ProjectDescriptionInput,
  ProjectNameInput,
  EstimatedBudgetInput,
  OrganizationDescriptionInput,
  SubmitButton
} from "./inputs";
import { createPreRequest, initialState } from "./helpers";
import { withRouter } from "react-router";

class PresubmissionForm extends React.Component<PreRequestFormProps, PreRequestFormState> {
  public state: PreRequestFormState = {
    ...initialState,
    isBusy: false
  };

  public render() {
    const { form } = this.props;
    const { isBusy } = this.state;
    const providerValue = {
      form,
      preRequest: { ...this.state },
      onFieldsChange: this.onFieldsChange
    };

    return (
      <PreRequestFormContext.Provider value={providerValue}>
        <Form
          id="presubmissionForm"
          onSubmit={this.handleSubmit}>
          <FormSection>
            <OrganizationDescriptionInput />
            <ProjectNameInput />
            <EstimatedBudgetInput />
            <ProjectDescriptionInput />
          </FormSection>
          <FormSection>
            <p>
              Al hacer click en el botón de Enviar, tu pre solicitud llegará al
              comité de Ficosec para evaluar y clasificar tu pre solicitud. En
              caso de ser aceptado podrás iniciar con el llenado de datos para
              su evaluación completa.
            </p>
          </FormSection>
          <FormSection>
              <SubmitButton loading={isBusy} />
          </FormSection>
        </Form>
      </PreRequestFormContext.Provider>
    );
  }

  private handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const { form } = this.props;
    evt.preventDefault();
    form.validateFields((err) => {
      if (err) {
        return;
      }
      this.savePreRequest();
    });
  }

  private onFieldsChange = (arg: ICustomChangeEvent) => {
    const updatedState = { ...this.state };
    updatedState[arg.key] = arg.value;
    this.setState({ ...updatedState });
  }

  private toggleIsBusy = () => {
    this.setState((prevState) => ({
      ...prevState,
      isBusy: !prevState.isBusy
    }));
  }

  private savePreRequest = async () => {
    this.toggleIsBusy();
    const { action, form, history } = this.props;
    const {
      organizationDescription,
      projectDescription,
      projectName,
      estimatedBudget
    } = this.state;
    try {
      if (action === "CREATE") {
        const result = await createPreRequest({
          organizationDescription,
          projectDescription,
          projectName,
          estimatedBudget,
          status: "IN_PROGRESS"
        });

        if (result !== undefined) {
          const preRequestId = result?.data?.createPreRequest?.id;
          history.push(`${preRequestId}/confirmacion`);
        }
      }
      delete initialState.isBusy;
      this.setState({
        ...initialState
      });
      form.resetFields();
      message.success("Pre-solicitud creada.");
    } catch (error) {
      bugsnagClient.notify(error, {
        beforeSend: (report) => {
          report.metaData = { error: JSON.stringify(error) };
          report.errorClass = "Implementer Create Pre Request";
          report.groupingHash = "Implementer - Create Pre Request";
        }
      });
      message.error("Ocurrió un error.");
    } finally {
      this.toggleIsBusy();
    }
  }
}

const WrappedPresubmissionForm =
  Form.create<PreRequestFormProps>({
    name: "presubmissionForm"
  })(PresubmissionForm);

export default withRouter(WrappedPresubmissionForm);
