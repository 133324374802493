import React, { useContext } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button, Descriptions } from "antd";

import { getBreadCrumbRoutes, getTotals } from "../helpers";
import { CustomPageHeader } from "../../../../organisms/ImplementerLayout";
import { IImplementer, IPreRequest } from "../../../../../types";
import RequestDetailsTemplateContext from "../RequestDetailsTemplateContext";
import { formatToCurrency } from "../../../../../utils/currency";
import { getSuppliesWithSubTotals } from "../../../../../utils";

type DetailsHeaderProps =
  & RouteComponentProps
  & Pick<RouteComponentProps<{ requestId: string }>, "match">;

const DetailsHeader: React.FC<DetailsHeaderProps> = (
  props: DetailsHeaderProps
) => {
  const { history, match } = props;
  const { request = {} } = useContext(RequestDetailsTemplateContext);
  const { implementer = {} as IImplementer } = request?.preRequest || {};
  const { name: projectName = "", id: projectID = '' } = request?.project || {};

  const { preRequest = {} as IPreRequest } = request;
  const suppliesWithSubtotals = getSuppliesWithSubTotals(
    request?.project?.supplies
  );

  const formatedestimatedBgt = formatToCurrency(preRequest?.estimatedBudget);

  const onGoBack = () => {
    history.goBack();
  };

  const navigateToSubmissionForm = () => {
    const { requestId = "" } = match?.params;
    history.push(`/implementadora/solicitudes/${requestId}/capacidad-financiera`);
  };

  const getExtraButton = () => {
    return (
      <Button
        type="primary"
        shape="round"
        onClick={navigateToSubmissionForm}>
        Ver solicitud completa
      </Button>
    );
  };

  return (
    <CustomPageHeader
      breadcrumb={{
        routes: getBreadCrumbRoutes(projectName, preRequest?.folio, projectID),
        itemRender: (route) => (
          <Link to={route.path}>{route.breadcrumbName}</Link>)
      }}
      title={projectName}
      ghost={false}
      extra={getExtraButton()}
      onBack={onGoBack}>
      <Descriptions column={1}>
        <Descriptions.Item label="Implementadora">
          {implementer?.displayName}
        </Descriptions.Item>
        <Descriptions.Item label="Presupuesto estimado">
          {formatedestimatedBgt} MXN
        </Descriptions.Item>
      </Descriptions>
    </CustomPageHeader>);
};

export default withRouter(DetailsHeader);
