import React, { useState, useContext } from "react";
import { Button, notification, message, Modal as ANTModal } from "antd";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";

import {
  ImplementerProfile,
  HRProfile,
  FinancialCapability,
  ProjectInfo,
  ProjectFile,
  Schedule,
  Budget,
  RiskManagement
} from "../../shared/templates";
import { RequestReviewSidebar } from "../../organisms";
import { ContentHeader, Modal } from "../../atoms";
import { RequestReviewControls } from "../../molecules";
import { client } from "../../../gql/apollo";
import {
  ApproveRequest,
  RejectRequest,
  BoardApprovement,
  RequestCorrection
} from "../../../gql/mutations";
import RequestRejectionForm from "../../RequestRejectionForm";
import { NewRequestContext } from "../../NewRequestContext";
import { RevisionBoard, RevisionStatus, Request, Revision } from "./ApprovedRequestsTemplate/types";
import { RequestReviewContext } from "../../RequestReviewContext";

interface RequestReviewTemplateState {
  visibleModal?: "APPROVE_MODAL" | "REJECT_MODAL";
  rejectReason?: string;
  busy: boolean;
}

interface RequestReviewTemplateProps {
  approval: boolean;
  review: boolean;
  request: {
    id: string;
  };
  currentStep: string;
}

const RequestReviewTemplate: React.FC<RequestReviewTemplateProps> = (props) => {
  const history = useHistory();
  const [approveRequestMutation] = useMutation(ApproveRequest, { client } as any);
  const [rejectRequestMutation] = useMutation(RejectRequest, { client } as any);
  const [boardApprovementMutation] = useMutation(BoardApprovement, { client } as any);
  const requestState = useContext(NewRequestContext);
  const reviewState = useContext(RequestReviewContext);
  const [state, setState] = useState<RequestReviewTemplateState>({
    busy: false
  });

  const stepTemplates = {
    0: ImplementerProfile,
    1: FinancialCapability,
    2: ProjectInfo,
    3: ProjectFile,
    4: Budget,
    5: Schedule,
    6: HRProfile,
    7: RiskManagement
  };

  const stepMap = {
    "implementadora": 0,
    "capacidad-financiera": 1,
    "proyecto": 2,
    "ficha": 3,
    "presupuesto": 4,
    "cronograma": 5,
    "recursos-humanos": 6,
    "riesgos": 7
  };

  const stepTitles = {
    0: "Paso 1 de 9 – Perfil de la Implementadora",
    1: "Paso 2 de 9 – Capacidad financiera gestora",
    2: "Paso 3 de 9 – Información general del proyecto",
    3: "Paso 4 de 9 – Ficha del proyecto",
    4: "Paso 5 de 9 – Presupuesto",
    5: "Paso 6 de 9 – Cronograma",
    6: "Paso 7 de 9 – Perfil del Recurso Humano",
    7: "Paso 8 de 9 – Supuestos y gestión de riesgos"
  };

  const { visibleModal, rejectReason } = state;
  const { approval, review, currentStep } = props;
  const step = stepMap[currentStep];
  const stepTitle = stepTitles[step];
  const StepTemplate = stepTemplates[step];


  const onClickApproveRequest = () =>
    setState({ ...state, visibleModal: "APPROVE_MODAL" });

  const findApprovalStep = (request: Request): Revision | undefined => {
    const { revisions = [] } = request;
    revisions.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    revisions.reverse();
    return revisions.find((revision) => revision.status === RevisionStatus.APPROVED);
  };

  const approvalStep = findApprovalStep(requestState.state as unknown as Request);

  const copy: { [key in "undefined" | RevisionBoard]: string } = {
    undefined: "Esta pasará a la etapa de aprobación para revisión con el consejo local.",
    UNITY: "Esta pasará a la etapa de aprobación para revisión con el consejo directivo.",
    LOCAL: "Esta pasará a la etapa de aprobación para revisión con el comité técnico.",
    DIRECTORS: "Esta pasará a la etapa de firma de convenio.",
    TECHNICIANS: "Esta pasará a la etapa de firma de convenio."
  };

  const renderApproveModalContent = () => (
    <div style={{ padding: "20px 80px" }}>
      <div style={{ fontSize: 18, marginBottom: 10 }}>
        ¿Estás seguro de que deseas aprobar la solicitud?
      </div>
      <div style={{ color: "#808080", fontSize: 14 }}>
        {copy[approvalStep?.board]}
      </div>
    </div>
  );

  // TODO: Not a fan of this, but works for now.
  const currentApprovalStep = (): string => {
    const { revisions } = requestState.state as any;

    const findRevision = (board: RevisionBoard) =>
      revisions?.find((revision: any) => revision.board === board && revision.status === RevisionStatus.APPROVED);

    if (revisions?.length > 0) {
      if (findRevision(RevisionBoard.DIRECTORS)) {
        return RevisionBoard.TECHNICIANS;
      }

      if (findRevision(RevisionBoard.LOCAL)) {
        return RevisionBoard.DIRECTORS;
      }

      if (findRevision(RevisionBoard.UNITY)) {
        return RevisionBoard.LOCAL;
      }
    }

    return RevisionBoard.UNITY;
  };

  const approveRequest = () =>
    approveRequestMutation({
      variables: {
        id: props.request.id
      }
    });

  const boardApprovement = () =>
    boardApprovementMutation({
      variables: {
        requestId: props.request.id,
        board: currentApprovalStep(),
        status: RevisionStatus.APPROVED
      }
    });

  const onApproveRequest = async () => {
    try {
      setState({ ...state, busy: true });

      const mutationFn = props.review
        ? approveRequest
        : boardApprovement;

      await mutationFn();

      message.success("Proyecto aprobado");
      history.push("/solicitudes/aprobacion");
    } catch (error) {
      // TODO: Report to bugsnag
      notification.error({
        message: "Ocurrió un error al aprobar el proyecto, por favor, inténtalo de nuevo."
      });
    } finally {
      setState({
        ...state,
        busy: false,
        visibleModal: undefined,
        rejectReason: undefined
      });
    }
  }

  const onClickRejectRequest = () =>
    setState({ ...state, visibleModal: "REJECT_MODAL" });

  const renderRejectModalContent = () => (
    <div style={{ paddingBottom: 80 }}>
      <RequestRejectionForm
        onChange={(changedFields: any) => setState({ ...state, rejectReason: changedFields.rejectReason.value })} />
    </div>
  );

  const renderReviewControls = () => {
    if (approvalStep?.board === RevisionBoard.TECHNICIANS) {
      return null;
    }

    if (review || approval) {
      // TODO: Not a fan of this, but works for now.
      // isReview: Mo more corrections, if greater than 3
      const reviewCount: number = requestState?.state?.reviews?.length + 1;
      const isReview: boolean = reviewCount < 3;

      return (
        <RequestReviewControls
          onApprove={onClickApproveRequest}
          onReject={onClickRejectRequest}
          onRequestCorrection={(review && isReview) ? onClickRequestCorrection : undefined}
        />
      );
    }
  };

  const reviewReject = async () => {
    try {
      setState({ ...state, busy: true });
      const { request } = props;
      await rejectRequestMutation({
        variables: {
          id: request.id,
          rejectReason: state.rejectReason
        }
      });
      message.success("Proyecto rechazado");
      history.push("/solicitudes/revision");
    } catch (error) {
      // TODO: Report to bugsnag
      notification.error({
        message: "Ocurrió un error al rechazar el proyecto, por favor, inténtalo de nuevo."
      });
    } finally {
      setState({
        ...state,
        busy: false,
        visibleModal: undefined,
        rejectReason: undefined
      });
    }
  };

  const approvalReject = async () => {
    try {
      setState({ ...state, busy: true });
      const { request } = props;
      await client.mutate({
        mutation: BoardApprovement,
        variables: {
          requestId: request.id,
          board: currentApprovalStep(),
          status: RevisionStatus.REJECTED,
          annotations: state.rejectReason
        }
      });
      message.success("Proyecto rechazado");
      history.push("/solicitudes/revision");
    } catch (error) {
      // TODO: Report to bugsnag
      notification.error({
        message: "Ocurrió un error al rechazar el proyecto, por favor, inténtalo de nuevo."
      });
    } finally {
      setState({
        ...state,
        busy: false,
        visibleModal: undefined,
        rejectReason: undefined
      });
    }
  };

  const onRejectRequest = () => {
    if (!approval && review) {
      reviewReject();
      return;
    }

    if (approval && !review) {
      approvalReject();
      return;
    }
  };

  const onClickRequestCorrection = () =>
    ANTModal.confirm({
      title: "Solicitar correción",
      content: "¿Estás seguro que deseas mandar esta solicitud para correción?",
      onOk: onRequestCorrection
    });

  const onRequestCorrection = async () => {
    try {
      const { request } = props;
      await client.mutate({
        mutation: RequestCorrection,
        variables: {
          requestId: request.id,
          fields: normalizeReviewFields()
        }
      });

      message.success("Correción solicitada");
      history.push("/solicitudes/revision");
    } catch (error) {
      // TODO: Report to bugsnag
      notification.error({
        message: "Ocurrió un error al solicitar la correción, por favor, inténtalo de nuevo."
      });
    }
  };

  const normalizeReviewFields = () => {
    const { state = {} } = reviewState;
    const normalized: Array<{ field: string, approved?: boolean, comment?: string }> = [];
    const fields = Object.keys(state);

    fields.forEach((field) =>
      normalized.push({ field, ...state[field] })
    );

    return normalized;
  };

  const onCancelAction = () =>
    setState({ ...state, visibleModal: undefined });

  return (
    <>
      <Modal
        centeredContent
        visible={visibleModal === "APPROVE_MODAL"}
        onCancel={onCancelAction}
        footer={[
          <Button
            loading={state.busy}
            shape="round"
            type="primary"
            onClick={onApproveRequest}>
            Aprobar
          </Button>,
          <Button shape="round" type="link" onClick={onCancelAction}>Cancelar</Button>,
        ]}>
        {renderApproveModalContent()}
      </Modal>
      <Modal
        visible={visibleModal === "REJECT_MODAL"}
        title={"Agregar observación a la solicitud"}
        onCancel={onCancelAction}
        footer={[
          <Button
            form="requestRejectionForm"
            key="submit"
            htmlType="submit"
            shape="round"
            type="primary"
            loading={state.busy}
            disabled={!rejectReason}
            onClick={onRejectRequest}>
            Notificar al aplicante
          </Button>,
          <Button shape="round" type="link" onClick={onCancelAction}>Cancelar</Button>
        ]}
      >
        {renderRejectModalContent()}
      </Modal>
      <RequestReviewSidebar currentStep={step} />
      <ContentHeader
        title={`Proyecto: ${requestState.state?.projectInfo?.name || "..."}`}
        subtitle={stepTitle}
        renderRight={renderReviewControls()} />
      <StepTemplate
        state={requestState.state}
        approval={approval}
        reviewable={review} />
    </>
  );
};

export default RequestReviewTemplate;
