export const implementerMenuItems = [
  {
    key: "prerequests",
    path: "/implementadora/presolicitudes",
    label: "Pre-solicitudes"
  },
  {
    key: "requests",
    path: "/implementadora/solicitudes",
    label: "Solicitudes"
  },
  {
    key: "projects",
    path: "/implementadora/proyectos",
    label: "Proyectos"
  }
];
