import { client } from "../../../../../gql/apollo";
import { RemoveRequest } from "../../../../../gql/mutations";
import { PendingRequests } from "../../../../../gql/queries";

export const removeRequest = async (requestId = "") => {
  // true if deleted, false if not
  return await client.mutate({
    mutation: RemoveRequest,
    variables: {
      requestId: requestId
    },
    refetchQueries: [
      { query: PendingRequests }
    ]
  });
};
