import React, { useContext, useState } from 'react'
import { Modal, Button } from "antd";
import CloseAgreementForm from "./CloseAgreementForm";
import { AgreementsTemplateContext } from '../../AgreementsTemplateContext'

interface ContractModalProps {
  onCancel: any;
  visible: boolean;
  project?: any;
}

export const CloseAgreementModal = ({
  onCancel,
  visible = false,
  project = {}
}: ContractModalProps) => {
  const { refetch } = useContext(AgreementsTemplateContext) as any;
  const [state, setState] = useState({
    busy: false
  });

  const readOnly = project?.agreement?.closed === true;
  const toggleBusy = (busy = false) => setState({ busy });

  return (
    <Modal
      destroyOnClose
      title={readOnly ?
        "Convenio de proyecto"
        : "Cerrar convenio de proyecto"}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          loading={state.busy}
          form="closeAgreement"
          htmlType="submit"
          shape="round"
          type="primary">
          Cerrar convenio
        </Button>,
        <Button
          shape="round"
          type="link"
          onClick={onCancel}>
          Cancelar
        </Button>
      ]}>
      <CloseAgreementForm
        toggleBusy={toggleBusy}
        project={project}
        readOnly={readOnly}
        closeCloseAgreementModal={onCancel}
        visible={visible}
        refetch={refetch}/>
    </Modal>
  );
};
