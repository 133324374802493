import React, { useState } from "react";
import { Modal, message } from "antd";
import { ModalProps } from "antd/lib/modal";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { useMutation } from "@apollo/react-hooks";
import { UploadFile } from "antd/lib/upload/interface";
import { useParams } from "react-router-dom";

import { BudgetVerificationForm } from "./BudgetVerificationForm";
import { IMonthlyBudget, InvestorType } from "../../../../../types";
import { UpdateBudgetVerification } from "../../../../../gql/mutations";
import { client } from "../../../../../gql/apollo";
import { SuppliesBudget } from "../../../../../gql/queries";

interface IBudgetVerificationModalOwnProps {
  formDisabled?: boolean;
  budget?: IMonthlyBudget;
  investorType?: InvestorType;
}

type BudgetVerificationModalProps = IBudgetVerificationModalOwnProps & ModalProps;

export const getVerificationRecord = (record?: IMonthlyBudget, type?: InvestorType) => {
  const verificationRecordIdx = record?.budgetVerification?.findIndex((verification) =>
    verification.type === type);
  if (verificationRecordIdx !== undefined && verificationRecordIdx > -1) {
    return record?.budgetVerification?.[verificationRecordIdx];
  }

  return undefined;
};

export const BudgetVerificationModal: React.FC<BudgetVerificationModalProps> = ({
  formDisabled,
  budget,
  investorType,
  ...modalProps
}) => {
  const { projectId } = useParams();
  const [updateBudgetVerification] = useMutation<any>(UpdateBudgetVerification, { client } as any);
  const [busy, setBusy] = useState(false);

  const formatInvoices = (files: Array<UploadFile<any>> = []) =>
    files.map((file) => ({
      fileName: file.name,
      ref: file.response?.imageUrl || file.url
    }));

  const handleOnSaveBudgetVerification = (form: WrappedFormUtils<any>) => {
    form.validateFields(async (errors: any, values: any) => {
      if (!errors) {
        setBusy(true);
        try {
          await updateBudgetVerification({
            variables: {
              id: getVerificationRecord(budget, investorType)?.id,
              supplyBudgetId: budget?.id,
              type: investorType,
              consumed: values.consumed,
              invoices: formatInvoices(values.invoices.fileList)
            },
            refetchQueries: [{
              query: SuppliesBudget,
              variables: {
                projectId
              }
            }]
          });

          modalProps.onCancel?.(undefined as unknown as React.MouseEvent<HTMLElement, MouseEvent>);
        } catch (error) {
          // TODO: Report to bugsnag
          console.error(error);
          message.error("Ocurrió un problema al guardar la información");
        } finally {
          setBusy(false);
        }
      }
    });
  };

  return (
    <Modal
      {...modalProps}
      footer={null}
      destroyOnClose>
      <BudgetVerificationForm
        data={budget}
        type={investorType}
        disabled={formDisabled}
        onSaveBudgetVerification={handleOnSaveBudgetVerification as () => void}
        busy={busy} />
    </Modal>
  )
};
