import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { PreRequests } from "../../../../gql/queries";
import Layout, { PageHeader, Content } from "../../../organisms/Layout";
import { PreRequestsTable } from "./components";
import { client } from "../../../../gql/apollo";
import { IPaginationSignature, sortDirectionMap } from "../../requests/ReviewalPage";

export default () => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { loading, data, error, refetch } = useQuery(PreRequests, { client } as any);

  if (error) {
    // @todo report to bugsnag and show a friendly message
    return <p>Error....</p>;
  }

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);

  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<Record<"id", string[]>>,
    sorter: SorterResult<{ id: string }>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <Layout noPadding>
      <PageHeader
        ghost={false}
        title="Pre-solicitudes" />
      <Content>
        <PreRequestsTable
          loading={loading}
          onPaginationChange={handleOnPaginationChange}
          preRequests={data?.preRequests} />
      </Content>
    </Layout>
  );
}
