import gql from "graphql-tag";

export const UpdateImplementerProjects = gql`
  mutation UpdateImplementerProjects(
    $projectId: String!
    $projectsToCreate: [CreateImplementerProjectInput!]!
    $projectsToUpdate: [UpdateImplementerProjectInput!]!
    $projectsToDelete: [String!]!
  ) {
    createImplementerProjects(
      projectId: $projectId
      data: $projectsToCreate
    ) {
      id
      financialInstitution
      amount
      pctFinanced
      projectName
      projectYear
      budgetType
      projectObjective
    }

    updateImplementerProjects(
      data: $projectsToUpdate
    ) {
      id
      financialInstitution
      amount
      pctFinanced
      projectName
      projectYear
      budgetType
      projectObjective
    }

    deleteImplementerProjects(ids: $projectsToDelete)
  }
`;
