import React from "react";
import { Icon } from "antd";

export const CircleIcon: React.FC<{}> = (props) => {
  return (
    <div style={{
        display: "inline-block",
        marginBottom: "5pt"
      }}>
      <div style={{
        display: "flex",
        alignItems: "center"
      }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            marginRight: "5pt",
            borderRadius: "50%",
            backgroundColor: "#2eb82e",
            color: "#FFF",
            width: "50pt",
            height: "50pt"
          }}>
            <Icon
              type="check"
              style={{
                fontSize: "25pt"
              }} />
        </div>
      </div>
    </div>
  );
};
