import React from "react";
import { Input, Select, Button, Form } from 'antd'

import { IAssumption, AssumptionImpact } from "../../../../../types";
import { assumptionImpact } from "../../../../../utils/maps";
import { RequestReviewContextOwnProps } from "../../../../RequestReviewContext";
import { ReviewControls, CommentButton } from "../../../../molecules";

interface IArgs {
  reviewState: Partial<RequestReviewContextOwnProps>;
  onChange: any;
  disabled: boolean;
  reviewable: boolean;
  approval: boolean;
  onRemoveAssumption: any;
  checkFields: boolean;
  reviews: any;
}

export const getColumns = (args: IArgs) => {
  const {
    reviewState,
    onChange,
    disabled,
    reviewable,
    approval,
    onRemoveAssumption,
    checkFields,
    reviews
  } = args;

  return ([
    {
      title: "Supuestos",
      align: "left" as "left",
      children: [
        {
          title: "Identificación",
          dataIndex: "identification",
          key: "identification",
          render: (value: string, original: Partial<IAssumption>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Input
                style={{ width: "100%" }}
                defaultValue={value}
                disabled={disabled}
                onBlur={
                  (e: React.FocusEvent<HTMLInputElement>) =>
                    onChange(original.id!, "identification", e.target.value)
                } />
            </Form.Item>
          )
        },
        {
          title: "Impacto",
          dataIndex: "impact",
          key: "impact",
          render: (value: AssumptionImpact, original: Partial<IAssumption>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Select
                placeholder="Selecciona el impacto"
                defaultValue={value}
                style={{ width: "100%" }}
                onChange={(v: AssumptionImpact) => onChange(original.id!, "impact", v)}
                disabled={disabled}>
                {Object.keys(assumptionImpact).map((impact) => (
                  <Select.Option key={impact}>
                    {assumptionImpact[impact]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        },
        {
          title: "Acciones",
          dataIndex: "actions",
          key: "actions",
          render: (value: string, original: Partial<IAssumption>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Input
                disabled={disabled}
                style={{ width: "100%" }}
                defaultValue={value}
                onBlur={
                  (e: React.FocusEvent<HTMLInputElement>) =>
                    onChange(original.id!, "actions", e.target.value)
                } />
            </Form.Item>
          )
        }
      ]
    },
    {
      children: [
        {
          align: "center" as "center",
          render: (_: any, assumption: Partial<IAssumption>) => {
            const fieldId = `assumptions.${assumption.id}`;
            if (approval) {
              return null;
            }

            if (!reviewable) {
              return (
                <Button.Group>
                  <Button
                    icon="delete"
                    disabled={disabled}
                    onClick={() => !disabled && onRemoveAssumption(assumption.id!)} />
                  <CommentButton field={fieldId} size="default" disabled={disabled}/>
                </Button.Group>
              );
            } else {
              return (
                <ReviewControls
                  reviews={reviews}
                  fieldId={fieldId}
                  comment={((reviewState.state || {})[fieldId] || { comment: undefined }).comment}
                  approved={((reviewState.state || {})[fieldId] || { approved: undefined }).approved}
                  onReset={(reviewState.methods || { onReset: () => undefined }).onReset}
                  onApprove={(reviewState.methods || { onApprove: () => undefined }).onApprove}
                  onReject={(reviewState.methods || { onReject: () => undefined }).onReject}
                  onSaveComment={(reviewState.methods || { onSaveComment: () => undefined }).onSaveComment}
                  onDeleteComment={(reviewState.methods || { onDeleteComment: () => undefined }).onDeleteComment}
                  relative />
              );
            }
          }
        }
      ]
    }
  ]);
};
