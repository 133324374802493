import gql from "graphql-tag";
import { client } from "../../apollo";
import { WorkTables } from "../../queries/workTables";
import { v4 as uuid } from "uuid";
import {
  ISectionGeneralState,
  IMember,
  IActivity
} from "../../../../src/components/templates/workTables/CreateWorkTableTemplate/types";

const CreateWorkTableMutation = async (
  general: ISectionGeneralState,
  members: IMember[],
  activities: IActivity[]
) => {
  const timestamp = Math.round(Date.now() / 1000);

  const newWorkTable = await CreateWorkTable({
    id: timestamp.toString(), // TODO: Remove this when implement the real server
    folio: timestamp.toString(),
    area: general.workTableArea,
    name: general.name,
    coordination: general.coordination,
    technicalSecretary: general.technicalSecretary,
    logoRef: general.logoRef,
    objective: general.objective,
    subject: general.objective,
    status: "IN_PROGRESS" // TODO: Use the proper enum when implement the real server
  });

  const newMembersResults = await Promise
    .all(members.map((member: IMember, index: number) => {
      return CreateMember({
        id: uuid(),
        workTable_id: newWorkTable.data.createWorkTable.id,
        fullName: member.fullName,
        institution: member?.institution?.name,
        institutionLevel: member?.institution?.level,
        type: member.type,
        email: member.email,
        phone: member.phone,

      });
    }))
    .then((res) => res)
    .catch((err) => { throw err; });

  const newActivities = await Promise
    .all(activities.map((activity: any, index: number) => {
      return CreateActivity({
        id: uuid(),
        workTable_id: newWorkTable.data.createWorkTable.id,
        title: activity.title,
        description: activity.title // the form don't have the description field
      });
    }))
    .then((res) => res)
    .catch((err) => { throw err; });

};

const CreateWorkTable = async (data: any) => {
  return await client.mutate({
    mutation: createWTMutation,
    variables: data,
    refetchQueries: [{ query: WorkTables }]
  });
};

const CreateMember = async (data: any) => {
  return await client.mutate({
    mutation: createMemberMutation,
    variables: data
  });
};

const CreateSubActivity = async (data: any) => {
  return await client.mutate({
    mutation: createSubActivityMutation,
    variables: data
  });
};

const CreateActivity = async (data: any) => {
  return await client.mutate({
    mutation: createActivityMutation,
    variables: data
  });
};

const createWTMutation = gql`
  mutation CreateWorkTable(
    $id: ID!
    $folio: String!
    $area: String!
    $name: String!
    $coordination: String!
    $technicalSecretary: String!
    $logoRef: String
    $objective: String!
    $subject: String!
    $status: String!
  ) {
    createWorktable(
      id: $id
      folio: $folio
      area: $area
      name: $name
      coordination: $coordination
      technicalSecretary: $technicalSecretary
      logoRef: $logoRef
      objective: $objective
      subject: $subject
      status: $status
    ) {
      id
    }
  }
`;

const createMemberMutation = gql`
  mutation CreateWorkTableMember (
    $id: ID!
    $workTable_id: ID!
    $fullName: String!
    $institution: String
    $type: String!,
    $institutionLevel: String,
    $phone: String,
    $email: String,
    $charge: String
  ) {
    createWorkTableMember(
      id: $id
      workTable_id: $workTable_id
      fullName: $fullName
      institution: $institution
      type: $type
      institutionLevel: $institutionLevel
      phone: $phone
      email: $email
      charge: $charge
    ) {
      id
    }
  }
`;

const createActivityMutation = gql`
  mutation CreateActivity (
    $id: ID!
    $workTable_id: ID!
    $title: String!
    $description: String!
  ) {
    createWorkTableActivity(
      id: $id
      workTable_id: $workTable_id
      title: $title
      description: $description
    ) {
      id
    }
  }
`;

const createSubActivityMutation = gql`
  mutation CreateSubActivity (
    $id: ID!
    $workTableActivity_id: ID!
    $title: String!
    $description: String!
  ) {
    createWorkTableSubactivity(
      id: $id
      workTableActivity_id: $workTableActivity_id
      title: $title
      description: $description
    ) {
      id
    }
  }
`;

export default CreateWorkTableMutation;
