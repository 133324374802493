import React, { useState, useContext } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { rejectPreRequest } from "./helpers";
import {
  ClasificationFormContext
} from "./ClasificationFormContext";
import { bugsnagClient } from "../../../../../../bugsnag";

interface IRejectConfirmationModalOwnProps {
  isVisible: boolean;
  onClose: () => void;
}

type RejectConfirmationModalProps =
  & FormComponentProps
  & IRejectConfirmationModalOwnProps;

const RejectConfirmationModal: React.FC<RejectConfirmationModalProps> = (props) => {
  const { isVisible, onClose, form } = props;
  const { getFieldDecorator, validateFieldsAndScroll } =  form;

  const [isBusy, setIsBusy] = useState(false);
  const preRequestContext = useContext(ClasificationFormContext);
  const { preRequest } = preRequestContext;

  const rejectPreSubmission = async () => {
    let result = false;
    const { id } = preRequest;
    const { annotation = "" } = form.getFieldsValue();

    try {
      await rejectPreRequest(id, annotation);
      message.success("Pre-solicitud rechazada.");
      result = true;
    } catch (error) {
      bugsnagClient.notify(error, {
        beforeSend: (report) => {
          report.metaData = { error: JSON.stringify(error) };
          report.errorClass = "Implementer Reject Pre Request";
          report.groupingHash = "Implementer - Reject Pre Request";
        }
      });
      message.error("Ocurrió un error");
    } finally {
      return result;
    }
  };

  const handleOnOk = () => {
    validateFieldsAndScroll(async (errors) => {
      setIsBusy(true);

      if (errors) {
        setIsBusy(false);
        return;
      }

      if (await rejectPreSubmission()) {
        form.resetFields();
        onClose();
      }
      setIsBusy(false);
    });
  }

  const handleOnCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="¿Seguro de rechazar la pre-solicitud?"
      visible={isVisible}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleOnCancel}>
          Cancelar
        </Button>,
        <Button
          key="reject"
          type="primary"
          loading={isBusy}
          onClick={handleOnOk}>
          Rechazar
        </Button>
      ]}>
      <Form id="rejectPreSubmissionForm">
        <Form.Item label="Agregar el motivo del rechazo">
          {getFieldDecorator("annotation", {
            initialValue: preRequest.annotation,
            rules: [
              {
                required: true,
                message: "Por favor, agrega el motivo."
              }
            ]
          })(
            <Input.TextArea rows={5} disabled={isBusy} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create<RejectConfirmationModalProps>({
  name: "reject_presubmission_form"
})(RejectConfirmationModal);
