import React from "react";
import { Button, Form, Input } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";

import { ColumnContainer, ColumnElement } from ".";
import { ActivityIndicator, ActivityIndicatorType } from "../../../../../types";
import { Upload } from "../../../../atoms";

interface IIndicatorRowProps {
  busy: boolean;
  data: any;
  type: ActivityIndicatorType;
  indicator: Partial<ActivityIndicator<ActivityIndicatorType>>;
  form: WrappedFormUtils<any>;
}

interface IDocument {
  id: string;
  fileName: string;
  ref: string;
}

export const IndicatorRow: React.FC<IIndicatorRowProps> = ({
  busy,
  data,
  type,
  indicator,
  form
}) => {
  const keys: { [key: string]: string } = {
    [ActivityIndicatorType.QUALITATIVE]: "qualitativeIndicator",
    [ActivityIndicatorType.QUANTITATIVE]: "quantitativeIndicator"
  };

  const getDocuments = () =>
    (data?.documents as IDocument[])?.map((document) => ({
      uid: document.id,
      name: document.fileName,
      response: {
        imageUrl: document.ref
      }
    }));

  return (
    <ColumnContainer key={indicator.id}>
      <ColumnElement title="Indicador">
        {indicator.description}
      </ColumnElement>
      <ColumnElement title="Medio de verificación">
        {indicator.verificationMethod}
      </ColumnElement>
      {form.getFieldDecorator(`${type}.${indicator.id}.id`, {
        initialValue: data?.id
      })(
        <Input type="hidden" />
      )}
      <ColumnElement>
        <Form.Item>
          {form.getFieldDecorator(`${type}.${indicator.id}.documents`, {
            rules: [
              {
                required: true,
                message: "Por favor, sube al menos un medio de verificación"
              }
            ],
            initialValue: getDocuments(),
            valuePropName: "defaultFileList"
          })(
            <Upload>
              <Button
                disabled={busy}
                type="dashed">
                Subir medio de verificación
              </Button>
            </Upload>
          )}
        </Form.Item>
      </ColumnElement>
    </ColumnContainer>
  );
};