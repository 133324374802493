import React from "react";
import { Button } from "antd";
import format from "date-fns/format";
import { Link } from "react-router-dom";

export default [
  {
    title: "Nombre",
    dataIndex: "name",
    sorter: true
  },
  {
    title: "Fecha de inicio",
    dataIndex: "startDate",
    render: (startDate?: string) =>
      startDate ? format(new Date(startDate), "dd/MM/yyyy") : null,
    sorter: true
  },
  {
    title: "Fecha de fin",
    dataIndex: "endDate",
    render: (endDate?: string) =>
      endDate ? format(new Date(endDate), "dd/MM/yyyy") : null,
    sorter: true
  },
  {
    render: (project: any) => (
      <Link to={`/proyectos/${project.id}`}>
        <Button
          type="primary"
          shape="round">
          Comenzar inspección
        </Button>
      </Link>
    )
  },
];

