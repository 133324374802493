import React, { useState } from "react";
import { Tabs, Button, message } from "antd";
import { TabPaneProps } from "antd/lib/tabs";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { RouteComponentProps, Link } from "react-router-dom";
import { format, isSameMonth, isSameQuarter } from "date-fns";
import es from "date-fns/locale/es";
import styled from "styled-components";

import {
  ImplementerProjectMonitoringTemplate,
  AdminProjectMonitoringTemplate,
  ErrorTemplate
} from "../../templates";
import { MonitoringEntry } from "../../../gql/queries";
import { client } from "../../../gql/apollo";
import { ASupply, IMonthlyBudget, UserRole } from "../../../types";
import { RoleConsumer } from "../../RoleConsumer";
import { Layout } from "../../organisms";
import { PageHeader, Content } from "../../organisms/Layout";
import { ApproveMonitoring } from "../../../gql/mutations";

type ProjectMonitoringPageProps =
  & RouteComponentProps
  & Pick<RouteComponentProps<{ projectId: string, monitoringEntry: string }>, "match">;

export type ProjectMonitoringTab =
  | "BUDGET_BREAKDOWN"
  | "BUDGET_SUMMARY"
  | "TECHNICAL_MONITORING"
  | "FIELD_VISIT";

export const ProjectMonitoringTab: { [key in ProjectMonitoringTab]: ProjectMonitoringTab } = {
  BUDGET_BREAKDOWN: "BUDGET_BREAKDOWN",
  BUDGET_SUMMARY: "BUDGET_SUMMARY",
  TECHNICAL_MONITORING: "TECHNICAL_MONITORING",
  FIELD_VISIT: "FIELD_VISIT"
};

export const TableContainer = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ApproveButton = styled(Button)`
  color: #ffffff;
  background-color: #6bb250;

  &:hover, &:focus {
    color: #ffffff;
    background-color: #6bb250;
  }
`;

export const ProjectMonitoringPage: React.FC<ProjectMonitoringPageProps> = ({ match }) => {
  const [approveMonitoring] = useMutation(ApproveMonitoring, { variables: { id: match.params.monitoringEntry }, client } as any);
  const [currentTab, setCurrentTab] = useState<string>(ProjectMonitoringTab.BUDGET_BREAKDOWN);
  const [busy, setBusy] = useState(false);
  const {
    loading,
    data,
    error
  } = useQuery(MonitoringEntry, {
    variables: {
      projectId: match.params.projectId,
      id: match.params.monitoringEntry
    },
    client
  } as any);

  const breadcrumbs = [{ path: "/proyectos", breadcrumbName: "Proyectos" }];
  const tabs: TabPaneProps[] = [
    {
      key: ProjectMonitoringTab.BUDGET_BREAKDOWN,
      tab: "Desglose presupuestal"
    },
    {
      key: ProjectMonitoringTab.BUDGET_SUMMARY,
      tab: "Resumen presupuestal"
    },
    {
      key: ProjectMonitoringTab.TECHNICAL_MONITORING,
      tab: "Monitoreo técnico"
    },
    {
      key: ProjectMonitoringTab.FIELD_VISIT,
      tab: "Visita de campo"
    }
  ];

  if (error) {
    // TODO: Report to bugsnag
    console.error(error);
    return <ErrorTemplate />;
  }

  const monitoringEntryDate = new Date(data?.monitoringEntry?.date);
  const formattedMonth = data?.monitoringEntry
    ? format(monitoringEntryDate, "MMMM yyyy", { locale: es })
    : "trimestral";

  const getBreadcrumbs = () => {
    if (data?.project?.name) {
      breadcrumbs.push(
        {
          path: `/proyectos/${match.params.projectId}`,
          breadcrumbName: data.project.name
        },
        {
          path: "#",
          breadcrumbName: `Monitoreo ${formattedMonth}`
        }
      );
    }

    return breadcrumbs;
  };

  const renderTabs = (tabs: TabPaneProps[]) => (
    <Tabs defaultActiveKey={currentTab} onChange={setCurrentTab}>
      {tabs.map((tab) =>
        <Tabs.TabPane key={tab.key} tab={tab.tab} />)}
    </Tabs>
  );

  const handleOnApprove = async () => {
    try {
      setBusy(true);
      await approveMonitoring({
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: MonitoringEntry,
          variables: {
            projectId: match.params.projectId,
            id: match.params.monitoringEntry
          }
        }]
      });
      message.success("Monitoreo aprobado.");
    } catch (error) {
      // TODO: Report to bugsnag
      console.error(error);
      message.error("Ocurrió un problema al aprobar el monitoreo.");
    } finally {
      setBusy(false);
    }
  };

  const renderExtra = () => (
    <ApproveButton
      shape="round"
      loading={busy}
      disabled={loading || data?.monitoringEntry?.closed}
      onClick={handleOnApprove}
      icon="check-circle">
      Aprobar
    </ApproveButton>
  );

  const supplies: ASupply[] = data?.project?.supplies || [];
  const suppliesForMonth: ASupply[] = [];

  supplies.forEach((supply) => {
    const monthlyBudgets: IMonthlyBudget[] = supply.monthlyBudget
      .filter((month) => isSameQuarter(parseInt(month.month as string, 10), monitoringEntryDate))
      .sort((a, b) => a.month > b.month ? 1 : -1);

    if (monthlyBudgets.length > 0) {
      suppliesForMonth.push({ ...supply, monthlyBudget: monthlyBudgets });
    }
  });

  return (
    <RoleConsumer>
      {({ claims }) => {
        const ProjectMonitoringTemplate = claims?.role === UserRole.IMPLEMENTER
          ? ImplementerProjectMonitoringTemplate
          : AdminProjectMonitoringTemplate;

        return (
          <Layout noPadding>
            <PageHeader
              breadcrumb={{ routes: getBreadcrumbs() }}
              title={`Monitoreo ${formattedMonth}`}
              footer={renderTabs(tabs)}
              extra={claims?.role === UserRole.ADMIN && renderExtra()} />
            <Content>
              <ProjectMonitoringTemplate
                closed={data?.monitoringEntry?.closed}
                currentTab={currentTab as ProjectMonitoringTab}
                loading={loading}
                supplies={suppliesForMonth} />
            </Content>
          </Layout>
        );
      }}
    </RoleConsumer>
  );
};
