import React, { useContext } from "react";
import { PreRequestFormContext } from "../PreRequestFormContext";
import Form from "antd/lib/form/Form";
import { InputNumber } from "antd";
import { IconLabel } from "./OrganizationDescriptionInput/IconLabel";

type IParserValue =
  | string
  | number
  | undefined;

export const EstimatedBudgetInput = (props: any) => {
  const implementerAccountContext = useContext(PreRequestFormContext);
  const { form, onFieldsChange, preRequest } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (value: number = 0) => {
    const fixedNumber: string = parseFloat(value.toString()).toFixed(2);
    onFieldsChange({
      key: "estimatedBudget",
      value: parseFloat(fixedNumber)
    });
  };

  const parseHandler = (value: IParserValue): string => {
    if (value !== undefined && value !== null) {
      return (value.toString()).replace(/\$\s?|(,*)/g, "");
    }
    return "";
  };

  const formatHandler = (value: IParserValue): string => {
    if (value !== undefined && value !== null) {
      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "";
  };

  return (
    <>
      <IconLabel
        number={3}
        label="Presupuesto estimado:" />
      <Form.Item>
        {getFieldDecorator("estimatedBudget", {
          rules: [
            {
              required: true,
              message: "Por favor, ingresa el presupuesto estimado."
            }
          ],
          initialValue: preRequest !== undefined && preRequest.estimatedBudget
        })(
          <InputNumber
            style={{ width: "100%" }}
            formatter={formatHandler}
            parser={parseHandler}
            id="estimatedBudget"
            name="estimatedBudget"
            placeholder="$"
            onChange={onChange}
            size="large" />
        )}
      </Form.Item>
    </>
  );
};
