import gql from "graphql-tag";

export const SaveSupplyBudgetComment = gql`
  mutation SaveSupplyBudgetComment(
    $supplyBudgetId: String!
    $comment: String!
    $id: String
  ) {
    saveSupplyBudgetComment(
      supplyBudgetId: $supplyBudgetId
      comment: $comment
      id: $id
    ) {
      id
      comment
    }
  }
`;
