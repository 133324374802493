import React from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import { ExpenseType, InvestorType } from "../../../../types";
import { expenseTypes } from "../../../../utils/maps";
import { formatToCurrency } from "../../../../utils";
import { InvoicesInput } from "../../../molecules";
import { getVerificationRecord } from "../../../templates/implementer/ProjectMonitoringTemplate/components/BudgetVerificationModal";
import { BreakdownTableType } from ".";
import { BudgetCommentsInput } from "../../../organisms";

export default (type?: BreakdownTableType, handleOnClickInvoices?: (budgetId: string, type: InvestorType) => void) => {
  const nameColumn = {
    title: "Concepto",
    dataIndex: "name",
    render: (name: string, record: any) => {
      if (record.expenseType === ExpenseType.HR) {
        return record.humanResource.fullName;
      }

      if (name === undefined) {
        const month = parseInt(record.month, 10);
        return format(month, "MMMM 'de' yyyy", { locale: es });
      }

      return name;
    }
  };

  const expenseTypeColumn = {
    title: "Tipo",
    dataIndex: "expenseType",
    render: (expenseType: ExpenseType) =>
      expenseTypes[expenseType]
  };

  const isVerifiableColumn = {
    title: "Comprobación",
    dataIndex: "isVerifiable",
    render: (isVerifiable: boolean) =>
      isVerifiable !== undefined
        ? isVerifiable
          ? "Comprobable"
          : "No comprobable"
        : null
  };

  const ficosecColumn = {
    title: "Presupuestado FICOSEC",
    dataIndex: "ficosec",
    render: (ficosec: number) =>
      ficosec !== undefined && formatToCurrency(ficosec)
  };

  const verifiableFicosecColumn = {
    title: "Comprobado FICOSEC",
    render: (_: any, record: any) => {
      if (record.ficosec !== undefined) {
        const verificationRecord = getVerificationRecord(record, InvestorType.FICOSEC);
        return (
          <InvoicesInput
            active={verificationRecord !== undefined}
            value={verificationRecord?.consumed}
            withInvoices={!!handleOnClickInvoices}
            onClickInvoices={() => handleOnClickInvoices?.(record.id, InvestorType.FICOSEC)} />
        );
      }

      return null;
    }
  };

  const coinvestorColumn = {
    title: "Presupuestado Co-inversión",
    dataIndex: "coinvestor",
    render: (coinvestor: number) =>
      coinvestor !== undefined && formatToCurrency(coinvestor)
  };

  const coinvestorVerifiableColumn = {
    title: "Comprobado Co-inversión",
    render: (_: any, record: any) => {
      if (record.coinvestor !== undefined) {
        const verificationRecord = getVerificationRecord(record, InvestorType.COINVESTOR);
        return (
          <InvoicesInput
            active={verificationRecord !== undefined}
            value={verificationRecord?.consumed}
            withInvoices={!!handleOnClickInvoices}
            onClickInvoices={() => handleOnClickInvoices?.(record.id, InvestorType.COINVESTOR)} />
        );
      }

      return null;
    }
  };

  const differenceColumn = {
    title: "Diferencia",
    render: (_: any, record: any) => {
      if (record.ficosec !== undefined && record.coinvestor !== undefined) {
        const budgeted = record.ficosec + record.coinvestor;
        const ficosecConsumed = getVerificationRecord(record, InvestorType.FICOSEC)?.consumed || 0;
        const coinvestorConsumed = getVerificationRecord(record, InvestorType.COINVESTOR)?.consumed || 0;
        const consumed = ficosecConsumed + coinvestorConsumed;
        const difference = budgeted - consumed;

        return formatToCurrency(difference);
      }

      return null;
    }
  };

  const observationColumn = {
    render: (_: any, record: any) => {
      if (record.ficosec !== undefined && record.coinvestor !== undefined) {
        return (
          <BudgetCommentsInput supplyBudgetId={record.id} />
        );
      }

      return null;
    }
  };

  if (type === BreakdownTableType.BREAKDOWN) {
    return [
      nameColumn,
      expenseTypeColumn,
      isVerifiableColumn,
      ficosecColumn,
      verifiableFicosecColumn,
      coinvestorColumn,
      coinvestorVerifiableColumn,
      differenceColumn,
      observationColumn
    ];
  }

  if (type === BreakdownTableType.SUBTOTAL) {
    return [
      {
        title: "Subtotales mensuales",
        dataIndex: "name"
      },
      ficosecColumn,
      verifiableFicosecColumn,
      coinvestorColumn,
      coinvestorVerifiableColumn,
      differenceColumn
    ];
  }

  if (type === BreakdownTableType.TOTAL) {
    return [
      {
        dataIndex: "name"
      },
      ficosecColumn,
      verifiableFicosecColumn,
      coinvestorColumn,
      coinvestorVerifiableColumn,
      differenceColumn
    ];
  }

  return [];
};
