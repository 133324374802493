import React from "react";

import { BudgetBreakdownTab } from "./components/BudgetBreakdownTab";
import { ASupply } from "../../../../types";
import { ProjectMonitoringTab } from "../../../pages/projects";
import { LoadingScreen } from "../../../atoms";
import { BudgetSummaryTab, FieldVisitTab } from "../../../shared/misc";
import { TechnicalMonitoringTab } from "../../../shared/misc/TechnicalMonitoringTab";

interface IProjectMonitoringTemplateProps {
  closed: boolean;
  currentTab: ProjectMonitoringTab;
  loading: boolean;
  supplies: ASupply[];
}

export const ProjectMonitoringTemplate: React.FC<IProjectMonitoringTemplateProps> = ({ closed, ...props }) => {
  if (props.currentTab === ProjectMonitoringTab.BUDGET_BREAKDOWN) {
    return <BudgetBreakdownTab disabled={closed} {...props} />;
  }

  if (props.currentTab === ProjectMonitoringTab.BUDGET_SUMMARY) {
    return <BudgetSummaryTab {...props} />;
  }

  if (props.currentTab === ProjectMonitoringTab.TECHNICAL_MONITORING) {
    return <TechnicalMonitoringTab {...props} />;
  }

  if (props.currentTab === ProjectMonitoringTab.FIELD_VISIT) {
    return <FieldVisitTab  {...props} implementer />;
  }

  return <LoadingScreen />;
};
