import gql from "graphql-tag";

export const AprovePreRequest = gql`
   mutation AprovePreRequest(
    $id: ID!
    $area: RequestArea
    $type: RequestType
   ) {
    approvePreRequest(
      data: {
        id: $id
        area: $area
        type: $type
      }
    ) {
      id
      area
      type
    }
  }
`;
