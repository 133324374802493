import React from 'react'
import { Form, Input, List } from 'antd'
import { FormComponentProps } from "antd/lib/form";
import format from 'date-fns/format'

interface RequestItemCommentFormOwnProps {
  field?: string;
  visible?: boolean;
  comment?: string;
  onChange: (changedFields: any) => void;
  reviews: any;
}

type RequestItemCommentFormProps = RequestItemCommentFormOwnProps & FormComponentProps;

class RequestItemCommentForm extends React.Component<RequestItemCommentFormProps> {
  public componentDidUpdate(prevProps: RequestItemCommentFormProps) {
    const { visible, form, comment } = this.props;
    if (!prevProps.visible && visible) {
      form.setFieldsValue({ comment });
    }

    if (prevProps.visible && !visible) {
      form.setFieldsValue({ comment: undefined });
    }
  }

  public renderListItem = (item: any[]) => {
    const reviewDate = format(parseInt(item?.review.createdAt, 10), "dd/MM/yyyy");
    const description = `Revisión ${item?.idx + 1} · ${reviewDate}`;
    return (
      <List.Item>
        <List.Item.Meta
          title={item?.fieldReview?.comment}
          description={description}
        />
      </List.Item>
    );
  };

  public render() {
    const { form, comment, reviews, field } = this.props;
    reviews.sort((a, b) => a.createdAt - b.createdAt)
    const fieldReviews: any[] = [];
    reviews?.forEach((review, idx) =>
      review.fields.forEach((fieldReview) =>
        fieldReview?.field === field && fieldReview?.comment && fieldReviews.push({ idx, review, fieldReview })));
    fieldReviews.sort((a, b) => a.idx > b.idx ? -1 : 1);

    return (
      <div>
        <Form id="requestItemCommentForm">
          <Form.Item>
            {form.getFieldDecorator("comment", {
              rules: [
                {
                  required: true,
                  message: "Por favor, ingresa el comentario."
                }
              ],
              initialValue: comment
            })(
              <Input size="large" placeholder="Comentario" />
            )}
          </Form.Item>
        </Form>
        <List
          locale={{ emptyText: "No hay comentarios" }}
          dataSource={fieldReviews}
          renderItem={this.renderListItem}
        />
      </div>
    );
  }
}

const onFieldsChange = (props: any, changedFields: any) =>
  props.onChange(changedFields);

export default Form.create({ name: "requestItemCommentForm", onFieldsChange })(RequestItemCommentForm);
