import React, { Component } from "react";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledFormContainer = styled.div`
  width: 500pt;
  padding: 50pt;
`;
