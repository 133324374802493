import React, { useState } from "react";

import { ASupply, InvestorType, IMonthlyBudget } from "../../../../../types";
import { BudgetVerificationModal } from "./BudgetVerificationModal";
import { TableContainer } from "../../../../pages/projects";
import { MonitoringBudgetBreakdownTable, BreakdownTableType } from "../../../../shared/misc";

interface IBudgetBreakdownTabProps {
  disabled: boolean;
  loading: boolean;
  supplies: ASupply[];
}

export interface IBudgetContextState {
  budgetId: string;
  type: InvestorType;
}

export const BudgetBreakdownTab: React.FC<IBudgetBreakdownTabProps> = ({ disabled, loading, supplies }) => {
  const [budgetContext, setSupplyContext] = useState<IBudgetContextState | undefined>(undefined);

  const openBudgetVerificationModal = (budgetId: string, type: InvestorType) =>
    setSupplyContext({ budgetId, type });

  const closeSupplyVerificationModal = () =>
    setSupplyContext(undefined);

  supplies.forEach((supply: ASupply) => supply.children = supply.monthlyBudget);

  const getSelectedBudget = () => {
    let budget: IMonthlyBudget | undefined;

    if (budgetContext !== undefined) {
      supplies.forEach((supply) => {
        if (budget !== undefined) {
          return;
        }

        budget = supply.monthlyBudget.find((monthBudget) => monthBudget.id === budgetContext.budgetId);
      });
    }

    return budget;
  };

  return (
    <>
      <BudgetVerificationModal
        formDisabled={disabled}
        visible={budgetContext !== undefined}
        budget={getSelectedBudget()}
        investorType={budgetContext?.type}
        onCancel={closeSupplyVerificationModal} />
      <TableContainer>
        <MonitoringBudgetBreakdownTable
          loading={loading}
          type={BreakdownTableType.BREAKDOWN}
          onOpenBudgetVerificationModal={openBudgetVerificationModal}
          dataSource={supplies} />
      </TableContainer>
    </>
  );
};
