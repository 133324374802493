// Dependency data
export * from "./AreaInput";
export * from "./ImplementerTypeInput";
export * from "./GovernmentLevelInput";
export * from "./GovernmentDependencyInput";
export * from "./CoordinationInput";
export * from "./InstitutionResponsibleNameInput";

// Institutional background
export * from "./ImplementerNameInput";
export * from "./ImplementerMissionInput";
export * from "./ImplementerVisionInput";
export * from "./SocialObjectiveInput";

// Responsible contact
export * from "./ContactNameInput";
export * from "./ContactChargeInput";
export * from "./ContactInstitutionInput";
export * from "./ContactPhoneNumberInput";
export * from "./ContactEmailInput";
export * from "./PersonTypeInput";

// Fiscal data
export * from "./PersonTypeInput";
export * from "./RFCInput";
export * from "./BusinessNameInput";
export * from "./SocialBehestInput";

// Fiscal address
export * from "./StreetInput";
export * from "./ExteriorNumberInput";
export * from "./InteriorNumberInput";
export * from "./ColonyInput";
export * from "./ZipCodeInput";
export * from "./CityInput";
export * from "./StateInput";

// Creation Docs
export * from "./CreationDocsInput";

// Board of directors (consejo directivo)
export * from "./DirectorNameInput";
export * from "./DirectorChargeInput";
