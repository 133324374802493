import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { Content } from "../../atoms";
import { FinancialCapabilityForm } from "../forms";
import { RequestFooter } from "../../organisms";
import { NewRequestContextProps, NewRequestMethods } from "../../NewRequestContext";
import { ReviewableProps } from '../../../types'
import { RequestReviewConsumer } from "../../RequestReviewContext";
import { UpdateImplementerProjects } from "../../../gql/mutations";
import { client } from "../../../gql/apollo";
import { disabledRequestInput } from "../../../utils"

type FinancialCapabilityProps = ReviewableProps & NewRequestContextProps & RouteComponentProps;

export const FinancialCapability: React.FC<FinancialCapabilityProps> = ({
  state: {
    projectId,
    projectsToCreate,
    projectsToUpdate,
    projectsToDelete,
    financialCapability,
    status,
    draft,
    checkFields,
    reviews
  },
  methods = {} as NewRequestMethods,
  history,
  reviewable,
  approval
}) => {
  const [updateImplementerProjects] = useMutation<any>(UpdateImplementerProjects, { client } as any);
  const [busy, setBusy] = useState(false);
  const disableByStatus: boolean = disabledRequestInput(status, draft);

  const handleOnClickNext = async () => {
    const toCreate = projectsToCreate
      .map((projectId) => financialCapability[projectId])
      .filter((project) => !!project);
    const toUpdate = projectsToUpdate.map((projectId) => financialCapability[projectId]);

    try {
      setBusy(true);
      await updateImplementerProjects({
        variables: {
          projectId,
          projectsToCreate: toCreate,
          projectsToUpdate: toUpdate,
          projectsToDelete
        }
      });
      setBusy(false);
      return history.push("proyecto");
    } catch (error) {
      // TODO: Report to bugsnag
      setBusy(false);
      message.error("Ocurrió un problema.");
    }
  };

  return (
    <RequestReviewConsumer>
      {({ reviewState }) => {
        return (
          <Content style={{ marginLeft: 245 }}>
            <FinancialCapabilityForm
              checkFields={checkFields}
              reviewState={reviewState}
              disabled={busy || reviewable || approval || disableByStatus}
              reviewable={reviewable}
              approval={approval}
              fields={financialCapability}
              addProject={methods.addFinancialCapabilityProject}
              removeProject={methods.removeFinancialCapabilityProject}
              saveInput={methods.saveFinancialCapabilityInput}
              reviews={reviews}
            />
            {reviewable || !approval && !disableByStatus && (
              <RequestFooter nextProps={{ onClick: handleOnClickNext, loading: busy }} />
            )}
          </Content>
        )
      }}
    </RequestReviewConsumer>
  );
};

export default withRouter(FinancialCapability);
