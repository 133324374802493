import gql from "graphql-tag";

export const UpdateWorktableAgreement = gql`
  mutation UpdateWorktableAgreement(
    $worktableAgreementId: String!,
    $description: String,
    $responsible: String,
    $deadline: DateTime,
    $documentsToCreate: [CreateAttachmentInput!],
    $documentsToDelete: [String!]
    $status: WorktableAgreementStatus
  ) {
    updateWorktableAgreement(
      worktableAgreementId: $worktableAgreementId,
      data : {
        description: $description
        responsible: $responsible
        deadline: $deadline
        documents: {
          create: $documentsToCreate
          delete: $documentsToDelete
        }
        status: $status
      }
    ) {
      id
      description
      responsible
      deadline
      documents {
        id
        fileName
        ref
      }
      status
    }
  }`;
