import React from "react";
import { Form, Input } from "antd";
import { FormSection } from "../../../../molecules";
import {
  RequestArea,
  RequestType,
  ImplementerFields
} from "../../../../../types/Request";
import { FormComponentProps } from "antd/lib/form";

interface ImplementerFormOwnProps {
  requestArea?: RequestArea;
  requestType?: RequestType;
  fields?: Partial<ImplementerFields>;
  reviewable?: boolean;
  approval?: boolean;
}

type ImplementerFormProps = ImplementerFormOwnProps & FormComponentProps;

export class InstitutionalBackgroundSection extends React.Component<ImplementerFormProps> {

  public constructor(props: ImplementerFormProps) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator },
      fields,
      reviewable,
      approval
    } = this.props;

    return (
      <FormSection title="Antecedentes institucionales">
        <Form.Item>
          {getFieldDecorator("implementerName", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa el nombre de la implementadora."
              }
            ],
            initialValue: fields?.implementerName
          })!(
            <Input
              placeholder="Nombre de la implementadora"
              size="large"
              disabled={reviewable || approval} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("implementerMission", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la misión de la implementadora."
              }
            ],
            initialValue: fields?.implementerMission
          })!(
            <Input.TextArea
              rows={3}
              placeholder="Misión de la implementadora"
              disabled={reviewable || approval} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("implementerVision", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la visión de la implementadora."
              }
            ],
            initialValue: fields?.implementerVision
          })!(
            <Input.TextArea
              rows={3}
              placeholder="Visión de la implementadora"
              disabled={reviewable || approval} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("socialObjective", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa el objetivo social relacionado al proyecto ."
              }
            ],
            initialValue: fields?.socialObjective
          })!(
            <Input.TextArea
              rows={3}
              placeholder="Objetivo social relacionado al proyecto"
              disabled={reviewable || approval} />
          )}
        </Form.Item>
      </FormSection>);
  }
}
