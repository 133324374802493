import React from "react";
import { Collapse } from "antd";
import { CollapsePanelProps } from "antd/lib/collapse";
import { differenceInCalendarMonths } from "date-fns";
import styles from "./styles.less";
import { SpecificObjective, Goal, Activity } from "../../../../types/Request";

interface ScheduleTableProps {
  disabled: boolean;
  reviewable: boolean;
  objectives: Array<Partial<SpecificObjective>>;
  goals: Array<Partial<Goal>>;
  activities: Array<Partial<Activity>>;
  projectDuration: string[] | number[];
  toggleMonth: (activityId: string, monthToToggle: number) => void;
}

interface ScheduleTableState {
  range: {
    earliestDate: {
      date?: Date | number;
      idx?: number;
    };
    latestDate: {
      date?: Date | number;
      idx?: number;
    };
    diff: number;
  };
}

class ScheduleTable extends React.Component<ScheduleTableProps, ScheduleTableState> {
  public state: ScheduleTableState = {
    range: {
      earliestDate: {
        date: undefined,
        idx: undefined
      },
      latestDate: {
        date: undefined,
        idx: undefined
      },
      diff: 1
    }
  };

  public componentDidMount() {
    const { activities } = this.props;
    const range = this.getMonthRange(activities);
    this.setState({ range });
  }

  public render() {
    const { objectives } = this.props;

    return (
      <>
        <this.TableHeader />
        <Collapse
          className={styles.objectiveCollapse}
          defaultActiveKey={objectives.map((objective) => objective.id!)}
        >
          {objectives.map((objective, idx) => (
            <this.ObjectivePanel key={objective.id} index={idx} objective={objective} />
          ))}
        </Collapse>
      </>
    );
  }

  private TableHeader: React.FC = () => {
    const { range: { diff } } = this.state;
    const months = new Array(diff || 0).fill(0);

    return (
      <div className={styles.tableHeader}>
        <div className={styles.activityHeading}>Actividad</div>
        {months.map((_, idx) => (
          <div className={styles.monthHeading}>
            <div>Mes</div>
            <div>{idx + 1}</div>
          </div>
        ))}
      </div>
    );
  }

  private ObjectivePanel:
    React.FC<{ index: number, objective: Partial<SpecificObjective> } & Partial<CollapsePanelProps>> =
    ({ objective, ...props }) => {
      const { goals } = this.props;
      const objectiveGoals = goals.filter((goal) => objective.goals!.includes(goal.id!));

      return (
        <Collapse.Panel
          {...props}
          showArrow={false}
          key={objective.id!}
          header={<div className={styles.objectiveHeader}>Objetivo específico #{props.index + 1}</div>}
        >
          {objectiveGoals.map(this.renderGoal)}
        </Collapse.Panel>
      );
    }

  private renderGoal = (goal: Partial<Goal>, idx: number) => {
    const { activities } = this.props;
    const goalActivities = activities.filter((activity) => goal.activities!.includes(activity.id!));

    return (
      <div className={styles.goalContainer}>
        <div className={styles.goalCell}>
          <div className={styles.goalTitleContainer}>Meta #{idx + 1}</div>
          <div className={styles.goalSummaryContainer}>{goal.description || "Sin descripción"}</div>
        </div>
        {this.renderActivityTable(goalActivities)}
      </div>
    );
  }

  private renderActivityTable = (activities: Array<Partial<Activity>>) => {
    const { toggleMonth, disabled } = this.props;
    const { range: { earliestDate, diff } } = this.state;
    const months = new Array(diff || 0).fill(0).map((_, idx) => earliestDate.idx! + idx);

    return (
      <div className={styles.activityTable}>
        {activities.map((activity, idx) => {
          return (
            <div className={styles.activityRow}>
              <div className={styles.activityNameCell}>{activity.description || `Actividad #${idx + 1}`}</div>
              {months.map((month) => (
                <div className={styles.activityCell} data-active={activity.months!.includes(month)}>
                  <div className={styles.cellTrigger} onClick={() => !disabled && toggleMonth(activity.id!, month)} />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  private getMonthRange = (activities: Array<Partial<Activity>>) => {
    const { projectDuration = [] } = this.props;
    const months: number[] = [];

    activities.map((activity) => {
      months.push(...activity.months!);
    });

    const startDate = new Date(projectDuration[0]);
    const endDate = new Date(projectDuration[1]);

    months.sort();
    const firstMonth = months[0];
    const lastMonth = months[months.length - 1];

    return {
      earliestDate: {
        date: new Date(0).setUTCMonth(firstMonth),
        idx: Math.abs(differenceInCalendarMonths(new Date(-1).setUTCMonth(0), startDate))

      },
      latestDate: {
        date: new Date(0).setUTCMonth(lastMonth),
        idx: lastMonth
      },
      diff: Math.abs(differenceInCalendarMonths(startDate, endDate) - 1)
    };
  }
}

export default ScheduleTable;
