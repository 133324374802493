import gql from "graphql-tag";

const WorkTableMembers = gql`
  query WorkTableMembers($id: ID!) {
    allWorkTableMembers(filter: { workTable_id: $id }) {
      id
    	fullName
    	institution
    	WorkTable {
        id
        title
      }
    }
  }
`;

export default WorkTableMembers;
