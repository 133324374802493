import React from "react";
import { ContentHeader } from "../../../../atoms";

interface IHeaderOwnProps {
  currentStep: string;
}

type HeaderProps = IHeaderOwnProps;

const Header: React.FC<HeaderProps> = ({ currentStep }) => {

  const getCurrentStep = () => {
    switch (currentStep) {
      case "general":
        return 1;
      case "miembros":
        return 2;
      case "actividades":
        return 3;
      default:
        return 0;
    }
  };

  return (
    <ContentHeader
      title="NUEVA MESA DE TRABAJO"
      subtitle={`PASO ${getCurrentStep()} DE 3 - ${currentStep.toUpperCase()}`} />
  );
};

export default Header;
