import React from "react";
import { Table } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { requestsTableColumns } from "./helpers";
import styles from "./styles.less";
import { PaginatedResult } from "../../requests/PendingRequestsTemplate";
import { Request } from "../../../../types";
import { PaginationConfig, SorterResult } from "antd/lib/table";

interface RequestsTemplateOwnProps {
  loading: boolean;
  onPaginationChange: (pagination: PaginationConfig, filters: Partial<Record<"id", string[]>>, sorter: SorterResult<{ id: string }>) => void;
  requests: PaginatedResult<Request>;
}

type RequestsTemplateProps =
  & RequestsTemplateOwnProps
  & RouteComponentProps;

const RequestsTemplate: React.FC<RequestsTemplateProps> = ({ history, loading, onPaginationChange, requests }) => {
  const goToDetails = (
    requestId: string = "",
    isDraft: boolean = false
  ) => {
    if (isDraft) {
      history.push(`/implementadora/solicitudes/${requestId}`);
    } else {
      history.push(`/implementadora/solicitudes/detalles/${requestId}`);
    }
  };

  // TODO: Define request shape
  const handleOnRowClick = (request: any) => {
    goToDetails(request?.id, request?.draft);
  };

  return (
    <div style={{ backgroundColor: "#FFF", padding: "25pt" }}>
      <Table
        loading={loading}
        rowClassName={() => styles.TableRow___clickable}
        onRowClick={handleOnRowClick}
        bodyStyle={{ minHeight: "400pt" }}
        onChange={onPaginationChange}
        pagination={{ total: requests?.count }}
        dataSource={requests?.items || []}
        columns={requestsTableColumns} />
    </div>
  );
};

export default withRouter(RequestsTemplate);
