import React from "react";
import { timestampToReadableDate } from ".";
import { Tag } from "antd";
import { preRequestStatusesMap } from "../../../../../utils/maps";
import { PreRequestStatus } from "../../../../../types";

const tagColorsMap: { [status in PreRequestStatus]: string } = {
  IN_PROGRESS: "orange",
  REJECTED: "red",
  APPROVED: "green"
};

export const preRequestsTableColumns = [
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (timestamp) => timestampToReadableDate(timestamp as number),
    sorter: true
  },
  {
    title: "Nombre de presolicitud",
    dataIndex: "projectName",
    key: "projectName",
    sorter: true
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    render: (status: PreRequestStatus) => (
      <Tag color={tagColorsMap[status]}>
        {preRequestStatusesMap[status]}
      </Tag>
    ),
    sorter: true
  },
];
