import React from "react";
import { Table, Card } from "antd";

import styles from "../styles.less";
import { SectionCard } from "../../../../organisms/UserLayout";
import { generalSummaryTableColumns, getSubtotalsPerType } from "../helpers";
import { IProcessedSupplyItem, SupplyItemsPerType } from "../types";

interface IGeneralSummaryTableProps {
  supplies: IProcessedSupplyItem[];
}

export const GeneralSummaryTable: React.FC<IGeneralSummaryTableProps> = (props) => {
  const { supplies = [] } = props;
  
  const suppliesPerType: SupplyItemsPerType = getSubtotalsPerType(supplies);

  const dataSource = Object
    .keys(suppliesPerType)
    .map((key) => ({
      expenseType: key,
      ficosecSubTotal: suppliesPerType[key].ficosecSubTotal,
      ficosecSubTotalPct: suppliesPerType[key].ficosecSubTotalPct,
      coInvestmentSubTotal: suppliesPerType[key].coInvestmentSubTotal,
      coInvestmentSubTotalPct: suppliesPerType[key].coInvestmentSubTotalPct
    }));

  return (
    <SectionCard>
      <Card.Meta title={<h2>Resumen general</h2>}/>
      <Table
        scroll={{ x: true }}
        pagination={false}
        className={`${styles.table} ${styles.BudgetPerType}`}
        columns={generalSummaryTableColumns}
        dataSource={dataSource} />
    </SectionCard>
  );
};
