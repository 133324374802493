import React from "react";
import { MonitoringListItem } from "./MonitoringListItem";

interface MonitoringListProps {
  monitorings?: any[];
  projectClosed?: boolean;
}

export const MonitoringList: React.FC<MonitoringListProps> = ({ monitorings, projectClosed = false }) => (
  <>
    {monitorings?.map((monitoring: any, index: any) => (
      <MonitoringListItem
        key={index}
        monitoring={monitoring}
        disabled={projectClosed} />
    ))}
  </>
);
