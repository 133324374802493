import styled from "styled-components";
import { Button } from "antd";

const mapImage = require("../../../../assets/map.png");

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentContainer = styled.div`
  padding-right: 50px;
  min-width: 480px;
  width: 50%;
`;

export const IllustrationContainer = styled.div`
  min-width: 480px;
  width: 50%;
  background-image: url(${mapImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const FormContainer = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  max-width: 480px;
`;

export const CTAContainer = styled.div`
  display: flex;

  & .ant-form-item {
    margin: 0;
  }
`;

export const CTAButton = styled(Button)`
  background-color: #8e1d1d;
  border: none;

  &:focus {
    background-color: #8e1d1d;
  }
`;

export const ExtraCTAContainer = styled.div`
  align-self: center;
  margin-left: 20px;
`;

export * from "./SignInForm";
export * from "./SignUpForm";
