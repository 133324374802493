import gql from "graphql-tag";

export const CreatePreRequest = gql`
  mutation createPreRequest(
    $estimatedBudget: Float!
    $projectDescription: String!
    $organizationDescription: String!
    $projectName: String!
  	$status: PreRequestStatus!
  ) {
    createPreRequest(
      data: {
        estimatedBudget: $estimatedBudget
        projectDescription: $projectDescription
        organizationDescription: $organizationDescription
        projectName: $projectName
        status: $status
      }
    ) {
      id
      estimatedBudget
      projectDescription
      organizationDescription
      projectName
      status
    }
  }
`;
