import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PageHeader, Button } from "antd";

const DetailsHeader: React.FC<RouteComponentProps> = (props) => {

  const onCreateNewWorktable = () => {
    const { history } = props;
    history.push("/mesas-de-trabajo/nueva/general");
  };

  const onGoBack = () => {
    window.history.back();
  };

  const renderCreateButton = () => {
    return (
      <Button
        icon="plus"
        type="default"
        shape="round"
        onClick={onCreateNewWorktable}>
        Agregar mesa de trabajo
      </Button>
    );
  };

  return (
    <PageHeader
      title="Mesas de trabajo"
      ghost={false}
      style={{ border: "1px solid rgb(235, 237, 240)" }}
      extra={renderCreateButton()}
      onBack={onGoBack}>
    </PageHeader>);
};

export default withRouter(DetailsHeader);
