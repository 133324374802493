import React from "react";
import { Modal as AntModal } from "antd";
import { ModalProps as AntModalProps } from "antd/lib/modal";

import styles from "./styles.less";

interface ModalOwnProps {
  noPadding?: boolean;
  centeredContent?: boolean;
  slimFooter?: boolean;
}

export type ModalProps = ModalOwnProps & AntModalProps;

const Modal: React.FC<ModalProps> = ({ noPadding, centeredContent, slimFooter, ...props }) => (
  <AntModal
    closable={false}
    centered
    {...props}
    className={[
      styles.modal,
      noPadding && styles.noPadding,
      centeredContent && styles.centeredModal,
      slimFooter && styles.slimFooter
    ].join(" ")}
  />
);

export default Modal;
