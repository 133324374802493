import React from "react";
import { Button, Icon } from "antd";
import { ButtonProps } from "antd/lib/button/button";

import { Footer } from "../../atoms";

interface RequestFooterProps {
  nextProps?: ButtonProps;
  sendProps?: ButtonProps;
  lastStep?: boolean;
}

const RequestFooter: React.FC<RequestFooterProps> = ({ nextProps, sendProps, lastStep = false }) =>
  <Footer>
    <Button
      {...nextProps}
      shape="round"
      type="primary"
    >
      Guardar
    </Button>
    {lastStep && (
      <Button
        {...sendProps}
        style={{ marginLeft: 10 }}
        shape="round"
        type="primary"
      >
        Enviar solicitud
        <Icon type="right-circle" theme="filled" />
      </Button>
    )}
  </Footer>;

export default RequestFooter;
