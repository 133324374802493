import React from "react";
import { Layout } from "antd";
import { LayoutProps  } from "antd/lib/layout";
import styled from "styled-components";

const { Content } = Layout;
const StyledContent = styled(Content)`
  background-color: #ECECEC;
  padding: 10pt;
`;

export const CustomContent: React.FC<LayoutProps> = (props) => {
  return <StyledContent {...props} />;
};
