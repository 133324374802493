import React from "react";
import { Upload, Button, Icon, Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { Supply } from "../../../../../types";
import { UploadFile } from "antd/lib/upload/interface";

interface IEquipmentBillsModalOwnProps {
  supply: Partial<Supply> | undefined;
  onChange: any | undefined;
  fileList: UploadFile[];
}

type IEquipmentBillsModalProps = ModalProps & IEquipmentBillsModalOwnProps;

export const EquipmentBillsModal: React.FC<IEquipmentBillsModalProps> = ({ fileList, onChange, ...props }) => {
  return (
    <Modal
      {...props}
      title="Cotización de equipo">
      <p>Agrega todas las cotizaciones relacionadas con este concepto.</p>
      <Upload
        onChange={onChange}
        fileList={fileList || []}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
          <Button
            type="dashed"
            style={{ width: "465px" }}>
            <Icon type="plus" /> Agregar cotización
          </Button>
      </Upload>
    </Modal>
  );
};
