import React, { Component } from "react";
import { Card } from "antd";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { Query, QueryResult } from "react-apollo";

import { PageHeader, ClasificationForm } from "./components";
import { PreRequest } from "../../../../gql/queries";
import { IPreRequest } from "../../../../types";
import Layout from "../../../organisms/Layout";
import { SectionCard } from "../../../organisms/UserLayout";
import { PreSubmissionStatusCard } from "../../../molecules";

type PreRequestDetailsPageProps =
  & RouteComponentProps
  & Pick<RouteComponentProps<{ preRequestId?: string }>, "match">;

const DescriptionContainer = styled.p`
  margin: 10pt 0pt;
`;

class PreRequestDetailsPage extends Component<PreRequestDetailsPageProps> {
  public constructor(props: RouteComponentProps) {
    super(props);
  }

  public render() {
    const { params } = this.props.match;
    const { preRequestId = "" } = params;

    return (
      <Layout>
        <Query
          query={PreRequest}
          variables={{ id: preRequestId }}>
          {({ data, error, loading = false }: QueryResult) => {

            if (error) {
              // @todo report to bugsnag
              // show a friendly error component
            }

            const preRequest: Partial<IPreRequest> = data?.preRequest || {};
            const {
              organizationDescription = "",
              projectDescription = ""
            } = preRequest;

            return (
              <>
                <PageHeader preRequestData={preRequest} />
                {preRequest.status === "REJECTED" ?
                  <PreSubmissionStatusCard
                    showDeleteOption={preRequest?.status === "REJECTED"}
                    loading={loading}
                    preRequest={preRequest as IPreRequest} />
                  : (
                    <SectionCard loading={loading}>
                      <Card.Meta title="Clasificación" />
                      <DescriptionContainer>
                        <ClasificationForm preRequest={preRequest} />
                      </DescriptionContainer>
                    </SectionCard>
                  )}
                <SectionCard loading={loading}>
                  <Card.Meta title="Acerca de la organización" />
                  <DescriptionContainer>
                    {organizationDescription}
                  </DescriptionContainer>
                </SectionCard>
                <SectionCard loading={loading}>
                  <Card.Meta title="Descripción del proyecto" />
                  <DescriptionContainer>
                    {projectDescription}
                  </DescriptionContainer>
                </SectionCard>
              </>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default withRouter(PreRequestDetailsPage);
