import React, { Context } from "react";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ICustomChangeEvent } from "./types";
import { IPreRequest } from "../../../types";

interface IDefaultValue {
  form: WrappedFormUtils;
  onFieldsChange: (arg: ICustomChangeEvent) => void;
  preRequest: Partial<IPreRequest>;
}

export const PreRequestFormContext: Context<IDefaultValue> = React.createContext({
      form: undefined,
      onFieldsChange: undefined,
      preRequest: undefined
    } as any);
