import gql from "graphql-tag";

export const RejectPreRequest = gql`
   mutation AprovePreRequest(
    $id: ID!
    $annotation: String
   ) {
    updatePreRequest(
      data: {
        id: $id
        status: REJECTED
        annotation: $annotation
      }
    ) {
      id
      status
      annotation
    }
  }
`;
