import React, { useState, useEffect } from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Input, Select, DatePicker, Col, Upload, Button } from "antd";
import { withRouter, RouteComponentProps } from "react-router";
import moment from "moment";
import { FormSection } from "../../../../../molecules";
import { Footer } from "../.";
import { getCustomFieldDecorator } from "./helpers";
import { ISectionGeneralState } from "../../types";
import { worktableAreas } from "../../../../../../utils/maps/workTableAreas";
import { WorkTableArea } from "../../../../../../types";
const { TextArea } = Input;
const { Option } = Select;

interface IFormGeneralState {
  workTableType: any;
  coordinator: any;
  title: string;
  meetingDate: string;
  meetingLocation: string;
  objective: string;
}

const workTableTypes = [
  { type: "TYPE_ONE", label: "TIPO UNO"},
  { type: "TYPE_TWO", label: "TIPO DOS"},
  { type: "TYPE_THREE", label: "TIPO TRES"}
];

interface IFormGeneralOwnProps {
  users?: any[];
  onCancel: () => void;
  data: ISectionGeneralState;
  onChange: (
    section: "general" | "members" | "activities",
    event: {target: { name: string, value: any}}) => void;
}

type FormGeneralProps = FormComponentProps & IFormGeneralOwnProps & RouteComponentProps;

const getConfirmedUsers = (users: any[] = []) => {
  return users.filter((user) => user.Profile !== null);
};

const FormGeneral: React.FC<FormGeneralProps> =
  ({ form, users = [], history, onCancel, location, data, onChange }) => {

    const { getFieldDecorator } = form;

    useEffect(() => {
      const { search } = location;
      if (search === "?review") {
        form.validateFields();
      }
    }, []);

    const onNext = async () => {
      // validateFields throws an exception if fields aren't valid
      await form.validateFields();
      history.push("integrantes");
    };

    const onChanging = (event: {target: { name: string, value: any}}) => {
      onChange("general", event);
    };

    const renderWorkTableAreaOptions = () => {
      return Object.keys(worktableAreas).map((keyArea, index) => (
        <Option key={keyArea} value={keyArea}>
          {worktableAreas[keyArea]}
        </Option>
      ));
    };

    const onWTAreaChange = (value: WorkTableArea) => {
      onChanging({
        target: {
          name: "workTableArea",
          value
        }
      });
    };

    return (
      <>
        <div style={{marginLeft: "40px"}}>
          <Col span={8} style={{ marginBottom: "4rem" }}>
            <Form layout="vertical">
              <FormSection title="INFORMACIÓN GENERAL">
                <Form.Item>
                  {getFieldDecorator(
                    "workTableArea",
                    {
                      initialValue: data.workTableArea,
                      valuePropName: "defaultValue"
                    })!(
                    <Select
                      size="large"
                      placeholder="Área de la mesa de trabajo"
                      onChange={onWTAreaChange}>
                        {renderWorkTableAreaOptions()}
                    </Select>)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(
                      "name",
                      getCustomFieldDecorator("name", data.name)
                    )!(
                    <Input
                      onChange={onChanging}
                      name="name"
                      placeholder="Nombre de la mesa de trabajo"
                      size="large"
                      disabled={false} />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(
                      "coordination",
                      getCustomFieldDecorator("coordination", data.coordination)
                    )!(
                      <Input
                        name="coordination"
                        onChange={onChanging}
                        placeholder="Coordinación"
                        size="large"
                        disabled={false} />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(
                      "technicalSecretary",
                      getCustomFieldDecorator("technicalSecretary", data.technicalSecretary)
                    )!(
                    <Input
                      name="technicalSecretary"
                      onChange={onChanging}
                      placeholder="Secretaría técnica"
                      size="large"
                      disabled={false} />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(
                      "objective",
                      getCustomFieldDecorator("objective", data.objective))!(
                    <TextArea
                      onChange={onChanging}
                      name="objective"
                      placeholder="Objetivo"
                      rows={8}
                      disabled={false} />)}
                </Form.Item>
                {data.workTableArea === "SECURITY_AND_JUSTICE" ?
                  <Form.Item>
                    {getFieldDecorator("attachments", {
                      valuePropName: "fileList",
                      initialValue: []
                    })(
                      // TODO:
                      // Save the files to a assets bucket
                      // Retrict file types
                      <Upload
                        action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                        headers={{ authorization: "authorization-text" }}>
                        <Button shape="round" icon="plus">
                          Logo de la mesa de trabajo (JPG, JPEG, PNG)
                        </Button>
                      </Upload>
                    )}
                  </Form.Item> : null}
              </FormSection>
            </Form>
          </Col>
        </div>
        <Footer
          onClickCancel={onCancel}
          onClickNext={onNext}
          lastStep={false} />
      </>
    );
};

export default Form.create<FormGeneralProps>
  ({ name: "customized_form_controls" })
  (withRouter(FormGeneral));
