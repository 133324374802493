import React, { useContext } from "react";
import { Steps, Alert, Descriptions } from "antd";
import styled from "styled-components";

import { SectionCard } from "../../../../organisms/ImplementerLayout";
import RequestDetailsTemplateContext from "../RequestDetailsTemplateContext";
import { RequestStatus } from "../../../../../types";

const RejectReasonContainer = styled(Descriptions)`
  margin-top: 10px;
`;

export const StatusSection = (props: any) => {
  const { request = {} } = useContext(RequestDetailsTemplateContext);

  const getCurrentStep = (status?: RequestStatus) => {
    if (!status) {
      return;
    }

    const steps: { [key in Exclude<RequestStatus, "REJECTED" | "PENDING_CORRECTION">]: number } = {
      IN_PROGRESS: 0,
      APPROVED: 1,
      FINISHED: 2
    };
    return steps[status];
  };

  const renderCardContents = () => {
    if (request?.status === "REJECTED") {
      return (
        <>
          <Alert message="SOLICITUD RECHAZADA" type="error" />
          <RejectReasonContainer
            layout="horizontal"
            column={1}>
            <Descriptions.Item
              label="Motivo del rechazo">
              {request.rejectReason}
            </Descriptions.Item>
          </RejectReasonContainer>
        </>
      );
    }

    return (
      <Steps progressDot current={getCurrentStep(request?.status)}>
        <Steps.Step
          title="En revisión" />
        <Steps.Step
          title="Aprobación" />
        <Steps.Step
          title="Convenio" />
      </Steps>
    );
  };

  return (
    <SectionCard title="Estado de solicitud">
      {renderCardContents()}
    </SectionCard>
  );
};
