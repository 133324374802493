import {
  IProcessedSupplyItem
} from "../../../../shared/misc/BudgetSummaryTable/types";

export const getBreadCrumbRoutes = (projectName = "", folio = '', projectID = '') => ([
  {
    path: "../../solicitudes",
    breadcrumbName: "Solicitudes"
  },
  {
    path: "#",
    breadcrumbName: projectName
  },
  {
    path: `../../proyectos/${projectID}`,
    breadcrumbName: `Número de proyecto: ${folio}`
  }
]);

export const getTotals = (suppliesWithSubtotals: IProcessedSupplyItem[] = []) => {
  let ficosecTotal: number = 0;
  let coInvestmentTotal: number = 0;

  suppliesWithSubtotals
    .forEach(({ ficosecSubTotal, coInvestmentSubTotal }) => {
      ficosecTotal += ficosecSubTotal;
      coInvestmentTotal += coInvestmentSubTotal;
    });

  return {
    ficosecTotal,
    coInvestmentTotal
  }
};
