import React, { useContext } from "react";
import { Select } from "antd";
import Form from "antd/lib/form/Form";
import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";
import { selectFilteringHelper } from "../../../../../utils";
import { implementerTypes } from "../../../../../utils/maps";
import { ImplementerType } from "../../../../../types";

export const ImplementerTypeInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile = {}
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const handleOnChange = (value: ImplementerType) => {
    onFieldsChange({
      key: "type",
      value: value
    });
  };

  return (
    <Form.Item label="Tipo de implementadora">
      {getFieldDecorator("type", {
        rules: [
          {
            required: true,
            message: "Por favor, selecciona el tipo de implementadora."
          }
        ],
        initialValue:  !!implementerProfile?.type ?
          implementerProfile?.type
          : undefined
      })(
        <Select
          disabled={isBusy}
          size="large"
          showSearch
          style={{ width: "100%" }}
          placeholder="Tipo de implementadora"
          optionFilterProp="children"
          onChange={handleOnChange}
          filterOption={selectFilteringHelper}>
          {Object.keys(implementerTypes)
            .map((it) => (
              <Select.Option
                value={it}
                key={it}>
                {implementerTypes[it]}
              </Select.Option>
            ))}
        </Select>
      )}
    </Form.Item>
  );
};
