import React from "react";
import { Form, InputNumber } from 'antd'
import { differenceInCalendarMonths } from "date-fns";
import { Supply } from "../../../../../types";
import { formatInput, parseInput, currency } from "../../../../../utils";

export interface IMonth {
  label: string;
  timestamp: number;
}

export const getMonthlyColumns = (props: any) => {
  const { projectDuration = [] } = props;
  if (projectDuration.length < 2) {
    return [];
  }

  const startDate: any = new Date(projectDuration[0]);
  const endDate: any = new Date(projectDuration[1]);

  // projectDuration contain the first and last dates as UTC strings
  const projectMonthsDuration = Math.abs(
    differenceInCalendarMonths(startDate, endDate)
    - 1);

  const allMonths: IMonth[] = [];

  const startDateWithFirstDay: Date = new Date(startDate.getFullYear(),
    (startDate.getMonth()), 1);

  for (let i = 0; i < projectMonthsDuration; i++) {
    startDateWithFirstDay.setMonth(i + 1);
    const monthTimestamp: number = startDateWithFirstDay.getTime();
    allMonths.push({ label: `${i + 1}`, timestamp: monthTimestamp });
  }

  const columns: any[] = [];
  allMonths.forEach((month) => {
    const columnsPerMonth = getMothCols({ ...props }, month);
    columnsPerMonth.forEach((column) => {
      columns.push(column);
    });
  });

  return columns;
};

const getMothCols = (args: any, month: IMonth) => {
  const {
    supplies = [],
    reviewable = false,
    approval = false,
    onRowChange,
    isBusy,
    disabled,
    checkFields
  } = args;
  const ficosecValuePath = `monthlyBudget.${month.timestamp}.ficosec`;
  const coinvestorValuePath = `monthlyBudget.${month.timestamp}.coinvestor`;

  return [
    {
      title: `FICOSEC MES ${month.label}`,
      dataIndex: ficosecValuePath,
      key: ficosecValuePath,
      render: (value: number = 0, supply: Partial<Supply>) => {
        if (supply.summary && supply.computedTotal !== undefined) {
          return null;
        }

        if (supply.summary) {
          const formatted = currency.format(value);
          return `$ ${formatted}`;
        }

        return (
          <Form.Item
            style={{marginBottom: 0 }}
            validateStatus={checkFields ? value ? "" : "error" : "" }>
            <InputNumber
              min={0}
              style={{ width: 150 }}
              defaultValue={value}
              formatter={formatInput}
              onBlur={(v) => onRowChange(supply.id!, ficosecValuePath, parseInput(v.target.value))}
              disabled={isBusy || reviewable || approval || disabled} />
          </Form.Item>
        );
      }
    },
    {
      title: `Co-inversión mes ${month.label}`,
      dataIndex: coinvestorValuePath,
      key: coinvestorValuePath,
      render: (value: number = 0, supply: Partial<Supply>) => {
        if (supply.summary && supply.computedTotal !== undefined) {
          return null;
        }

        if (supply.summary) {
          const formatted = currency.format(value);
          return `$ ${formatted}`;
        }

        return (
          <Form.Item
            style={{marginBottom: 0 }}
            validateStatus={checkFields ? value ? "" : "error" : "" }>
            <InputNumber
              min={0}
              style={{ width: 150 }}
              defaultValue={value}
              formatter={formatInput}
              onBlur={(v) => onRowChange(supply.id!, coinvestorValuePath, parseInput(v.target.value))}
              disabled={isBusy || reviewable || approval || disabled} />
          </Form.Item>
        );
      }
    }
  ];
};
