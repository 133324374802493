import { Agreement } from "./Agreement";
import { IWorkTable, Attachment } from "./";

export interface IWorktableMeeting {
  id: string;
  folio: number;
  agenda: string;
  agendaAttachments: Attachment[];
  name: string;
  subject: string;
  place: string;
  date: string;
  objective: string;
  status: WorktableMeetingStatus;
  worktable?: IWorkTable;
  agreements: Agreement[];
  documents?: Attachment[];
}

export type WorktableMeetingStatus =
  | "OPEN"
  | "CLOSED";

export const WorktableMeetingStatus: { [key in WorktableMeetingStatus]: WorktableMeetingStatus } = {
  OPEN: "OPEN",
  CLOSED: "CLOSED"
};
