import React from "react";
import { Form, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { withRouter, RouteComponentProps } from "react-router";
import { v4 as uuid } from "uuid";
import {
  SectionNoPublicChargeMembers,
  SectionWithPublicChargeMembers,
  SectionGuestMembers
} from "./components";
import {
  ISectionMembersState,
  MemberStateKey,
  Section,
  IMember,
  IEvent,
  MemberType
} from "../../types";
import { Footer } from "../.";
import styled from "styled-components";

const membersTypeMap: { [key: string]: MemberType } = {
  guests: "GUEST",
  withPublicCharge: "WITH_PUBLIC_CHARGE",
  withoutPublicCharge: "WITHOUT_PUBLIC_CHARGE"
};

const Container = styled.div`
  marginLeft: 380px;
  padding: 40px;
`;

interface IFormMembersOwnProps {
  data: ISectionMembersState;
  onCancel: () => void;
  onChange: (
    section: Section,
    event: { target: { name: string, value: any } }) => void;
}

type IFormMembersProps =
  & IFormMembersOwnProps
  & RouteComponentProps
  & FormComponentProps;

const FormMembers: React.FC<IFormMembersProps> = ({
  form,
  history,
  onCancel,
  onChange,
  data
}) => {

  const onAddNewMember = (memberType: MemberStateKey) => {
    const members: any[] = data[memberType] || [];
    const type = membersTypeMap[memberType];
    const newMember: IMember = {
      id: uuid(),
      fullName: "",
      type
    };
    members.push(newMember);
    const membersUpdated = Array.from(members);

    onChange("members", {
      target: {
        name: memberType,
        value: membersUpdated
      }
    });
  };

  const onRemoveSelectedMember =
    (memberType: MemberStateKey, selectedMemberIndex: number) => {
      const type = membersTypeMap[memberType];
      const emptyMember: IMember = {
        id: uuid(),
        fullName: "",
        type
      };
      const filteredMembers: IMember[] = (data[memberType] || [])
        .filter((member, index) => index !== selectedMemberIndex);

      data[memberType] = filteredMembers.length ? filteredMembers : [emptyMember];

      onChange("members", {
        target: {
          name: memberType,
          value: data[memberType]
        }
      });
    };

  const onChanging = (
    memberType: MemberStateKey,
    index: number = 0,
    event: IEvent) => {
    const { value, name } = event.target;
    const members: IMember[] = data[memberType] || [];
    members[index][name] = value;
    (members[index] as any).type = membersTypeMap[memberType];

    onChange("members", { target: { name: memberType, value: members } });
  };

  const onNext = async () => {
    await form.validateFields();
    history.push("actividades");
  };

  return (
    <>
      <Container>
        <Col span={24} style={{ marginBottom: "6rem" }}>
          <Form layout="vertical">
            <SectionWithPublicChargeMembers
              form={form}
              onChange={onChanging}
              members={data.withPublicCharge}
              onAddNewMember={onAddNewMember}
              onRemoveSelectedMember={onRemoveSelectedMember} />
            <SectionNoPublicChargeMembers
              form={form}
              onChange={onChanging}
              members={data.withoutPublicCharge}
              onAddNewMember={onAddNewMember}
              onRemoveSelectedMember={onRemoveSelectedMember} />
            <SectionGuestMembers
              form={form}
              onChange={onChanging}
              members={data.guests}
              onAddNewMember={onAddNewMember}
              onRemoveSelectedMember={onRemoveSelectedMember} />
          </Form>
        </Col>
      </Container>
      <Footer
        onClickCancel={onCancel}
        onClickNext={onNext}
        lastStep={false} />
    </>
  );
};

export default Form.create<IFormMembersProps>
  ({ name: "customized_form_controls" })
  (withRouter(FormMembers));
