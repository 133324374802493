import React from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";

import { NewRequestProvider } from "../../../NewRequestContext";
import { NewRequestTemplate } from "../../../templates/implementer-onboarding";

type NewRequestDetailPageProps =
  & RouteComponentProps
  & Pick<RouteComponentProps<{ requestId: string, step: string }>, "match">;

const NewRequestPage: React.FC<NewRequestDetailPageProps> = ({ match }) => {
  if (!match.params.step) {
    // TODO: This should be dynamic, should the administrator see a different
    // step first (for example, the implementer profile)
    return <Redirect to={`${match.url}/capacidad-financiera`} />;
  }

  return (
    <NewRequestProvider requestId={match.params.requestId}>
      <NewRequestTemplate currentStep={match.params.step} />
    </NewRequestProvider>
  );
};

export default withRouter(NewRequestPage);
