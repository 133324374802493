import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import styled from "styled-components";

interface IRedirectProps {
  label: string;
  to: string;
}

interface IErrorScreenProps {
  message?: string;
  redirect?: IRedirectProps;
}

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ErrorScreen: React.FC<IErrorScreenProps> = ({ message = "Ocurrió un error, por favor, recarga la página", redirect }) => (
  <CenteredContainer>
    <Typography.Paragraph>{message}</Typography.Paragraph>
    {redirect && (
      <Typography.Paragraph>
        <Link to={redirect.to}>{redirect.label}</Link>
      </Typography.Paragraph>
    )}
  </CenteredContainer>
);
