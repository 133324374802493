import React, { useContext } from "react";
import { MeetingFormContext } from "../../../MeetingFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";

export const WorktableInput = (props: any) => {
  const meetingFormContext = useContext(MeetingFormContext);
  const { form, worktable, onInputChange } = meetingFormContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item
      label="Mesa">
      {getFieldDecorator("worktable", {
        rules: [
          {
            message: "Por favor, ingresa la mesa de trabajo."
          }
        ],
        initialValue: worktable.name
      })(
        <Input
          disabled
          id="worktable"
          name="worktable"
          type="text"
          onChange={onChange} />
      )}
    </Form.Item>
  );
};
