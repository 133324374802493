import React from "react";
import { GetFieldDecoratorOptions } from "antd/lib/form/Form";
import styled from "styled-components";

import { ReviewControls, CommentButton } from "../components/molecules";
import { RequestReviewConsumer } from "../components/RequestReviewContext";

type GetFieldDecorator = <T extends object = {}>(id: keyof T, options?: GetFieldDecoratorOptions) =>
  (node: React.ReactNode) =>
    React.ReactNode;

interface ReviewableFieldObject {
  reviewable?: boolean;
  disabled?: boolean;
  fieldDecorator?: GetFieldDecorator;
  reviews: any;
  render: (id: string, options?: GetFieldDecoratorOptions & { relative?: boolean }) => ReviewableField | null;
}

type ReviewableField = (node: React.ReactElement) => React.ReactNode;

export type ReviewableFieldRenderer =
  (id: string, options?: GetFieldDecoratorOptions & { relative?: boolean }) => ReviewableField | null;

const CommentButtonContainer = styled.div`
  position: absolute;
  right: -20px;
  transform: translate(100%, -100%);
  white-space: nowrap;
`;

const reviewableField: ReviewableFieldObject = {
  disabled: false,
  reviewable: false,
  fieldDecorator: undefined,
  reviews: undefined,

  render: (id: string, options?: GetFieldDecoratorOptions & { relative?: boolean }): ReviewableField | null => {
    if (reviewableField.fieldDecorator !== undefined) {
      return (field: React.ReactElement) => (
        <div style={{ position: "relative" }}>
          {reviewableField.fieldDecorator!(id, options)(field)}
          {!reviewableField.reviewable && (
            <CommentButtonContainer>
              <CommentButton field={id} disabled={reviewableField.disabled}/>
            </CommentButtonContainer>
          )}
          {reviewableField.reviewable && (
            <RequestReviewConsumer>
              {({ reviewState }) => (
                <ReviewControls
                  fieldId={id}
                  reviews={reviewableField.reviews}
                  comment={((reviewState.state || {})[id] || { comment: undefined }).comment}
                  approved={((reviewState.state || {})[id] || { approved: undefined }).approved}
                  onReset={(reviewState.methods || { onReset: () => undefined }).onReset}
                  onApprove={(reviewState.methods || { onApprove: () => undefined }).onApprove}
                  onReject={(reviewState.methods || { onReject: () => undefined }).onReject}
                  onSaveComment={(reviewState.methods || { onSaveComment: () => undefined }).onSaveComment}
                  onDeleteComment={(reviewState.methods || { onDeleteComment: () => undefined }).onDeleteComment}
                  relative={(options || {}).relative}
                />
              )}
            </RequestReviewConsumer>
          )}
        </div>
      );
    }

    return null;
  }
};

export default reviewableField;
