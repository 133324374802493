import gql from "graphql-tag";

export const AttachClosingDocument = gql`
  mutation AttachClosingDocument(
    $id: String!
    $attachment: UpdateAttachmentInput!
  ) {
    attachClosingDocument(
      projectId: $id
      attachment: $attachment
    ) {
      id
    }
  }
`;
