import React from "react";

import { RoleConsumer } from "../../../RoleConsumer";
import { SectionCard } from "../../../organisms/ImplementerLayout";
import Layout from "../../../organisms/Layout";
import { ImplementerProfileForm } from "../../../organisms";

export default class ImplementerProfilePage extends React.Component {

  public render() {
    return (
      <RoleConsumer>
        {({ claims }) => {
          return (
            <Layout>
              <SectionCard title="Perfil de implementadora">
                <ImplementerProfileForm
                  implementerAccountId={claims?.user_id} />
              </SectionCard>
            </Layout>
          );
        }}
      </RoleConsumer>
    );
  }
}
