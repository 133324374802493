import gql from "graphql-tag";

export const UpdateHRProfiles = gql`
  mutation UpdateHRProfiles(
    $projectId: String!

    $profilesToCreate: [CreateHRInput!]!
    $profilesToUpdate: [UpdateHRInput!]!
    $profilesToDelete: [String!]!
  ) {
    createHumanResources(
      projectId: $projectId
      data: $profilesToCreate
    ) {
      id
      fullName
      position
      projectGoals {
        id
      }
      responsibilities
      incomeEstimation
      weeklyHours
      eduLevel
      specialtyArea
      yearsExp
      workExp
      resume {
        fileName
        ref
      }
    }

    updateHumanResources(
      data: $profilesToUpdate
    ) {
      id
      fullName
      position
      projectGoals {
        id
      }
      responsibilities
      incomeEstimation
      weeklyHours
      eduLevel
      specialtyArea
      yearsExp
      workExp
      resume {
        id
        fileName
        ref
      }
    }

    deleteHumanResources(ids: $profilesToDelete)
  }
`;
