import { CreateDraftRequest } from "../../../../../gql/mutations";
import { PendingRequests, PreRequests } from "../../../../../gql/queries";
import { client } from "../../../../../gql/apollo";

export const createDraftRequest = async (preRequestId: string) => {
  await client.mutate({
    mutation: CreateDraftRequest,
    variables: { preRequestId },
    refetchQueries: [
      { query: PendingRequests },
      { query: PreRequests }
    ]
  });
};
