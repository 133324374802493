import React, { useContext, useState } from "react";
import { Button as ANTButton } from "antd";
import { ButtonProps } from "antd/lib/button/button";
import styled from "styled-components";
import { NewRequestContext } from "../NewRequestContext";
import { CommentsModal, FieldReview } from "./CommentsModal";

interface ICommentButtonProps {
  field: string;
}

type CommentButtonProps = ICommentButtonProps & ButtonProps;

interface IButtonProps {
  count: number;
}

const Button = styled(ANTButton)`
  float: right;
  margin-left: 10px;

  &, &:hover, &:focus, &:active {
    color: ${({ count }: IButtonProps) => count > 0 && "#FFFFFF"};
    background-color: ${({ count }: IButtonProps) => count > 0 && "#B94C65"};
  }
`;

export const CommentButton: React.FC<CommentButtonProps> = ({ field, ...buttonProps }) => {
  const [modalState, setModalState] = useState({ visible: false });
  const requestContext = useContext(NewRequestContext);
  const { state } = requestContext;

  if (!state) {
    return null;
  }

  const reviewsLength = state.reviews?.length || 0;

  if (reviewsLength === 0) {
    return null;
  }

  const latestReview = state.reviews[reviewsLength - 1];
  const latestReviewsComments = latestReview.fields?.filter((field) => field.comment);
  const fieldComments = latestReviewsComments.filter((comment) => comment.field === field);
  const fieldReviews: FieldReview[] = [];
  state.reviews?.forEach((review, idx) =>
    review.fields.forEach((fieldReview) =>
      fieldReview.field === field && fieldReview.comment && fieldReviews.push({ idx, review, fieldReview })));

  const toggleModal = () =>
    setModalState({ visible: !modalState.visible });

  return (
    <>
      <CommentsModal
        reviews={fieldReviews}
        visible={modalState.visible}
        onCancel={toggleModal} />
      <Button
        size="large"
        icon="message"
        {...buttonProps}
        count={fieldComments.length}
        onClick={toggleModal} />
    </>
  )
};
