import React from "react";
import { Table, Modal as ANTModal, message } from "antd";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import { withRouter, RouteComponentProps } from "react-router";
import { getColumns, removeRequest } from "./helpers";
import { Request } from "../../../../types";

interface PendingRequestsTemplateState {
  reviewModal: {
    requestId?: string;
    visible: boolean;
  };
  busy: boolean;
}

export interface PaginatedResult<E> {
  items: E[];
  count: number;
}

interface PendingRequestsTemplateOwnProps {
  loading: boolean;
  onPaginationChange: (pagination: PaginationConfig, filters: Partial<Record<"id", string[]>>, sorter: SorterResult<{ id: string }>) => void;
  requests: PaginatedResult<Request>;
}

type PendingRequestsTemplateProps = PendingRequestsTemplateOwnProps & RouteComponentProps;

class PendingRequestsTemplate extends React.Component<PendingRequestsTemplateProps, PendingRequestsTemplateState> {
  public state: PendingRequestsTemplateState = {
    busy: false,
    reviewModal: {
      requestId: undefined,
      visible: false
    }
  };
  public render() {
    const { reviewModal } = this.state;
    const { loading, onPaginationChange, requests } = this.props;

    const columns = getColumns({
      onReview: this.onReviewRequest, // TODO: Change back to opening modal once its contents are defined
      onDeleteRequest: this.handleOnDeleteRequest
    });

    return (
      <div>
        {/* <Modal
          title="Resumen del proyecto"
          centeredContent
          visible={reviewModal.visible}
          onCancel={this.onCancelReview}
          footer={[
            <Button shape="round" type="primary" onClick={this.onReviewRequest}>Comenzar revisión</Button>,
            <Button shape="round" type="link" onClick={this.onCancelReview}>Cancelar</Button>
          ]}>
          Hey
        </Modal> */}
        <Table
          loading={loading}
          onChange={onPaginationChange}
          columns={columns}
          pagination={{ total: requests?.count }}
          dataSource={requests?.items}
          scroll={{ x: "auto" }} />
      </div>
    );
  }

  private handleOnDeleteRequest = (requestId = "") => {
    const removeRequest = this.removeRequest;
    ANTModal.confirm({
      title: "¿Estás seguro de que deseas eliminar la solicitud?",
      content: "No podrás revertir esta acción.",
      okText: "Eliminar",
      cancelText: "Cancelar",
      onOk: async () => {
        await removeRequest(requestId);
      }
    });
  }

  private removeRequest = async (requestId = "") => {
    try {
      this.toggleBusy();
      const result: any = await removeRequest(requestId);
      if (result?.data?.deleteRequest === false) {
        message.error("No se pudo eliminar la solicitud");
      } else {
        message.success("Solicitud eliminada.");
      }
    } catch (error) {
      message.error("Ocurrió un error.");
    } finally {
      this.toggleBusy();
    }
  }

  private onClickReviewRequest = (requestId: string) =>
    this.setState({ reviewModal: { requestId, visible: true } })

  private onCancelReview = () =>
    this.setState({ reviewModal: { requestId: undefined, visible: false } })

  private onReviewRequest = (requestId: string) =>
    this.props.history.push(`/solicitudes/${requestId}/implementadora`)

  private toggleBusy = () => this.setState((prevState) => ({
    busy: !prevState.busy
  }));
}

export default withRouter(PendingRequestsTemplate);
