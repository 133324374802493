import React from "react";
import styled from "styled-components";

import { MonitoringTab, Layout } from "../../organisms";
import { InMonitoringTable, ConcludedTable } from "./monitoring";
import { MonitoringStatus } from "../../organisms/MonitoringTab";

interface MonitoringTemplateProps {
  currentTab: MonitoringStatus;
}

const TableContainer = styled.div`
  padding: 0px 140px;
  background: #ffffff;
`;

const MonitoringTemplate: React.FC<MonitoringTemplateProps> = ({ currentTab }) => {
  const tabMap: { [key in MonitoringStatus]: React.ElementType } = {
    "en-monitoreo": InMonitoringTable,
    "concluidos": ConcludedTable
  };

  const CurrentTabTable = tabMap[currentTab];

  return (
    <Layout>
      <MonitoringTab currentTab={currentTab} />
      <TableContainer>
        <CurrentTabTable />
      </TableContainer>
    </Layout>
  );
}

export default MonitoringTemplate;
