import gql from "graphql-tag";

export const UpdateRiskManagement = gql`
  mutation UpdateRiskManagement(
    $projectId: String!

    $risksToCreate: [CreateRiskInput!]!
    $risksToUpdate: [UpdateRiskInput!]!
    $risksToDelete: [String!]!

    $assumptionsToCreate: [CreateAssumptionInput!]!
    $assumptionsToUpdate: [UpdateAssumptionInput!]!
    $assumptionsToDelete: [String!]!
  ) {
    createRisks(
      projectId: $projectId
      data: $risksToCreate
    ) {
      id
      identification
      probability
      impact
      mitigationStrategy
    }

    updateRisks(
      data: $risksToUpdate
    ) {
      id
      identification
      probability
      impact
      mitigationStrategy
    }

    deleteRisks(ids: $risksToDelete)

    createAssumptions(
      projectId: $projectId
      data: $assumptionsToCreate
    ) {
      id
      identification
      impact
      actions
    }

    updateAssumptions(
      data: $assumptionsToUpdate
    ) {
      id
      identification
      impact
      actions
    }

    deleteAssumptions(ids: $assumptionsToDelete)
  }
`;
