import React from "react";
import {
  Button,
  Empty,
  Spin,
  Table,
  PageHeader
} from "antd";
import { format } from "date-fns";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container } from "../../atoms";

type MemorandumKind = "PLENARY_MEETING";

const MemorandumKindMap = {
  PLENARY_MEETING: "Reunion Plenaria"
};

interface MemorandumsTemplateOwnPProps {
  loading: boolean;
  data: any[];
}

type MemorandumsTemplateProps = MemorandumsTemplateOwnPProps & RouteComponentProps;

class MemorandumsTemplate extends React.Component<MemorandumsTemplateProps> {

  private memorandumsColumns = [
    {
      title: "ID",
      dataIndex: "folio",
      key: "folio",
      render: (folio: number) => (
        <div style={{
          textAlign: "center",
          color: "#8E1D1D"
        }}>
          {folio}
        </div>
      )
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (kind: MemorandumKind) => (
        <div>
          {MemorandumKindMap[kind]}
        </div>
      )
    },
    {
      title: "Fecha",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: any) => {
        const date = format(startDate, "DD/MM/YY hh:mm aa");
        return `${date}`;
      }
    },
    {
      title: "Dirigido",
      dataIndex: "addressedTo",
      key: "addressedTo"
    },
    {
      title: "Asunto",
      dataIndex: "subject",
      key: "subject"
    },
    {
      render: () => (
        <>
          <Button style={{ margin: "0 4px" }} icon="edit" shape="circle" size="large" />
          <Button style={{ margin: "0 4px" }} icon="delete" shape="circle" size="large" />
        </>
      )
    }
  ];

  public render() {
    const { history, loading, data } = this.props;
    const WorkTableMemorandums = data || [];

    return (
      <Container>
        <PageHeader
          style={{
            borderBottom: "1px solid rgb(235, 237, 240)",
          }}
          title="Bitácoras de Minutas" />
        <this.EmptyView
          loading={loading}
          empty={WorkTableMemorandums.length === 0}>
          <div style={{ backgroundColor: "#ffffff" }}>
            <Table
              pagination={false}
              columns={this.memorandumsColumns}
              dataSource={WorkTableMemorandums}
              onRow={(record, idx) => ({
                onClick: (event) => history.push(`/minutas/${record.id}`)
              })}
            />
          </div>
        </this.EmptyView>
      </Container>
    );
  }

  private EmptyView: React.FC<{ loading: boolean, empty: boolean }> = ({ loading, empty, children }) => {
    if (loading) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spin />
        </div>
      );
    }

    if (empty) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Empty
            image={require("../../../assets/empty_agreements.png")}
            imageStyle={{ height: 81 }}
            description={
              <div style={{ fontSize: 16, marginTop: 20 }}>
                No se han registrado minutas
              </div>
            }>
          </Empty>
        </div>
      );
    }

    return <>{children}</>;
  }

}

export default withRouter(MemorandumsTemplate);
