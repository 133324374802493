import gql from "graphql-tag";

const Memorandum = gql`
  query Memorandum($id: ID!) {
    Memorandum(id: $id) {
      id
      folio
      title
      addressedTo
      subject
      startDate
      type
      location
      MemorandumNonMembers {
        User {
          id
          fullName
        }
      }
      MemorandumPublicMembers {
        User {
          id
          fullName
        }
      }
      MemorandumGuestMembers {
        User {
          id
          fullName
        }
      }
    }
  }
`;

export default Memorandum;
