import React, { useState } from "react";
import { message } from "antd";
import { Redirect, useHistory } from "react-router-dom";
import firebase from "firebase";

import {
  MainContainer,
  ContentContainer,
  IllustrationContainer,
  FormContainer,
  SignInForm
} from "./components";
import { bugsnagClient } from "../../../bugsnag";
import { RoleConsumer } from "../../RoleConsumer";
import { getSignInRedirectPath } from "../../ProtectedRoute";

const ficosecLogo = require("../../../assets/ficosec_logo.svg");

export const SignInTemplate: React.FC = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false);

  const handleOnSignInAttempt = async (emailAddress: string, password: string) => {
    try {
      setBusy(true);
      const user = await firebase.auth()
        .signInWithEmailAndPassword(emailAddress, password);
      const tokenResult = await user.user?.getIdTokenResult();
      const redirectPath = getSignInRedirectPath(tokenResult?.claims?.role);
      history.push(redirectPath);
    } catch (error) {
      const errorCode = error.code;
      // We catch either invalid email or wrong password for
      // security purposes.
      if (
        errorCode === "auth/wrong-password"
        || errorCode === "auth/invalid-email"
        || errorCode === "auth/user-not-found"
      ) {
        message.error("Las credenciales proporcionadas son incorrectas.");
      } else {
        message.error("Ocurrió un error.");
      }

      bugsnagClient.notify(error, {
        beforeSend: (report) => {
          report.metaData = { error: JSON.stringify(error) };
          report.errorClass = "SignIn";
          report.groupingHash = report.errorClass;
        }
      });
    } finally {
      setBusy(false);
    }
  };

  return (
    <RoleConsumer>
      {({ isSignedIn, claims }) => {
        if (isSignedIn) { // navigate to the desired route
          const query = new URLSearchParams(location.search);
          const defaultRedirectPath = getSignInRedirectPath(claims?.role);
          const redirectPath = query.get("redirect") || defaultRedirectPath;
          return <Redirect to={redirectPath} />;
        }

        return (
          <MainContainer>
            <ContentContainer>
              <FormContainer>
                <img src={ficosecLogo} />
                <h1 style={{ fontSize: "30pt", fontWeight: "bold" }}>Bienvenido</h1>
                <SignInForm
                  loading={busy}
                  onSignInAttempt={handleOnSignInAttempt}
                />
              </FormContainer>
            </ContentContainer>
            <IllustrationContainer />
          </MainContainer>
        );
      }}
    </RoleConsumer>
  );
};
