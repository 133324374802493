import gql from "graphql-tag";

export const TechnicalMonitoringEntry = gql`
  query TechnicalMonitoringEntry($activityId: String!, $monitoringEntryId: String!) {
    activityMonitoringEntry(
      monitoringEntryId: $monitoringEntryId
      activityId: $activityId
    ) {
      id
      plannedActivities
      activitiesDone
      hoursInvested
    }

    activityBeneficiaryMonitoringEntries(
      monitoringEntryId: $monitoringEntryId
      activityId: $activityId
    ) {
      id
      obtained
      activityBeneficiary {
        id
      }
    }

    qnIndicatorMonitoringEntries(
      monitoringEntryId: $monitoringEntryId
      activityId: $activityId
    ) {
      id
      activityQuantitativeIndicator {
        id
      }
      documents {
        id
        ref
        fileName
      }
    }

    qxIndicatorMonitoringEntries(
      monitoringEntryId: $monitoringEntryId
      activityId: $activityId
    ) {
      id
      activityQualitativeIndicator {
        id
      }
      documents {
        id
        ref
        fileName
      }
    }
  }
`;
