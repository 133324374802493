import React from "react";
import { Card, Typography } from "antd";

import { ImplementerProfileForm } from "../../organisms";
import {
  ImplementerProfileState,
  InputChangeSignature
} from "../../pages/implementer-onboarding/ImplementerOnboardingPage";

interface ProfileTemplateProps {
  state: Partial<ImplementerProfileState>;
  implementerName: string;
  onInputChange: InputChangeSignature;
  onNextStep: (currentStep: number) => void;
}

class ProfileTemplate extends React.Component<ProfileTemplateProps> {
  public render() {
    const { state, implementerName, onInputChange, onNextStep } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          top: 100,
          left: "50%",
          transform: "translateX(-50%)"
        }}
      >
        <Card style={{ padding: "60px 180px", marginBottom: 100, width: 800 }}>
          <div style={{ textAlign: "center", marginBottom: 50 }}>
            <Typography.Title level={3}>Bienvenido, {implementerName}</Typography.Title>
            <Typography style={{ fontSize: 20 }}>
              Ya casi está lista tu cuenta, sólo unas preguntas más.
            </Typography>
          </div>
          <ImplementerProfileForm
            profile={state}
            onFieldsChange={onInputChange}
            onNextStep={onNextStep}
          />
        </Card>
      </div>
    );
  }
}

export default ProfileTemplate;
