export const formatInput = (value?: string | number) =>
  `$ ${value || ""}`
    .replace(/([A-Za-z])+/g, "")
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

export const parseInput = (value?: any) => {
  const parsed = Number(String(value).replace(/\$\s?|(,*)/g, ""));

  if (!Number(parsed)) {
    return 0;
  }

  if (!isFinite(parsed)) {
    return Number.MAX_SAFE_INTEGER;
  }

  return parsed;
};

export const formatToCurrency = (amount: number) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(amount);
};

export const currency = new Intl.NumberFormat();
