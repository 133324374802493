import React from "react";
import { Typography, message } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { FieldVisitForm } from "./components";
import { client } from "../../../../gql/apollo";
import { SaveFieldVisit } from "../../../../gql/mutations";
import { FieldVisit } from "../../../../gql/queries";
import { LoadingScreen, ErrorScreen } from "../../../atoms";

interface IFieldVisitTabProps {
  implementer?: boolean;
}

export const FieldVisitTab: React.FC<IFieldVisitTabProps> = ({ implementer }) => {
  const { projectId, monitoringEntry } = useParams();
  const { loading, data, error } = useQuery(FieldVisit, { variables: { projectId, monitoringEntry }, client } as any);
  const [saveFieldVisit] = useMutation<any>(SaveFieldVisit, { client } as any);

  if (error) {
    // TODO: Report to bugsnag
    return <ErrorScreen message="Ocurrió un problema, ya se ha reportado." />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  const handleOnSubmitForm = async (values: any, setBusy: (busy: boolean) => void) => {
    setBusy(true);
    try {
      await saveFieldVisit({
        variables: {
          id: data?.fieldVisit?.id,
          projectId,
          monitoringEntry,
          ...values
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: FieldVisit,
          variables: { projectId, monitoringEntry }
        }]
      });
      message.success("Se guardaron los datos correctamente.");
    } catch (error) {
      // TODO: Report to bugsnag
      console.error(error);
      message.error("Ocurrió un problema al guardar los datos.");
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      <Typography.Paragraph>
        Cuestionario para hacer en el lugar de implementación de una actividad.
      </Typography.Paragraph>
      <FieldVisitForm
        readonly={implementer}
        fields={data?.fieldVisit}
        onSubmit={handleOnSubmitForm} />
    </>
  );
};
