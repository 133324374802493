import gql from "graphql-tag";
import { client } from "../../apollo";

interface IData {
  id: string;
  folio: string;
  title: string;
  addressedTo: string;
  subject: string;
  startDate: string;
  type: string;
  location: string;
  workTableAgreements_id: any;
  workTable_id: any;
  status: string;
}

export default async (data: IData) => {
  await client.mutate({
    mutation: gql`
      mutation NewMemorandum(
        $id: ID!,
        $folio: String!,
        $title: String!,
        $addressedTo: String!,
        $subject: String!,
        $startDate: String!,
        $type: String!,
        $location: String!,
        $workTableAgreements_id: ID!,
        $workTable_id: ID!,
        $status: String!
      ) {
        createMemorandum(
          id: $id,
          folio: $folio,
          title: $title,
          addressedTo: $addressedTo,
          subject: $subject,
          startDate: $startDate,
          type: $type,
          location: $location
        ) {
          id
        }

        updateWorkTableAgreement(id: $workTableAgreements_id, memorandum_id: $id) {
          id
        }

        updateWorkTable(id: $workTable_id, status: $status) {
          id
        }
      }
      `,
    variables: { ...data }
  });
};
