import React from "react";

import { RequestSidebar } from "../../organisms";

import {
  HRProfile,
  FinancialCapability,
  ProjectInfo,
  ProjectFile,
  Schedule,
  Budget,
  RiskManagement
} from "../../shared/templates";
import { NewRequestConsumer } from "../../NewRequestContext";
import { ContentHeader } from "../../atoms";
import { getStepTitle } from "./helpers";

interface NewRequestTemplateProps {
  currentStep: string;
}

class NewRequestTemplate extends React.Component<NewRequestTemplateProps> {
  private stepTemplates = {
    0: FinancialCapability,
    1: ProjectInfo,
    2: HRProfile,
    3: ProjectFile,
    4: Schedule,
    5: Budget,
    6: RiskManagement
  };

  private stepMap: { [key: string]: number } = {
    "capacidad-financiera": 0,
    "proyecto": 1,
    "recursos-humanos": 2,
    "ficha": 3,
    "cronograma": 4,
    "presupuesto": 5,
    "riesgos": 6
  };

  public render() {
    const { currentStep } = this.props;
    const step = this.stepMap[currentStep];
    const StepTemplate = this.stepTemplates[step];

    return (
      <div>
        <NewRequestConsumer>
          {(state) => {
            let stepsToSkip = 0;
            const type: string = state?.state?.implementer?.type || "";

            if (type === "GOVERNMENT" || type === "INTERNATIONAL") {
              stepsToSkip = 1;
            }
            const stepTitle = getStepTitle(step, stepsToSkip);

            return (<>
              <RequestSidebar
                {...state}
                stepsToSkip={stepsToSkip}
                currentStep={step} />
              <ContentHeader
                title={stepTitle.title}
                subtitle={stepTitle.subtitle} />
              <StepTemplate {...state} />
            </>);
          }}
        </NewRequestConsumer>
      </div>
    );
  }
}

export default NewRequestTemplate;
