import React from "react";
import { Button, Row, Col } from "antd";
import styled from "styled-components";

import { UserRole } from "../../types";
import { RoleConsumer } from "../RoleConsumer";
import { ReadOnlyInput } from "../atoms";
import { formatToCurrency } from "../../utils";

interface IInvoicesInputProps {
  active: boolean;
  value?: number;
  withInvoices: boolean;
  onClickInvoices: () => void;
}

interface IInvoicesButtonProps {
  active: boolean;
}

const InvoicesButton = styled(Button) <IInvoicesButtonProps>`
  color: ${({ active }) => active && "#ffffff"};
  background-color: ${({ active }) => active && "#8e1d41"};

  &:hover {
    color: ${({ active }) => active && "#ffffff"};
    background-color: ${({ active }) => active && "#8e1d41"};
    border-color: ${({ active }) => active && "#ffffff"};
  }
`;

export const InvoicesInput: React.FC<IInvoicesInputProps> = ({
  active = false,
  value = 0,
  withInvoices,
  onClickInvoices
}) => {
  const inputRowSpan = withInvoices ? 18 : 24;
  return (
    <Row gutter={10}>
      <Col span={inputRowSpan}>
        <ReadOnlyInput value={formatToCurrency(value)} disabled />
      </Col>
      {withInvoices && (
        <Col span={4}>
          <InvoicesButton
            active={active}
            icon="file-text"
            onClick={onClickInvoices} />
        </Col>
      )}
    </Row>
  );
};
