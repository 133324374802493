import React from "react";
import { Table, Tag } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { PageHeader } from "./components";
import Layout, { Content } from "../../organisms/Layout";
import { PaginatedResult } from "../requests/PendingRequestsTemplate";

type WorkTableStatus =
  | "IN_PROGRESS"
  | "FINISHED";

const workTableStatusMap = {
  IN_PROGRESS: "En proceso",
  FINISHED: "Concluido"
};

const workTableStatusColorMap = {
  IN_PROGRESS: "orange",
  FINISHED: "green"
};

interface WorkTablesTemplateOwnProps {
  loading: boolean;
  onPaginationChange: (pagination: PaginationConfig, filters: Partial<any>, sorter: SorterResult<any>) => void;
  workTables: PaginatedResult<any>;
}

type WorkTablesTemplateProps = WorkTablesTemplateOwnProps & RouteComponentProps;

class WorkTablesTemplate extends React.Component<WorkTablesTemplateProps> {
  private columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: true
    },
    {
      title: "Coordinación",
      dataIndex: "coordination",
      key: "coordination",
      sorter: true
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      sorter: true
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      render: (status: WorkTableStatus) => (
        <Tag color={workTableStatusColorMap[status]}>
          {workTableStatusMap[status]}
        </Tag>
      ),
      sorter: true
    }
  ];

  public render() {
    const { history, loading, onPaginationChange, workTables } = this.props;
    return (
      <Layout noPadding>
        <PageHeader />
        <Content>
          <div style={{ background: "#ffffff" }}>
            <Table
              loading={loading}
              pagination={{ total: workTables?.count }}
              columns={this.columns}
              onChange={onPaginationChange}
              dataSource={this.getDataSource(workTables?.items || [])}
              onRow={(record) => ({
                onClick: () => history.push(`/mesas-de-trabajo/${record.id}`)
              })}
            />
          </div>
        </Content>
      </Layout>
    );
  }

  private getDataSource(workTables: any[]) {
    return workTables.map((workTable) => ({
      ...workTable
    }));
  }
}

export default withRouter(WorkTablesTemplate);
