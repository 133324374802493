import gql from "graphql-tag";

export const UnityBoardRequestApprovement = gql`
  mutation UnityBoardRequestApprovement (
    $requestId: ID!
    $annotations: String
    $unityApprovement: Boolean
    $request_Id: String!
    $status: String!
  ) {
    reviewRequest (
      data: {
        requestId: $requestId
        annotations: $annotations
        unityApprovement: $unityApprovement
      }) {
      id
      unityApprovement
      request {
        id
      }
    }
    
    updateRequest (
      data: {
        id: $request_Id
        status: $status
      }
    ) {
      id
      status
    }
  }
`;
