import gql from "graphql-tag";

export const TechnicalMonitoring = gql`
  query TechnicalMonitoring($projectId: String!) {
    projectActivities(projectId: $projectId) {
      id
      description
      beneficiaries {
        id
        goalValue
        type
        description
        attendance
      }
      quantitativeIndicators {
        id
        description
        verificationMethod
      }
      qualitativeIndicators {
        id
        description
        verificationMethod
      }
    }
  }
`;
