import React, { useContext } from "react";
import { Row, Col, Icon, Button, Skeleton } from "antd";
import { v4 as uuid } from "uuid";

import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import {
  DirectorChargeInput,
  DirectorNameInput
} from "./inputs";
import { DirectorsBoardMember } from "../../../../types";

interface BoardOfDirectorsSectionProps {
  members: {
    connected: DirectorsBoardMember[];
    toConnect: DirectorsBoardMember[];
    toDisconnect: DirectorsBoardMember[];
  };
  onAdd: (member: DirectorsBoardMember) => void;
  onRemove: (member: DirectorsBoardMember) => void;
  onChangeMember: (member: DirectorsBoardMember) => void;
}

interface DirectorsBoardMembersItemProps {
  member: DirectorsBoardMember;
  idx: number;
  onChange: (member: DirectorsBoardMember) => void;
  onRemove: (member: DirectorsBoardMember) => void;
}

const DirectorsBoardMembersItem = (props: DirectorsBoardMembersItemProps) => {
  const {
    member,
    idx,
    onChange,
    onRemove
  } = props;

  return (
    <Row
      gutter={16}
      key={member.id}>
      <Col span={12}>
        <DirectorNameInput
          onChange={({ key, value }) => {
            member.name = value;
            onChange(member);
          }}
          member={member} />
      </Col>
      <Col span={12}>
        <DirectorChargeInput
          onChange={({ key, value }) => {
            member.charge = value;
            onChange(member);
          }}
          member={member} />
      </Col>
      <div style={{
        position: "absolute",
        top: 10.5,
        right: -20
      }}>
        {idx > 0 ? // don't show for the first item
          <Icon
            type="delete"
            onClick={() => onRemove(member)} />
          : null}
      </div>
    </Row>
  );
}

export const BoardOfDirectorsSection = (props: BoardOfDirectorsSectionProps) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    implementerProfile,
    isLoadingData,
    isBusy
  } = implementerAccountContext;

  const {
    onAdd,
    onRemove,
    onChangeMember,
    members
  } = props;
  const { type: implementerType } = implementerProfile

  const allMembers = members
    .connected
    .concat(members.toConnect);

  if (isLoadingData) {
    return <Skeleton loading />;
  }

  if (implementerType === "CONSULTANT" || implementerType === "GOVERNMENT") {
    return null;
  }

  const emptyMember = { id: uuid(), charge: "", name: "" };

  return (
    <FormSection title="CONSEJO DIRECTIVO">
      {allMembers
        .map((m, index) => (
          <DirectorsBoardMembersItem
            onRemove={onRemove}
            key={m.id}
            onChange={onChangeMember}
            member={m}
            idx={index} />)
        )}
      <Button
        disabled={isBusy}
        type="link"
        icon="plus"
        onClick={() => onAdd(emptyMember)}>
        Agregar otro miembro
      </Button>
    </FormSection>
  );
};
