import gql from "graphql-tag";

const PendingRequests = gql`
  query PendingRequests(
    $skip: Int,
    $sortField: String,
    $sortDirection: String,
    $draft: Boolean = true
  ) {
    requests(
      status: IN_PROGRESS,
      skip: $skip,
      sortField: $sortField,
      sortDirection: $sortDirection,
      draft: $draft
    ) {
      items {
        id
        project {
          id
          name
        }
        preRequest {
          id
          # implementer
          organizationDescription
          projectName
          estimatedBudget
          projectDescription
          status
          area
          type
        }
        reviews {
          id
        }
        responsibleName
        responsiblePosition
        responsiblePhoneNumber
        responsibleEmailAddress
        status
        draft
        createdAt
      }
      count
    }
  }
`;

export default PendingRequests;
