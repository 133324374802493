import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Tabs, message } from "antd";
import C3Chart from "react-c3-component";
import styled from "styled-components";
import "c3/c3.css";

import { Content } from "../../atoms";
import { BudgetBreakdownTable, BudgetSummaryTable } from "../misc";
import { NewRequestContextProps, NewRequestMethods } from "../../NewRequestContext";
import { RequestFooter } from "../../organisms";
import { withRouter, RouteComponentProps } from "react-router";
import { Supply, ReviewableProps } from "../../../types/Request";
import expenseTypesMap from "../../.../../../utils/maps/expenseTypes";
import chartColors from "../../../utils/maps/chartColors";
import { RequestReviewConsumer } from "../../RequestReviewContext";
import { UpdateSupplies } from "../../../gql/mutations/projects";
import { client } from "../../../gql/apollo";
import { ApolloClient } from "apollo-boost";
import { disabledRequestInput } from '../../../utils'

type BudgetProps = ReviewableProps & NewRequestContextProps & RouteComponentProps;

const AddButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: right;
`;

export const Budget: React.FC<BudgetProps> = ({
  state: {
    projectId,
    activities,
    HRProfiles,
    supplies,
    projectDuration,
    status,
    draft,
    checkFields,
    reviews
  },
  history,
  methods = {} as NewRequestMethods,
  reviewable,
  approval
}) => {
  const [updateSupplies] = useMutation<any>(UpdateSupplies, { client } as any);

  const [suppliesToCreate, setSuppliesToCreate] = useState<string[]>([]);
  const [suppliesToUpdate, setSuppliesToUpdate] = useState<string[]>([]);
  const [suppliesToDelete, setSuppliesToDelete] = useState<string[]>([]);
  const [busy, setBusy] = useState(false);
  const disableByStatus: boolean = disabledRequestInput(status, draft);

  const { addSupply, saveSupplyInput, removeSupply, addEquipmentBill } = methods;
  const supplyValues = Object.values(supplies);

  const onAddSupply = () => {
    const supply = addSupply();
    setSuppliesToCreate([...suppliesToCreate, supply.id]);
  };

  const onSaveSupplyInput = (supplyId: string, field: string, value?: any) => {
    saveSupplyInput(supplyId, field, value);

    if (suppliesToCreate.includes(supplyId) || suppliesToUpdate.includes(supplyId)) {
      return;
    }

    setSuppliesToUpdate([...suppliesToUpdate, supplyId]);
  };

  const onRemoveSupply = (supplyId: string) => {
    removeSupply(supplyId);

    if (suppliesToCreate.includes(supplyId)) {
      return;
    }

    if (suppliesToUpdate.includes(supplyId)) {
      const newSuppliesToUpdate = [...suppliesToUpdate].filter((supply) => supply !== supplyId);
      setSuppliesToUpdate(newSuppliesToUpdate);
    }

    setSuppliesToDelete([...suppliesToDelete, supplyId]);
  };

  const handleOnClickNext = async () => {
    const toCreate = suppliesToCreate
      .map((supplyId) => {
        // TODO: Clean up
        const supply = supplies[supplyId];
        if (supply) {
          return {
            ...supply,
            monthlyBudget: Object.keys(supply.monthlyBudget || {}).map((month: string) => ({
              month,
              coinvestor: supply.monthlyBudget?.[month]?.coinvestor || 0,
              ficosec: supply.monthlyBudget?.[month]?.ficosec || 0
            }))
          };
        }

        return;
      })
      .filter((supply) => !!supply);

    const toUpdate = suppliesToUpdate.map((supplyId) => {
      // TODO: Clean up
      const { HRProfileId, ...supply } = supplies[supplyId];
      if (supply) {
        return {
          ...supply,
          monthlyBudget: Object.keys(supply.monthlyBudget || {}).map((month: string) => ({
            id: supply.monthlyBudget?.[month]?.id,
            month,
            coinvestor: supply.monthlyBudget?.[month]?.coinvestor || 0,
            ficosec: supply.monthlyBudget?.[month]?.ficosec || 0
          }))
        };
      }

      return;
    });

    try {
      setBusy(true);
      await updateSupplies({
        variables: {
          projectId,
          suppliesToCreate: toCreate,
          suppliesToUpdate: toUpdate,
          suppliesToDelete
        }
      });
      setBusy(false);
      return history.push("cronograma");
    } catch (error) {
      // TODO: Report to bugsnag
      setBusy(false);
      message.error("Ocurrió un problema al guardar el presupuesto.");
    }
  };

  const getChartColumns = (supplies: Array<Partial<Supply>>) => {
    const expenseTypes: { [type: string]: { total: number } } = {};
    supplies
      .map((supply) => {
        const supplyTotal = (supply.count || 0) * (supply.unitCost || 0);
        const expenseType = String(supply.expenseType);
        return expenseTypes[expenseType] = {
          ...expenseTypes[expenseType],
          total: (expenseTypes[expenseType] || { total: 0 }).total + supplyTotal
        };
      });

    return Object.keys(expenseTypes).map((expenseType) => {
      const total = Intl
        .NumberFormat("es-MX", { style: "currency", currency: "MXN" })
        .format(expenseTypes[expenseType].total);
      const title = `${expenseTypesMap[expenseType]} – ${total}`;

      if (expenseType === "undefined") {
        return [`Sin tipo de gasto – ${total}`, expenseTypes[expenseType].total];
      }

      return [title, expenseTypes[expenseType].total];
    });
  };

  const chartColumns = getChartColumns(supplyValues);
  const chartConfig = {
    data: {
      columns: chartColumns,
      type: "donut"
    },
    color: {
      pattern: Object.values(chartColors)
    },
    legend: {
      position: "right"
    }
  };

  return (
    <RequestReviewConsumer>
      {({ reviewState }) => (
        <Content style={{ marginLeft: 245 }}>
          <Tabs defaultActiveKey="BREAKDOWN" className="customTabs" style={{ marginTop: 74, marginBottom: 74 }}>
            <Tabs.TabPane tab="Desglose presupuestal" key="BREAKDOWN">
              <div style={{ padding: "20px 30px" }}>
                <AddButtonContainer>
                  {!(reviewable || approval) && (
                    <Button shape="round" icon="plus" disabled={disableByStatus} onClick={onAddSupply}>Agregar concepto</Button>
                  )}
                </AddButtonContainer>
                <BudgetBreakdownTable
                  reviews={reviews}
                  checkFields={checkFields}
                  disabled={disableByStatus}
                  reviewable={reviewable}
                  approval={approval}
                  busy={busy}
                  reviewState={reviewState}
                  HRProfiles={HRProfiles}
                  activities={activities}
                  supplies={supplyValues}
                  onRowChange={onSaveSupplyInput}
                  onRowRemove={onRemoveSupply}
                  addEquipmentBill={addEquipmentBill}
                  projectDuration={projectDuration} />
                <div style={{ marginTop: 40 }}>
                  <C3Chart config={chartConfig} />
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Resumen presupuestal" key="SUMMARY">
              <div style={{ padding: "20px 30px" }}>
                <BudgetSummaryTable supplies={supplyValues} />
              </div>
            </Tabs.TabPane>
          </Tabs>
          {reviewable || !approval && !disableByStatus && (
            <RequestFooter nextProps={{ onClick: handleOnClickNext, loading: busy }} />
          )}
        </Content>
      )}
    </RequestReviewConsumer>
  );
}

export default withRouter(Budget);
