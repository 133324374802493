import {
  HRProfile,
  FinancialCapability,
  ProjectInfo,
  ProjectFile,
  Schedule,
  Budget,
  RiskManagement
} from "../../../../shared/templates";

export const stepTemplates = {
  0: FinancialCapability,
  1: ProjectInfo,
  2: ProjectFile,
  3: Budget,
  4: Schedule,
  5: HRProfile,
  6: RiskManagement
};

export const stepMap: { [key: string]: number } = {
  "capacidad-financiera": 0,
  "proyecto": 1,
  "ficha": 2,
  "presupuesto": 3,
  "cronograma": 4,
  "recursos-humanos": 5,
  "riesgos": 6
};

export const getStepTitle = (currentStep: number, stepsToSkip: number): {
  title: string,
  subtitle: string
} => {
  const totalSteps = 7 - stepsToSkip;

  const stepTitles: {
    [key: number]: {
      title: string,
      subtitle: string
    }
  } = {
    0: {
      title: "Capacidad financiera gestora",
      subtitle: `Paso ${1 - stepsToSkip} de ${totalSteps}`
    },
    1: {
      title: "Información general del proyecto",
      subtitle: `Paso ${2 - stepsToSkip} de ${totalSteps}`
    },
    2: {
      title: "Ficha del proyecto",
      subtitle: `Paso ${3 - stepsToSkip} de ${totalSteps}`
    },
    3: {
      title: "Presupuesto",
      subtitle: `Paso ${4 - stepsToSkip} de ${totalSteps}`
    },
    4: {
      title: "Cronograma",
      subtitle: `Paso ${5 - stepsToSkip} de ${totalSteps}`
    },
    5: {
      title: "Perfil del Recurso Humano",
      subtitle: `Paso ${6 - stepsToSkip} de ${totalSteps}`
    },
    6: {
      title: "Supuestos y gestión de riesgos",
      subtitle: `Paso ${7 - stepsToSkip} de ${totalSteps}`
    }
  };

  const keyExists = Object.keys(stepTitles).some((key) =>
    parseInt(key, 10) === currentStep);

  if (keyExists) {
    return stepTitles[parseInt(currentStep.toString(), 10)];
  } else {
    return { title: "", subtitle: "" };
  }
};
