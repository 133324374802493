import React from "react";

import styles from "./styles.less";

const ficosecLogo = require("../../../assets/ficosec_logo.svg");

interface HeaderProps {
  simple?: boolean;
}

class Header extends React.Component<HeaderProps> {
  public render() {
    const { simple = false } = this.props;
    return (
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <img src={ficosecLogo} />
        </div>
        {!simple && (
          <div className={styles.navContainer}>
            <div className={styles.navItem}>Pre solicitudes</div>
            <div className={styles.navItem}>Solicitudes</div>
            <div className={styles.navItem}>Proyectos</div>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
