import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { withRouter, RouteComponentProps } from "react-router";
import { message } from "antd";
import styled from "styled-components";

import { Content } from "../../atoms";
import { ScheduleTable } from "../misc";
import { NewRequestContextProps } from "../../NewRequestContext";
import { RequestFooter } from "../../organisms";
import { UpdateActivity } from "../../../gql/mutations/activity";
import { client } from "../../../gql/apollo";
import { disabledRequestInput } from '../../../utils'

type ScheduleProps = { reviewable: boolean } & NewRequestContextProps & RouteComponentProps;

const GeneralObjectiveContainer = styled.div`
  margin: 0px 20px 20px 30px;
  padding: 20px;
  background: #ffffff;

  &:before {
    content: "Objetivo general: ";
    font-weight: bold;
  }
`;

const Container = styled(Content)`
  position: absolute;
  top: 64px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: 245px;
  overflow: scroll;
`;

const scheduleContainerStyle = {
  marginTop: 74,
  paddingTop: 30,
  display: "inline-block",
  minWidth: "100%"
};

export const Schedule: React.FC<ScheduleProps> = ({
  state: {
    generalObjective,
    specificObjectives,
    goals,
    activities,
    projectDuration,
    status,
    draft
  },
  methods,
  history,
  reviewable
}) => {
  const toggleActivityMonth = methods?.toggleActivityMonth;
  const [updateActivity] = useMutation<any>(UpdateActivity, { client } as any);
  const disableByStatus: boolean = disabledRequestInput(status, draft);

  const handleToggleMonth = async (activityId: string, monthToToggle: number) => {
    const fromState = toggleActivityMonth?.(activityId, monthToToggle);

    try {
      await updateActivity({
        variables: {
          id: activityId,
          months: fromState.entity.months
        }
      });
      fromState.save();
    } catch (err) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema la guardar la actividad");
    }
  };

  const handleOnClickNext = () => {
    history.push("recursos-humanos");
  };

  return (
    <Container>
      <div style={scheduleContainerStyle}>
        <GeneralObjectiveContainer>
          {generalObjective.summary}
        </GeneralObjectiveContainer>
        <ScheduleTable
          disabled={disableByStatus}
          reviewable={reviewable}
          objectives={Object.values(specificObjectives)}
          goals={Object.values(goals)}
          activities={Object.values(activities)}
          projectDuration={projectDuration}
          toggleMonth={handleToggleMonth} />
      </div>
      {!reviewable && !disableByStatus &&
        <RequestFooter nextProps={{ onClick: handleOnClickNext }} />}
    </Container>
  );
};

export default withRouter(Schedule);
