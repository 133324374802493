import gql from "graphql-tag";

export const Requests = gql`
  query Requests (
    $status: RequestStatus,
    $id: String
  ) {
    requests(status: $status, id: $id) {
      items {
        id
        status
        preRequest {
          id
          area
          type
          status
          annotation
          projectName
          estimatedBudget
          projectDescription
          organizationDescription
        }
        project {
          id
          name
          startDate
          endDate
          estimatedDuration
          description
          place
          agreement {
            id
            closed
            scheduledSigningDate
            contract {
              id
              ref
              fileName
            }
          }
        }
        revisions {
          id
          board
          status
          annotations
          createdAt
        }
      }
      count
    }
  }
`;
