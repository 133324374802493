import React, { useContext } from "react";
import { PreRequestFormContext } from "../../PreRequestFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";
import { IconLabel } from "./IconLabel";
import { Popover } from '../../../../atoms'
import { organizationInfo } from "../../../../templates/implementer-onboarding/TooltipHelpers"

const OrganizationDescriptionInput = (props: any) => {
  const implementerAccountContext = useContext(PreRequestFormContext);
  const { form, onFieldsChange, preRequest } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <>
      <IconLabel
        number={1}
        label="Cuéntanos a detalle sobre tu organización:" />
      <Form.Item>
        <Popover
          placement="right"
          content={organizationInfo}
          trigger="hover">
          {getFieldDecorator("organizationDescription", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la descripción de tu organización."
              }
            ],
            initialValue: preRequest && preRequest.organizationDescription
          })(
            <Input.TextArea
              id="organizationDescription"
              name="organizationDescription"
              placeholder="Describe"
              onChange={onChange}
              rows={5} />
          )}
        </Popover>
      </Form.Item>
    </>
  );
};

export default OrganizationDescriptionInput;
