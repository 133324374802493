import React from "react";
import { NewRequestConsumer } from "../../../NewRequestContext";
import { stepMap, stepTemplates, getStepTitle } from "./helpers";
import { RequestSidebar, Layout } from "../../../organisms";
import { ContentHeader } from "../../../atoms";

interface INewRequestTemplateProps {
  currentStep: string;
}

export default class NewRequestTemplate extends React.Component<INewRequestTemplateProps> {
  public render() {
    const { currentStep } = this.props;
    const step = stepMap[currentStep];
    const StepTemplate = stepTemplates[step];

    return (
      <NewRequestConsumer>
        {(state) => {
          const stepsToOmit: string[] = ["tipo", "implementadora"];
          const stepsToSkip = 0;
          const stepTitle = getStepTitle(step, stepsToSkip);

          return (
            <Layout>
              <div style={{
                marginTop: "-10pt",
                marginLeft: "-10pt"
              }}>
                <ContentHeader
                  title={stepTitle.title}
                  subtitle={stepTitle.subtitle} />
                <RequestSidebar
                  {...state}
                  stepsToOmit={stepsToOmit}
                  currentStep={step} />
                <StepTemplate {...state} />
              </div>
            </Layout>
          );
        }}
      </NewRequestConsumer>
    );
  }
}
