import React, { useContext } from "react";
import { Table } from "antd";
import { SectionCard } from "../../../../organisms/ImplementerLayout";
import RequestDetailsTemplateContext from "../RequestDetailsTemplateContext";
import { SpecificObjective, Beneficiary, Activity, BeneficiaryType } from "../../../../../types";
import { beneficiaryTypesMap, assistanceFrequency } from "../../../../../utils/maps";

const getBeneficiaries = (specificObjectives: SpecificObjective[] = []) => {
  let beneficiaries: Beneficiary[] = [];

  specificObjectives.forEach((objective: SpecificObjective) => {
    objective?.goals?.forEach((goal: any) => {
      goal?.activities?.forEach((ac: Activity) => {
        if (ac.beneficiaries) {
          beneficiaries = beneficiaries.concat(ac.beneficiaries);
        }
      });
    });
  });

  return beneficiaries;
};

export const BeneficiariesSection = (props: any) => {
  const { request = {} } = useContext(RequestDetailsTemplateContext);
  const { specificObjectives = [] as SpecificObjective[] } = request?.project || {};

  const beneficiaries = getBeneficiaries(specificObjectives);

  return (
    <SectionCard
      title="Beneficiarios">
      <Table
        pagination={false}
        dataSource={beneficiaries}>
        <Table.Column
          key="id"
          dataIndex="description"
          title="Beneficiario" />
        <Table.Column
          key="id"
          dataIndex="goalValue"
          title="Valor meta" />
        <Table.Column
          key="id"
          dataIndex="type"
          render={(type: BeneficiaryType[] = []) => {
            if (type?.length === 1) {
              return beneficiaryTypesMap[type[0]];
            } else if (type?.length === 2) {
              return beneficiaryTypesMap.UNITS[type[1]];
            }
          }}
          title="Tipo" />
        <Table.Column
          key="id"
          dataIndex="attendance"
          render={(attendance) => assistanceFrequency[attendance] }
          title="Asistencia" />
      </Table>
    </SectionCard>
  );
};
