import React from "react";
import { Row, Col, Button, Typography, Tag } from "antd";
import "moment/locale/es";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

import {
  SectionCard
} from "../../../../../../components/organisms/Layout";
import { RoleConsumer } from "../../../../../RoleConsumer";
import { UserRole } from "../../../../../../types";

interface MonitoringListItemProps {
  monitoring?: any;
  disabled: boolean;
}

export const MonitoringListItem: React.FC<MonitoringListItemProps> = ({ monitoring, disabled }) => {
  const { projectId } = useParams();
  const monitoringTitle = monitoring.date ?
    moment(monitoring.date).format("MMMM YYYY")
    : "N/A";

  const getLinkToMonitoringEntry = (role?: UserRole) => {
    if (role === UserRole.ADMIN) {
      return `/proyectos/${projectId}/monitoreo/${monitoring.id}`;
    } else if (role === UserRole.IMPLEMENTER) {
      return `/implementadora/proyectos/${projectId}/monitoreo/${monitoring.id}`;
    }

    return "#";
  }

  const getDisabledState = (role?: UserRole) => {
    if (role === UserRole.IMPLEMENTER) {
      return monitoring.closed || disabled;
    }

    return false;
  };

  return (
    <RoleConsumer>
      {({ claims }) => (
        <SectionCard>
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <Typography.Title level={4}>
                Monitoreo {monitoringTitle} {monitoring.closed && (<Tag color="green">Aprobado</Tag>)}
              </Typography.Title>
            </Col>
            <Col span={12}>
              <Link to={getLinkToMonitoringEntry(claims?.role)}>
                <Button
                  disabled={getDisabledState(claims?.role)}
                  style={{ float: "right" }}
                  type="primary"
                  shape="round">
                  {claims?.role === UserRole.IMPLEMENTER ? "Actualizar" : "Ver"}
                </Button>
              </Link>
            </Col>
          </Row>
        </SectionCard>
      )}
    </RoleConsumer>
  );
};
