import styled from "styled-components";
import { Form, Button } from "antd";

import { Upload } from "../../../../atoms";

export const FieldLabel = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
`;

export const FieldDescription = styled.span`
  margin-bottom: 10px;
`;

export const FormItemContainer = styled.div`
  margin-bottom: 24px;

  &:last-child{
    margin-bottom: 0px;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const CUpload = styled(Upload)`
  & .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
`;

export const SaveButton = styled(Button)`
  float: right;
`;
