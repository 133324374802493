import React from 'react'
import { Form, DatePicker, message } from "antd";
import moment, { Moment } from "moment";
import { FormComponentProps } from "antd/lib/form";

import { client } from "../../../../../../gql/apollo";
import { StartProject } from "../../../../../../gql/mutations/projects";
import {
  requestsWithUnclosedAgreement
} from "../../../../../../gql/queries/requests";
import { FormSection } from "../../../../../molecules";

interface CloseAgreementFormOwnProps {
  toggleBusy: (busy?: boolean) => void;
  closeCloseAgreementModal: any;
  project?: any;
  visible: boolean;
  readOnly: boolean;
  refetch: any;
}

type CloseAgreementFormProps = CloseAgreementFormOwnProps & FormComponentProps;

class CloseAgreementForm extends React.Component<CloseAgreementFormProps> {
  public componentDidUpdate(prevProps: CloseAgreementFormProps) {
    const {
      form: { resetFields },
      visible
    } = this.props;

    if (prevProps.visible && !visible) {
      resetFields();
    }
  }

  public render() {
    const {
      form: {
        getFieldDecorator
      },
      readOnly,
      project
    } = this.props;
    const dateRange = this.getDates(project);

    const getDisabledDate = (current: Moment | null) =>
      current
        ? current < moment().endOf("day")
        : false;

    return (
      <Form
        id="closeAgreement"
        layout="vertical"
        onSubmit={this.handleOnSubmit}>
        <FormSection>
          <Form.Item>
            {getFieldDecorator("dateRange", {
              initialValue: dateRange,
              rules: [
                {
                  required: true,
                  message: "Por favor, selecciona una fecha de inicio"
                }
              ]
            })(
              <DatePicker.RangePicker
                disabled={readOnly}
                disabledDate={getDisabledDate}
                placeholder={["Inicio del proyecto", "Fin del proyecto"]}
                size="large"
                style={{ width: "100%" }}
              />
            )}
          </Form.Item>
        </FormSection>
      </Form>
    );
  }

  private getDates = (project: any = {}) => {
    const dateRange = [];
    if (project?.startDate) {
      dateRange.push(moment(project.startDate));
      if (project?.endDate) {
        dateRange.push(moment(project.endDate));
      }
    }
    return dateRange;
  }

  private handleOnCloseAgreement = async (project: any = {}) => {
    try {
      this.props.toggleBusy(true);
      await client.mutate({
        mutation: StartProject,
        variables: {
          id: project.id,
          startDate: project.startDate,
          endDate: project.endDate
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: requestsWithUnclosedAgreement }]
      });
      message.success("Convenio cerrado.");
      this.props.closeCloseAgreementModal();
      this.props.refetch();
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error al cerrar el convenio de proyecto.");
    } finally {
      this.props.toggleBusy(false);
    }
  }

  private handleOnSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const { form, project } = this.props;

    form.validateFields((errors, values) => {
      if (errors) {
        return;
      }

      return this.handleOnCloseAgreement({
        ...project,
        startDate: values.dateRange[0].toISOString(),
        endDate: values.dateRange[1].toISOString()
      });
    });
  }
}

export default Form.create<CloseAgreementFormProps>({ name: "closeAgreement" })(CloseAgreementForm);
