import React from "react";

import {
  NewRequestProvider,
  NewRequestConsumer,
  NewRequestContextOwnProps
} from "./NewRequestContext";

export interface RequestReviewState {
  [fieldPath: string]: {
    approved?: boolean;
    comment?: string;
  };
}

export interface RequestReviewProps {
  requestId?: string;
}

export interface RequestReviewMethods {
  onReset: (fieldId: string) => void;
  onApprove: (fieldId: string) => void;
  onReject: (fieldId: string) => void;
  onSaveComment: (field: string, comment: string) => void;
  onDeleteComment: (field: string) => void;
}

export interface RequestReviewContextOwnProps {
  state: RequestReviewState;
  methods: RequestReviewMethods;
}

export type RequestReviewContextProps = RequestReviewContextOwnProps;

export const RequestReviewContext = React.createContext<Partial<RequestReviewContextProps>>({});

interface RequestReviewConsumerState {
  requestState: Partial<NewRequestContextOwnProps>;
  reviewState: Partial<RequestReviewContextOwnProps>;
}

type RequestReviewConsumerChildren = (states: RequestReviewConsumerState) => React.ReactElement;

export const RequestReviewConsumer = ({ children }: { children: RequestReviewConsumerChildren }) => (
  <NewRequestConsumer>
    {(state) => (
      <RequestReviewContext.Consumer>
        {(reviewState) => children({ requestState: state, reviewState })}
      </RequestReviewContext.Consumer>
    )}
  </NewRequestConsumer>
);

export class RequestReviewProvider extends React.PureComponent<RequestReviewProps, RequestReviewState> {
  public state: RequestReviewState = {};

  public render() {
    const { children, requestId = "" } = this.props;

    const value = {
      state: this.state,
      methods: {
        onReset: this.onResetField,
        onApprove: this.onApproveField,
        onReject: this.onRejectField,
        onSaveComment: this.onSaveComment,
        onDeleteComment: this.onDeleteComment
      }
    };

    return (
      <NewRequestProvider requestId={requestId}>
        <RequestReviewContext.Provider value={value}>
          {children}
        </RequestReviewContext.Provider>
      </NewRequestProvider>
    );
  }

  private onResetField = (fieldId: string) =>
    this.setState((state) => ({ [fieldId]: { ...state[fieldId], approved: undefined } }))

  private onApproveField = (fieldId: string) =>
    this.setState((state) => ({ [fieldId]: { ...state[fieldId], approved: true } }))

  private onRejectField = (fieldId: string) =>
    this.setState((state) => ({ [fieldId]: { ...state[fieldId], approved: false } }))

  private onSaveComment = (fieldId: string, comment: string) =>
    this.setState((state) => ({ [fieldId]: { ...state[fieldId], comment } }))

  private onDeleteComment = (fieldId: string) =>
    this.setState((state) => ({ [fieldId]: { ...state[fieldId], comment: undefined } }))
}
