import React, { useState } from "react";
import { Form, Button, InputNumber } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { UploadFile } from "antd/lib/upload/interface";

import { OnChangeSignature, ReadOnlyInput } from "../../../../atoms";
import { IMonthlyBudget, InvestorType, UserRole } from "../../../../../types";
import { formatToCurrency, formatInput, parseInput } from "../../../../../utils";
import { FormItem, FormItemContainer, FieldLabel, FieldDescription, CUpload, SaveButton } from ".";
import { RoleConsumer } from "../../../../RoleConsumer";

interface IRawBudgetVerificationFormProps {
  disabled?: boolean;
  data?: IMonthlyBudget;
  type?: InvestorType;
  busy: boolean;
  onSaveBudgetVerification: (form: WrappedFormUtils<any>) => void;
}

type RawBudgetVerificationFormProps = IRawBudgetVerificationFormProps & FormComponentProps;

const RawBudgetVerificationForm: React.FC<RawBudgetVerificationFormProps> = ({
  disabled,
  data,
  type,
  busy = false,
  onSaveBudgetVerification,
  form
}) => {
  const { getFieldDecorator } = form;

  const onInvoiceChanged: OnChangeSignature = (info) =>
    setInvoices(info.fileList);

  const budgetVerification = data?.budgetVerification?.find((verification) =>
    verification.type === type);

  const consumed = budgetVerification?.consumed || 0;

  const getBudgeted = () => {
    if (data) {
      if (type === InvestorType.FICOSEC) {
        return parseInt(data.ficosec as unknown as string, 10);
      } else if (type === InvestorType.COINVESTOR) {
        return parseInt(data.coinvestor as unknown as string, 10);
      }
    }

    return 0;
  };

  const budgeted = getBudgeted();

  const getInvoices = (): Array<UploadFile<any>> | undefined =>
    budgetVerification?.invoices?.map((invoice, idx) => ({
      uid: invoice.id || String(idx),
      name: invoice.fileName,
      url: invoice.ref,
      size: null as unknown as number,
      type: null as unknown as string
    }));

  const [invoices, setInvoices] = useState<Array<UploadFile<any>>>(getInvoices() || []);

  return (
    <RoleConsumer>
      {({ claims }) => (
        <Form id="test">
          <FormItemContainer>
            <div>
              <FieldLabel>Presupuesto</FieldLabel>
              <FieldDescription>Cantidad que designaste para esta actividad para este periodo del proyecto.</FieldDescription>
            </div>
            <FormItem>
              {getFieldDecorator("budgeted", {
                initialValue: formatToCurrency(budgeted)
              })(
                <ReadOnlyInput disabled />
              )}
            </FormItem>
          </FormItemContainer>
          <FormItemContainer>
            <div>
              <FieldLabel>Consumido</FieldLabel>
              <FieldDescription>Cantidad que se consumió durante la actividad y el mes ejercido.</FieldDescription>
            </div>
            <FormItem>
              {getFieldDecorator("consumed", {
                initialValue: consumed,
                valuePropName: "defaultValue",
              })(
                claims?.role === UserRole.ADMIN || disabled
                  ? (
                    <ReadOnlyInput disabled />
                  ) : (
                    <InputNumber
                      style={{ width: "100%" }}
                      max={budgeted}
                      min={0}
                      formatter={formatInput}
                      parser={parseInput}
                    />
                  )
              )}
            </FormItem>
          </FormItemContainer>
          <FormItemContainer>
            <div>
              <FieldLabel>Facturas</FieldLabel>
              <FieldDescription>Adjunta las facturas que demuestra el uso de los recursos.</FieldDescription>
            </div>
            <FormItem>
              {getFieldDecorator("invoices", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, adjunta al menos una factura."
                  }
                ],
                initialValue: invoices,
                valuePropName: "defaultFileList"
              })(
                <CUpload
                  onChange={onInvoiceChanged}
                  disabled={claims?.role === UserRole.ADMIN || disabled}>
                  {claims?.role === UserRole.IMPLEMENTER && !disabled && (
                    <Button
                      icon="paper-clip"
                      type="dashed"
                      block>
                      Adjuntar factura
                    </Button>
                  )}
                </CUpload>
              )}
            </FormItem>
          </FormItemContainer>
          {claims?.role === UserRole.IMPLEMENTER && !disabled && (
            <FormItemContainer>
              <FormItem>
                <SaveButton
                  loading={busy}
                  type="primary"
                  shape="round"
                  onClick={() => onSaveBudgetVerification(form)}
                  disabled={invoices?.length === 0}>
                  Guardar
                </SaveButton>
              </FormItem>
            </FormItemContainer>
          )}
        </Form>
      )}
    </RoleConsumer>
  );
};

export const BudgetVerificationForm = Form.create<RawBudgetVerificationFormProps>({
  name: "BudgetVerificationForm"
})(RawBudgetVerificationForm);
