import React, { useContext } from "react";
import { Row, Col, Skeleton } from "antd";

import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import {
  ImplementerMissionInput,
  ImplementerNameInput,
  ImplementerVisionInput,
  SocialObjectiveInput
} from "./inputs";;


export const InstitutionalBackgroundSection = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const { implementerProfile, isLoadingData } = implementerAccountContext;

  if (isLoadingData) {
    return <Skeleton loading={true} />;
  }

  if (implementerProfile.type === "GOVERNMENT") {
    return null;
  }

  return (
    <FormSection title="ANTECEDENTES INSTITUCIONALES">
      <Row gutter={16}>
        <Col span={12}>
          <ImplementerNameInput />
        </Col>
        <Col span={12}>
          <ImplementerMissionInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ImplementerVisionInput />
        </Col>
        <Col span={12}>
          <SocialObjectiveInput />
        </Col>
      </Row>
    </FormSection>
  );
};
