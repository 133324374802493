import React from "react";
import { withRouter } from "react-router";
import { Form, message, Button, Modal } from "antd";
const { confirm } = Modal;

import { ClasificationFormContext } from "./ClasificationFormContext";
import { bugsnagClient } from "../../../../../../bugsnag";
import CategoriesDropdown from "./CategoriesDropdown";
import InstancesDropdown from "./InstancesDropdown";
import RejectConfirmationModal from "./RejectConfirmationModal";
import { aprovePreRequest } from "./helpers";
import {
  IClasificationFormProps,
  ClasificationFormState,
  ICustomChangeEvent
} from "../types";

class ClassificationForm extends React.Component<IClasificationFormProps, ClasificationFormState> {
  public state: ClasificationFormState = {
    isBusy: false,
    type: undefined,
    area: undefined,
    annotation: "",
    rejectModalIsVisible: false 
  };

  public render() {
    const { form, preRequest } = this.props;
    const { isBusy, rejectModalIsVisible } = this.state;
    const providerValue = {
      form,
      preRequest: { ...preRequest },
      onFieldsChange: this.onFieldsChange
    };

    return (
      <ClasificationFormContext.Provider value={providerValue}>
        <RejectConfirmationModal
          isVisible={rejectModalIsVisible}
          onClose={this.toggleRejectModal} />
        <Form
          layout="inline"
          id="presubmissionForm"
          onSubmit={this.handleSubmit}>
          <InstancesDropdown disabled={isBusy} />
          <CategoriesDropdown disabled={isBusy} />
          {
            preRequest.status === "APPROVED" ?
              <Form.Item>
                <Button
                  shape="round"
                  style={{
                    backgroundColor: "#009900",
                    color: "#FFF" }}>
                  Aprobado
                </Button>
              </Form.Item>
              : (
                <>
                  <Form.Item>
                    <Button
                      loading={isBusy}
                      htmlType="submit"
                      shape="round"
                      type="primary">Aprobar</Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      shape="round"
                      onClick={this.toggleRejectModal}
                      style={{
                        backgroundColor: "#8E1D41",
                        color: "#FFF"
                      }}>
                      Rechazar
                    </Button>
                  </Form.Item>
                </>)
          }
        </Form>
      </ClasificationFormContext.Provider>
    );
  }

  private toggleRejectModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      rejectModalIsVisible: !prevState.rejectModalIsVisible
    }));
  }

  private showConfirm = () => {
    confirm({
      cancelText: "Cancelar",
      okText: "Aprobar",
      okType: "primary",
      title: "¿Seguro de aprobar la solicitud?",
      content: `Al aprobar la pre-solicitud,
        ahora la implementadora podrá completar una solicitud.`,
      onOk: () => {
        this.savePreRequest();
      }
    });
  }

  private handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const { form } = this.props;
    evt.preventDefault();
    form.validateFields((err) => {
      if (err) {
        return;
      }
      this.showConfirm();
    });
  }

  private onFieldsChange = (arg: ICustomChangeEvent) => {
    const updatedState = { ...this.state };
    updatedState[arg.key] = arg.value;
    this.setState({ ...updatedState });
  }

  private toggleIsBusy = () => {
    this.setState((prevState) => ({
      ...prevState,
      isBusy: !prevState.isBusy
    }));
  }

  private savePreRequest = async () => {
    this.toggleIsBusy();
    try {
      await aprovePreRequest(this.props.preRequest?.id, this.state);
      message.success("Pre-solicitud aprobada.");
    } catch (error) {
      bugsnagClient.notify(error, {
        beforeSend: (report) => {
          report.metaData = { error: JSON.stringify(error) };
          report.errorClass = "Implementer Create Pre Request";
          report.groupingHash = "Implementer - Create Pre Request";
        }
      });
      message.error("Ocurrió un error.");
    } finally {
      this.toggleIsBusy();
    }
  }
}

const WrappedClassificationForm =
  Form.create<IClasificationFormProps>({
    name: "classification_form"
  })(ClassificationForm);

export default withRouter(WrappedClassificationForm);
