import React from "react";

export const impactIndicatorInfo: JSX.Element = (
  <div>
    <h4>Indicador de impacto</h4>
    <div>
      <h5>Cualitativos</h5>
      <p>Recuerde, estos indicadores son por lo regular:</p>
      <ul>
        <li>Cambios de conducta (p.ej.)</li>
        <li>Cambios de actitud (p.ej.)</li>
        <li>Cambios en la dinámica de vida (p.ej.)</li>
      </ul>
    </div>
    <div>
      <h5>Cuantitativos</h5>
      <p>Recuerde, estos indicadores son por lo regular:</p>
      <ul>
        <li>Número de talleres o actividades (p.ej.)</li>
        <li>Número de participantes (p.ej.)</li>
        <li>Número de sesiones y horas (p.ej.)</li>
      </ul>
    </div>
  </div>
)


export const organizationInfo: JSX.Element = (
  <div>
    <h5>Organización</h5>
    <ul>
      <li>OSC/institución</li>
      <li>Historia de organización e institución</li>
      <li>Años de experiencia y/o intervención</li>
      <li>Enfoque</li>
      <li>Población objetivo</li>
    </ul>
  </div>
)

export const projectInfo: JSX.Element = (
  <div>
    <h5>Proyecto</h5>
    <ul>
      <li>Objetivo General</li>
      <li>Población de trabajo</li>
      <li>Nivel de prevención</li>
      <li>Zonas de intervención</li>
    </ul>
  </div>
)

export const methodologyInfo: JSX.Element = (
  <div>
    <h4>Metodología</h4>
    <ul>
      <li>
        Referir estudios o trabajos que explican el porqué del problema o fenómeno
        (sugerentemente relacionadas con la manera en que se abordara en el proyecto)
      </li>
      <li>Mencionar buenas prácticas en atención al problema o fenómeno</li>
      <li>Articular las explicaciones o causas del problema, las buenas prácticas en atención y la manera en que se pretende abordar</li>
      <li>Definir la manera en que se abordara el problema, (Describir en caso de que sea necesario las etapas o procesos)</li>
    </ul>
  </div>
)

export const objectiveGeneralInfo: JSX.Element = (
  <div>
    <h4>Objetivo General</h4>
    <p>Recuerde un objetivo es un enunciado que integra:</p>
    <ul>
      <li>¿Que se va a hacer? (fomentar, promover, etc.)</li>
      <li>¿Con quién se va a hacer? (participantes o grupo a ser atendido)</li>
      <li>¿Como se va a hacer? (a través de qué acciones)</li>
      <li>¿Para qué se va a hacer? (que se lograra)</li>
    </ul>
  </div>
)

export const projectJustificationInfo: JSX.Element = (
  <div>
    <h4>Justificación del proyecto</h4>
    <ul>
      <li>Definir el problema o fenómeno (concepto y definición)</li>
      <li>Referir formas o maneras en el que se expresa el problema (síntomas o manifestaciones del problema)</li>
      <li>Referir los lugares o zonas donde se presenta más común el problema (áreas de la ciudad o sectores)</li>
      <li>
        Dimensionar amplitud, profundidad del problema e impacto en los grupos sociales que lo viven
        (estadística de a cuantos afecta, quienes son los más afectados y la manera en que les afecta)
      </li>
      <li>
        Dimensionar amplitud, profundidad del problema e impacto en los grupos sociales que lo viven
        (estadística de a cuantos afecta, quienes son los más afectados y la manera en que les afecta)
      </li>
      <li>
        Dibujar ligeramente la manera en que se pretende abordar (la manera en la que se puede abordar el problema
        y sus causas desde el proyecto a presentar)
      </li>
    </ul>
  </div>
)
