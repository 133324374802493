import React from "react";

export const IconLabel: React.FC<{ label: string, number?: number }> = (props) => {
  return (
    <div style={{
        display: "inline-block",
        marginBottom: "5pt"
      }}>
      <div style={{
        display: "flex",
        alignItems: "center"
      }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            marginRight: "5pt",
            borderRadius: "50%",
            backgroundColor: "#8e1d41",
            color: "#FFF",
            width: "24pt",
            height: "24pt"
          }}>
          {props.number || ""}
        </div>
        {props.label}
      </div>
    </div>
  );
};
