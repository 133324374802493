import React from "react";
import { Descriptions, Card, Icon } from "antd";
import { SpecificObjectiveInfo } from "./components";

interface ProjectFileAlternativeViewProps {
  onToggleView: () => void;
  data: any;
}

const ProjectFileAlternativeView = (props: ProjectFileAlternativeViewProps) => {
  const { onToggleView } = props;
  const {
    specificObjectives = {}
  } = props.data;

  return (
    <Card extra={
      <Icon
        onClick={onToggleView}
        style={{ fontSize: "20pt" }}
        type="unordered-list" />
    }>
      {Object.values(specificObjectives).map((specificObjective) => (
        <SpecificObjectiveInfo
          data={props.data}
          specificObjective={specificObjective} />
      ))}
    </Card>
  );
};

export default ProjectFileAlternativeView;
