import React from "react";
import { Modal, List } from "antd";
import { ModalProps } from "antd/lib/modal";
import format from "date-fns/format";

import { Review, ReviewField } from "../../types";

export interface FieldReview {
  idx: number;
  review: Review;
  fieldReview: ReviewField;
}

interface ICommentsModalOwnProps {
  reviews: FieldReview[];
}

type CommentsModalProps = ICommentsModalOwnProps & ModalProps;

export const CommentsModal: React.FC<CommentsModalProps> = ({ reviews, ...modalProps }) => {
  reviews.sort((a, b) => a.idx > b.idx ? -1 : 1);

  const renderListItem = (item: FieldReview) => {
    const reviewDate = format(parseInt(item.review.createdAt, 10), "dd/MM/yyyy");
    const description = `Revisión ${item.idx + 1} · ${reviewDate}`;
    return (
      <List.Item>
        <List.Item.Meta
          title={item.fieldReview.comment}
          description={description}
        />
      </List.Item>
    );
  };

  return (
    <Modal
      title={`Comentarios para revisión (${reviews.length})`}
      footer={null}
      closable
      {...modalProps}
    >
      <List
        locale={{ emptyText: "No hay comentarios" }}
        dataSource={reviews}
        renderItem={renderListItem}
      />
    </Modal>
  );
};
