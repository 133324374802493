import React from "react";
import { Form, Input, Button, DatePicker, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment";

import {
  CreateWorktableAgreement,
  UpdateWorktableAgreement
} from "../../../gql/mutations/workTables";
import { WorkTableMeeting } from "../../../gql/queries/workTables";
import { client } from "../../../gql/apollo";
import { Agreement } from "../../../types/Agreement";
import { FormSection } from "../../molecules";
import { Modal, Upload } from "../../atoms";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

export type AgreementFormAction =
  | "EDIT"
  | "CREATE"
  | "DELETE";

interface IAgreementFormOwnProps {
  worktableMeetingId: string;
  action: AgreementFormAction;
  visible: boolean;
  agreementData?: Partial<Agreement>;
  onClose: () => void;
}

interface IDocument {
  fileName: string;
  ref: string;
}

interface IAgreementFormState {
  agreement?: Partial<Agreement & { documentsToCreate?: IDocument[], documentsToDelete?: string[] }>;
  busy: boolean;
}

type AgreementFormProps = IAgreementFormOwnProps & FormComponentProps;

class UnwrappedAgreementForm extends React.Component<AgreementFormProps, IAgreementFormState> {
  public state: IAgreementFormState = {
    agreement: {},
    busy: false
  };

  public render() {
    const {
      form: { getFieldDecorator },
      visible,
      onClose,
      agreementData: agreement
    } = this.props;
    const { busy } = this.state;

    return (
      <Modal
        onCancel={onClose}
        destroyOnClose
        title="Acuerdo de trabajo"
        footer={[
          <Button
            loading={busy}
            style={{ float: "left" }}
            shape="round"
            htmlType="submit"
            type="primary"
            onClick={this.onSaveAgreement}>
            Guardar
          </Button>,
          <Button style={{ float: "left" }} shape="round" onClick={onClose}>Cancelar</Button>
        ]}
        slimFooter
        closable
        visible={visible}>
        <Form>
          <FormSection>
            <Form.Item>
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, describe el acuerdo de trabajo."
                  }
                ],
                valuePropName: "defaultValue",
                initialValue: agreement?.description
              })(
                <Input.TextArea
                  rows={3}
                  placeholder="Descripción del acuerdo de trabajo"
                  onChange={(event) => {
                    this.onInputChange("description", event.target.value);
                  }} />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("responsible", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, especifica al responsable."
                  }
                ],
                valuePropName: "defaultValue",
                initialValue: agreement?.responsible
              })(
                <Input
                  size="large"
                  placeholder="Responsable"
                  onChange={(event) => {
                    this.onInputChange("responsible", event.target.value);
                  }} />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("deadline", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, especifica la fecha límite."
                  }
                ],
                valuePropName: "defaultValue",
                initialValue: agreement?.deadline && moment(agreement?.deadline)
              })(
                <DatePicker
                  placeholder="Fecha límite"
                  style={{ width: "100%" }}
                  size="large"
                  onChange={(date) => this.onInputChange("deadline", date)} />
              )}
            </Form.Item>
            <Form.Item>
              <Upload
                listType="picture"
                onDone={this.attachFiles}
                onRemove={this.deleteFile}
                defaultFileList={this.getAgreementDocuments() as Array<UploadFile<any>>}
                multiple>
                <Button icon="plus">
                  Adjuntar documentos
                </Button>
              </Upload>
            </Form.Item>
          </FormSection>
        </Form>
      </Modal>
    );
  }

  private onInputChange = (inputName: string, value: any) => {
    const { agreement = {} } = this.state;
    agreement[inputName] = value;

    this.setState((prevState) => ({
      ...prevState,
      agreement
    }));
  }

  private getAgreementDocuments = () =>
    this.props.agreementData?.documents?.map((document, idx) => ({
      uid: document.id || String(idx),
      name: document.fileName,
      url: document.ref,
    }))

  private attachFiles = async (info: UploadChangeParam<UploadFile<any>>) => {
    const { action } = this.props;
    const documents = info.fileList.map((file) => ({
      id: file.uid,
      fileName: file.name,
      ref: file.response?.imageUrl
    }));

    if (action === "EDIT") {
      const existingDocuments = this.getAgreementDocuments()?.map((doc) => doc.uid);
      const documentsToCreate = documents
        .filter((file) => !existingDocuments?.includes(file.id))
        .map((doc) => ({ fileName: doc.fileName, ref: doc.ref }));

      this.setState((prevState) => ({
        ...prevState,
        agreement: {
          ...prevState.agreement,
          documentsToCreate
        }
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        agreement: {
          ...prevState.agreement,
          documents
        }
      }));
    }
  }

  private deleteFile = async (file: UploadFile<any>) =>
    this.setState((prevState) => ({
      ...prevState,
      agreement: {
        ...prevState.agreement,
        documentsToDelete: [...prevState.agreement?.documentsToDelete || [], file.uid]
      }
    }))

  private onSaveAgreement = async () => {
    const { action, worktableMeetingId, agreementData, onClose, form } = this.props;
    const { agreement } = this.state;

    await form.validateFields();

    switch (action) {
      case "CREATE":
        this.setState({ busy: true });
        try {
          await client.mutate({
            mutation: CreateWorktableAgreement,
            variables: {
              worktableMeetingId,
              description: agreement?.description,
              responsible: agreement?.responsible,
              deadline: agreement?.deadline,
              documents: agreement?.documents
            },
            refetchQueries: [{ query: WorkTableMeeting, variables: { id: worktableMeetingId } }]
          });
          onClose();
          message.success("Se ha creado el acuerdo");
          this.setState({ busy: false });
        } catch (err) {
          // TODO: Report to bugsnag
          message.error("Ha ocurrido un error");
          this.setState({ busy: false });
        }
        break;
      case "EDIT":
        this.setState({ busy: true });
        try {
          await client.mutate({
            mutation: UpdateWorktableAgreement,
            variables: {
              worktableAgreementId: agreementData?.id,
              description: agreement?.description,
              responsible: agreement?.responsible,
              deadline: agreement?.deadline,
              documentsToCreate: agreement?.documentsToCreate,
              documentsToDelete: agreement?.documentsToDelete
            },
            refetchQueries: [{ query: WorkTableMeeting, variables: { id: worktableMeetingId } }]
          });
          onClose();
          message.success("Se ha actualizado el acuerdo");
          this.setState({ busy: false });
        } catch (err) {
          // TODO: Report to bugsnag
          message.error("Ha ocurrido un error");
          this.setState({ busy: false });
        }
        break;
      default:
        break;
    }
  }
}

export const AgreementForm = Form.create<AgreementFormProps>({ name: "agreementForm" })(UnwrappedAgreementForm);
