import React from "react";

import { BudgetBreakdownTab } from "./components";
import { ASupply } from "../../../../types";
import { ProjectMonitoringTab } from "../../../pages/projects";
import { LoadingScreen } from "../../../atoms";
import { BudgetSummaryTab, FieldVisitTab } from "../../../shared/misc";

interface IProjectMonitoringTemplateProps {
  closed?: boolean;
  currentTab: ProjectMonitoringTab;
  loading: boolean;
  supplies: ASupply[];
}

export const ProjectMonitoringTemplate: React.FC<IProjectMonitoringTemplateProps> = (props) => {
  if (props.currentTab === ProjectMonitoringTab.BUDGET_BREAKDOWN) {
    return <BudgetBreakdownTab {...props} />;
  }

  if (props.currentTab === ProjectMonitoringTab.BUDGET_SUMMARY) {
    return <BudgetSummaryTab {...props} />;
  }

  if (props.currentTab === ProjectMonitoringTab.FIELD_VISIT) {
    return <FieldVisitTab {...props} />;
  }

  return <LoadingScreen />;
};
