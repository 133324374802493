import React from "react";
import styled from "styled-components";

import { ExpenseType } from "../../../../../types";
import { expenseTypes } from "../../../../../utils/maps";
import { formatCurrency } from "../helpers";

const RightAlignedDiv = styled.div`
  text-align: right;
`;

export const totalsTableColumns = [
  {
    key: "supplyName",
    title: "Tipo de gasto",
    dataIndex: "expenseType",
    render: (expenseType: ExpenseType) =>
      ({ children: expenseTypes[expenseType] })
  },
  {
    title: "Tipo de comprobación",
    dataIndex: "isVerifiable",
    render: (isVerifiable: boolean) => {
      return isVerifiable ? "Comprobable" : "No comprobable";
    }
  },
  {
    title: "Concepto",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Sub-total FICOSEC",
    dataIndex: "ficosecSubTotal",
    render: (ficosecSubTotal: number = 0) => (
      <RightAlignedDiv>
        {formatCurrency(ficosecSubTotal)}
      </RightAlignedDiv>)
  },
  {
    title: "Sub-total Co-inversión",
    dataIndex: "coInvestmentSubTotal",
    render: (coInvestmentSubTotal: number = 0) => (
      <RightAlignedDiv>
        {formatCurrency(coInvestmentSubTotal)}
      </RightAlignedDiv>
    )
  },
  {
    title: "% Sub-total FICOSEC",
    dataIndex: "ficosecSubTotalPct",
    render: (ficosecSubTotalPct: number = 0) => (
      <RightAlignedDiv>
        {`${ficosecSubTotalPct.toFixed(2)} %`}
      </RightAlignedDiv>
      
    )
  },
  {
    title: "% Sub-total Co-inversión",
    dataIndex: "coInvestmentSubTotalPct",
    render: (coInvestmentSubTotalPct: number = 0) => (
      <RightAlignedDiv>
        {`${coInvestmentSubTotalPct.toFixed(2)} %`}
      </RightAlignedDiv>
    )
  }
];
