import React from "react";
import { PageHeader as AntPageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledPageHeader = styled(AntPageHeader)`
  margin: 0px;
  border: 1px solid rgb(235, 237, 240);
`;

/**
 * Styled Ant PageHeader component.
 * The ghost prop is set to false by default.
 * The margin is set to 0px by default.
 * @param props
 */
export const PageHeader: React.FC<PageHeaderProps> = ({ ghost = false, ...props }) => (
  <StyledPageHeader
    ghost={ghost}
    {...props}
    breadcrumb={{
      routes: props.breadcrumb?.routes,
      itemRender: (route) =>
        <Link to={route.path}>{route.breadcrumbName}</Link>,
      ...props.breadcrumb
    }} />
);
