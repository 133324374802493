import gql from "graphql-tag";

export const StartProject = gql`
  mutation StartProject(
    $id: String!
    $startDate: String!
    $endDate: String!
  ) {
  startProject(
    projectId: $id
    startDate: $startDate
    endDate: $endDate
  ) {
    id
  }
}
`;
