import gql from "graphql-tag";

const EditAgreementSigningDate = gql`
  mutation EditAgreementSigningDate(
    $id: ID!
    $date: String!
    $time: String!
    $place: String!
  ) {
    updateAgreement(
      id: $id
      date: $date
      time: $time
      place: $place
    ) {
      id
    }
  }
`;

export default EditAgreementSigningDate;
