import React from "react";
import { format } from "date-fns";
import moment from "moment";
import { Button } from "antd";

interface args {
  onViewMeeting: (id: string) => void
}

export const getMeetingColumns = (args: args) => {
  const { onViewMeeting } = args;
  return (
    [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Fecha",
        dataIndex: "date",
        key: "date",
        render: (date: string) => format(parseInt(date, 10), "dd/MM/yyyy")
      },
      {
        title: "Hora",
        dataIndex: "time",
        key: "time",
        render: (time: string) => moment(time, "HHmm").format("hh:mm a")
      },
      {
        title: "Lugar",
        dataIndex: "place",
        key: "place"
      },
      {
        title: "Jueces",
        dataIndex: "judgeEmails",
        key: "judgeEmails",
        render: (judgeEmails: string[] = []) => judgeEmails.length
      },
      {
        title: "Invitados",
        dataIndex: "guestEmails",
        key: "guestEmails",
        render: (guestEmails: string[] = []) => guestEmails.length
      },
      {
        fixed: "right" as "right",
        width: 1,
        render: (_: any, meeting: { id: string }) => (
          <Button
            shape="round"
            style={{ marginRight: 10 }}
            onClick={() => onViewMeeting(meeting.id)}>
            Ver
          </Button>
        )
      }
    ]
  );
};
