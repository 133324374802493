import * as yup from "yup";
import { mapValues } from "lodash";
import {
  FinancialCapabilityProjectBudgetType,
  EduLevel,
  DeliverablePeriodicity,
  ActivityBeneficiaryAttendance,
  ExpenseType,
  MeasurementUnit,
  RiskImpact,
  RiskProbability
} from "../../types";

const dynamicObject = (schema: yup.ObjectSchema) =>
  yup.lazy((obj: any) =>
    yup.object(mapValues(obj, () => schema))
      .test("count", "${path} is empty", (value) => Object.keys(value as object).length !== 0));

const attachmentSchema = yup.object().shape({
  fileName: yup.string().required(),
  ref: yup.string().required()
});

const financialCapabilitySchema = yup.object().shape({
  financialInstitution: yup.string().required(),
  amount: yup.number().required(),
  pctFinanced: yup.number().required(),
  projectName: yup.string().required(),
  projectYear: yup.string().length(4).required(),
  budgetType: yup.string().oneOf(Object.keys(FinancialCapabilityProjectBudgetType)).required(),
  projectObjective: yup.string().required()
});

const projectInfoSchema = yup.object().shape({
  name: yup.string().required(),
  place: yup.string().required(),
  estimatedDuration: yup.number().required(),
  responsibleName: yup.string().required(),
  responsibleEmailAddress: yup.string().required(),
  preventionLevel: yup.array().of(yup.string()).required(),
  preventionScope: yup.array().of(yup.string()).required(),
  problem: yup.string().required(),
  description: yup.string().required(),
  beneficiariesProfile: yup.string().required(),
  substantiation: yup.string().required()
});

const HRProfileSchema = yup.object().shape({
  id: yup.string().required(),
  fullName: yup.string().required(),
  position: yup.string().required(),
  projectGoals: yup.array().of(yup.string()).required(),
  responsibilities: yup.string().required(),
  incomeEstimation: yup.string().required(),
  weeklyHours: yup.string().required(),
  eduLevel: yup.string().oneOf(Object.keys(EduLevel)).required(),
  specialtyArea: yup.string().required(),
  yearsExp: yup.string().required(),
  workExp: yup.string().required(),
  resume: attachmentSchema.required()
});

const specificObjectiveSchema = yup.object().shape({
  summary: yup.string().required(),
  goals: yup.array().of(yup.string()).required(),
  description: yup.string().required(),
  deliverableDescription: yup.string().required(),
  deliverablePeriodicity: yup.string().oneOf(Object.keys(DeliverablePeriodicity)).required(),
  deliverableTitle: yup.string().required()
});

const beneficiarySchema = yup.object().shape({
  attendance: yup.string().oneOf(Object.keys(ActivityBeneficiaryAttendance)).required(),
  profile: yup.string().required(),
  range: yup.string().required(),
  goalValue: yup.string().required(),
  type: yup.array().of(yup.string()).required()
});

const qualitativeIndicatorSchema = yup.object().shape({
  description: yup.string().required(),
  verificationMethod: yup.string().required()
});

const quantitativeIndicatorSchema = yup.object().shape({
  description: yup.string().required(),
  verificationMethod: yup.string().required()
});

const goalSchema = yup.object().shape({
  specificObjectiveId: yup.string().required(),
  activities: yup.array().of(yup.string()).required(),
  description: yup.string().required(),
  qualitativeIndicators: yup.array().of(qualitativeIndicatorSchema).min(1).required(),
  quantitativeIndicators: yup.array().of(quantitativeIndicatorSchema).min(1).required()
});

const activitySchema = yup.object().shape({
  beneficiaries: yup.array().of(beneficiarySchema).min(1).required(),
  description: yup.string().required(),
  goalId: yup.string().required(),
  months: yup.array().of(yup.number()).required(),
});

const sustainabilityArgumentSchema = yup.object().shape({
  argument: yup.string().required()
});

const monthlyBudgetSchema = yup.object().shape({
  ficosec: yup.number().min(0).required()
});

const supplySchema = yup.object().shape({
  HRProfileId: yup.string(),
  activityId: yup.string().required(),
  count: yup.number().min(1).required(),
  expenseType: yup.string().oneOf(Object.keys(ExpenseType)).required(),
  isVerifiable: yup.boolean().default(false).nullable(),
  measurementUnit: yup.string().oneOf(Object.keys(MeasurementUnit)).required(),
  monthlyBudget: dynamicObject(monthlyBudgetSchema),
  name: yup.string().when("HRProfileId", { is: undefined, then: yup.string().required(), otherwise: yup.string().nullable() }),
  unitCost: yup.number().min(1).required()
});

const riskSchema = yup.object().shape({
  identification: yup.string().required(),
  impact: yup.string().oneOf(Object.keys(RiskImpact)).required(),
  mitigationStrategy: yup.string().required(),
  probability: yup.string().oneOf(Object.keys(RiskProbability)).required()
});

const assumptionSchema = yup.object().shape({
  actions: yup.string().required(),
  identification: yup.string().required(),
  impact: yup.string().required()
});

export const requestSchema = yup.object().shape({
  // Step 1
  financialCapability: dynamicObject(financialCapabilitySchema),
  // Step 2
  projectInfo: projectInfoSchema,
  // Step 3
  HRProfiles: dynamicObject(HRProfileSchema),
  // Step 4
  projectMethodology: yup.string().required(),
  projectDuration: yup.array().of(yup.string()).max(2).min(2).required(),
  sustainabilityArguments: dynamicObject(sustainabilityArgumentSchema),
  generalObjective: yup.object().shape({
    summary: yup.string().required()
  }).required(),
  impactIndicator: yup.string().required(),
  specificObjectives: dynamicObject(specificObjectiveSchema),
  goals: dynamicObject(goalSchema),
  activities: dynamicObject(activitySchema),
  // Step 6
  supplies: dynamicObject(supplySchema),
  // Step 7
  assumptionManagement: dynamicObject(assumptionSchema),
  riskManagement: dynamicObject(riskSchema),
});
