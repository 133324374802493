import React from "react";
import { Query } from "react-apollo";

import Users from "../../../gql/queries/users/Users";
import { UsersTemplate, ErrorTemplate } from "../../templates";

class UsersPage extends React.Component {
  public render() {
    return (
      <Query query={Users}>
        {({ loading, data, error }: { loading: boolean, data: any, error?: any }) => {
          if (error) {
            // TODO: report to bugsnag
            return <ErrorTemplate message="Hubo un problema al cargar los usuarios." />;
          }

          return <UsersTemplate loading={loading} users={data.allUsers} />;
        }}
      </Query>
    );
  }
}

export default UsersPage;
