import React from "react";
import Form from "antd/lib/form/Form";
import { Button } from "antd";

export const SubmitButton = ({ loading = false }: { loading?: boolean }) => (
    <Form.Item>
      <Button
        loading={loading}
        id="submitPresubmissinFormButton"
        shape="round"
        type="primary"
        htmlType="submit"
        size="default">
        Enviar
      </Button>
    </Form.Item>
  );
