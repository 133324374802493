import React, { useContext } from "react";
import { Input } from "antd";
import Form from "antd/lib/form/Form";
import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";

export const StreetInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item label="Calle">
      {getFieldDecorator("street", {
        initialValue: implementerProfile?.street
      })(
        <Input
          disabled={isBusy}
          id="street"
          name="street"
          placeholder="Calle"
          onChange={onChange}
          size="large" />
      )}
    </Form.Item>
  );
};
