import React from "react";
import { Card } from "antd";
import { CardProps } from "antd/lib/card";
import styled from "styled-components";

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 5pt;
`;

export const SectionCard: React.FC<CardProps> = (props) =>
  <StyledCard {...props} />;
