import React, { useState, useRef } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useMutation } from "@apollo/react-hooks";;
import { message } from "antd";
import { FormProps } from "antd/lib/form";

import { Content } from "../../atoms";
import { HRProfileForm } from "../forms";
import { NewRequestContextProps, NewRequestMethods } from "../../NewRequestContext";
import { RequestFooter } from "../../organisms";
import { ReviewableProps } from "../../../types/Request";
import { UpdateHRProfiles } from "../../../gql/mutations/projects";
import { client } from "../../../gql/apollo";
import { disabledRequestInput } from '../../../utils'

type HRProfileProps = ReviewableProps & NewRequestContextProps & RouteComponentProps;

export const HRProfile: React.FC<HRProfileProps> = ({
  state: {
    projectId,
    HRProfilesToCreate,
    HRProfilesToUpdate,
    HRProfilesToDelete,
    HRProfiles,
    goals,
    supplies,
    status,
    draft,
    checkFields,
    reviews
  },
  history,
  methods = {} as NewRequestMethods,
  reviewable,
  approval
}) => {
  const formRef = useRef<{ props: FormProps }>();
  const [updateHRProfiles] = useMutation<any>(UpdateHRProfiles, { client } as any);
  const [busy, setBusy] = useState(false);
  const disableByStatus: boolean = disabledRequestInput(status, draft);

  const { addHRProfile, saveHRProfileInput, removeHRProfile } = methods;

  const onClickNext = () => {
    const { form } = formRef!.current!.props;

    form?.validateFields(async (err: any, _: any) => {
      if (err) {
        return;
      }

      const toCreate = HRProfilesToCreate
        .map((profileId) => {
          const profile = HRProfiles[profileId]
          const supplyId = Object.keys(supplies).find((supplyId) => supplies[supplyId].HRProfileId === profileId);
          return { ...profile, supplyId };
        })
        .filter((profile) => !!profile);
      const toUpdate = HRProfilesToUpdate.map((profileId) => HRProfiles[profileId]);

      try {
        setBusy(true);
        await updateHRProfiles({
          variables: {
            projectId,
            profilesToCreate: toCreate,
            profilesToUpdate: toUpdate,
            profilesToDelete: HRProfilesToDelete
          }
        });
        setBusy(false);
        return history.push("riesgos");
      } catch (error) {
        // TODO: Report to bugsnag
        setBusy(false);
        message.error("Ocurrió un problema al guardar los perfiles.");
      }
    });
  };

  return (
    <Content style={{ marginLeft: 245 }}>
      <HRProfileForm
        reviews={reviews}
        disabled={busy || reviewable || approval || disableByStatus}
        reviewable={reviewable}
        approval={approval}
        fields={HRProfiles}
        goals={goals}
        methods={{
          addHRProfile,
          saveHRProfileInput,
          removeHRProfile
        }}
        wrappedComponentRef={formRef}
        checkFields={checkFields}
      />
      {reviewable || !approval && !disableByStatus && (
        <RequestFooter nextProps={{ onClick: onClickNext, loading: busy }} />
      )}
    </Content>
  );
}

export default withRouter(HRProfile);
