import { getFirstColumns, getLastColumns, getMonthlyColumns } from ".";

export const getColumnsDefinition = (reviewState: any, props: any, openModal: any, isBusy: boolean) => {
  const propsData = {
    supplies: props.supplies,
    onRowChange: props.onRowChange,
    onRowRemove: props.onRowRemove,
    reviewable: props.reviewable,
    approval: props.approval,
    activities: props.activities,
    HRProfiles: props.HRProfiles,
    projectDuration: props.projectDuration,
    disabled: props.disabled,
    openModal,
    isBusy,
    checkFields: props.checkFields,
    reviews: props.reviews
  };

  const firstColumns = getFirstColumns(propsData);
  const monthlyColumns = getMonthlyColumns(propsData);
  const lastColumns = getLastColumns(reviewState, propsData);
  return [...firstColumns, ...monthlyColumns, ...lastColumns];
};
