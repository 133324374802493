import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { WorkTablesTemplate } from "../../templates/workTables";
import { WorkTables } from "../../../gql/queries";
import { ErrorTemplate } from "../../templates";
import { client } from "../../../gql/apollo";
import { IPaginationSignature, sortDirectionMap } from "../requests/ReviewalPage";

const WorkTablesPage: React.FC = () => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { loading, data, error, refetch } = useQuery(WorkTables, { client } as any);

  if (error) {
    // TODO: report to bugsnag
    return <ErrorTemplate message="Hubo un problema al cargar las mesas de trabajo." />;
  }

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);


  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<any>,
    sorter: SorterResult<any>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <WorkTablesTemplate
      loading={loading}
      onPaginationChange={handleOnPaginationChange}
      workTables={data?.worktables} />
  );
};

export default WorkTablesPage;
