import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { message, Modal } from "antd";
import { v4 as uuid } from "uuid";
import { Content } from "../../../atoms";
import { Header, Sidebar } from "./components";
import { CreateWorkTable } from "../../../../gql/mutations/workTables";
import {
  ICreateWorkTableState,
  IMember,
  IActivity
} from "./types";
import {
  containerStyle,
  sections,
  validateGlobalData,
  purgeEmptyMembers,
  memberTypesMap,
  sectionsMap
} from "./helpers";
import Layout from "../../../organisms/Layout";

type CreateWorkTableTemplateRouteProps =
  Pick<RouteComponentProps<{ currentStep: string }>, "match">;

type CreateWorkTableProps =
  CreateWorkTableTemplateRouteProps
  & RouteComponentProps;

class WorkTableTemplate extends React.Component
  <CreateWorkTableProps, ICreateWorkTableState> {

  public state: ICreateWorkTableState = {
    general: {
      workTableArea: undefined,
      coordination: "",
      name: "",
      objective: "",
      technicalSecretary: "",
      logoRef: ""
    },
    members: {
      withoutPublicCharge: [],
      withPublicCharge: [],
      guests: []
    },
    activities: []
  };

  public constructor(props: CreateWorkTableProps) {
    super(props);
    this.saveNewWorkTable = this.saveNewWorkTable.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadEmptyMembers();
  }

  public render() {
    const { match } = this.props;
    const { currentStep } = match.params;
    const sectionData = this.state[sectionsMap[currentStep as string]];

    return (
      <Layout noPadding>
        <Header currentStep={currentStep.toString()} />
        <Sidebar />
        <Content style={containerStyle}>
          {this.renderSelectedSection(
            currentStep, {
            onCancel: this.onCancel,
            saveNewWorkTable: this.saveNewWorkTable,
            data: sectionData,
            onChange: this.onChange
          })}
        </Content>
      </Layout>
    );
  }

  private loadEmptyMembers(): void {
    const { members } = this.state;

    for (const typeKey in memberTypesMap) {
      if (members[typeKey]) {
        (members[typeKey] as IMember[]).push({
          type: memberTypesMap[typeKey],
          fullName: "",
          id: uuid()
        });
      }
    }

    this.setState({ members });
  }

  private onChange(
    section: "general" | "members" | "activities",
    event: { target: { name: string, value: any } }) {
    if (section === "activities") {
      const activitiesUpdated: IActivity[] = event.target.value;
      this.setState({ activities: activitiesUpdated });
    } else {
      this.state[section][event.target.name] = event.target.value;
      this.setState(this.state);
    }
  }

  private renderSelectedSection(currentStep: string, props: object) {
    return React.createElement(
      sections[currentStep],
      { ...props });
  }

  private async goToList() {
    this.props.history.replace("../");
  }

  private async saveNewWorkTable() {
    const { history } = this.props;
    const generalSectionData = { ...this.state.general };
    delete generalSectionData.logoRef;

    const validations =
      validateGlobalData(generalSectionData, this.state.activities);

    if (validations.isValid) {
      try {
        const {
          withPublicCharge = [],
          withoutPublicCharge = [],
          guests = []
        } = this.state.members;
        const allMembers = withPublicCharge.concat(withoutPublicCharge, guests);
        await CreateWorkTable(
          this.state.general,
          purgeEmptyMembers(allMembers),
          this.state.activities);
        this.goToList();
        return message.success("Se ha creado una nueva mesa de trabajo.");
      } catch (err) {
        // TODO: Report to bugsnag
        return message.error(`Ha ocurrido un error.`);
      }
    } else {
      return Modal.confirm({
        title: "Campos requeridos faltantes",
        content: "Ingresa los campos marcados como requeridos.",
        okText: "Revisar",
        cancelText: "Entendido",
        onOk() {
          history.push(validations.section);
        }
      });
    }
  }

  private onCancel() {
    const { history } = this.props;
    return Modal.confirm({
      title: "Advertencia",
      content: `Estás a punto de cancelar la operación. Los datos ingresados se
        perderán. ¿Deseas continuar?`,
      okText: "Continuar",
      cancelText: "Cancelar",
      onOk() {
        history.push("../");
      }
    });
  }
}

export default withRouter(WorkTableTemplate);
