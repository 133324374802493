import React, { useContext } from "react";
import { PreRequestFormContext } from "../PreRequestFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";
import { IconLabel } from "./OrganizationDescriptionInput/IconLabel";

export const ProjectNameInput = (props: any) => {
  const implementerAccountContext = useContext(PreRequestFormContext);
  const { form, onFieldsChange, preRequest } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <>
      <IconLabel
        number={2}
        label="Nombre del proyecto:" />
      <Form.Item>
        {getFieldDecorator("projectName", {
          rules: [
            {
              required: true,
              message: "Por favor, ingresa el nombre del proyecto."
            }
          ],
          initialValue: preRequest && preRequest.projectName
        })(
          <Input
            id="projectName"
            name="projectName"
            placeholder="Nombre del proyecto"
            onChange={onChange}
            size="large" />
        )}
      </Form.Item>
    </>
  );
};
