export const getGoalIndicators = (
  goalActivitiesIds: any = [],
  activities: any = {}): any => {
  const quantitative: any[] = [];
  const qualitative: any[] = [];

  goalActivitiesIds
    .map((id: string) => {
      const activity = activities[id];
      const {
        qualitativeIndicators = {},
        quantitativeIndicators = {}
      } = activity;
      
      Object.keys(qualitativeIndicators)
        .forEach((indicatorId: any) => {
          const indicator = qualitativeIndicators[indicatorId];
          qualitative.push({ id: indicatorId, ...indicator });
        });
      Object.keys(quantitativeIndicators)
        .forEach((indicatorId: any) => {
          const indicator = quantitativeIndicators[indicatorId];
          quantitative.push({ id: indicatorId, ...indicator });
        });
    });

  return { quantitative, qualitative };
};
