import React, { useContext } from "react";
import { Row, Col, Skeleton, Button, Form } from 'antd'
import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import { Upload } from "../../../atoms"
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'

export const DocsSection = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const { implementerProfile, isLoadingData, onFieldsChange, onDeleteField } = implementerAccountContext;

  if (isLoadingData) {
    return <Skeleton loading />;
  }

  if (implementerProfile.type === "CONSULTANT") {
    return null;
  }

  const attachFiles = async (info: UploadChangeParam<UploadFile<any>>) => {
    const listDocuments = info.fileList.map(file => ({
      id: file.uid,
      fileName: file.name,
      ref: file.response?.imageUrl
    }));

    const existingDocuments = getAgreementDocuments()?.map(document => document.uid);
    const documents = listDocuments
      .filter(file => !existingDocuments?.includes(file.id))
      .map(doc => ({ fileName: doc.fileName, ref: doc.ref }));

    onFieldsChange && onFieldsChange({ key: 'documentsToCreate', value: documents })
  }

  const deleteFile = async (file: UploadFile<any>) => {
    onDeleteField && onDeleteField({ key: 'documentsToDelete', value: file.uid })
  }

  const getAgreementDocuments = () => (
    implementerProfile.documents?.map((document, idx) => ({
      uid: document.id || String(idx),
      name: document.fileName,
      url: document.ref
    }))
  )

  return (
    <FormSection title="DOCUMENTOS A SUBIR">
      <Row gutter={16}>
        <Col span={18}>
          <Form.Item>
            {implementerProfile.type === "CIVIL_ASSOCIATION"
              ? (
                <>
                  <Upload
                    multiple
                    onDone={attachFiles}
                    onRemove={deleteFile}
                    defaultFileList={getAgreementDocuments() as Array<UploadFile<any>>}>
                    <Button
                      shape="round"
                      icon="plus"
                      type="dashed">
                      Adjuntar documentos (Acta constitutiva, Donataria autorizada, Cumplimiento positivo del SAT)
                    </Button>
                  </Upload>
                </>
              ) : (
                <Upload
                  multiple
                  onDone={attachFiles}
                  onRemove={deleteFile}
                  defaultFileList={getAgreementDocuments() as Array<UploadFile<any>>}>
                  <Button
                    shape="round"
                    icon="plus"
                    type="dashed">
                    Adjuntar documento de creación (Institución de gobierno) o Ley
                  </Button>
                </Upload>
              )
            }
          </Form.Item>
        </Col>
      </Row>
    </FormSection>
  );
};
