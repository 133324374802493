import React, { useState } from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

import styles from "./styles.less";
import { Modal } from "../../atoms";
import RequestItemCommentForm from "../../RequestItemCommentForm";
import { FieldReview } from '../CommentsModal'

interface ReviewControlsProps {
  fieldId: string;
  approved?: boolean;
  comment?: string;
  onReset: (fieldId: string) => void;
  onApprove: (fieldId: string) => void;
  onReject: (fieldId: string) => void;
  onSaveComment: (fieldId: string, comment: string) => void;
  onDeleteComment: (fieldId: string) => void;
  relative?: boolean;
  buttonProps?: ButtonProps;
  reviews: any;
}

const ReviewControls: React.FC<ReviewControlsProps> =
  ({
    fieldId,
    approved,
    comment: c,
    onReset,
    onApprove,
    onReject,
    onSaveComment,
    onDeleteComment,
    relative = false,
    buttonProps = {},
    reviews
  }) => {
    const [modalVisible, setModalVisibility] = useState(false);
    const [comment, setComment] = useState<string>();
    const [rejecting, setRejecting] = useState(false);

    const rejectItem = () => {
      setModalVisibility(true);
      setRejecting(true);
    };

    const saveComment = () => {
      onSaveComment(fieldId, comment!);
      setComment(undefined);
      setModalVisibility(false);

      if (rejecting) {
        onReject(fieldId);
        setRejecting(false);
      }
    };

    const closeModal = () => {
      setComment(undefined);
      setModalVisibility(false);
      setRejecting(false);
    };

    const deleteComment = () => {
      onDeleteComment(fieldId);
      setComment(undefined);
      setModalVisibility(false);
      setRejecting(false);

      if (!approved) {
        onReset(fieldId);
      }
    };

    const actualComment = comment !== undefined ? comment : c;

    return (
      <div
        className={
          relative
            ? styles.relativeReviewControlsContainer
            : styles.reviewControlsContainer
        }
      >
        <Modal
          title="Añadir comentario"
          visible={modalVisible}
          onCancel={closeModal}
          footer={[
            <Button
              shape="round"
              type="primary"
              onClick={saveComment}
              form="requestItemCommentForm"
              disabled={!(actualComment || "").trim()}
            >
              Guardar
            </Button>,
            <Button shape="round" type="link" onClick={closeModal}>Cancelar</Button>,
            <Button shape="round" type="link" onClick={deleteComment} disabled={!c}>Eliminar</Button>
          ]}
        >
          <RequestItemCommentForm
            field={fieldId}
            reviews={reviews}
            visible={modalVisible}
            comment={actualComment}
            onChange={(changedFields: any) => setComment(changedFields.comment.value)}
          />
        </Modal>
        <Button.Group>
          <Button
            size={buttonProps.size || "large"}
            className={approved === true ? styles.approved : undefined}
            onClick={() => onApprove(fieldId)!}
          >
            Aprobar
          </Button>
          <Button
            size={buttonProps.size || "large"}
            className={approved === false ? styles.rejected : undefined}
            onClick={approved !== false ?
              rejectItem : () => setModalVisibility(!modalVisible)} >
            Observación
          </Button>
        </Button.Group>
      </div>
    );
  };

export default ReviewControls;
