import gql from "graphql-tag";

const Request = gql`
  query Request($id: String!) {
    request(id: $id) {
      id
      revisions {
        id
        board
        status
        annotations
        createdAt
      }
      project {
        id
        name
        place
        estimatedDuration
        responsibleName
        responsibleEmailAddress
        preventionLevel
        preventionScope
        problem
        description
        beneficiariesProfile
        substantiation
        methodology
        startDate
        endDate
        generalObjective
        impactIndicator
        sustainabilityArguments {
          id
          argument
        }
        implementerProjects {
          id
          financialInstitution
          amount
          pctFinanced
          projectName
          projectYear
          budgetType
          projectObjective
        }
        humanResources {
          id
          fullName
          position
          projectGoals {
            id
            description
          }
          responsibilities
          incomeEstimation
          weeklyHours
          eduLevel
          specialtyArea
          yearsExp
          workExp
          resume {
            id
            ref
            fileName
          }
        }
        specificObjectives {
          id
          goals {
            id
            qualitativeIndicators {
              id
              description
              verificationMethod
            }
            quantitativeIndicators {
              id
              description
              verificationMethod
            }
            specificObjective {
              id
            }
            activities {
              id
              goal {
                id
              }
              beneficiaries {
                id
                goalValue
                type
                profile
                range
                attendance
              }
              description
              months
            }
            description
          }
          description
          deliverableTitle
          deliverableDescription
          deliverablePeriodicity
        }
        supplies {
          id
          humanResource {
            id
          }
          activity {
            id
          }
          monthlyBudget {
            id
            month
            ficosec
            coinvestor
          }
          name
          expenseType
          isVerifiable
          unitCost
          measurementUnit
          count
        }
        risks {
          id
          identification
          probability
          impact
          mitigationStrategy
        }
        assumptions {
          id
          identification
          impact
          actions
        }
        name
        place
        estimatedDuration
        responsibleName
        responsibleEmailAddress
        preventionLevel
        preventionScope
        problem
        description
        beneficiariesProfile
        substantiation
        methodology
        startDate
        endDate
        generalObjective
        impactIndicator
      }
      preRequest {
        id
        folio
        implementer {
          id
          uid
          displayName
          implementerProfile {
            id
            type
            governmentLevel
            governmentDependency
            coordination
            area
            institutionResponsibleName
            implementerName
            implementerMission
            implementerVision
            socialObjective
            contactName
            contactCharge
            contactInstitution
            contactEmail
            contactPhoneNumber
            personType
            RFC
            businessName
            socialBehest
            street
            exteriorNumber
            interiorNumber
            colony
            zipCode
            city
            state
            directorsBoardMembers {
              id
              name
              charge
            }
          }
        }
        organizationDescription
        projectName
        estimatedBudget
        projectDescription
        status
        area
        type
      }
      responsibleName
      responsiblePosition
      responsiblePhoneNumber
      responsibleEmailAddress
      status
      draft
      rejectReason
      revisions {
        id
        board
        status
        annotations
      }
      reviews {
        id
        createdAt
        fields {
          id
          field
          approved
          comment
        }
      }
      createdAt
    }
  }
`;

export default Request;
