import React, { useContext } from "react";
import { Input, Select } from "antd";
import Form from "antd/lib/form/Form";
import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";
import { selectFilteringHelper } from "../../../../../utils";
import { governmentLevels } from "../../../../../utils/maps";
import { GovernmentLevel } from "../../../../../types";

export const GovernmentLevelInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const handleOnChange = (value: GovernmentLevel) => {
    onFieldsChange({
      key: "governmentLevel",
      value
    });
  };

  return (
    <Form.Item label="Nivel de gobierno">
      {getFieldDecorator("governmentLevel", {
        rules: [
          {
            required: true,
            message: "Por favor, selecciona el nivel de gobierno."
          }
        ],
        initialValue:  !!implementerProfile?.governmentLevel ?
          implementerProfile?.governmentLevel
          : undefined
      })(
        <Select
          disabled={isBusy}
          size="large"
          showSearch
          style={{ width: "100%" }}
          placeholder="Nivel de gobierno"
          optionFilterProp="children"
          onChange={handleOnChange}
          filterOption={selectFilteringHelper}>
          {Object.keys(governmentLevels)
            .map((gl) => (
              <Select.Option
                value={gl}
                key={gl}>
                {governmentLevels[gl]}
              </Select.Option>
            ))}
        </Select>
      )}
    </Form.Item>
  );
};
