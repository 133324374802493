import { IProcessedSupplyItem, SupplyItemsPerType } from "../types";

export const getSubtotalsPerType = (supplies: IProcessedSupplyItem[]): SupplyItemsPerType => {
 const suppliesWithSubTotals = computeSubtotalsPerType(supplies);
 const supppliesWithPercentage = computePercentages(suppliesWithSubTotals);
 return supppliesWithPercentage;
};

const computeSubtotalsPerType = (supplies: IProcessedSupplyItem[]): SupplyItemsPerType => {
  const suppliesPerExpenseType: SupplyItemsPerType = { };

  supplies.forEach(({expenseType, ...supply}) => {
    if(expenseType === undefined) {
      return;
    }

    if (suppliesPerExpenseType[expenseType] === undefined) {
      suppliesPerExpenseType[expenseType] = {};
    }

    const {
      ficosecSubTotal = 0,
      coInvestmentSubTotal = 0
    } = suppliesPerExpenseType[expenseType] as IProcessedSupplyItem;

    (suppliesPerExpenseType[expenseType] as any)
      .ficosecSubTotal = ficosecSubTotal + supply.ficosecSubTotal;

    (suppliesPerExpenseType[expenseType] as any)
      .coInvestmentSubTotal = coInvestmentSubTotal + supply.coInvestmentSubTotal;
  });

  return suppliesPerExpenseType;
};

const computePercentages = (supplies: SupplyItemsPerType): SupplyItemsPerType => {
  Object.keys(supplies).forEach((key) => {
    const {
      ficosecSubTotal = 0,
      coInvestmentSubTotal = 0
    } = supplies[key];

    const totalPerType = ficosecSubTotal + coInvestmentSubTotal;

    supplies[key]
      .ficosecSubTotalPct = getPercentage(totalPerType, ficosecSubTotal)
    supplies[key]
      .coInvestmentSubTotalPct = getPercentage(totalPerType, coInvestmentSubTotal)
  });

  return supplies;
};

const getPercentage = (total: number = 0, fraction: number = 0) => {
  if (total === 0) {
    return 0;
  }

  const percentage = (fraction * 100) / total;
  return parseFloat(percentage.toFixed(2));
};
