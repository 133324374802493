import React from "react";

import styles from "./styles.less";
import { Label } from "../../atoms";

interface FormSectionProps {
  title?: string;
}

const FormSection: React.FC<FormSectionProps> = ({ title, children }) => (
  <div className={styles.formSection}>
    {title && <Label>{title}</Label>}
    {children}
  </div>
);

export default FormSection;
