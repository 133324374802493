import React from "react";
import { FormSection } from "../../../../../../molecules";
import { Row, Button } from "antd";
import { MemberForm } from ".";
import { IMember, MemberStateKey, IEvent } from "../../../types";

interface IFormMembersProps {
  form: any;
  members?: IMember[];
  onAddNewMember: (memberType: MemberStateKey) => void;
  onRemoveSelectedMember: (memberType: MemberStateKey, index: number) => void;
  onChange: (
    memberType: MemberStateKey,
    index: number,
    event: { target: { name: string, value: any } }) => void;
}

const SectionGuestMembers: React.FC<IFormMembersProps> = (props) => {
  const memberStateKey: MemberStateKey = "guests";
  const {
    members = [],
    onAddNewMember,
    onRemoveSelectedMember,
    onChange,
    form
  } = props;

  const renderNewMemberFields = () => {
    return members.map((member, i) => (
      <MemberForm
        member={member}
        form={form}
        key={member.id}
        index={i as number}
        onChange={onChanging}
        fieldDecoratorPrefix="guestMember"
        onRemoveSelectedMember={removeSelectedMember} />
    ));
  };

  const addNewMember = () => {
    onAddNewMember(memberStateKey);
  };

  const removeSelectedMember = (index: number) => {
    onRemoveSelectedMember(memberStateKey, index);
  };

  const onChanging = (index: number, event: IEvent) => {
    onChange(memberStateKey, index, event);
  };

  return (
    <FormSection title="INTEGRANTES INVITADOS">
      {renderNewMemberFields()}
      <Row>
        <Button
          onClick={addNewMember}
          type="dashed"
          style={{ fontWeight: "bold" }}>
          + Agregar otro invitado
        </Button>
      </Row>
    </FormSection>
  );
};

export default SectionGuestMembers;
