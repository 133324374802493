import { Request, RevisionBoard, RevisionStatus } from "../types";
import { RequestApprovalStep } from "../../../../organisms/ApprovedRequestsSidebar";

export const requestsByApproval = (requests: Request[]) => {
  const steppedRequests: { [key: string]: Request[] } = {
    [RequestApprovalStep.UNITY]: [],
    [RequestApprovalStep.LOCAL]: [],
    [RequestApprovalStep.DIRECTORS]: [],
    [RequestApprovalStep.TECHNICIANS]: [],
    [RequestApprovalStep.APPROVED]: []
  };

  const findRequest = (request: Request, board: RevisionBoard) => {
    const { revisions = [] } = request;
    revisions.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    const lastRevision = revisions[revisions.length - 1];
    return lastRevision.board === board && lastRevision.status === RevisionStatus.APPROVED;
  };

  if (requests) {
    requests.forEach((request) => {
      if (request.revisions.length === 0) {
        steppedRequests[RequestApprovalStep.UNITY].push(request);
        return;
      }

      if (findRequest(request, RevisionBoard.TECHNICIANS)) {
        steppedRequests[RequestApprovalStep.APPROVED].push(request);
        return;
      }

      if (findRequest(request, RevisionBoard.DIRECTORS)) {
        steppedRequests[RequestApprovalStep.TECHNICIANS].push(request);
        return;
      }

      if (findRequest(request, RevisionBoard.LOCAL)) {
        steppedRequests[RequestApprovalStep.DIRECTORS].push(request);
        return;
      }

      if (findRequest(request, RevisionBoard.UNITY)) {
        steppedRequests[RequestApprovalStep.LOCAL].push(request);
        return;
      }
    });
  }

  return steppedRequests;
};
