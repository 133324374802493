import React from "react";
import { Form, Input, InputNumber, Select, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import prevention_level from "../../../prevention_level.json";
import prevention_environment from "../../../prevention_environment.json";
import { Popover } from "../../atoms";
import { FormSection } from "../../molecules";
import { ProjectInfo } from "../../../types";
import reviewableField from "../../../utils/reviewableField";
import { projectJustificationInfo } from "../../../components/templates/implementer-onboarding/TooltipHelpers"

interface ProjectFormOwnProps {
  fields: Partial<ProjectInfo>;
  disabled: boolean;
  reviewable: boolean;
  approval: boolean;
  checkFields: boolean;
  reviews: any;
}

const keyTitle = "title";
const preventionLevel = ["primaria", "secundaria", "terciaria"];
const preventionEnvironment = ["social", "comunitaria", "situacional", "psicosocial"];

const preventionLevelInfo = (
  <div>
    {prevention_level.map((x, index) =>
      <div>
        <h5>{x[keyTitle]}</h5>
        <p> {x[preventionLevel[index]]}</p>
      </div>
    )}
  </div>
);

const preventionStatesInfo = (
  <div>
    {prevention_environment.map((x, index) =>
      <div>
        <h5>{x[keyTitle]}</h5>
        <p> {x[preventionEnvironment[index]]}</p>
      </div>
    )}
  </div>
);

type ProjectFormProps = ProjectFormOwnProps & FormComponentProps;

class ProjectForm extends React.Component<ProjectFormProps> {
  public render() {
    const { form: { getFieldDecorator }, fields, disabled, reviewable, checkFields, reviews } = this.props;

    reviewableField.reviewable = reviewable;
    reviewableField.fieldDecorator = getFieldDecorator;
    reviewableField.disabled = disabled;
    reviewableField.reviews = reviews;
    const renderReviewableField = reviewableField.render;

    return (
      <Row style={{ padding: 40, marginTop: 74, marginBottom: 74 }}>
        <Col span={8}>
          <Form layout="vertical">
            <FormSection>
              <Form.Item validateStatus={checkFields ? fields.name ? "" : "error" : "" }>
                {renderReviewableField("name", {
                  initialValue: fields.name
                })!(
                  <Input placeholder="Nombre del proyecto" size="large" disabled={disabled} />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.place ? "" : "error" : "" }>
                {renderReviewableField("place", {
                  initialValue: fields.place
                })!(
                  <Input placeholder="Lugar, colonia o polígono de implementación"
                    size="large" disabled={disabled} />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.estimatedDuration ? "" : "error" : "" }>
                {renderReviewableField("estimatedDuration", {
                  initialValue: fields.estimatedDuration
                })!(
                  <InputNumber
                    placeholder="Duración estimada en meses"
                    size="large"
                    min={1}
                    style={{ width: "100%" }}
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.responsibleName ? "" : "error" : "" }>
                {renderReviewableField("responsibleName", {
                  initialValue: fields.responsibleName
                })!(
                  <Input placeholder="Responsable del proyecto" size="large" disabled={disabled} />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.responsibleEmailAddress ? "" : "error" : "" }>
                {renderReviewableField("responsibleEmailAddress", {
                  initialValue: fields.responsibleEmailAddress
                })!(
                  <Input
                    placeholder="Correo electrónico del responsable"
                    size="large"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.preventionLevel?.length ? "" : "error" : "" }>
                <Popover
                  placement="right"
                  content={preventionLevelInfo}
                  trigger="hover"
                  overlayStyle={reviewable && ({ display: "none" })}>
                  {renderReviewableField("preventionLevel", {
                    initialValue: fields.preventionLevel || []
                  })!(
                    <Select
                      mode="multiple"
                      placeholder="Nivel de prevención"
                      size="large"
                      disabled={disabled}>
                      <Select.Option key="NA">N/A</Select.Option>
                      <Select.Option key="PRIMARY">Primaria</Select.Option>
                      <Select.Option key="SECONDARY">Secundaria</Select.Option>
                      <Select.Option key="TERTIARY">Terciaria</Select.Option>
                    </Select>
                  )}
                </Popover>
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.preventionScope?.length ? "" : "error" : "" }>
                <Popover
                  placement="right"
                  content={preventionStatesInfo}
                  trigger="hover"
                  overlayStyle={reviewable && ({ display: "none" })}>
                  {renderReviewableField("preventionScope", {
                    initialValue: fields.preventionScope || []
                  })!(
                    <Select
                      mode="multiple"
                      placeholder="Ámbito de prevención"
                      size="large"
                      disabled={disabled}>
                      <Select.Option key="NA">N/A</Select.Option>
                      <Select.Option key="PSYCHOSOCIAL">Psicosocial</Select.Option>
                      <Select.Option key="COMMUNITY">Comunitario</Select.Option>
                      <Select.Option key="SOCIAL">Social</Select.Option>
                      <Select.Option key="SITUATIONAL">Situacional</Select.Option>
                    </Select>
                  )}
                </Popover>
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.problem ? "" : "error" : "" }>
                {renderReviewableField("problem", {
                  initialValue: fields.problem
                })!(
                  <Input.TextArea
                    rows={3}
                    placeholder="Problema a atender"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.description ? "" : "error" : "" }>
                {renderReviewableField("description", {
                  initialValue: fields.description
                })!(
                  <Input.TextArea
                    rows={3}
                    placeholder="Descripción del proyecto"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.beneficiariesProfile ? "" : "error" : "" }>
                {renderReviewableField("beneficiariesProfile", {
                  initialValue: fields.beneficiariesProfile
                })!(
                  <Input.TextArea
                    rows={3}
                    placeholder="Perfil de los beneficiarios"
                    disabled={disabled}
                  />
                )}
              </Form.Item>
              <Form.Item validateStatus={checkFields ? fields.substantiation ? "" : "error" : "" }>
                <Popover
                  placement="right"
                  content={projectJustificationInfo}
                  trigger="click"
                  overlayStyle={reviewable && ({ display: "none" })}>
                  {renderReviewableField("substantiation", {
                    initialValue: fields.substantiation
                  })!(
                    <Input.TextArea
                      rows={3}
                      placeholder="Justificación del proyecto"
                      disabled={disabled}
                      style={{ marginBottom: 0 }}
                    />
                  )}
                </Popover>
              </Form.Item>
            </FormSection>
          </Form>
        </Col>
      </Row>
    );
  }
}

const onFieldsChange = (props: any, changedFields: any) => {
  const field = (Object.values(changedFields)[0] || {}) as { name: string, value: string | [string] };

  if (!props.reviewable) {
    props.onChange(field.name, field.value);
  }
};

const WrappedProjectForm = Form.create({ name: "project", onFieldsChange })(ProjectForm);

export default WrappedProjectForm;
