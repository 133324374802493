import gql from "graphql-tag";

export const SaveTechnicalMonitoring = gql`
  mutation SaveTechnicalMonitoring(
    $monitoringEntryId: String!
    $activityId: String!
    $activityMonitoringEntryId: String
    $plannedActivities: Int!
    $activitiesDone: Int!
    $hoursInvested: Int!
    $activityBeneficiariesMonitoringEntries: [UpsertActivityBeneficiaryMonitoringEntry!]!
    $qnIndicatorMonitoringEntries: [UpsertIndicatorMonitoringEntry!]!
    $qxIndicatorMonitoringEntries: [UpsertIndicatorMonitoringEntry!]!
  ) {
    saveActivityMonitoringEntry(
      activityMonitoringEntryId: $activityMonitoringEntryId
      monitoringEntryId: $monitoringEntryId
      activityId: $activityId,
      plannedActivities: $plannedActivities,
      activitiesDone: $activitiesDone,
      hoursInvested: $hoursInvested
    ) {
      id
    }

    saveActivityBeneficiaryMonitoringEntries(
      monitoringEntryId: $monitoringEntryId
      data: $activityBeneficiariesMonitoringEntries
    ) {
      id
    }

    saveQnIndicatorMonitoringEntries(
      monitoringEntryId: $monitoringEntryId
      data: $qnIndicatorMonitoringEntries
    ) {
      id
    }

    saveQxIndicatorMonitoringEntries(
      monitoringEntryId: $monitoringEntryId
      data: $qxIndicatorMonitoringEntries
    ) {
      id
    }
  }
`;