import React from "react";
import { withRouter } from "react-router";
import { Skeleton } from "antd";
import { Query } from "react-apollo";

import { PreRequestConfirmationPageProps, IQueryResult } from "./types";
import { IPreRequest } from "../../../../types";
import { PreRequest } from "../../../../gql/queries";
import Layout, { Content } from "../../../organisms/Layout";
import {
  StyledContainer,
  StyledFormContainer,
  InfoTable
} from "./components";

class PreRequestConfirmationPage extends React.Component<PreRequestConfirmationPageProps> {
  public render() {
    const { match: { params } } = this.props;
    const { newPreRequestId = "" } = params;

    return (
      <Layout>
        <Query query={PreRequest} variables={{ id: newPreRequestId }}>
          {({ loading, data, error }: IQueryResult) => {
            if (loading || error !== undefined || data === undefined) {
              return (
                <Content>
                  <StyledContainer>
                    <StyledFormContainer>
                      <Skeleton loading />
                      <Skeleton loading />
                    </StyledFormContainer>
                  </StyledContainer>
                </Content>
              );
            }
            let preRequestDetails: Partial<IPreRequest> = {};

            if (data?.preRequest !== undefined) {
              preRequestDetails = { ...data?.preRequest };
            }

            return <InfoTable preRequest={preRequestDetails} />;
          }}
        </Query>
      </Layout>
    );
  }
}

export default withRouter(PreRequestConfirmationPage);
