import gql from "graphql-tag";

export const ApprovedProjects = gql`
  query ApprovedProjects(
    $skip: Int
    $sortField: String
    $sortDirection: String
  ) {
    openProjects: projects(
      closed: true
      concluded: false
      skip: $skip
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        startDate
        endDate
        closed
      }
      count
    }
    concludedProjects: projects(
      closed: true
      concluded: true
      skip: $skip
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        startDate
        endDate
        closed
      }
      count
    }
  }
`;
