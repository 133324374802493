import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";

import { BudgetCommentsModal } from "../molecules";
import { RoleConsumer } from "../RoleConsumer";

interface ICommentsButtonProps {
  active: boolean;
}

interface IBudgetCommentsInputProps {
  supplyBudgetId: string;
}

const CommentsButton = styled(Button) <ICommentsButtonProps>`
  color: ${({ active }) => active && "#ffffff"};
  background-color: ${({ active }) => active && "#8e1d41"};

  &:hover {
    color: ${({ active }) => active && "#ffffff"};
    background-color: ${({ active }) => active && "#8e1d41"};
    border-color: ${({ active }) => active && "#ffffff"};
  }
`;

export const BudgetCommentsInput: React.FC<IBudgetCommentsInputProps> = ({ supplyBudgetId }) => {
  const [visible, setVisible] = useState(false);

  const toggleModal = () =>
    setVisible(!visible);

  return (
    <RoleConsumer>
      {({ claims }) => (
        <>
          <BudgetCommentsModal
            closable
            onCancel={toggleModal}
            visible={visible}
            supplyBudgetId={supplyBudgetId}
            role={claims?.role} />
          <CommentsButton
            active={false}
            onClick={toggleModal}
            icon="message" />
        </>
      )}
    </RoleConsumer>
  )
};
