import React, { useContext } from "react";
import { Descriptions } from "antd";
import styled from "styled-components";
import { SectionCard } from "../../../../organisms/ImplementerLayout";
import RequestDetailsTemplateContext from "../RequestDetailsTemplateContext";
import { IPreRequest } from "../../../../../types";
import {
  getSuppliesWithSubTotals,
  formatToCurrency,
  getPercentage
} from "../../../../../utils";
import { getTotals } from "../helpers";

const AmountLabel = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const FinancialSection = (props: any) => {
  const { request = {} } = useContext(RequestDetailsTemplateContext);
  const { preRequest = {} as IPreRequest } = request;
  const suppliesWithSubtotals = getSuppliesWithSubTotals(request?.project?.supplies);
  const {
    ficosecTotal = 0,
    coInvestmentTotal = 0
  } = getTotals(suppliesWithSubtotals);

  const formatedFicosec = formatToCurrency(ficosecTotal);
  const formatedCoInvest = formatToCurrency(coInvestmentTotal);
  const ficoPct = getPercentage(preRequest.estimatedBudget, ficosecTotal);
  const coInvestPct = getPercentage(preRequest.estimatedBudget, coInvestmentTotal);

  return (
    <SectionCard
      title="Financiero">
      <Descriptions layout="vertical">
        <Descriptions.Item
          label={<b>Presupuesto estimado</b>}>
          <AmountLabel>
            {formatToCurrency(preRequest?.estimatedBudget)} MXN
          </AmountLabel>
        </Descriptions.Item>
        <Descriptions.Item
          label={<b>Solicitado a FICOSEC</b>}>
          <AmountLabel>
            {formatedFicosec} ({ficoPct} %)
          </AmountLabel>
        </Descriptions.Item>
        <Descriptions.Item
          label={<b>Co-inversión</b>}>
          <AmountLabel>
          {formatedCoInvest} ({coInvestPct} %)
          </AmountLabel>
        </Descriptions.Item>
      </Descriptions>
    </SectionCard>
  );
};
