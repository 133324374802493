import React from "react";
import { List, Avatar, Typography } from 'antd'
import styled from "styled-components";

export const ReverseDirectionContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20pt;
`;

interface NotifierProps {
  listError: {
    step: number,
    title: string
  }[];
}

export const Notifier = (props: NotifierProps) => {
  return (
    <>
      <Typography.Paragraph>
        Aún hay datos faltantes, por favor, verifica que todos los datos estén correctos.
      </Typography.Paragraph>
      <List
        itemLayout="horizontal"
        dataSource={props.listError}
        renderItem={item => (
          <List.Item style={{border: "none" }}>
            <List.Item.Meta
              style={{alignItems: "center"}}
              avatar={<Avatar>{item?.step}</Avatar>}
              title={item?.title}
            />
          </List.Item>
        )}
      />
    </>
  )
};
