import React from "react";
import { formatCurrency } from "../helpers";

export const totalPerYearTableColumns = [
  {
    key: "label",
    dataIndex: "label",
    title: "",
    render: (totalFicosec: number) =>
    <div style={{ textAlign: "left" }}>
      { totalFicosec } 
    </div>
  },
  {
    key: "totalFicosec",
    title: "Ficosec",
    dataIndex: "totalFicosec",
    render: (totalFicosec: number) => formatCurrency(totalFicosec)
  },
  {
    key: "totalCoInvestment",
    title: "Co-inversión",
    dataIndex: "totalCoInvestment",
    render: (totalCoInvestment: number) => formatCurrency(totalCoInvestment)
  },
];
