import React, { useState } from "react";
import { Typography } from "antd";
import { getTime, startOfMonth, format } from "date-fns";
import { flatten } from "lodash";
import { es } from "date-fns/locale";

import { TableContainer } from "../../../../pages/projects";
import { ASupply, IMonthlyBudget, IBudgetVerification, InvestorType } from "../../../../../types";
import { MonitoringBudgetBreakdownTable, BreakdownTableType } from "../../../../shared/misc";
import { BudgetVerificationModal } from "../../../implementer/ProjectMonitoringTemplate/components/BudgetVerificationModal";
import { IBudgetContextState } from "../../../implementer/ProjectMonitoringTemplate/components/BudgetBreakdownTab";

interface IBudgetBreakdownTab {
  loading: boolean;
  supplies: ASupply[];
}

export const BudgetBreakdownTab: React.FC<IBudgetBreakdownTab> = ({ loading, supplies }) => {
  const [budgetContext, setSupplyContext] = useState<IBudgetContextState | undefined>(undefined);

  const openBudgetVerificationModal = (budgetId: string, type: InvestorType) =>
    setSupplyContext({ budgetId, type });

  const closeSupplyVerificationModal = () =>
    setSupplyContext(undefined);

  supplies.forEach((supply: ASupply) => supply.children = supply.monthlyBudget);

  const getSelectedBudget = () => {
    let budget: IMonthlyBudget | undefined;

    if (budgetContext !== undefined) {
      supplies.forEach((supply) => {
        if (budget !== undefined) {
          return;
        }

        budget = supply.monthlyBudget.find((monthBudget) => monthBudget.id === budgetContext.budgetId);
      });
    }

    return budget;
  };

  supplies.forEach((supply: ASupply) => supply.children = supply.monthlyBudget);

  const monthlyBudgets: Array<IMonthlyBudget & { supplyId: string }> = [];
  supplies.forEach((supply) =>
    supply.monthlyBudget.forEach((month) => monthlyBudgets.push({ ...month, supplyId: supply.id })));

  const budgetVerifications: Array<IBudgetVerification & { budgetId: string }> = [];
  monthlyBudgets.forEach((budget) =>
    budget.budgetVerification?.forEach((verification) => budgetVerifications.push({ ...verification, budgetId: budget.id })));

  const budgets: { [monthKey: string]: IMonthlyBudget[] } = {};
  monthlyBudgets.forEach((month) => {
    const integerTimestamp = parseInt(month.month as string, 10);
    const date = new Date(integerTimestamp);
    const monthKey = getTime(startOfMonth(date));
    const budget = budgets[monthKey] || [];
    budget.push(month);
    budgets[monthKey] = budget;
  });

  const getBudgeted = (monthlyBudgets: IMonthlyBudget[]) => ({
    ficosec: monthlyBudgets.reduce((acc, curr) => acc + curr.ficosec, 0),
    coinvestor: monthlyBudgets.reduce((acc, curr) => acc + curr.coinvestor, 0)
  });

  const getBudgetVerifications = (budgetVerifications: IBudgetVerification[], type: InvestorType) =>
    budgetVerifications.filter((verification) => verification.type === type);

  const getCombinedVerifications = (budgetVerifications: IBudgetVerification[], type: InvestorType) =>
    ({ consumed: budgetVerifications.reduce((acc, curr) => acc + curr.consumed, 0), type });

  const getVerifications = (monthlyBudgets: IMonthlyBudget[]) => {
    const verifications = flatten(monthlyBudgets.map((monthlyBudget) => monthlyBudget.budgetVerification || []));
    const ficosecVerification =
      getCombinedVerifications(
        getBudgetVerifications(verifications, InvestorType.FICOSEC),
        InvestorType.FICOSEC
      );

    const coinvestorVerification =
      getCombinedVerifications(
        getBudgetVerifications(verifications, InvestorType.COINVESTOR),
        InvestorType.COINVESTOR
      );

    return {
      ficosecVerification,
      coinvestorVerification
    };
  };

  const subtotal = Object.keys(budgets).map((budget) => {
    const integerTimestamp = parseInt(budget, 10);
    const budgeted = getBudgeted(budgets[budget]);
    const verifications = getVerifications(budgets[budget]);

    return {
      id: budget,
      name: format(integerTimestamp, "MMMM 'de' yyyy", { locale: es }),
      month: budget,
      ficosec: budgeted.ficosec,
      coinvestor: budgeted.coinvestor,
      budgetVerification: [
        verifications.ficosecVerification,
        verifications.coinvestorVerification
      ]
    };
  });

  const total = () => {
    const budgeted = getBudgeted(monthlyBudgets);
    const verifications = getVerifications(monthlyBudgets);

    return [
      {
        id: "0",
        name: "Total trimestral",
        ficosec: budgeted.ficosec,
        coinvestor: budgeted.coinvestor,
        budgetVerification: [
          verifications.ficosecVerification,
          verifications.coinvestorVerification
        ]
      }
    ];
  };

  return (
    <>
      <BudgetVerificationModal
        visible={budgetContext !== undefined}
        budget={getSelectedBudget()}
        investorType={budgetContext?.type}
        onCancel={closeSupplyVerificationModal} />
      <TableContainer>
        <Typography.Paragraph>Resumen de presupuesto y comprobaciones</Typography.Paragraph>
        <MonitoringBudgetBreakdownTable
          loading={loading}
          type={BreakdownTableType.TOTAL}
          dataSource={total()} />
        <MonitoringBudgetBreakdownTable
          loading={loading}
          type={BreakdownTableType.SUBTOTAL}
          dataSource={subtotal} />
      </TableContainer>
      <TableContainer>
        <MonitoringBudgetBreakdownTable
          loading={loading}
          type={BreakdownTableType.BREAKDOWN}
          onOpenBudgetVerificationModal={openBudgetVerificationModal}
          dataSource={supplies} />
      </TableContainer>
    </>
  );
};
