import React, { Component } from "react";
import { message, Form } from "antd";
import "moment/locale/es";

import { WorktableMeetingModalProps } from "./types";
import { Modal } from "../../atoms";
import { SaveButton, CancelButton, Form as MeetingForm } from "./components";
import { MeetingFormContext } from "./MeetingFormContext";
import { createWorktableMeeting, updateWorktableMeeting } from "./helpers";

interface IWorktableMeetingModalState {
  isBusy: boolean;
}

class WorktableMeetingModal extends
  Component<WorktableMeetingModalProps, IWorktableMeetingModalState> {
  public state: IWorktableMeetingModalState = {
    isBusy: false
  };

  public render() {
    const {
      form,
      action,
      visible,
      onClose,
      onInputChange,
      worktableMeeting,
      worktable
    } = this.props;

    return (
      <Modal
        slimFooter
        closable
        title={action === "CREATE" ? "Nueva reunión" : "Editar reunión"}
        visible={visible}
        afterClose={this.afterClose}
        onCancel={onClose}
        footer={[
          <SaveButton
            isBusy={this.state.isBusy}
            onSaveMeeting={this.onSaveMeeting} />,
          <CancelButton
            onClose={onClose} />
        ]}>
        <MeetingFormContext.Provider
          value={{
            worktableMeeting,
            form,
            onInputChange,
            worktable
          }}>
          <MeetingForm />
        </MeetingFormContext.Provider>
      </Modal>
    );
  }

  private afterClose = async () => {
    const { form } = this.props;
    form.resetFields();
  }

  private onSaveMeeting = async () => {
    const { action, form } = this.props;

    form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        try {
          if (action === "CREATE") {
            this.createNewMeeting();
          } else if (action === "EDIT") {
            this.updateMeeting();
          }
        } catch (err) {
          message.error("Ha ocurrido un error");
        }
      }
    });
  }

  private toggleIsBusy = () => {
    this.setState((prevState) => ({
      isBusy: !prevState.isBusy
    }));
  }

  private createNewMeeting = async () => {
    const { worktableMeeting, onClose, workTableId } = this.props;
    if (worktableMeeting) {
      this.toggleIsBusy();
      try {
        await createWorktableMeeting(worktableMeeting, workTableId);
        onClose();
        message.success("La reunión ha sido creada.");
      } catch (error) {
        message.error("Ha ocurrido un error.");
      } finally {
        this.toggleIsBusy();
      }
    }
  }

  private updateMeeting = async () => {
    const { worktableMeeting, onClose, workTableId } = this.props;
    if (worktableMeeting) {
      this.toggleIsBusy();
      try {
        await updateWorktableMeeting(worktableMeeting.id!, worktableMeeting, workTableId);
        onClose();
        message.success("La reunión ha sido guardada.");
      } catch (error) {
        message.error("Ha ocurrido un error.");
      } finally {
        this.toggleIsBusy();
      }
    }
  }
}

const WrappedWorktableMeetingModal = Form.create<WorktableMeetingModalProps>({
  name: "WorktableMeetingModal"
})(WorktableMeetingModal);

export default WrappedWorktableMeetingModal;
