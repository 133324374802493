import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import { useQuery } from "@apollo/react-hooks";

import { MonitoringTemplate, ErrorTemplate } from "../../../templates";
import { MonitoringStatus } from "../../../organisms/MonitoringTab";
import { client } from "../../../../gql/apollo";
import { ApprovedProjects } from "../../../../gql/queries";
import { MonitoringTemplateContext } from "../../../templates/projects/MonitoringTemplateContext";
import { IPaginationSignature, sortDirectionMap } from "../../requests/ReviewalPage";

type MonitoringPageProps = Pick<RouteComponentProps<{ status: MonitoringStatus }>, "match">;

const MonitoringPage: React.FC<MonitoringPageProps> = ({ match }) => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const queryResult = useQuery(ApprovedProjects, { client } as any);
  const { error, refetch } = queryResult;

  if (error) {
    // TODO: Report to bugsnag
    console.error(error);
    return <ErrorTemplate />;
  }

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);


  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<any>,
    sorter: SorterResult<any>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <MonitoringTemplateContext.Provider value={{ ...queryResult, onPaginationChange: handleOnPaginationChange }}>
      <MonitoringTemplate currentTab={match.params.status} />
    </MonitoringTemplateContext.Provider>
  );
};

export default MonitoringPage;
