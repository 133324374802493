import React from "react";
import { Row, Button } from "antd";
import { FormSection } from "../../../../../../molecules";
import { IActivity, ActivityType } from "../../../types";
import { ActivitySection } from ".";

interface IFormActivitiesProps {
  form?: any;
  activities: IActivity[];
  onChange: any;
  onAddActivity: (activityType: ActivityType, activityIndex?: number) => void;
  onRemoveActivity: (
    activityType: ActivityType,
    activityIndex: number,
    subActivityIndex?: number) => void;
}

const ActivityForm: React.FC<IFormActivitiesProps> = ({
  form,
  onChange,
  activities = [],
  onAddActivity,
  onRemoveActivity }) => {

  const renderActivities = () => {
    return activities.map((activity, i) => {
      return (
        <ActivitySection
          form={form}
          onChange={onChange}
          key={activity.id}
          onRemoveActivity={onRemoveActivity}
          onAddActivity={onAddActivity}
          activityIndex={i}
          activity={activity} />);
    });
  };

  const addActivity = () => {
    onAddActivity("activity");
  };

  return(
    <FormSection title="ACTIVIDADES">
      <div style={{ marginBottom: 14 }}>
        {renderActivities()}
      </div>
      <Row>
        <Button
          onClick={addActivity}
          type="dashed"
          style={{ fontWeight: "bold" }}>
          + Agregar Actividad
        </Button>
      </Row>
    </FormSection>
  );
};

export default ActivityForm;
