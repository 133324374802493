import { Supply } from "../types";
import { IProcessedSupplyItem, IMonthlyBudget } from "../components/shared/misc/BudgetSummaryTable/types";

const getTotalSupply = (supply: Partial<Supply>) => {
  const { monthlyBudget = {} } = supply;

  const months = Object.keys(monthlyBudget);
  const monthBudgets = months.map((month) =>
    (monthlyBudget[month]?.ficosec || 0) + (monthlyBudget[month]?.coinvestor || 0));
  return monthBudgets.reduce((acc, curr) => acc + curr, 0);
};

const getMonthlySubTotal = (
  monthlyBudget: IMonthlyBudget = {},
  type: "ficosec" | "coinvestor") => {

    return Object
      .values(monthlyBudget)
      .filter((m = { ficosec: 0, coinvestor: 0 }) => !!m[type])
      .reduce(((acc, cur) => acc + cur[type]), 0);
};

const getPercentage = (total: number = 0, fraction: number = 0) => {
  if (total <= 0) {
    return 0;
  }

  const percent =  (fraction * 100) / total;
  return parseFloat(percent.toFixed(2));
}

export const getSuppliesWithSubTotals = (supplies: Array<Partial<Supply>> = []): IProcessedSupplyItem[] => {
  return supplies.map((supply, index) => {

    const totalSupply: number = getTotalSupply(supply);

    const ficosecSubTotal: number =
      getMonthlySubTotal(supply.monthlyBudget, "ficosec");
    const coInvestmentSubTotal: number =
      getMonthlySubTotal(supply.monthlyBudget, "coinvestor");

    const ficosecSubTotalPct = getPercentage(totalSupply, ficosecSubTotal);
    const coInvestmentSubTotalPct = getPercentage(totalSupply, coInvestmentSubTotal);

    return {
      key: index,
      name: supply.name || "",
      expenseType: supply.expenseType,
      isVerifiable: supply.isVerifiable || false,
      monthlyBudget: supply.monthlyBudget,
      ficosecSubTotal,
      ficosecSubTotalPct,
      coInvestmentSubTotal,
      coInvestmentSubTotalPct
    };
  });
};
