import gql from "graphql-tag";

export const CreateWorktableAgreement = gql`
  mutation CreateWorktableAgreement(
    $worktableMeetingId: String!,
    $description: String!,
    $responsible: String!,
    $deadline: DateTime!,
    $documents: [CreateAttachmentInput!],
  ) {
    createWorktableAgreement(
      worktableMeetingId: $worktableMeetingId,
      data : {
        description: $description
        responsible: $responsible
        deadline: $deadline
        documents: $documents
      }
    ) {
      id
      description
      responsible
      deadline
      documents {
        id
        fileName
        ref
      }
    }
  }`;
