import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { Sidebar, StepIndicator } from "../../../../../atoms";
import { menuItems, getCurrentStep } from "./helpers";
import styles from "./styles.less";

type CreateWorkTablePageRouteProps =
  Pick<RouteComponentProps<{ currentStep: string }>, "match">;

type CustomSidebarPropsType = RouteComponentProps & CreateWorkTablePageRouteProps;

const CustomSidebar: React.FC<CustomSidebarPropsType> =
  ({ match }) => {
    return (
      <Sidebar>
        <Sidebar.Heading>Nueva mesa de trabajo</Sidebar.Heading>
        <Menu
          className={styles.menu as string}
          selectedKeys={[
            getCurrentStep(match.params.currentStep).toString()
          ]}>
          {menuItems.map((
            { label, link }: { label: string, link: string },
            index) => {
            return (
              <Menu.Item key={index}>
                <StepIndicator label={(index + 1).toString()} />
                <Link to={link}>
                  <span className={styles.itemLabel as string}>
                    {label}
                  </span>
                </Link>
              </Menu.Item>);
          })}
        </Menu>
      </Sidebar>
    );
  };

export default withRouter(CustomSidebar);
