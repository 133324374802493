import React from "react";
import { Col } from "antd";

const InfoLabel = (props: any) => {
  return (
    <Col
      span={9}
      {...props}>
      <strong>
        {props.children}
      </strong>
    </Col>
  );
};

export default InfoLabel;
