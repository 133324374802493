import gql from "graphql-tag";

// @todo UPDATE THE GRAPHQL RESOLVER QUERY TO ACCEPT "WHERE"
// CONSTRAINTS IN ORDER TO BE ABLE TO FILTER THE RESULSTS ACCORDING TO CERTAIN
// CRITERIA AS LIKE AS PER STATUS, PER ID OR PER IMPLEMENTER
export const ImplementerPreRequest = gql`
  query ImplementerPreRequest ($preRequestId: String!) {
    preRequest(id: $preRequestId) {
      id
      folio
      estimatedBudget
      organizationDescription
      projectDescription
      projectName
      annotation
      createdAt
      status
      type
      area
      request {
        id
        project {
          id
        }
      }
    }
  }
`;
