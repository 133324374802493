import React from "react";
import { Table as ANTTable, message } from "antd";
import { UploadFile, UploadChangeParam } from "antd/lib/upload/interface";
import styled from "styled-components";

import styles from "./styles.less";
import { Activity, Supply, HRProfile } from "../../../../types/Request";
import { Attachment } from "../../../../types/Attachment";
import { RequestReviewContextOwnProps } from "../../../RequestReviewContext";
import { getColumnsDefinition } from "./helpers";
import { EquipmentBillsModal } from "./components";

interface BudgetBreakdownTableProps {
  disabled: boolean;
  reviewable: boolean;
  approval: boolean;
  busy: boolean;
  reviewState: Partial<RequestReviewContextOwnProps>;
  activities: Partial<Activity>;
  HRProfiles: { [id: string]: Partial<HRProfile> };
  supplies: Array<Partial<Supply>>;
  onRowChange: (
    supplyId: string,
    field: string,
    value?: any) => void;
  onRowRemove: (supplyId: string) => void;
  projectDuration: string[] | number[];
  addEquipmentBill?: any;
  checkFields: boolean;
  reviews: any;
}

interface IBudgetBreakdownTableState {
  billsModal: {
    isVisible: boolean,
    equipmentBills: Attachment[],
    selectedSupplyId: string;
    fileList: UploadFile[];
  };
}

const Table = styled(ANTTable)`
  white-space: nowrap;
`;

class BudgetBreakdownTable extends React.Component<BudgetBreakdownTableProps, IBudgetBreakdownTableState> {
  public state: IBudgetBreakdownTableState = {
    billsModal: {
      selectedSupplyId: "",
      isVisible: false,
      equipmentBills: [],
      fileList: []
    }
  };

  public render() {
    const { supplies, busy } = this.props;
    const { billsModal } = this.state;

    const monthlyBudget = {};
    const monthlyBudgets = supplies.map((supply) => supply.monthlyBudget);
    monthlyBudgets.map((budget) => {
      if (budget) {
        const months = Object.keys(budget);
        months.map((month: string) => {
          const ficosec = monthlyBudget[month]?.ficosec || 0;
          const coinvestor = monthlyBudget[month]?.coinvestor || 0;

          monthlyBudget[month] = {
            ficosec: ficosec + (budget[month]?.ficosec || 0),
            coinvestor: coinvestor + (budget[month]?.coinvestor || 0)
          };
        });
      }
    });

    const totalCosts = { summary: "Subtotal mensual", monthlyBudget };

    const totalFicosec = Object.keys(monthlyBudget)
      .map((month) => monthlyBudget[month]?.ficosec as number)
      .reduce((acc, curr) => acc + curr, 0);
    const ficosecTotal = { summary: "Total FICOSEC", computedTotal: totalFicosec };

    const totalCoinvestor = Object.keys(monthlyBudget)
      .map((month) => monthlyBudget[month]?.coinvestor as number)
      .reduce((acc, curr) => acc + curr, 0);
    const coinvestorTotal = { summary: "Total co-inversión", computedTotal: totalCoinvestor };

    return (
      <>
        <EquipmentBillsModal
          fileList={this.state.billsModal.fileList}
          afterClose={this.handleAfterClose}
          onChange={this.handleFileOnChange}
          supply={this.props.supplies
            .find((s) => s.id === this.state.billsModal.selectedSupplyId)}
          onOk={this.saveEquipmentBillsChanges}
          okText="Guardar"
          onCancel={this.toggleModal}
          visible={billsModal.isVisible} />
        <Table
          tableLayout="fixed"
          pagination={false}
          className={styles.table}
          columns={...this.getColumns()}
          rowKey="id"
          dataSource={[...supplies, totalCosts, ficosecTotal, coinvestorTotal]}
          bordered
          scroll={{ x: "auto" }} />
      </>
    );
  }

  private toggleModal = () => {
    this.setState((prevState: IBudgetBreakdownTableState) => ({
      billsModal: {
        ...prevState.billsModal,
        isVisible: !prevState.billsModal.isVisible
      }
    }));
  }

  private handleFileOnChange = (event: UploadChangeParam): void => {
    const { file, fileList } = event;

    switch (file?.status) {
      case "done":
        this.handleOnFileChange(event.file, "add", fileList);
        break;
      case "uploading":
        const { billsModal } = this.state;
        this.setState({
          billsModal: {
            ...billsModal,
            fileList
          }
        });
        break;
      case "error":
        message.error("Ha ocurrido un error al cargar el archivo.");
        break;
      case "removed":
        this.handleOnFileChange(event.file, "remove", fileList);
        break;
      default:
        message.error("Ha ocurrido un error al cargar el archivo.");
        break;
    }
  }

  private openEquipmentBillsModal = (supplyId = "") => {
    const { supplies = [] } = this.props;
    const supply = supplies.find((s) => s.id === supplyId) || {};

    this.setState((prevState) => ({
      billsModal: {
        ...prevState.billsModal,
        isVisible: true,
        selectedSupplyId: supplyId,
        fileList: this.formatToFileList(supply.equipmentBills),
        equipmentBills: supply.equipmentBills || []
      },
    }));
  }

  private formatToFileList(files: Attachment[] = []): UploadFile[] {
    return (files || []).map((file, index) => {
      return {
        uid: index.toString(),
        name: file.fileName,
        status: "done",
        url: file.ref
      } as UploadFile;
    });
  }

  private handleAfterClose = () => {
    const { billsModal } = this.state;
    this.setState({
      billsModal: {
        ...billsModal,
        fileList: [],
        equipmentBills: []
      }
    });
  }

  private handleOnFileChange = (
    uploadedFile: UploadFile,
    action: "add" | "remove",
    fileList: UploadFile[]): void => {
    const { onRowChange } = this.props;
    const { billsModal } = this.state;
    const { equipmentBills = [] } = billsModal;

    switch (action) {
      case "add":
        equipmentBills.push({
          id: uploadedFile.uid,
          fileName: uploadedFile.name,
          ref: uploadedFile?.response?.url
        });

        onRowChange(
          billsModal.selectedSupplyId,
          "equipmentBills",
          equipmentBills
        );
        break;
      case "remove":
        onRowChange(
          billsModal.selectedSupplyId,
          "equipmentBills",
          equipmentBills.filter((eb) => eb.id !== uploadedFile.uid)
        );
        break;
    }
    this.setState({
      billsModal: {
        ...billsModal,
        fileList
      }
    });
  }

  private saveEquipmentBillsChanges = () => {
    // @todo Implement the edit supply and files uploading to S3 service
    this.toggleModal();
  }

  private getColumns = () => {
    const { reviewState, busy } = this.props;
    return getColumnsDefinition(reviewState, this.props, this.openEquipmentBillsModal, busy);
  }
}

export default BudgetBreakdownTable;
