import gql from "graphql-tag";

export const CreateGoal = gql`
  mutation CreateGoal(
    $specificObjectiveId: String!
    $id: String!
    $description: String
    $qualitativeIndicators: [CreateGoalIndicatorInput!]!
    $quantitativeIndicators: [CreateGoalIndicatorInput!]!
  ) {
    createGoal(
      specificObjectiveId: $specificObjectiveId
      data: {
        id: $id
        description: $description
        qualitativeIndicators: $qualitativeIndicators
        quantitativeIndicators: $quantitativeIndicators
      }
    ) {
      id
      description
      qualitativeIndicators {
        id
      }
      quantitativeIndicators {
        id
      }
    }
  }
`;
