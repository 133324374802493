import React from "react";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";

import { RequestReviewTemplate } from "../../templates/requests";
import Layout from "../../organisms/Layout";
import { RequestReviewProvider } from "../../RequestReviewContext";

export type RequestReviewPageRouteProps = Pick<RouteComponentProps<{ reqId: string, currentStep: string }>, "match">;

interface RequestReviewPageOwnProps {
  approval?: boolean;
}

type RequestReviewPageProps = RequestReviewPageOwnProps & RequestReviewPageRouteProps;

class RequestReviewPage extends React.Component<RequestReviewPageProps> {
  public render() {
    const { match, approval } = this.props;
    const requestId = match.params.reqId;

    if (!match.params.currentStep) {
      return <Redirect to={`${match.url}/implementadora`} />;
    }

    return (
      <Layout noPadding>
        <RequestReviewProvider requestId={requestId}>
          <RequestReviewTemplate
            approval={approval || false}
            review={!approval}
            request={{ id: requestId }}
            currentStep={match.params.currentStep} />
        </RequestReviewProvider>
      </Layout>
    );
  }
}

export default RequestReviewPage;
