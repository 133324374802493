import gql from "graphql-tag";

export const CreateMeeting = gql`
  mutation CreateWorktableMeeting (
    $worktableId: String!,
    $name: String!,
    $subject: String!,
    $place: String!,
    $date: DateTime!,
    $objective: String!
  ) {
    createWorktableMeeting(
      worktableId: $worktableId,
      data : {
        name: $name
        subject: $subject
        place: $place
        date: $date
        objective: $objective
      }
    ) {
      id
      subject
      place
      date
      objective
    }
  }`;
