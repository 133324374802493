import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { ActivityCard, ActivityModal } from "./components";
import { TechnicalMonitoring } from "../../../../gql/queries";
import { client } from "../../../../gql/apollo";
import { LoadingScreen, ErrorScreen } from "../../../atoms";
import { Activity } from "../../../../types";

export const TechnicalMonitoringTab: React.FC = () => {
  const { projectId, monitoringEntry } = useParams();
  const { loading, data, error } = useQuery(TechnicalMonitoring, {
    variables: {
      projectId,
      monitoringEntry
    },
    client
  } as any);
  const [modal, setModal] = useState<string | undefined>();

  if (error) {
    // TODO: Report to bugsnag
    console.error(error);
    const errorMessage = "Ocurrió un problema al cargar las actividades.";
    return <ErrorScreen message={errorMessage} />
  }

  if (loading) {
    return <LoadingScreen />;
  }

  const activities: Activity[] = data?.projectActivities;

  const toggleActivityModal = (activityId?: string) =>
    setModal(activityId);

  const getOpenActivity = () =>
    activities.find((activity) => activity.id === modal);

  return (
    <>
      <ActivityModal
        visible={!!modal}
        activity={getOpenActivity()}
        onCancel={() => toggleActivityModal()} />
      {activities.map((activity) => (
        <ActivityCard
          id={activity.id}
          name={activity.description}
          onClickUpdate={toggleActivityModal} />
      ))}
    </>
  );
};