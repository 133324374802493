import React from "react";
import { Layout } from "antd";
import { LayoutProps } from "antd/lib/layout";
import styled from "styled-components";

const { Content: ANTContent} = Layout;
const StyledContent = styled(ANTContent)`
  background-color: #ececec;
  margin-top: 64px; 
`;

/**
 * Styled Ant Content component.
 * It has a margin-top as space compensation because the navbar is fixed
 * @param props 
 */
export const ContentContainer: React.FC<LayoutProps> = (props) => {
  return <StyledContent {...props} />;
};

/**
 * Styled Ant Content component.
 * Use this component to wrap the content of the view. It has a default margin
 * of 10pt but you can overwrite the styles
 * @param props 
 */
export const Content: React.FC<LayoutProps> = ({style, ...props}) => {
  return (
    <ANTContent
      {...props}
      style={{
        margin: "10pt",
        ...style
      }} />
  );
};
