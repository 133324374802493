import React from "react";
import { Row, Col } from "antd";
import { CenteredCol } from ".";
import { beneficiaryTypesMap, assistanceFrequency } from "../../../../../utils/maps";

const renderBeneficiariesRows = (beneficiaries: any[] = []) => {
  return (
    beneficiaries
      .map((beneficiary: any, indexB: number) => {
        const isLast = beneficiaries.length === indexB + 1;
        let beneficiaryType = "";

        if (beneficiary?.type?.length === 1) {
          beneficiaryType = beneficiaryTypesMap[beneficiary?.type[0]];
        } else if (beneficiary?.type?.length === 2) {
          beneficiaryType = beneficiaryTypesMap[beneficiary?.type[1]];
        } else {
          beneficiaryType = "Otro"
        }

        return (
          <CenteredCol
            style={{
              width: "100%",
              alignItems: "flex-start",
              borderBottom: isLast ?
                "none" : "solid 1px #ddd"
            }}>
            {beneficiary?.goalValue} -
            {beneficiaryType} -
            {beneficiary?.description} - 
              Asistencia {assistanceFrequency[beneficiary?.assistance]?.toLocaleLowerCase()}
          </CenteredCol>
        );
      })
  )
};

export const GoalActivitiesColumn = (props: any) => {
  const {
    goalActivities = [],
    activities = []
  } = props;
  return (
    <Row style={{ height: "100%" }}>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          height: "100%"
        }}
        span={24}>
        {goalActivities.map((activityId: string, indexA: number) => {
          const activity = activities[activityId] || {};
          const { beneficiaries = {} } = activity;
          const activityBeneficiaries = [] = Object
            .values(beneficiaries)
            .map((beneficiary: any) => beneficiary);
          
          const isLastAct = goalActivities.length === indexA + 1;

          return (
            <Row
              style={{
                borderBottom: isLastAct ?
                  "none" : "solid 1px #ddd",
                height: "100%"
              }}>
              <CenteredCol
                style={{
                  borderRight: "solid 1px #ddd",
                  height: "100%"
                }}
                span={12}>
                {activity?.description}
              </CenteredCol>
              <Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch"
                }}>
                {renderBeneficiariesRows(activityBeneficiaries)}
              </Col>
            </Row>);
        })}
      </Col>
    </Row>
  );
};

