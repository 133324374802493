import React from "react";
import { Row, Button } from "antd";
import { FormSection } from "../../../../../../molecules";
import { MemberForm } from ".";
import {
  IMember,
  IEvent,
  MemberStateKey
} from "../../../types";

interface IFormMembersProps {
  form: any;
  members?: IMember[];
  onAddNewMember: (memberType: MemberStateKey) => void;
  onRemoveSelectedMember: (memberType: MemberStateKey, index: number) => void;
  onChange: (
    memberType: MemberStateKey,
    index: number,
    event: { target: { name: string, value: any } }) => void;
}

const SectionWithPublicChargeMembers: React.FC<IFormMembersProps> = (props) => {
  const memberStateKey: MemberStateKey = "withPublicCharge";
  const {
    members = [],
    onAddNewMember,
    onRemoveSelectedMember,
    onChange,
    form
  } = props;

  const renderNewMemberFields = () => {
    return members.map((member, i) => (
      <MemberForm
        member={member}
        form={form}
        key={member.id}
        index={i as number}
        onChange={onChanging}
        showInstitutionLevels
        fieldDecoratorPrefix="withPublicChargeMember"
        onRemoveSelectedMember={removeSelectedMember} />
    ));
  };

  const addNewMember = () => onAddNewMember(memberStateKey);

  const removeSelectedMember = (index: number) =>
    onRemoveSelectedMember(memberStateKey, index);

  const onChanging = (index: number, event: IEvent) =>
    onChange(memberStateKey, index, event);

  return (
    <FormSection title="INTEGRANTES CON CARGO PÚBLICO">
      {renderNewMemberFields()}
      <Row>
        <Button
          onClick={addNewMember}
          type="dashed"
          style={{ fontWeight: "bold" }}>
          + Agregar otro integrante
        </Button>
      </Row>
    </FormSection>
  );
};

export default SectionWithPublicChargeMembers;
