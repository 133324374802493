import React from "react";
import { Upload as ANTUpload } from "antd";
import { UploadProps as ANTUploadProps, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

export type OnChangeSignature = (info: UploadChangeParam<UploadFile<any>>) => void;

interface IUploadOwnProps {
  readonly onDone?: OnChangeSignature;
  readonly onError?: OnChangeSignature;
}

type UploadProps = ANTUploadProps & IUploadOwnProps;

const uploadEndpoint = process.env.ASSETS_ENDPOINT;

const onChange = (
  info: UploadChangeParam<UploadFile<any>>,
  onDone?: OnChangeSignature,
  onError?: OnChangeSignature
) => {
  if (info.file.status === "done" && typeof onDone === "function") {
    onDone(info);
  } else if (info.file.status === "error" && typeof onError === "function") {
    onError(info);
  }
};

export const Upload: React.FC<UploadProps> = ({ children, onDone, onError, ...uploadProps }) =>
  <ANTUpload
    onChange={(info) => onChange(info, onDone, onError)}
    {...uploadProps}
    name="file"
    action={uploadEndpoint}>
    {children}
  </ANTUpload>;
