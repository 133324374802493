import gql from "graphql-tag";

export const UpdateMeeting = gql`
  mutation UpdateWorktableMeeting (
    $worktableMeetingId: String!,
    $agenda: String,
    $agendaAttachments: [CreateAttachmentInput!]
    $agendaAttachmentsToDelete: [String!]
    $name: String,
    $subject: String,
    $place: String,
    $date: DateTime,
    $objective: String,
    $status: WorktableMeetingStatus,
    $documents: [CreateAttachmentInput!]
  ) {
    updateWorktableMeeting(
      data : {
        id: $worktableMeetingId
        agenda: $agenda
        agendaAttachments: {
          create: $agendaAttachments
          delete: $agendaAttachmentsToDelete
        }
        name: $name
        subject: $subject
        place: $place
        date: $date
        objective: $objective
        status: $status
        documents: {
          create: $documents
        }
      }
    ) {
      id
      agenda
      agendaAttachments {
        fileName
        ref
      }
      name
      subject
      place
      date
      objective
      status
      documents {
        fileName
        ref
      }
    }
  }`;
