import { isNullOrEmpty } from "../../../../../../utils/validations";

export const getCustomFieldDecorator = (key: string = "", initialValue: any) => {
  if (!isNullOrEmpty(fieldDecorators[key])) {
    fieldDecorators[key].initialValue = initialValue;
    return fieldDecorators[key];
  }
  return undefined;
};

const fieldDecorators = {
  workTableArea: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa el tipo de mesa de trabajo."
      }
    ],
    initialValue: ""
  },
  coordination: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa quién coordina la mesa de trabajo."
      }
    ],
    initialValue: undefined
  },
  name: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa el nombre de la mesa de trabajo."
      }
    ],
    initialValue: ""
  },
  objective: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa el objetivo de la reunión."
      }
    ],
    initialValue: ""
  },
  technicalSecretary: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa el nombre de la secretaría técnica."
      }
    ],
    initialValue: ""
  }
};
