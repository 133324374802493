import firebase from "firebase";
import ApolloClient, { InMemoryCache } from "apollo-boost";

export const client = new ApolloClient<object>({
  uri: process.env.GQL_ENDPOINT,
  request: async (operation) => {
    const { currentUser } = firebase.auth();
    const currentUserToken = await currentUser?.getIdToken();
    operation.setContext({
      headers: {
        authorization: currentUserToken
      }
    });
  },
  cache: new InMemoryCache({
    addTypename: false
  })
});
