import gql from "graphql-tag";

export const Projects = gql`
  query Projects(
    $closed: Boolean
    $skip: Int
    $sortField: String
    $sortDirection: String
  ) {
    projects(
      closed: $closed,
      concluded: false,
      skip: $skip,
      sortField: $sortField,
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        startDate
        endDate
        monitoringEntries {
          id
          date
          closed
        }
        supplies {
          id
          name
          expenseType
          isVerifiable
          humanResource {
            id
            fullName
          }
          monthlyBudget {
            id
            ficosec
            coinvestor
            month
            budgetVerification {
              id
              type
              consumed
              invoices {
                id
                ref
                fileName
              }
            }
          }
        }
      }
      count
    }
  }
`;
