import { schema } from "normalizr";

interface Budget {
  id: string;
  month: string;
  ficosec: number;
  coinvestor: number;
}

export const activity = new schema.Entity("activities", {}, {
  processStrategy: (entity: any) => {
    const { goal, ...rest } = entity;
    return {
      ...rest,
      goalId: goal.id
    };
  }
});

export const goal = new schema.Entity("goals", {
  activities: [activity]
}, {
  processStrategy: (entity: any) => {
    const { specificObjective, ...rest } = entity;
    return {
      ...rest,
      specificObjectiveId: specificObjective.id,
      summary: entity.description
    };
  }
});

export const specificObjective = new schema.Entity("specificObjectives", {
  goals: [goal]
}, {
  processStrategy: (entity: any) => ({
    ...entity,
    summary: entity.description,
    deliverable: {
      title: entity.deliverableTitle,
      description: entity.deliverableDescription,
      periodicity: entity.deliverablePeriodicity
    }
  })
});

const sustainabilityArgument = new schema.Entity("sustainabilityArguments");

const implementerProject = new schema.Entity("financialCapability");

const supply = new schema.Entity("supplies", {}, {
  processStrategy: (entity: any) => {
    const { activity, humanResource, ...rest } = entity;
    const monthlyBudget = {};
    entity.monthlyBudget.map((budget: Budget) =>
      monthlyBudget[budget.month] = {
        id: budget.id,
        coinvestor: budget.coinvestor,
        ficosec: budget.ficosec
      });

    return {
      ...rest,
      HRProfileId: humanResource?.id,
      activityId: activity?.id,
      monthlyBudget
    };
  }
});

const humanResource = new schema.Entity("HRProfiles", {}, {
  processStrategy: (entity: any) => ({
    ...entity,
    projectGoals: entity.projectGoals.map((goal: any) => goal.id)
  })
});

const risk = new schema.Entity("riskManagement");
const assumption = new schema.Entity("assumptionManagement");

export const project = new schema.Entity("projects", {
  humanResources: [humanResource],
  implementerProjects: [implementerProject],
  specificObjectives: [specificObjective],
  sustainabilityArguments: [sustainabilityArgument],
  supplies: [supply],
  risks: [risk],
  assumptions: [assumption],
});
