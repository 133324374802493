import React, { useContext } from "react";
import { Table } from "antd";

import { MonitoringTemplateContext } from "../../MonitoringTemplateContext";
import columns from "./columns";
import { getOpenProjects } from "./helpers";

const InMonitoringTable: React.FC = () => {
  const monitoringTemplateContext = useContext(MonitoringTemplateContext);
  const onPaginationChange = monitoringTemplateContext?.onPaginationChange;
  const projects = monitoringTemplateContext?.data?.openProjects;

  const openProjects = getOpenProjects(projects?.items || []);

  return (
    <Table
      loading={monitoringTemplateContext?.loading}
      pagination={{ total: projects?.count }}
      columns={columns}
      onChange={onPaginationChange}
      dataSource={openProjects}
    />
  );
}

export default InMonitoringTable;
