import React from "react";
import { Table, Select, Button, Avatar } from "antd";
import { ColumnProps } from "antd/lib/table";
import styled from "styled-components";

import { Agreement, AgreementStatusEnum, AgreementStatus, AgreementStatusDict } from "../../../../../types";

const Container = styled.div`
  background: #ffffff;
`;

type MutateSignature = (agreementId: string) => void;

type MutateStatusSignature = (agreement: string, status: AgreementStatus) => void;

interface IAgreementsTableProps {
  disabled: boolean;
  loading: boolean;
  area?: string;
  agreements?: Agreement[];
  onEdit: MutateSignature;
  onEditStatus: MutateStatusSignature;
  onDelete: MutateSignature;
}

const AgreementsTableColumns = (
  disabled: boolean = false,
  onEdit: MutateSignature,
  onEditStatus: MutateStatusSignature,
  onDelete: MutateSignature
): Array<ColumnProps<any>> => {
  const columns = [
    {
      render: (_: any, __: any, idx: number) =>
        <Avatar>{idx + 1}</Avatar>
    },
    {
      title: "Acuerdo",
      dataIndex: "description"
    },
    {
      title: "Área",
      dataIndex: "area"
    },
    {
      title: "Responsable",
      dataIndex: "responsible"
    },
    {
      title: "Estatus",
      dataIndex: "status",
      render: (status: AgreementStatus, record: any) => {
        if (disabled) {
          return AgreementStatusDict[status];
        }

        return (
          <Select defaultValue={status} onChange={(value: AgreementStatus) => onEditStatus(record.id, value)}>
            <Select.Option value={AgreementStatusEnum.IN_PROGRESS}>
              {AgreementStatusDict[AgreementStatusEnum.IN_PROGRESS]}
            </Select.Option>
            <Select.Option value={AgreementStatusEnum.FINISHED}>
              {AgreementStatusDict[AgreementStatusEnum.FINISHED]}
            </Select.Option>
          </Select>
        );
      }
    },
    {
      disabled,
      render: (record: any) =>
        <>
          <Button shape="circle" icon="upload" />
          <Button shape="circle" icon="edit" onClick={() => onEdit(record.id)} />
          <Button shape="circle" icon="delete" onClick={() => onDelete(record.id)} />
        </>
    }
  ];

  return columns.filter((column) => !column.disabled);
};

export const AgreementsTable: React.FC<IAgreementsTableProps> = ({
  disabled,
  loading,
  area,
  agreements,
  onEdit,
  onEditStatus,
  onDelete
}) =>
  <Container>
    <Table
      pagination={false}
      loading={loading}
      columns={AgreementsTableColumns(disabled, onEdit, onEditStatus, onDelete)}
      dataSource={agreements?.map((agreement) => ({ ...agreement, area }))}
    />
  </Container>;
