export const getCurrentStep = (urlSectionParam: string) => {
  switch (urlSectionParam) {
    case "general":
      return 0;
    case "integrantes":
      return 1;
    case "actividades":
      return 2;
    default:
      return 0;
  }
};

export const menuItems = [
  { link: "./general", label: "Información general" },
  { link: "./integrantes", label: "Integrantes" },
  { link: "./actividades", label: "Orden del día" }
];
