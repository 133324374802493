import React from "react";
import { Table, Tag } from "antd";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { Project } from "../../../../../types";

interface IProjectsTableProps {
  loading: boolean;
  total?: number;
  onChange: (paginationConfig: PaginationConfig, filters: Partial<any>, sorter: SorterResult<any>) => void;
  dataSource?: Project[];
}

const columns = [
  {
    dataIndex: "name",
    title: "Nombre del proyecto",
    sorter: true
  },
  {
    dataIndex: "startDate",
    title: "Fecha de inicio",
    render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    sorter: true
  },
  {
    dataIndex: "endDate",
    title: "Fecha de de fin",
    render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    sorter: true
  },
  {
    dataIndex: "status",
    title: "Estado",
    render: () => <Tag color="orange">Monitoreo</Tag>,
    sorter: true
  }
];

export const ProjectsTable: React.FC<IProjectsTableProps> = ({ loading, dataSource = [], total, onChange }) => {
  const history = useHistory();

  const onClickRow = (project: Project) => {
    history.push(`/implementadora/proyectos/${project.id}`);
  };

  return (
    <Table
      pagination={{ total }}
      loading={loading}
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      onChange={onChange}
      onRow={(project) => ({
        onClick: () => onClickRow(project)
      })} />
  )
};
