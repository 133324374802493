import React from "react";
import { Table } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { PaginationConfig } from "antd/lib/table";

import { preRequestsTableColumns } from "./helpers";
import styles from "./styles.less";
import { IPreRequest } from "../../../../types";
import { PaginatedResult } from "../../requests/PendingRequestsTemplate";

interface IPreRequestsTemplateOwnProps {
  loading: boolean;
  onPaginationChange: (pagination: PaginationConfig, filters: any, sorter: any) => void;
  preRequests: PaginatedResult<IPreRequest>;
}

type IPreRequestsTemplateProps =
  & IPreRequestsTemplateOwnProps
  & RouteComponentProps;

const PreRequestsTemplate: React.FC<IPreRequestsTemplateProps> = ({ loading, preRequests, onPaginationChange, history }) => {
  const goToDetails = (preRequestId: string = "") => {
    history.push(`/implementadora/presolicitudes/${preRequestId}`);
  };

  const handleOnRowClick = (preRequest: IPreRequest) => {
    goToDetails(preRequest.id);
  };

  return (
    <div style={{ backgroundColor: "#FFF", padding: "25pt" }}>
      <Table
        loading={loading}
        rowClassName={() => styles.TableRow___clickable}
        onRowClick={handleOnRowClick}
        bodyStyle={{ minHeight: "400pt" }}
        pagination={{ total: preRequests?.count }}
        dataSource={preRequests?.items || []}
        onChange={onPaginationChange}
        columns={preRequestsTableColumns} />
    </div>
  );
};

export default withRouter(PreRequestsTemplate);
