import React from "react";
import { Icon, Tooltip, Button } from "antd";
import styled from "styled-components";

import { Supply } from "../../../../../types";
import { formatInput, parseInput, currency } from "../../../../../utils/currency";
import { ReviewControls, CommentButton } from "../../../../molecules";

interface IArgs {
  supplies: any[];
  onRowChange: any;
  onRowRemove: any;
  reviewable: boolean;
  approval: boolean;
  disabled: boolean;
  reviews: any;
}

const Total = styled.div<{ pct: number }>`
  color: ${({ pct }) => pct > 100 ? "#8e1d41" : "inherit"};
  background-color: ${({ pct }) => pct > 100 ? "rgba(142, 29, 65,0.25)" : "transparent"};
`;

export const getLastColumns = (reviewState: any, args: IArgs) => {
  const {
    supplies = [],
    onRowChange,
    onRowRemove,
    reviewable = false,
    approval = false,
    disabled,
    reviews
  } = args;

  const getTotal = (supply: Partial<Supply>) => {
    const { monthlyBudget } = supply;

    if (monthlyBudget) {
      const months = Object.keys(monthlyBudget);
      const monthBudgets = months.map((month) =>
        (monthlyBudget[month]?.ficosec || 0) + (monthlyBudget[month]?.coinvestor || 0));
      return monthBudgets.reduce((acc, curr) => acc + curr, 0);
    }

    return 0;
  };

  const formatTotal = (value: number, supply: Partial<Supply>, idx: number) => {
    if (!supply.summary || supply.computedTotal) {
      const total = getTotal(supply);
      const formatted = currency.format(value || total);
      return `$ ${formatted}`;
    }

    return null;
  };

  const getInvestorTotal = (investor: "ficosec" | "coinvestor", supply: Partial<Supply>) => {
    const { monthlyBudget } = supply;

    if (monthlyBudget) {
      const months = Object.keys(monthlyBudget);
      const monthBudgets = months.map((month) => monthlyBudget[month]?.[investor] || 0);
      return monthBudgets.reduce((acc, curr) => acc + curr, 0);
    }

    return 0;
  };

  const getFicosecPct = (_: any, supply: Partial<Supply>, idx: number) => {
    if (!supply.summary) {
      const total = getTotal(supply);
      const ficosecTotal = getInvestorTotal("ficosec", supply);
      const pct = ((100 / (total || 1)) * ficosecTotal).toFixed(2);
      return `${pct}%`;
    }

    return null;
  };

  const getCoinvestorPct = (_: any, supply: Partial<Supply>, idx: number) => {
    if (!supply.summary) {
      const total = getTotal(supply);
      const coinvestorTotal = getInvestorTotal("coinvestor", supply);
      const pct = ((100 / (total || 1)) * coinvestorTotal).toFixed(2);
      return `${pct}%`;
    }

    return null;
  };

  const getTotalPct = (_: any, supply: Partial<Supply>, idx: number) => {
    if (!supply.summary) {
      const total = (supply?.unitCost || 0) * (supply?.count || 0);
      const totalBudgeted = getTotal(supply);
      const totalPct = ((100 / (total || 1)) * totalBudgeted);
      return <Total pct={totalPct}>{totalPct.toFixed(2)}%</Total>;
    }

    return null;
  };

  return [
    {
      title: "% Ficosec",
      render: getFicosecPct
    },
    {
      title: "% Co-inversión",
      render: getCoinvestorPct
    },
    {
      title: "Total",
      fixed: "right" as "right",
      dataIndex: "computedTotal",
      render: formatTotal
    },
    {
      title: "% Total",
      fixed: "right" as "right",
      render: getTotalPct
    },
    {
      width: 1,
      fixed: "right" as "right",
      render: (_: any, supply: Partial<Supply>, idx: number) => {
        const fieldId = `supplies.${supply.id}`;
        if (!supply.summary) {
          if (approval) {
            return null;
          }

          if (!reviewable) {
            return (
              <Button.Group>
                <Tooltip
                  title={supply.HRProfileId ? "Este concepto está ligado a un recurso humano" : undefined}
                  placement="left">
                  <Button
                    icon="delete"
                    disabled={disabled}
                    onClick={!supply.HRProfileId ? (() => onRowRemove(supply.id!)) : undefined}
                  />
                </Tooltip>
                <CommentButton field={fieldId} size="default" disabled={disabled} />
              </Button.Group>
            );
          } else {
            return (
              <ReviewControls
                fieldId={fieldId}
                reviews={reviews}
                comment={((reviewState.state || {})[fieldId] || { comment: undefined }).comment}
                approved={((reviewState.state || {})[fieldId] || { approved: undefined }).approved}
                onReset={(reviewState.methods || { onReset: () => undefined }).onReset}
                onApprove={(reviewState.methods || { onApprove: () => undefined }).onApprove}
                onReject={(reviewState.methods || { onReject: () => undefined }).onReject}
                onSaveComment={(reviewState.methods || { onSaveComment: () => undefined }).onSaveComment}
                onDeleteComment={(reviewState.methods || { onDeleteComment: () => undefined }).onDeleteComment}
                relative
              />
            );
          }
        }

        return null;
      }
    }
  ];
};
