import { Modal } from "antd";

import { Risk } from "../../../../types";

interface IRisks {
  [id: string]: Partial<Risk>;
}

export const validateRisksTable = (risks: IRisks) => {
  const riskIds = Object.keys(risks);
  if (riskIds.length === 0) {
    Modal.error({
      title: "Cantidad de riesgos",
      content: "Por favor, agrega al menos un riesgo.",
      okText: "Ok"
    });
    return false;
  }

  if (hasEmptyValues(risks, riskIds)) {
    Modal.error({
      title: "Verifica los datos",
      content: "Verifica que todos los campos estén rellenados.",
      okText: "Ok"
    });
    return false;
  }

  return true;
};

const hasEmptyValues = (risks: IRisks, riskIds: string[]) => {
  return riskIds.some((riskId) => (
    !risks[riskId].identification ||
    !risks[riskId].probability ||
    !risks[riskId].impact ||
    !risks[riskId].mitigationStrategy
  ));
};

