import React, { useContext } from "react";
import { PreRequestFormContext } from "../PreRequestFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";
import { IconLabel } from "./OrganizationDescriptionInput/IconLabel";
import { projectInfo } from '../../../templates/implementer-onboarding/TooltipHelpers'
import { Popover } from '../../../atoms'

export const ProjectDescriptionInput = (props: any) => {
  const implementerAccountContext = useContext(PreRequestFormContext);
  const { form, onFieldsChange, preRequest } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <>
      <IconLabel
        number={4}
        label="Describe el proyecto que quieres realizar:" />
      <Form.Item>
        <Popover
          placement="right"
          content={projectInfo}
          trigger="hover">
          {getFieldDecorator("projectDescription", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la descripción del proyecto."
              }
            ],
            initialValue: preRequest && preRequest.projectDescription
          })(
            <Input.TextArea
              id="projectDescription"
              name="projectDescription"
              placeholder="Describe"
              onChange={onChange}
              rows={5} />
          )}
        </Popover>
      </Form.Item>
    </>
  );
};
