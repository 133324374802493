import gql from "graphql-tag";

export const BoardApprovement = gql`
  mutation BoardApprovement (
    $requestId: ID!
    $board: RevisionBoard!
    $status: RevisionStatus!
    $annotations: String
  ) {
    reviewRequest (
      data: {
        requestId: $requestId
        board: $board
        status: $status
        annotations: $annotations
      }) {
      id
      board
      status
      annotations
    }
  }
`;
