import React from "react";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { Layout } from "../../../organisms";
import { PageHeader, Content, SectionCard } from "../../../organisms/Layout";
import { Project } from "../../../../types";
import { ProjectsTable } from "./components";
import { PaginatedResult } from "../../requests/PendingRequestsTemplate";

interface IProjectsListTemplateProps {
  loading: boolean;
  onPaginationChange: (paginationConfig: PaginationConfig, filters: Partial<any>, sorter: SorterResult<any>) => void;
  projects?: PaginatedResult<Project>;
}

export const ProjectsListTemplate: React.FC<IProjectsListTemplateProps> = ({ loading, onPaginationChange, projects }) => {
  return (
    <Layout noPadding>
      <PageHeader title="Proyectos" />
      <Content>
        <SectionCard>
          <ProjectsTable
            loading={loading}
            total={projects?.count}
            onChange={onPaginationChange}
            dataSource={projects?.items || []} />
        </SectionCard>
      </Content>
    </Layout>
  );
};
