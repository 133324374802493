import gql from "graphql-tag";

const UpdateUser = gql`
  mutation UpdateUser($id: ID!, $profileName: String!, $role: String!, $email: String!) {
    updateUser(id: $id, role: $role, email: $email) {
      id
      status
    }
    updateProfile(id: $id, name: $profileName) {
      id
    }
  }
`;

export default UpdateUser;
