import React from "react";
import styled from "styled-components";

import { Request } from "../../../../types";
import { DefaultContextValue } from "./types";
import {
  DetailsHeader,
  StatusSection,
  PreventionLevelsSection,
  ProjectSummarySection,
  BeneficiariesSection,
  FinancialSection
} from "./components";
import RequestDetailsTemplateContext from "./RequestDetailsTemplateContext";
const { Provider } = RequestDetailsTemplateContext;

const Container = styled.div`
  width: 700pt;
  margin: auto;
  margin-top: 10pt;
`;

interface RequestDetailsTemplateProps {
  request: Partial<Request>;
}

const RequestDetailsTemplate = (props: RequestDetailsTemplateProps) => {
  const { request } = props;
  const providerValue: DefaultContextValue = {
    request,
    isBusy: false
  };
  return (
    <Provider value={providerValue}>
      <Container>
        <DetailsHeader />
        <StatusSection />
        <PreventionLevelsSection />
        <ProjectSummarySection />
        <BeneficiariesSection />
        <FinancialSection />
      </Container>
    </Provider>
  );
};

export default RequestDetailsTemplate;
