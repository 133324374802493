import gql from "graphql-tag";

const WorkTableAgreements = gql`
  query workTableAgreements {
    allWorkTableAgreements {
      id
      agreement
      responsible
      status
      idMinuta
      dueDate
      createdDate
      WorkTable {
        id
      }
    }
  }
`;

export default WorkTableAgreements;
