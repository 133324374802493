import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button, Icon } from "antd";

import { getBreadCrumbRoutes } from "../helpers";
import { CustomPageHeader } from "../../../../organisms/ImplementerLayout";
import { IPreRequest } from "../../../../../types";

interface IDetailsHeaderOwnProps {
  preRequestData: Partial<IPreRequest>;
}

type IDetailsHeaderProps = IDetailsHeaderOwnProps & RouteComponentProps;

const DetailsHeader: React.FC<IDetailsHeaderProps> = (props) => {
  const { preRequestData, history } = props;
  const { projectName = "", folio = "", request = {} } = preRequestData;

  const onGoBack = () => {
    history.goBack();
  };

  const formatMoney = (amount: number = 0): string => {
    return `$ ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderBudget = () => {
    return (
      <div style={{ fontWeight: "bold" }}>
        Presupuesto estimado: {
            formatMoney(props.preRequestData.estimatedBudget)
          } mxn
      </div>
    );
  };

  return (
    <CustomPageHeader
      breadcrumb={{
        routes: getBreadCrumbRoutes(projectName, folio, request?.project?.id),
        itemRender: (route) => (
          <Link to={route.path}>{route.breadcrumbName}</Link>)
      }}
      title={projectName}
      ghost={false}
      extra={renderBudget()}
      onBack={onGoBack}>
    </CustomPageHeader>);
};

export default withRouter(DetailsHeader);
