import React, { Context } from "react";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { IPreRequest } from "../../../../../../types";

interface IDefaultValue {
  form: WrappedFormUtils;
  onFieldsChange: ({ key, value }: { key: string, value: any }) => void;
  preRequest: Partial<IPreRequest>;
}

export const ClasificationFormContext: Context<IDefaultValue> = React.createContext({
      form: undefined,
      onFieldsChange: undefined,
      preRequest: undefined
    } as any);
