import React from "react";
import {
  Typography,
  List,
  Avatar,
  Popover,
  Button,
  Icon,
  Switch,
  Form,
  Input,
  Select,
  Modal as AntModal,
  message
} from "antd";

import { v4 as uuid } from "uuid";

import { Container, Modal, SimpleInput } from "../../atoms";
import { userStatuses, userRoles } from "../../../utils/maps/user";
import { UserStatus, UserRole, User } from "../../../types/User";
import { client } from "../../../gql/apollo";
import { RemoveUser, UpdateUserStatus, InviteUsers, UpdateUser } from "../../../gql/mutations/users";
import { Users } from "../../../gql/queries/users";
import _ from "lodash";

interface UsersTemplateProps {
  loading: boolean;
  users: User[];
}

interface UsersTemplateState {
  invitesModal: boolean;
  emailInvites: EmailInvite[];
  detailsModal: DetailsModal;
}

export interface EmailInvite {
  id: string;
  email: string;
  role: UserRole;
}

interface DetailsModal {
  user?: User;
  isVisible: boolean;
}

interface RolePopoverProps {
  user: User;
}

interface InviteModalProps {
  visible: boolean;
}

class UsersTemplate extends React.Component<UsersTemplateProps, UsersTemplateState> {
  public state: UsersTemplateState = {
    invitesModal: false,
    emailInvites: [],
    detailsModal: {
      user: undefined,
      isVisible: false,
    }
  };

  public render() {
    const { invitesModal } = this.state;
    const { loading, users } = this.props;
    const { detailsModal } = this.state;

    return (
      <Container>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 40,
          paddingBottom: 0
        }}>
          <this.InviteModal visible={invitesModal} />
          <div>
            <Typography.Title level={3}>Usuarios</Typography.Title>
            <Typography.Text type="secondary">
              Agrega colaboradores que desees que realicen alguna acción en FICOSEC
            </Typography.Text>
          </div>
          <Button
            type="primary"
            shape="round"
            onClick={this.openInvitesModal}>
            Invitar usuarios
          </Button>
        </div>
        <div style={{ padding: 40 }}>
          <List
            itemLayout="horizontal"
            loading={loading}
            dataSource={users}
            renderItem={this.renderUser} />
        </div>
        <this.RenderDetailsModal
          isVisible={detailsModal.isVisible} />
      </Container>
    );
  }

  private onCloseDetailsModal = () => {
    this.setState({
      detailsModal: {
        user: undefined,
        isVisible: false
      }
    });
  }

  private openDetailsModal = (user: User) => {
    const { isVisible } = this.state.detailsModal;
    const userToEdit = { ...user };
    // Spread syntax just copy the first level
    // The second level is still an object reference so
    // it will affect the list item data
    userToEdit.Profile = { ...user.Profile };

    this.setState({
      detailsModal: {
        isVisible: !isVisible,
        user: userToEdit
      }
    });
  }

  private renderUser = (user: User) => {
    const avatar = <Avatar
      size={48}
      style={{ color: "black", backgroundColor: "white" }}
      icon="user" />;

    if (!user.Profile && user.status === "PENDING_CONFIRMATION") {
      return (
        <List.Item
          key={user.id}>
          <List.Item.Meta
            avatar={avatar}
            title={user.email}
            description={userStatuses[user.status]} />
          <this.RolePopover
            user={user} />
          <Button
            type="link"
            onClick={() => {
              this.openDetailsModal(user);
            }}>
            Detalles
            </Button>
        </List.Item>
      );
    }

    return (
      <List.Item>
        <List.Item.Meta
          avatar={avatar}
          title={user.Profile.name}
          description={user.email} />
        <this.RolePopover user={user} />
        <Button
          type="link"
          onClick={() => {
            this.openDetailsModal(user);
          }}>
          Detalles
        </Button>
      </List.Item>
    );
  }

  private RenderDetailsModal: React.FC<DetailsModal> = (props) => {
    const { isVisible } = props;
    const { detailsModal: { user } } = this.state;
    let userHasProfile = false;

    if (user !== undefined && user.status === "ACTIVE") {
      userHasProfile = true;
    }

    return (
      <Modal
        title="Editar usuario"
        centeredContent
        visible={isVisible}
        onCancel={this.onCloseDetailsModal}
        footer={[
          <Button
            key="saveButton"
            shape="round"
            type="primary"
            onClick={this.saveUserChanges}>Guardar</Button>,
          <Button
            key="cancelButton"
            shape="round"
            type="link"
            onClick={this.onCloseDetailsModal}>Cancelar</Button>
        ]}>
        <Form.Item>
          <Typography.Text>
            {userHasProfile ? "" : "El usuario no ha sido confirmado aún"}
          </Typography.Text>
        </Form.Item>
        <Form.Item>
          <Input
            placeholder="Nombre completo"
            size="large"
            disabled={!userHasProfile}
            value={userHasProfile ? _.get(user, "Profile.name", "") : ""}
            onChange={(event) => {
              this.onFormInputChange("profileName", event.target.value);
            }} />
        </Form.Item>
        <Form.Item>
          <Input
            placeholder="Email"
            size="large"
            disabled={!userHasProfile}
            value={user ? user.email : ""}
            onChange={(event) => {
              this.onFormInputChange("email", event.target.value);
            }} />
        </Form.Item>
        <Form.Item>
          <Select
            placeholder="Rol de usuario"
            size="large"
            style={{ width: "100%" }}
            defaultValue={user ? user.role : undefined}
            onChange={(value: UserRole) => {
              this.onRoleChange(value);
            }}>
            <Select.Option value="ADMIN">
              Administrador
            </Select.Option>
            <Select.Option value="IMPLEMENTER">
              Implementador
            </Select.Option>
            <Select.Option value="LEGAL_AREA">
              Área legal
            </Select.Option>
            <Select.Option value="TECHNICAL_AREA">
              Área técnica
            </Select.Option>
          </Select>
        </Form.Item>
      </Modal>
    );
  }

  private RolePopover: React.FC<RolePopoverProps> = ({ user }) => (
    <Popover
      trigger="click"
      placement="bottom"
      content={this.renderPopoverContent(user.id, user.status, user.role)}>
      <Button type="link">
        {userRoles[user.role]}
        <Icon type="down" />
      </Button>
    </Popover>
  )

  private saveUserChanges = async () => {
    const { detailsModal: { user } } = this.state;

    const userDataToSave = {
      id: _.get(user, "id", undefined),
      email: _.get(user, "email", undefined),
      role: _.get(user, "role", undefined),
      profileName: _.get(user, "Profile.name", undefined)
    };

    if (Object.values(userDataToSave || { _: undefined }).some((value) => !value)) {
      return message.error("Hubo un problema al actualizar el usuario");
    }

    client.mutate({
      mutation: UpdateUser,
      variables: { ...userDataToSave },
      refetchQueries: [{ query: Users }]
    })
      .then((result: any) => {
        this.onCloseDetailsModal();
        return message.success("Usuario actualizado");
      })
      .catch((err: any) => {
        return message.error("Hubo un problema al actualizar el usuario");
      });

    return message.error("Hubo un problema al actualizar el usuario");
  }

  private onRoleChange = (selectedRole: UserRole) => {
    const editingData = this.state.detailsModal;
    const { user } = editingData;

    if (user !== undefined) {
      user.role = selectedRole;
      this.setState({
        detailsModal: editingData
      });
    }
  }

  private onFormInputChange = (field: string, value: any) => {
    const editingData = this.state.detailsModal;
    const { user } = editingData;

    if (user !== undefined) {
      if (field === "profileName") {
        user.Profile.name = value;
      } else {
        user[field] = value;
      }
    }

    this.setState({
      detailsModal: { ...editingData }
    });
  }

  private renderPopoverContent = (userId: string, userStatus: UserStatus, userRole: UserRole) => (
    <div style={{ width: 360 }}>
      <p>{userRoles[userRole]}</p>
      <Typography.Text type="secondary">
        Puede cambiar cualquier configuración del sistema, asi como tener acceso a todos los módulos.
      </Typography.Text>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between"
        }}>
        <a onClick={() => this.removeUser(userId)}>Eliminar de FICOSEC</a>
        {userStatus !== "PENDING_CONFIRMATION" && (
          <Switch
            onChange={() => this.changeStatus(userId, userStatus)}
            checkedChildren="Activo"
            unCheckedChildren="Inactivo"
            defaultChecked={userStatus === "ACTIVE"} />
        )}
      </div>
    </div>
  )

  private removeUser = async (userId: string) => {
    AntModal.confirm({
      zIndex: 10000,
      title: "Eliminar usuario",
      content: "¿Estás seguro que deseas eliminar a este usuario de FICOSEC? Esta acción no se puede deshacer.",
      cancelText: "Cancelar",
      cancelButtonProps: {
        shape: "round"
      },
      okText: "Eliminar",
      okType: "primary",
      okButtonProps: {
        shape: "round"
      },
      onOk() {
        return client.mutate({
          mutation: RemoveUser,
          variables: { id: userId },
          refetchQueries: [{ query: Users }]
        });
      }
    });
  }

  private changeStatus = async (userId: string, currentStatus: UserStatus) => {
    client.mutate({
      mutation: UpdateUserStatus,
      variables: {
        id: userId,
        status: currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"
      },
      refetchQueries: [{ query: Users }]
    });
  }

  private InviteModal: React.FC<InviteModalProps> = ({ visible }) => {
    const { emailInvites } = this.state;
    return (
      <Modal
        title="Invitar usuarios"
        noPadding
        slimFooter
        onCancel={this.closeInvitesModal}
        footer={[
          <Button
            shape="round"
            onClick={this.closeInvitesModal}>
            Cancelar
          </Button>,
          <Button
            shape="round"
            type="primary"
            onClick={this.handleOnPressInvite}>
            Invitar
          </Button>
        ]}
        visible={visible}
        closable>
        <List
          locale={{ emptyText: "Ingresa correos para mandar invitaciones a FICOSEC" }}
          itemLayout="horizontal"
          dataSource={emailInvites}
          renderItem={this.renderInvitedUser} />
        <SimpleInput
          id="email-input"
          placeholder="Escribe un correo para invitar"
          suffix={<Icon type="enter" onClick={this.handleOnClickEnterEmailInput} />}
          onPressEnter={this.handleOnPressEnterEmailInput} />
      </Modal>
    );
  }

  private openInvitesModal = () =>
    this.setState({ invitesModal: true })

  private closeInvitesModal = () => {
    const { emailInvites } = this.state;

    const clear = () => this.setState({ invitesModal: false, emailInvites: [] });

    if (emailInvites.length > 0) {
      return AntModal.confirm({
        zIndex: 10000,
        title: "¡Cuidado!",
        content: "¿Estás seguro que quieres cerrar sin enviar las invitaciones?.",
        cancelText: "Cancelar",
        cancelButtonProps: {
          shape: "round"
        },
        okText: "Seguro",
        okType: "primary",
        okButtonProps: {
          shape: "round"
        },
        onOk() {
          return clear();
        }
      });
    }

    return clear();
  }

  private renderInvitedUser = (invite: EmailInvite) =>
    <List.Item style={{ flexDirection: "row", display: "flex" }}>
      <List.Item.Meta
        style={{ marginLeft: 15 }}
        key={invite.id}
        title={invite.email} />
      {userRoles[invite.role]}
      <Button type="link" onClick={() => this.removeInvite(invite.id)}>
        <Icon type="close" />
      </Button>
    </List.Item>

  private handleOnClickEnterEmailInput = () => {
    const target = document.getElementById("email-input");
    const { value } = target as HTMLInputElement;

    if (value && value !== " ") {
      this.addUser(value, target as HTMLInputElement);
    }
  }

  private handleOnPressEnterEmailInput = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    evt.persist();
    const { value } = (evt.target as HTMLInputElement);

    if (value && value !== " ") {
      this.addUser(value, evt.target as HTMLInputElement);
    }
  }

  private addUser = (email: string, target: HTMLInputElement) =>
    this.setState(
      (state) => ({
        emailInvites: [...state.emailInvites, {
          id: uuid(),
          email,
          role: "ADMIN"
        }]
      }),
      () => (target).value = ""
    )

  private removeInvite = (inviteId: string) => {
    const { emailInvites } = this.state;
    const newInvites = emailInvites.filter((invite) => invite.id !== inviteId);
    this.setState({ emailInvites: newInvites });
  }

  private handleOnPressInvite = async () => {
    const { emailInvites } = this.state;
    if (emailInvites.length > 0) {
      // FIXME: We only send the first one because we can't create more than one invites at a time :(
      const invite: Partial<User> = {
        ...emailInvites[0],
        role: "ADMIN",
        status: "PENDING_CONFIRMATION"
      };

      client.mutate({
        mutation: InviteUsers,
        variables: { ...invite },
        refetchQueries: [{ query: Users }]
      })
        .then((result: any) => {
          return this.setState({ invitesModal: false, emailInvites: [] });
        })
        .catch((err: any) => {
          return message.error("Hubo un problema al enviar las invitaciones.");
        });
    }
  }
}

export default UsersTemplate;
