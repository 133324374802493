import React from "react";
import style from "./style.less";

export const ScrollableContainer = ({ children, ...props }: any) => {
  return (
    <div
      className={style.Div_scrollable}
      {...props}>
      {children}
    </div>
  );
};
