import React, { useContext } from "react";
import { MeetingFormContext } from "../../../MeetingFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";
import { worktableAreas } from "../../../../../../utils/maps/workTableAreas";
import { WorkTableArea } from "../../../../../../types";

export const AreaInput = (props: any) => {
  const meetingFormContext = useContext(MeetingFormContext);
  const { form, onInputChange, worktable } = meetingFormContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item
      label="Área de la reunión">
      {getFieldDecorator("area", {
        rules: [
          {
            message: "Por favor, ingresa el área de la reunión."
          }
        ],
        initialValue: worktable !== undefined &&
          worktableAreas[worktable.area as WorkTableArea]
      })(
        <Input
          disabled
          id="area"
          name="area"
          type="text"
          onChange={onChange} />
      )}
    </Form.Item>
  );
};
