import React, { Component } from "react";
import { Card, message } from "antd";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { Query, QueryResult } from "react-apollo";

import { PageHeader } from "./components/";
import { SectionCard } from "../../../organisms/ImplementerLayout";
import { PreSubmissionStatusCard } from "../../../molecules";
import { ImplementerPreRequest } from "../../../../gql/queries";
import { IPreRequest } from "../../../../types";
import { requestTypes } from "../../../../utils/maps";
import Layout, { Content } from "../../../organisms/Layout";

type PreRequestDetailsPageProps =
  & RouteComponentProps
  & Pick<RouteComponentProps<{ preRequestId?: string }>, "match">;

const DescriptionContainer = styled.p`
  margin: 10pt 0pt;
`;

class PreRequestDetailsPage extends Component<PreRequestDetailsPageProps> {
  public constructor(props: RouteComponentProps) {
    super(props);
  }

  public render() {
    const { params } = this.props.match;
    const { preRequestId = "" } = params;

    return (
      <Layout noPadding>
        <Content>
          <Query query={ImplementerPreRequest} variables={{ preRequestId }}>
            {({ data, error, loading = false }: QueryResult) => {

              if (error) {
                // @todo report to bugsnag
                // show a friendly error component
                message.error("Ocurrió un error");
              }

              const preRequest: Partial<IPreRequest> = data?.preRequest || {};
              const {
                organizationDescription = "",
                projectDescription = "",
                status,
                type
              } = preRequest;

              const area: string = preRequest.area || "";

              return (
                <>
                  <PageHeader preRequestData={preRequest} />
                  <PreSubmissionStatusCard
                    loading={loading}
                    preRequest={preRequest as IPreRequest} />
                  <SectionCard loading={loading}>
                    <Card.Meta
                      title="Clasificación"
                      description={status === "APPROVED" ?
                        `${area} - ${type ? requestTypes[type] : ""}`
                        : "Sin asignar"} />
                  </SectionCard>
                  <SectionCard loading={loading}>
                    <Card.Meta title="Acerca de la organización" />
                    <DescriptionContainer>
                      {organizationDescription}
                    </DescriptionContainer>
                  </SectionCard>
                  <SectionCard loading={loading}>
                    <Card.Meta title="Descripción del proyecto" />
                    <DescriptionContainer>
                      {projectDescription}
                    </DescriptionContainer>
                  </SectionCard>
                </>
              );
            }}
          </Query>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(PreRequestDetailsPage);
