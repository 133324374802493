import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { PageHeader, Descriptions, Skeleton, Typography, Input, Button, message, Modal } from "antd";
import { format } from "date-fns";
import styled from "styled-components";

import { AgreementsTable } from "./components";
import { WorkTableMeeting } from "../../../../gql/queries";
import { client } from "../../../../gql/apollo";
import { bugsnagClient } from "../../../../bugsnag";
import { Content, Footer as OFooter, Upload } from "../../../atoms";
import { UpdateMeeting } from "../../../../gql/mutations";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { IWorktableMeeting, Agreement, AgreementStatus } from "../../../../types";
import { AgreementForm, AgreementFormAction } from "../../../organisms";
import { DeleteWorktableAgreement, UpdateWorktableAgreement } from "../../../../gql/mutations/workTables";

const SectionTitle = styled.div`
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
`;

const Section = styled.div`
  padding: 20px;
  padding-top: 0px;
`;

const Footer = styled(OFooter)`
  display: flex;
  justify-content: flex-end;
  width: auto;
  left: 245px;
  right: 0;
`;

type MeetingDetailTemplateProps = RouteComponentProps<{ meetingId: string }>;

interface IUpdateMeetingVariables {
  worktableMeetingId: string;
  agenda: string;
}

interface IAgreementModalState {
  action: AgreementFormAction;
  visible: boolean;
  agreement?: Partial<Agreement>;
}

const MeetingDetailTemplate: React.FC<MeetingDetailTemplateProps> = ({ history, match }) => {
  const [closingMeeting, setClosingMeeting] = useState(false);
  const [agreementModal, setAgreementModal] = useState<IAgreementModalState>({ action: "CREATE", visible: false });
  const { loading, error, data } = useQuery(WorkTableMeeting, { variables: { id: match.params.meetingId }, client } as any);
  const [updateMeeting] = useMutation<IUpdateMeetingVariables>(
    UpdateMeeting,
    {
      refetchQueries: [{ query: WorkTableMeeting, variables: { id: match.params.meetingId } }],
      client
    } as any
  );
  const worktableMeeting: IWorktableMeeting = data?.worktableMeeting;

  if (error) {
    bugsnagClient.notify(error, {
      beforeSend: (report) => {
        report.metaData = { error: JSON.stringify(error) };
        report.errorClass = "Client Provider";
        report.groupingHash = report.errorClass;
      }
    });
  }

  const goToMemo = () =>
    history.push(`/minutas/${match.params.meetingId}`);

  const updateAgenda = async (evt: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
    const agenda = evt.currentTarget.value || null;
    const { errors } = await updateMeeting({
      variables: {
        worktableMeetingId: match.params.meetingId,
        agenda
      }
    });

    if (errors && errors?.length > 0) {
      // TODO: Notify to bugsnag
      message.error("Hubo un problema al guardar la orden del día.");
    }
  };

  const closeMeeting = async () => {
    setClosingMeeting(true);
    const { errors } = await updateMeeting({
      variables: {
        worktableMeetingId: match.params.meetingId,
        status: "CLOSED"
      }
    });

    if (errors && errors?.length > 0) {
      // TODO: Notify to bugsnag
      setClosingMeeting(false);
      message.error("Hubo un problema al concluir la reunión.");
    }

    setClosingMeeting(false);
    return goToMemo();
  };

  const attachFiles = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = [{ fileName: info.file.name, ref: info.file.response.imageUrl }];

    const { errors } = await updateMeeting({
      variables: {
        worktableMeetingId: match.params.meetingId,
        agendaAttachments: file
      }
    });

    if (errors && errors?.length > 0) {
      // TODO: Notify to bugsnag
      message.error("Hubo un problema al guardar los archivos.");
    }
  };

  const deleteFile = async (file: UploadFile<any>) => {
    const { errors } = await updateMeeting({
      variables: {
        worktableMeetingId: match.params.meetingId,
        agendaAttachmentsToDelete: [file.uid]
      }
    });

    if (errors && errors?.length > 0) {
      // TODO: Notify to bugsnag
      message.error("Hubo un problema al eliminar el archivo.");
    }
  };

  const getAgendaAttachments = () =>
    worktableMeeting?.agendaAttachments?.map((attachment, idx) => ({
      uid: attachment.id || String(idx),
      name: attachment.fileName,
      url: attachment.ref,
    }));

  const findWorktableAgreement = (agreementId: string) =>
    worktableMeeting?.agreements?.find((agreement) => agreement.id === agreementId);

  const toggleCreateAgreementModal = () =>
    setAgreementModal({
      action: "CREATE",
      visible: !agreementModal.visible
    });

  const editAgreementStatus = async (agreementId: string, status: AgreementStatus) => {
    try {
      await client.mutate({
        mutation: UpdateWorktableAgreement,
        variables: {
          worktableAgreementId: agreementId,
          status
        }
      });
    } catch (err) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un error");
    }
  };

  const toggleEditAgreementModal = (agreementId: string) =>
    setAgreementModal({
      action: "EDIT",
      visible: !agreementModal.visible,
      agreement: findWorktableAgreement(agreementId)
    });

  const handleOnDelete = (agreementId: string) =>
    Modal.confirm({
      title: "Eliminar acuerdo",
      content: "¿Estás seguro que deseas eliminar este acuerdo? Esta acción no se puede deshacer.",
      okText: "Eliminar",
      okType: "primary",
      okButtonProps: {
        shape: "round"
      },
      cancelText: "No",
      cancelButtonProps: {
        shape: "round"
      },
      onOk() {
        return client.mutate({
          mutation: DeleteWorktableAgreement,
          variables: { worktableAgreementId: agreementId },
          refetchQueries: [{ query: WorkTableMeeting, variables: { id: match.params.meetingId } }]
        });
      }
    });

  const CTALabel = worktableMeeting?.status === "CLOSED" ? "Ver minuta" : "Concluir reunión";
  const CTAAction = worktableMeeting?.status === "CLOSED" ? goToMemo : closeMeeting;

  return (
    <Content style={{ marginLeft: 245, marginBottom: 81 }}>
      <AgreementForm
        worktableMeetingId={match.params.meetingId}
        action={agreementModal.action}
        visible={agreementModal.visible}
        agreementData={agreementModal.agreement}
        onClose={toggleCreateAgreementModal}
      />
      <PageHeader
        title="Reunión"
        ghost={false}
        onBack={history.goBack}
      >
        <Skeleton
          loading={loading}
          title={false}
          paragraph={{ rows: 2 }}
        >
          <Typography.Paragraph>{worktableMeeting?.subject}</Typography.Paragraph>
          <Descriptions>
            <Descriptions.Item label="Área">{worktableMeeting?.worktable?.area}</Descriptions.Item>
            <Descriptions.Item label="Lugar">{worktableMeeting?.place}</Descriptions.Item>
            <Descriptions.Item label="Objetivo">{worktableMeeting?.objective}</Descriptions.Item>
            <Descriptions.Item label="Fecha">{format(parseInt(worktableMeeting?.date, 10), "dd/MM/yyyy")}</Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </PageHeader>
      <>
        <SectionTitle>Orden del día</SectionTitle>
        <Section>
          {!loading && (
            <>
              {worktableMeeting?.status !== "CLOSED" ? (
                <Input
                  defaultValue={worktableMeeting?.agenda}
                  onBlur={updateAgenda}
                  onPressEnter={updateAgenda} />
              ) : (
                  <Typography.Paragraph>{worktableMeeting?.agenda}</Typography.Paragraph>
                )}
              <Upload
                disabled={worktableMeeting?.status === "CLOSED"}
                listType="picture"
                onDone={attachFiles}
                onRemove={deleteFile}
                defaultFileList={getAgendaAttachments() as Array<UploadFile<any>>}
                multiple>
                {worktableMeeting?.status !== "CLOSED" && (
                  <Button
                    style={{ marginTop: 10 }}
                    type="ghost"
                    icon="plus">
                    Adjuntar documentos
                  </Button>
                )}
              </Upload>
            </>
          )}
        </Section>
        <SectionTitle>Acuerdos</SectionTitle>
        <AgreementsTable
          disabled={worktableMeeting?.status === "CLOSED"}
          loading={loading}
          area={worktableMeeting?.worktable?.area}
          agreements={worktableMeeting?.agreements || []}
          onEdit={toggleEditAgreementModal}
          onEditStatus={editAgreementStatus}
          onDelete={handleOnDelete}
        />
        {worktableMeeting?.status !== "CLOSED" && (
          <Button
            style={{ margin: 20 }}
            type="ghost"
            icon="plus"
            onClick={toggleCreateAgreementModal}
          >
            Agregar acuerdo de trabajo
          </Button>
        )}
      </>
      <Footer>
        <Button
          loading={closingMeeting}
          onClick={CTAAction}
          shape="round"
          type="primary"
          size="large">
          {CTALabel}
        </Button>
      </Footer>
    </Content>
  );
};

export default MeetingDetailTemplate;
