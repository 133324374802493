import React, { useState } from "react";
import { ClickParam } from "antd/lib/menu";

import { ApprovedRequestsSidebar } from "../../../organisms";
import { RequestsTable } from "./components";
import {
  ApprovedRequestsTemplateProps,
  ApprovedRequestsTemplateState
} from "./types";
import { RequestApprovalStep } from "../../../organisms/ApprovedRequestsSidebar";
import { requestsByApproval } from "./helpers";

const ApprovedRequestsTemplate: React.FC<ApprovedRequestsTemplateProps> = ({
  loading,
  requests
}) => {
  const [state, setState] = useState<ApprovedRequestsTemplateState>({ currentStep: RequestApprovalStep.UNITY });

  const { currentStep } = state;

  const handleOnStepChange = (param: ClickParam) =>
    setState({ ...state, currentStep: param.key as RequestApprovalStep });

  const steppedRequests = requestsByApproval(requests?.items || []);
  const currentStepRequests = steppedRequests[currentStep];

  return (
    <div style={{ background: "#ffffff" }}>
      <ApprovedRequestsSidebar
        requests={steppedRequests}
        currentStep={currentStep}
        onChange={handleOnStepChange} />
      <div style={{ position: "relative", marginLeft: 245 }}>
        <RequestsTable
          loading={loading}
          total={requests?.count}
          requests={currentStepRequests} />
      </div>
    </div>
  );
}

export default ApprovedRequestsTemplate;
