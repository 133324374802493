import React from "react";
import { Icon } from "antd";

import styles from "./styles.less";

interface StepIndicatorProps {
  label: string;
  active?: boolean;
  checked?: boolean;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ label, active = false, checked = false }) => (
  <div
    className={[styles.indicatorContainer, (active || checked) && styles.activeIndicator].join(" ").trim()}>
    {checked && (
      <div className={styles.check}>
        <Icon type="check" />
      </div>
    )}
    {!checked && <div className={styles.label}>{label}</div>}
  </div>
);

export default StepIndicator;
