import gql from "graphql-tag";

const RejectRequest = gql`
  mutation RejectRequest($id: String!, $rejectReason: String!) {
  updateRequest(data: { id: $id, rejectReason: $rejectReason, status: "REJECTED" }) {
    id
    rejectReason
  }
}

`;

export default RejectRequest;
