import React from "react";
import { Query } from "react-apollo";

import { ErrorTemplate } from "../../templates";
import { Requests } from "../../../gql/queries";
import { ApprovedRequestsTemplate } from "../../templates/requests";
import { Layout } from "../../organisms";
import { RequestStatus } from "../../../types";

class ApprovalPage extends React.Component {
  public render() {
    return (
      <Layout noPadding>
        <Query
          query={Requests}
          variables={{ status: RequestStatus.APPROVED }}
          fetchPolicy="network-only">
          {({ loading, data, error }: { loading: boolean, data: any, error?: any }) => {
            if (error) {
              // TODO: report to bugsnag
              return <ErrorTemplate message="Hubo un problema al cargar las solicitudes." />;
            }

            return (
              <ApprovedRequestsTemplate
                loading={loading}
                requests={data?.requests} />
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default ApprovalPage;
