import {
  FormActivities,
  FormGeneral,
  FormMembers
} from "./components";
import { isNullOrEmpty } from "../../../../utils/validations";
import { IActivity, IMember, MemberType } from "./types";

export const sections = {
  general: FormGeneral,
  integrantes: FormMembers,
  actividades: FormActivities
};

export const containerStyle = {
  marginLeft: 245,
  marginTop: 120,
  marginBottom: 90,
  overflow: "visible"
};

export const validateActivities = (activities: IActivity[] = []) => {
  let isValid = true;

  if (activities.length > 0) {
    activities
    .some((activity: IActivity) => {
      if (isNullOrEmpty(activity.title)) {
        isValid = false;
        return true;
      } else {
        // validate subActivities
        if (!isNullOrEmpty(activity.subActivities)) {
          if (!validateActivities(activity.subActivities)) {
            isValid = false;
            return true;
          }
        }
        return false;
      }
    });
  }

  return isValid;
};

export const validateGlobalData = (
  generalData: object,
  activities: IActivity[] = []) => {
    const generalIsValid = !Object
      .keys(generalData)
      .some((p: any) => isNullOrEmpty(generalData[p]));

    const activitiesIsValid = validateActivities(activities);

    return {
      isValid: (activitiesIsValid && generalIsValid),
      section: selectSectionToReview(activitiesIsValid, generalIsValid)
    };
};

const selectSectionToReview = (activitiesIsValid: boolean, generalIsValid: boolean) => {
  if (!generalIsValid) {
    return "general?review";
  } else if (!activitiesIsValid) {
    return "actividades?review";
  } else {
    return "actividades?review";
  }
};

export const purgeEmptyMembers = (members: IMember[] = []) => {
  if (members.length < 1) {
    return members;
  } else {
    return members.filter((member) => !isNullOrEmpty(member.fullName));
  }
};

export const memberTypesMap: { [key: string]: MemberType } = {
  guests: "GUEST",
  withPublicCharge: "WITH_PUBLIC_CHARGE",
  withoutPublicCharge: "WITHOUT_PUBLIC_CHARGE"
};

export const sectionsMap: {[key: string]: string} = {
  integrantes: "members",
  general: "general",
  actividades: "activities"
};
