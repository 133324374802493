import gql from "graphql-tag";

export const ProjectActivities = gql`
  query ProjectActivities($projectId: String!) {
    projectActivities(projectId: $projectId) {
      id
      description
    }
  }
`;
