import gql from "graphql-tag";

export const UpdateSpecificObjective = gql`
  mutation UpdateSpecificObjective(
    $id: String!
    $description: String
    $deliverableTitle: String
    $deliverableDescription: String
    $deliverablePeriodicity: DeliverablePeriodicity
  ) {
    updateSpecificObjective(
      data: {
        id: $id
        description: $description
        deliverableTitle: $deliverableTitle
        deliverableDescription: $deliverableDescription
        deliverablePeriodicity: $deliverablePeriodicity
      }
    ) {
      id
      description
      deliverableTitle
      deliverableDescription
      deliverablePeriodicity
    }
  }
`;
