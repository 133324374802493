import React from "react";

import styles from "./styles.less";

class Sidebar extends React.Component {
  public static Heading: React.FC = ({ children }) => {
    return (
      <div className={styles.sidebarHeading}>
        <h2>{children}</h2>
      </div>
    );
  }

  public render() {
    const { children } = this.props;
    return (
      <div className={styles.sidebar}>{children}</div>
    );
  }
}

export default Sidebar;
