import React from "react";
import { Icon, Popover } from "antd";

import styles from "./styles.less";

interface CardProps {
  title: string;
  description: string;
  info?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: (title: string) => void;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  info,
  active = false,
  disabled = false,
  onClick = () => undefined
}) => (
    <div data-active={active} data-disabled={disabled} className={styles.card} onClick={() => onClick(title)}>
      {info && (
        <Popover content={info}>
          <div className={styles.tooltipContainer}>
            <Icon type="info-circle" />
          </div>
        </Popover>
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );

export default Card;
