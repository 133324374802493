import React, { useContext } from "react";
import { MeetingFormContext } from "../../../MeetingFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";

export const PlaceInput = (props: any) => {
  const meetingFormContext = useContext(MeetingFormContext);
  const { form, worktableMeeting, onInputChange } = meetingFormContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item
      label="Sede">
      {getFieldDecorator("place", {
        rules: [
          {
            required: true,
            message: "Por favor, ingresa la sede de la reunión."
          }
        ],
        initialValue: worktableMeeting?.place
      })(
        <Input
          id="place"
          name="place"
          type="text"
          onChange={onChange} />
      )}
    </Form.Item>
  );
};
