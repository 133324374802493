import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { withRouter, RouteComponentProps } from "react-router";
import { Anchor, Col, Row, Icon, message } from "antd";
import { Content } from "../../atoms";
import {
  RequestFooter,
  ProjectFileTree,
  SpecificObjectiveModal
} from "../../organisms";
import { NewRequestContextProps, NewRequestMethods, MutationResult } from "../../NewRequestContext";
import { ActivityForm, GoalForm } from "../../molecules";
import { ReviewableProps, SpecificObjective, Goal, Activity } from "../../../types";
import { RequestReviewConsumer } from "../../RequestReviewContext";
import { client } from "../../../gql/apollo";
import { ToRemoveGoal } from "../../molecules/GoalForm";
import { ToRemove } from "../../molecules/ActivityForm";
import { UpdateProject, CreateSpecificObjective, UpdateSpecificObjective, DeleteSpecificObjective } from "../../../gql/mutations";
import { CreateGoal, UpdateGoal, DeleteGoal } from "../../../gql/mutations/goals";
import { CreateActivity, UpdateActivity, DeleteActivity } from "../../../gql/mutations/activity";
import { CreateSustainabilityArgument, UpdateSustainabilityArgument, DeleteSustainabilityArgument } from "../../../gql/mutations/sustainabilityArguments";
import { ReverseDirectionContainer } from "./components";
import ProjectFileAlternativeView from "./ProjectFileAlternativeView/index";
import { disabledRequestInput } from '../../../utils'

type ProjectFileProps = ReviewableProps & NewRequestContextProps & RouteComponentProps;

interface IGoalModalState {
  visible: boolean;
  busy: boolean;
  goalId?: string;
  specificObjectiveId?: string;
}

interface IActivityModalState {
  visible: boolean;
  busy: boolean;
  goalId?: string;
  activityId?: string;
}

interface ISpecificObjectiveModal {
  visible?: boolean;
  busy: boolean;
  selectedSpecificObjective?: Partial<SpecificObjective>;
}

type ProjectField =
  | "methodology"
  | "duration"
  | "generalObjective"
  | "impactIndicator";

export const ProjectFile: React.FC<ProjectFileProps> = ({
  state,
  state: {
    projectId,
    projectMethodology,
    projectDuration,
    generalObjective,
    impactIndicator,
    specificObjectives,
    goals,
    activities,
    supplies,
    sustainabilityArguments,
    status,
    draft,
    checkFields,
    reviews
  },
  history,
  methods = {} as NewRequestMethods,
  reviewable,
  approval
}) => {
  const [createSpecificObjective] = useMutation<any>(CreateSpecificObjective, { client } as any);
  const [updateSpecificObjective] = useMutation<any>(UpdateSpecificObjective, { client } as any);
  const [deleteSpecificObjective] = useMutation<any>(DeleteSpecificObjective, { client } as any);

  const [createGoal] = useMutation<any>(CreateGoal, { client } as any);
  const [updateGoal] = useMutation<any>(UpdateGoal, { client } as any);
  const [deleteGoal] = useMutation<any>(DeleteGoal, { client } as any);

  const [createActivity] = useMutation<any>(CreateActivity, { client } as any);
  const [updateActivity] = useMutation<any>(UpdateActivity, { client } as any);
  const [deleteActivity] = useMutation<any>(DeleteActivity, { client } as any);

  const [createSustainabilityArgument] = useMutation<any>(CreateSustainabilityArgument, { client } as any);
  const [updateSustainabilityArgument] = useMutation<any>(UpdateSustainabilityArgument, { client } as any);
  const [deleteSustainabilityArgument] = useMutation<any>(DeleteSustainabilityArgument, { client } as any);

  const [updateProject] = useMutation<any>(UpdateProject, { client } as any);

  const [goalModal, setGoalModal] = useState<IGoalModalState>({
    visible: false,
    busy: false,
    goalId: undefined,
    specificObjectiveId: undefined
  });

  const [activityModal, setActivityModal] = useState<IActivityModalState>({
    visible: false,
    busy: false,
    goalId: undefined,
    activityId: undefined
  });

  const [specificObjectiveModal, setSpecificObjectiveModal] = useState<ISpecificObjectiveModal>({
    visible: false,
    busy: false,
    selectedSpecificObjective: undefined
  });

  const [busyAddingGoal, setBusyAddingGoal] = useState(false);
  const [busyArgument, setBusyArgument] = useState({});
  const [alternativeViewEnabled, setAlternativeViewEnabled] = useState(false);

  const {
    saveGeneralObjectiveSummary,
    saveGoalSummary,
    saveActivityInput,
    saveSupplyInput,
    saveProjectMethodology,
    saveProjectDuration,
    saveImpactIndicator,
    addSpecificObjective,
    removeSpecificObjective,
    addGoal,
    removeGoal,
    addActivity,
    saveActivity,
    removeActivity,
    addSustainabilityArgument,
    saveSustainabilityArgument,
    removeSustainabilityArgument
  } = methods;

  const { selectedSpecificObjective } = specificObjectiveModal;

  const disableByStatus: boolean = disabledRequestInput(status, draft);

  const toggleView = () =>
    setAlternativeViewEnabled(!alternativeViewEnabled);

  const handleSaveSpecificObjective = async (
    data: Partial<Pick<SpecificObjective, "summary" | "goals" | "deliverable">>,
    existingId: string | undefined,
    closeModalCb: () => void
  ) => {
    setSpecificObjectiveModal({ ...specificObjectiveModal, busy: true });
    const fromState = addSpecificObjective(data as any, existingId);

    try {
      if (existingId !== undefined) {
        await updateSpecificObjective({
          variables: {
            id: existingId,
            description: fromState.entity.summary,
            deliverableTitle: fromState.entity.deliverable!.title,
            deliverableDescription: fromState.entity.deliverable!.description,
            deliverablePeriodicity: fromState.entity.deliverable!.periodicity
          }
        });
      } else {
        await createSpecificObjective({
          variables: {
            projectId: projectId,
            id: fromState.entity.id,
            description: fromState.entity.summary,
            deliverableTitle: fromState.entity.deliverable!.title,
            deliverableDescription: fromState.entity.deliverable!.description,
            deliverablePeriodicity: fromState.entity.deliverable!.periodicity
          }
        });
      }

      setSpecificObjectiveModal({ ...specificObjectiveModal, busy: false });
      fromState.save();
      closeModalCb();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al guardar el objetivo específico");
      setSpecificObjectiveModal({ ...specificObjectiveModal, busy: false });
    }
  };

  const handleRemoveSpecificObjective = async (objectiveId: string) => {
    const fromState = removeSpecificObjective(objectiveId);
    try {
      await deleteSpecificObjective({ variables: { id: objectiveId } });
      fromState.save();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al borrar el objetivo específico");
    }
  };

  const handleAddGoal = async (
    objectiveId: string,
    data: Pick<Goal, "quantitativeIndicators" | "qualitativeIndicators" | "description">,
    closeFormCb: () => void
  ) => {
    setGoalModal({ ...goalModal, busy: true });
    const fromState = addGoal(objectiveId, data);

    try {
      await createGoal({
        variables: {
          specificObjectiveId: objectiveId,
          id: fromState.entity.id,
          description: fromState.entity.description,
          qualitativeIndicators: fromState.entity.qualitativeIndicators || [],
          quantitativeIndicators: fromState.entity.quantitativeIndicators || [],
        }
      });
      setGoalModal({ ...goalModal, busy: false });
      fromState.save();
      closeFormCb();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al agregar la meta");
      setGoalModal({ ...goalModal, busy: false });
    }
  };

  const handleSaveGoalSummary = async (goalId: string, data: any, toRemove: ToRemoveGoal, closeFormCb: () => void) => {
    setGoalModal({ ...goalModal, busy: true });
    const fromState = saveGoalSummary(goalId, data);
    try {
      await updateGoal({
        variables: {
          id: fromState.entity.id,
          description: fromState.entity.description || null,
          qualitativeIndicators: fromState.entity.qualitativeIndicators || [],
          qualitativeIndicatorsToRemove: toRemove.qualitativeIndicators,
          quantitativeIndicators: fromState.entity.quantitativeIndicators || [],
          quantitativeIndicatorsToRemove: toRemove.quantitativeIndicators,
        }
      });
      setGoalModal({ ...goalModal, busy: false });
      fromState.save();
      closeFormCb();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al guardar la meta");
    }
  };

  const handleRemoveGoal = async (goalId: string) => {
    const fromState = removeGoal(goalId);
    try {
      await deleteGoal({ variables: { id: goalId } });
      fromState.save();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al borrar la meta");
    }
  };

  const handleAddActivity = async (
    goalId: string,
    data: Pick<Activity,  "description" | "months" | "beneficiaries">,
    closeFormCb: () => void
  ) => {
    setActivityModal({ ...activityModal, busy: true });
    const fromState = addActivity(goalId, data as any);

    try {
      await createActivity({
        variables: {
          goalId,
          id: fromState.entity.id,
          beneficiaries: fromState.entity.beneficiaries || [],
          description: fromState.entity.description,
          months: fromState.entity.months
        }
      });
      setActivityModal({ ...activityModal, busy: false });
      fromState.save();
      closeFormCb();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al agregar la actividad");
      setActivityModal({ ...activityModal, busy: false });
    }
  };

  const handleSaveActivity = async (
    goalId: string,
    data: Pick<Activity, "beneficiaries" | "description" | "months">,
    toRemove: ToRemove,
    closeFormCb: () => void
  ) => {
    setActivityModal({ ...activityModal, busy: true });
    const fromState = saveActivity(goalId, data);

    try {
      await updateActivity({
        variables: {
          goalId,
          id: fromState.entity.id,
          beneficiaries: fromState.entity.beneficiaries || [],
          beneficiariesToRemove: toRemove.beneficiaries,
          description: fromState.entity.description,
          months: fromState.entity.months
        }
      });
      setActivityModal({ ...activityModal, busy: false });
      fromState.save();
      closeFormCb();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al agregar la actividad");
      setActivityModal({ ...activityModal, busy: false });
    }
  };

  const handleRemoveActivity = async (activityId: string) => {
    const fromState = removeActivity(activityId);
    try {
      await deleteActivity({ variables: { id: activityId } });
      fromState.save();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al borrar la actividad");
    }
  };

  const handleAddSustainabilityArgument = async () => {
    setBusyArgument({ action: "add" });
    const fromState = addSustainabilityArgument();
    try {
      await createSustainabilityArgument({
        variables: {
          projectId,
          id: fromState.entity.id
        }
      });
      fromState.save();
      setBusyArgument({});
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al agregar el argumento de sostenibilidad");
      setBusyArgument({});
    }
  };

  const handleSaveSustainabilityArgument = async (argumentId: string, argument: string) => {
    setBusyArgument({ id: argumentId, action: "save" });
    const fromState = saveSustainabilityArgument(argumentId, argument);
    try {
      await updateSustainabilityArgument({
        variables: {
          id: fromState.entity.id,
          argument: fromState.entity.argument
        }
      });
      fromState.save();
      setBusyArgument({});
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al guardar el argumento de sostenibilidad");
      setBusyArgument({});
    }
  };

  const handleRemoveSustainabilityArgument = async (argumentId: string) => {
    setBusyArgument({ id: argumentId, action: "delete" });
    const fromState = removeSustainabilityArgument(argumentId);
    try {
      await deleteSustainabilityArgument({ variables: { id: [fromState.entity.id] } });
      fromState.save();
      setBusyArgument({});
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al borrar el argumento de sostenibilidad");
      setBusyArgument({});
    }
  };

  const handleSaveProjectField = async (field: ProjectField, value: string | string[]) => {
    type MethodSignature =
      | ((value: string) => MutationResult<string>)
      | ((value: string[]) => MutationResult<string[]>);

    let method: MethodSignature;
    let variables: any;

    switch (field) {
      case "generalObjective":
        method = saveGeneralObjectiveSummary;
        variables = { generalObjective: value };
        break;
      case "duration":
        method = saveProjectDuration;
        variables = { startDate: value[0], endDate: value[1] };
        break;
      case "methodology":
        method = saveProjectMethodology;
        variables = { methodology: value };
        break;
      case "impactIndicator":
        method = saveImpactIndicator;
        variables = { impactIndicator: value };
        break;
    }

    const fromState = method(value as any);
    try {
      await updateProject({
        variables: {
          id: projectId,
          ...variables
        }
      });
      fromState.save();
    } catch (error) {
      // TODO: Report to bugsnag
      message.error("Ocurrió un problema al borrar la actividad");
    }
  };

  const openGoalForm = (specificObjectiveId: string) =>
    setGoalModal({
      ...goalModal,
      visible: true,
      specificObjectiveId
    })

  const closeGoalForm = () =>
    setGoalModal({
      ...goalModal,
      visible: false,
      goalId: undefined,
      specificObjectiveId: undefined
    })

  const openGoalDetail = (goalId: string) => {
    setGoalModal({
      ...goalModal,
      visible: true,
      goalId
    })
  }

  const openActivityForm = (goalId: string) =>
    setActivityModal({
      ...activityModal,
      visible: true,
      goalId
    })

  const closeActivityForm = () =>
    setActivityModal({
      ...activityModal,
      visible: false,
      goalId: undefined,
      activityId: undefined
    })

  const openActivityDetail = (activityId: string) =>
    setActivityModal({
      ...activityModal,
      visible: true,
      activityId
    })

  const openSpecificObjectiveDetail = (specificObjective?: Partial<SpecificObjective>) =>
    setSpecificObjectiveModal({
      ...specificObjectiveModal,
      visible: !specificObjectiveModal.visible,
      selectedSpecificObjective: specificObjective
    })

  const closeSpecificObjectiveDetail = () =>
    setSpecificObjectiveModal({
      ...specificObjectiveModal,
      visible: !specificObjectiveModal.visible
    })

  const handleOnClickNext = () =>
    history.push("presupuesto");

  return (
    <RequestReviewConsumer>
      {({ reviewState }) => (
        <Content style={{ marginLeft: 245 }}>
          <GoalForm
            reviewState={reviewState}
            reviewable={reviewable}
            approval={approval || disableByStatus}
            busy={goalModal.busy}
            specificObjectiveId={goalModal.specificObjectiveId}
            goal={goals[(goalModal || {}).goalId || ""]}
            addGoal={handleAddGoal}
            saveGoal={handleSaveGoalSummary}
            onClose={closeGoalForm}
            visible={goalModal.visible}
          />
          <ActivityForm
            reviewState={reviewState}
            reviewable={reviewable}
            approval={approval || disableByStatus}
            busy={activityModal.busy}
            goalId={activityModal.goalId}
            activity={activities[(activityModal || {}).activityId || ""]}
            addActivity={handleAddActivity}
            saveActivity={handleSaveActivity}
            durationRange={projectDuration}
            onClose={closeActivityForm}
            visible={activityModal.visible}
          />
          <SpecificObjectiveModal
            specificObjective={selectedSpecificObjective}
            saveSpecificObjective={handleSaveSpecificObjective}
            visible={specificObjectiveModal.visible}
            reviewable={reviewable}
            approval={approval || disableByStatus}
            busy={specificObjectiveModal.busy}
            onClose={closeSpecificObjectiveDetail} />
          <Row gutter={48} style={{ padding: 40, marginTop: 74, marginBottom: 74 }}>
            {alternativeViewEnabled ?
              <ProjectFileAlternativeView
                data={state}
                onToggleView={toggleView} />
              : (
                <>
                  <ReverseDirectionContainer >
                    <Icon
                      style={{ fontSize: "20pt" }}
                      onClick={toggleView}
                      theme={"filled"}
                      type={"appstore"} />
                  </ReverseDirectionContainer>
                  <Col span={18}>
                    <ProjectFileTree
                      reviews={reviews}
                      checkFields={checkFields}
                      busyAddingGoal={busyAddingGoal}
                      busyArgument={busyArgument}
                      reviewable={reviewable}
                      approval={approval}
                      disabled={disableByStatus}
                      onAddGoal={openGoalForm}
                      onGoalDetail={openGoalDetail}
                      onAddActivity={openActivityForm}
                      onActivityDetail={openActivityDetail}
                      fields={{
                        projectMethodology,
                        projectDuration,
                        generalObjective,
                        impactIndicator,
                        specificObjectives,
                        goals,
                        activities,
                        supplies,
                        sustainabilityArguments
                      }}
                      methods={{
                        saveGeneralObjectiveSummary: (value) => handleSaveProjectField("generalObjective", value),
                        saveActivityInput,
                        saveSupplyInput,
                        saveProjectMethodology: (value) => handleSaveProjectField("methodology", value),
                        saveProjectDuration: (value) => handleSaveProjectField("duration", value),
                        saveImpactIndicator: (value) => handleSaveProjectField("impactIndicator", value),
                        addSpecificObjective: openSpecificObjectiveDetail,
                        removeSpecificObjective: handleRemoveSpecificObjective,
                        removeGoal: handleRemoveGoal,
                        removeActivity: handleRemoveActivity,
                        addSustainabilityArgument: handleAddSustainabilityArgument,
                        saveSustainabilityArgument: handleSaveSustainabilityArgument,
                        removeSustainabilityArgument: handleRemoveSustainabilityArgument
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <Anchor style={{ backgroundColor: "transparent" }} offsetTop={184}>
                      <Anchor.Link href="#projectMethodology" title="Metodología" />
                      <Anchor.Link href="#generalObjective" title="Objetivo general"/>
                      <Anchor.Link href="#specificObjectives" title="Objetivos específicos">
                        {generalObjective.specificObjectives!.map((specificObjectiveId, idx) => (
                          <Anchor.Link
                            href={`#specificObjectives.${specificObjectiveId}`}
                            title={`Objetivo específico #${idx + 1}`}
                          />
                        ))}
                      </Anchor.Link>
                      <Anchor.Link href="#sustainabilityArguments" title="Sustentabilidad" />
                    </Anchor>
                  </Col>
                </>
              )}
          </Row>
          {reviewable || !approval && !disableByStatus && (
            <RequestFooter nextProps={{ onClick: handleOnClickNext }} />
          )}
        </Content>
      )}
    </RequestReviewConsumer>
  );
};

export default withRouter(ProjectFile);
