import { UserStatus, UserRole } from "../../types/User";

export const userStatuses: { [status in UserStatus]: string } = {
  PENDING_CONFIRMATION: "No confirmado",
  ACTIVE: "Activo",
  INACTIVE: "Inactivo"
};

export const userRoles: { [role in UserRole]: string } = {
  ADMIN: "Administrador",
  IMPLEMENTER: "Implementador",
  LEGAL_AREA: "Área legal",
  TECHNICAL_AREA: "Área técnica"
};
