import React from "react";
import { Input, Select, InputNumber, Button, Checkbox, Form } from 'antd'

import { Supply, ExpenseType, MeasurementUnit, HRProfile } from "../../../../../types";
import { expenseTypes, verificationTypes, measurementUnit } from "../../../../../utils/maps";
import { IActivity } from "../../../../templates/workTables/CreateWorkTableTemplate/types";
import { formatInput, parseInput } from "../../../../../utils/currency";

interface IArgs {
  supplies: any[];
  onRowChange: any;
  reviewable: boolean;
  approval: boolean;
  activities: IActivity[];
  HRProfiles: { [id: string]: HRProfile };
  openModal: any;
  isBusy: boolean;
  disabled: boolean;
  checkFields: boolean
}

export const getFirstColumns = ({
  supplies = [],
  onRowChange,
  reviewable = false,
  approval = false,
  activities = [],
  HRProfiles = {},
  openModal,
  isBusy,
  disabled,
  checkFields
}: IArgs) => {
  return ([{
    title: "Concepto",
    dataIndex: "name",
    key: "name",
    fixed: "left" as "left",
    render: (value: string, supply: Partial<Supply>, idx: number) => {
      if (!supply.summary) {
        if (supply.HRProfileId) {
          const HR = HRProfiles[supply.HRProfileId];
          return HR?.fullName || "Recurso humano sin nombre";
        }

        return (
          <Form.Item
            style={{marginBottom: 0 }}
            validateStatus={checkFields ? value ? "" : "error" : "" }>
            <Input
              style={{ width: 250 }}
              defaultValue={value}
              onBlur={(evt) => onRowChange(supply.id!, "name", evt.target.value)}
              disabled={isBusy || reviewable || approval || disabled}
            />
          </Form.Item>
        );
      }

      return supply.summary;
    }
  },
  {
    title: "Tipo de gasto",
    dataIndex: "expenseType",
    key: "expenseType",
    render: (value: string, supply: Partial<Supply>, idx: number) => {
      if (!supply.summary) {
        if (supply.HRProfileId) {
          return expenseTypes[value as ExpenseType];
        }

        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Select
                value={value as ExpenseType}
                placeholder="Selecciona el tipo de gasto"
                style={{ width: 250 }}
                onChange={(expenseType: ExpenseType) =>
                  onRowChange(supply.id!, "expenseType", expenseType)}
                disabled={isBusy || reviewable || approval || disabled}>
                {
                  Object.keys(expenseTypes).map((type) => (
                    <Select.Option
                      key={type}
                      value={type}>
                      {expenseTypes[type]}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            {
              value === "EQUIPMENT" ?
                <Button
                  onClick={() => openModal(supply?.id)}
                  shape="circle"
                  type="primary"
                  icon="file-add" />
                : null
            }
          </div>
        );
      }

      return null;
    }
  },
  {
    title: "Tipo de comprobación",
    dataIndex: "isVerifiable",
    key: "isVerifiable",
    render: (value: boolean, supply: Partial<Supply>, idx: number) =>
      !supply.summary ? (
        <Checkbox
          defaultChecked={value}
          onChange={(evt) => onRowChange(supply.id!, "isVerifiable", evt.target.checked || false)}
          disabled={isBusy || reviewable || approval || disabled}>
          Comprobable
        </Checkbox>
      ) : null
  },
  {
    title: "Costo unitario",
    dataIndex: "unitCost",
    key: "unitCost",
    render: (value: number = 0, supply: Partial<Supply>, idx: number) =>
      !supply.summary ? (
        <Form.Item
          style={{marginBottom: 0 }}
          validateStatus={checkFields ? value ? "" : "error" : "" }>
          <InputNumber
            min={0}
            style={{ width: 150 }}
            defaultValue={value}
            formatter={formatInput}
            onBlur={(unitCost) => onRowChange(supply.id!, "unitCost", parseInput(unitCost.target.value))}
            disabled={isBusy || reviewable || approval || disabled} />
        </Form.Item>
      ) : null
  },
  {
    title: "Unidad de medida",
    dataIndex: "measurementUnit",
    key: "measurementUnit",
    render: (value: string, supply: Partial<Supply>, idx: number) =>
      !supply.summary ? (
        <Form.Item
          style={{marginBottom: 0 }}
          validateStatus={checkFields ? value ? "" : "error" : "" }>
          <Select
            value={value as MeasurementUnit}
            placeholder="Selecciona la unidad de medida"
            style={{ width: 150 }}
            onChange={(measurementUnitValue: MeasurementUnit) =>
              onRowChange(supply.id!, "measurementUnit", measurementUnitValue)}
            disabled={isBusy || reviewable || approval || disabled}>
            {
              Object.keys(measurementUnit).map((mUnit) => (
                <Select.Option key={mUnit}>
                  {measurementUnit[mUnit]}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      ) : null
  },
  {
    title: "Unidades",
    dataIndex: "count",
    key: "count",
    render: (count: number = 0, supply: Partial<Supply>, idx: number) => {
      if (!supply.summary) {
        if (supply.HRProfileId) {
          return 1;
        }

        return (
          <Form.Item
            style={{marginBottom: 0 }}
            validateStatus={checkFields ? count ? "" : "error" : "" }>
            <InputNumber
              min={0}
              defaultValue={count}
              onBlur={(evt) => onRowChange(supply.id!, "count", (Number(evt.target.value) || 0))}
              disabled={isBusy || reviewable || approval || disabled} />
          </Form.Item>
        );
      }

      return null;
    }
  },
  {
    title: "Actividad",
    dataIndex: "activityId",
    key: "activityId",
    render: (id: string, supply: Partial<Supply>, idx: number) =>
      !supply.summary ? (
        <Form.Item
          style={{marginBottom: 0 }}
          validateStatus={checkFields ? id ? "" : "error" : "" }>
          <Select
            value={id}
            placeholder="Selecciona la actividad"
            style={{ width: 150 }}
            onChange={(activityId: string) =>
              onRowChange(supply.id!, "activityId", activityId)}
            disabled={isBusy || reviewable || approval || disabled}>
            {Object.keys(activities).map((activityId) => (
              <Select.Option key={activityId}>
                {activities[activityId].description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : null
  }
  ]);
};
