import React, { useContext } from "react";
import { Row, Col, Skeleton } from "antd";

import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import {
  StreetInput,
  ExteriorNumberInput,
  InteriorNumberInput,
  ColonyInput,
  ZipCodeInput,
  CityInput,
  StateInput
} from "./inputs";


export const FiscalAddressSection = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const { isLoadingData } = implementerAccountContext;

  if (isLoadingData) {
    return <Skeleton loading />;
  }

  return (
    <FormSection title="DIRECCIÓN FISCAL">
      <Row>
        <Col span={24}>
          <StreetInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ExteriorNumberInput />
        </Col>
        <Col span={12}>
          <InteriorNumberInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ColonyInput />
        </Col>
        <Col span={12}>
          <ZipCodeInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <CityInput />
        </Col>
        <Col span={12}>
          <StateInput />
        </Col>
      </Row>
    </FormSection>
  );
};
