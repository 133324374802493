import { UserRole } from "../../../../../types";
import { implementerMenuItems } from "./implementerMenuItems";
import { adminMenuItems } from "./adminMenuItems";

export const sectionsMap = {
  presolicitudes: "prerequests",
  "solicitudes/revision": "requests:review",
  "solicitudes/aprobacion": "requests:approved",
  "solicitudes/convenio": "requests:agreement",
  solicitudes: "requests",
  proyectos: "projects",
  "mesas-de-trabajo": "worktables"
};

export const sectionMatcher = (pathname: string) =>
  Object.keys(sectionsMap).find((section) => pathname.match(section));

export const menuItemsMap: { [key in UserRole]: any } = {
  IMPLEMENTER: implementerMenuItems,
  ADMIN: adminMenuItems,
  LEGAL_AREA: null,
  TECHNICAL_AREA: null
};
