import React from "react";
import {
  Button,
  Empty,
  Spin,
  Table,
  Tag,
  PageHeader
} from "antd";
import { Agreement, AgreementStatus } from "../../../types/Agreement";
import { agreementStatuses } from "../../../utils/maps/workTables";
import { Container } from "../../atoms";

interface AgreementsTemplateProps {
  loading: boolean;
  data: any;
}

interface AgreementsTemplateState {
  agreementModal: {
    isVisible: boolean;
    agreement?: Agreement;
  };
}

class AgreementsTemplate extends React.Component<
AgreementsTemplateProps,
  AgreementsTemplateState
  > {

  public state: AgreementsTemplateState = {
    agreementModal: {
      isVisible: false,
      agreement: undefined
    }
  };

  private agreementsColumns = [
    {
      title: "ID minuta",
      dataIndex: "idMinuta",
      key: "idMinuta",
      render: (idMinuta) => (
        <div style={{
          textAlign: "center",
          color: "#8E1D1D"
        }}>
          {idMinuta}
        </div>
      )
    },
    {
      title: "Creación",
      dataIndex: "createdDate",
      key: "createdDate"
    },
    {
      title: "Acuerdo",
      dataIndex: "agreement",
      key: "agreement"
    },
    {
      title: "Responsable",
      dataIndex: "responsible",
      key: "responsible"
    },
    {
      title: "Fecha límite",
      dataIndex: "dueDate",
      key: "dueDate"
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      render: (status: AgreementStatus) => (
        <Tag
          color={this.getTagColor(status)}>
          {agreementStatuses[status]}
        </Tag>
      )
    },
    {
      render: () => (
        <>
          <Button style={{ margin: "0 4px" }} icon="edit" shape="circle" size="large" />
          <Button style={{ margin: "0 4px" }} icon="delete" shape="circle" size="large" />
        </>
      )
    }
  ];

  public render() {
    const { agreementModal } = this.state;
    const { loading, data } = this.props;
    const WorkTableAgreements = data || [];

    return (
      <Container>
        <PageHeader
          style={{
            borderBottom: "1px solid rgb(235, 237, 240)",
          }}
          title="Acuerdos de trabajo" />
        <this.EmptyView
          loading={loading}
          empty={WorkTableAgreements.legth === 0}>
          <div style={{ backgroundColor: "#ffffff" }}>
            <Table
              pagination={false}
              columns={this.agreementsColumns}
              dataSource={WorkTableAgreements}
              onRow={() => null}/>
          </div>
        </this.EmptyView>
      </Container>
    );
  }

  private getTagColor(status: string) {
    switch (status) {
      case "IN_PROGRESS":
        return "gold";
      case "FINISHED":
        return "red";
      case "COMPLETED":
        return "green";
      default:
        return "";
    }
  }

  private EmptyView: React.FC<{ loading: boolean, empty: boolean }> = ({ loading, empty, children }) => {
    if (loading) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spin />
        </div>
      );
    }

    if (empty) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Empty
            image={require("../../../assets/empty_agreements.png")}
            imageStyle={{ height: 81 }}
            description={
              <div style={{ fontSize: 16, marginTop: 20 }}>
                No se han registrado acuerdos
              </div>
            }>
          </Empty>
        </div>
      );
    }

    return <>{children}</>;
  }

}

export default AgreementsTemplate;
