import React from "react";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import { FormComponentProps } from "antd/lib/form";

import { FormSection } from "../../../molecules";
import { CTAButton, CTAContainer, ExtraCTAContainer } from ".";

interface IRawSignInFormOwnProps {
  loading: boolean;
  onSignInAttempt: (emailAddress: string, password: string) => void;
}

type RawSignInFormProps = FormComponentProps & IRawSignInFormOwnProps;

const RawSignInForm: React.FC<RawSignInFormProps> = ({ form, loading, onSignInAttempt }) => {
  const { getFieldDecorator } = form;

  const handleOnSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        // TODO: Report to bugsnag
        return;
      }

      onSignInAttempt(values?.emailAddress, values?.password);
    });
  };

  return (
    <Form>
      <FormSection>
        <Form.Item label="Correo electrónico">
          {getFieldDecorator("emailAddress", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa el correo electrónico."
              },
              {
                type: "email",
                message: "Por favor, ingresa un correo electrónico válido."
              }
            ],
          })(
            <Input size="large" disabled={loading} />
          )}
        </Form.Item>
        <Form.Item label="Contraseña">
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa una contraseña."
              },
              {
                min: 6,
                message: "Usa una contraseña de al menos 6 caracteres."
              }
            ]
          })(
            <Input.Password size="large" disabled={loading} />
          )}
        </Form.Item>
      </FormSection>
      <FormSection>
        <CTAContainer>
          <Form.Item>
            <CTAButton
              shape="round"
              type="primary"
              size="large"
              htmlType="submit"
              onClick={handleOnSubmit}
              loading={loading}>
              Iniciar sesión
            </CTAButton>
          </Form.Item>
          <ExtraCTAContainer>
            <span style={{ marginRight: 10 }}>¿No tienes cuenta?</span>
            <Link to="/crear-cuenta">Crear cuenta</Link>
          </ExtraCTAContainer>
        </CTAContainer>
      </FormSection>
    </Form>
  );
};

export const SignInForm = Form.create<RawSignInFormProps>({
  name: "SignInForm"
})(RawSignInForm);
