import gql from "graphql-tag";

export const SaveFieldVisit = gql`
  mutation SaveFieldVisit(
    $id: String # Field visit ID
    $projectId: String!
    $monitoringEntry: String!
    $activityName: String
    $activityObjective: String
    $reporterName: String
    $visitPlace: String
    $visitDate: String
    $visitStartTime: String
    $visitEndTime: String
    $activityContent: String
    $attendanceList: Boolean
    $ficosecLogos: Boolean
    $ficosecLogosReason: String
    $activityPlace: Boolean
    $initialPlanning: Boolean
    $initialPlanningReason: String
    $organizationEvidence: Boolean
    $organizationEvidenceType: String
    $suppliesUsed: String
    $goalBeneficiaries: Int
    $actualBeneficiaries: Int
    $observations: String
    $obstacles: String
  ) {
  saveFieldVisit(
    projectId: $projectId
    monitoringEntry: $monitoringEntry
    data: {
      id: $id
      activityName: $activityName
      activityObjective: $activityObjective
      reporterName: $reporterName
      visitPlace: $visitPlace
      visitDate: $visitDate
      visitStartTime: $visitStartTime
      visitEndTime: $visitEndTime
      activityContent: $activityContent
      attendanceList: $attendanceList
      ficosecLogos: $ficosecLogos
      ficosecLogosReason: $ficosecLogosReason
      activityPlace: $activityPlace
      initialPlanning: $initialPlanning
      initialPlanningReason: $initialPlanningReason
      organizationEvidence: $organizationEvidence
      organizationEvidenceType: $organizationEvidenceType
      suppliesUsed: $suppliesUsed
      goalBeneficiaries: $goalBeneficiaries
      actualBeneficiaries: $actualBeneficiaries
      observations: $observations
      obstacles: $obstacles
    }
  ) {
    id
    activityName
    activityObjective
    reporterName
    visitPlace
    visitDate
    visitStartTime
    visitEndTime
    activityContent
    attendanceList
    ficosecLogos
    ficosecLogosReason
    activityPlace
    initialPlanning
    initialPlanningReason
    organizationEvidence
    organizationEvidenceType
    suppliesUsed
    goalBeneficiaries
    actualBeneficiaries
    observations
    obstacles
  }
}
`;
