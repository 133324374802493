import { RequestApprovalStep } from "../../../organisms/ApprovedRequestsSidebar";
import { Review } from "../../../../types";
import { PaginatedResult } from "../PendingRequestsTemplate";

export type RevisionBoard =
  | "UNITY"
  | "LOCAL"
  | "DIRECTORS"
  | "TECHNICIANS";

export const RevisionBoard: { [key in RevisionBoard]: RevisionBoard } = {
  UNITY: "UNITY",
  LOCAL: "LOCAL",
  DIRECTORS: "DIRECTORS",
  TECHNICIANS: "TECHNICIANS",
};

export type RevisionStatus =
  | "APPROVED"
  | "REJECTED"
  | "CORRECTION_REQUESTED";

export const RevisionStatus: { [key in RevisionStatus]: RevisionStatus } = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  CORRECTION_REQUESTED: "CORRECTION_REQUESTED",
};

export interface Revision {
  id: string;
  board: RevisionBoard;
  status: RevisionStatus;
  annotations?: string;
  createdAt: string;
}

export interface Request { // TODO: Define request object
  id: string;
  draft: boolean;
  revisions: Revision[];
  reviews: Review[];
}

export interface Meeting { // TODO: Define meeting object
  id: string;
}

export interface ApprovedRequestsTemplateProps {
  loading: boolean;
  requests: PaginatedResult<Request>;
}

export interface ApprovedRequestsTemplateState {
  currentStep: RequestApprovalStep;
}
