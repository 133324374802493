import { Menu, Layout } from "antd";
import styled from "styled-components";

export const StyledLogoContainer = styled.div`
  width: 230px;
  min-width: 230px;
`;

export const StyledMenu = styled(Menu)`
  line-height: 63px;
  border: none;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
  width: calc(100% - 230px);
`;

export const StyledHeader = styled(Layout.Header)`
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
  z-index: 10;
  padding: 0 30px 0 15px;
  display: flex;
`;

export const StyledAvatarContainer = styled.div`
  float: right;
`;

export const imageStyle = {
  width: "120px",
  height: "31px"
};
