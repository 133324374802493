import React, { useState } from "react";
import { Modal, message } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { UploadFile } from "antd/lib/upload/interface";

import { ModalProps } from "../../../../atoms/Modal";
import { ActivityForm } from ".";
import { Activity, ActivityIndicatorType } from "../../../../../types";
import { SaveTechnicalMonitoring } from "../../../../../gql/mutations";
import { client } from "../../../../../gql/apollo";
import { TechnicalMonitoringEntry } from "../../../../../gql/queries";

interface IActivityModalOwnProps {
  activity?: Activity;
}

interface IActivityBeneficiaryRecord {
  id?: string;
  obtained: number;
}

type ActivityModalProps = IActivityModalOwnProps & ModalProps;

export const ActivityModal: React.FC<ActivityModalProps> = ({ activity, ...modalProps }) => {
  const [busy, setBusy] = useState(false);
  const { projectId, monitoringEntry } = useParams();
  const [saveTechnicalMonitoring] = useMutation(SaveTechnicalMonitoring, { client } as any);

  const getActivityBeneficiariesMonitoringEntries = (beneficiaries: { [uuid: string]: IActivityBeneficiaryRecord }) => {
    const beneficariesIds = Object.keys(beneficiaries);
    return beneficariesIds.map((beneficiaryId) => ({
      id: beneficiaries[beneficiaryId].id,
      activityBeneficiaryId: beneficiaryId,
      obtained: beneficiaries[beneficiaryId].obtained
    }));
  };

  const getIndicatorMonitoringEntries = (
    entries: Array<{ [uuid: string]: any }>,
    type: ActivityIndicatorType
  ) => {
    const indicatorEntries = entries[type];
    const entriesIds = Object.keys(indicatorEntries);

    return entriesIds.map((entryId) => ({
      id: indicatorEntries[entryId].id,
      indicatorId: entryId,
      documents: getDocuments(indicatorEntries[entryId].documents.fileList)
    }));
  };

  const getDocuments = (files: UploadFile[]) =>
    files?.map((file) => ({
      ref: file.response?.imageUrl,
      fileName: file.name
    }));

  const handleOnSubmit = (form: WrappedFormUtils<any>) => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setBusy(true);
          await saveTechnicalMonitoring({
            variables: {
              monitoringEntryId: monitoringEntry,
              activityId: activity?.id,
              activityMonitoringEntryId: values.activityMonitoringEntryId,
              plannedActivities: values.plannedActivities,
              activitiesDone: values.activitiesDone,
              hoursInvested: values.hoursInvested,
              activityBeneficiariesMonitoringEntries: getActivityBeneficiariesMonitoringEntries(values.beneficiaries),
              qnIndicatorMonitoringEntries: getIndicatorMonitoringEntries(values, ActivityIndicatorType.QUANTITATIVE),
              qxIndicatorMonitoringEntries: getIndicatorMonitoringEntries(values, ActivityIndicatorType.QUALITATIVE)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: TechnicalMonitoringEntry,
                variables: {
                  activityId: activity?.id,
                  monitoringEntryId: monitoringEntry
                }
              }
            ]
          });
          modalProps.onCancel?.(null as any);
        } catch (error) {
          // TODO: Report to bugsnag
          console.error(error);
          message.error("Ocurrió un problema al guardar los datos.");
        } finally {
          setBusy(false);
        }
      }
    })
  };

  return (
    <Modal
      width={720}
      destroyOnClose
      footer={null}
      {...modalProps}>
      <ActivityForm
        busy={busy}
        activity={activity}
        onSubmit={handleOnSubmit}
        onCancel={modalProps.onCancel} />
    </Modal>
  );
};  