import React from "react";
import { Card as ANTCard, Button } from "antd";
import styled from "styled-components";

interface IActivityCardProps {
  id: string;
  name: string;

  onClickUpdate: (activityId?: string) => void;
}

const Card = styled(ANTCard)`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ActivityTitle = styled.div`
  font-weight: bold;
`;

const ActivityMeta = styled.div``;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  flex: 1;
  float: left;
`;

const RightContainer = styled.div`
  float: right;
`;

export const ActivityCard: React.FC<IActivityCardProps> = ({ id, name, onClickUpdate }) =>
  <Card bordered={false} key={id}>
    <MainContainer>
      <LeftContainer>
        <ActivityTitle>{name}</ActivityTitle>
        <ActivityMeta>Actualiza las actividades hechas</ActivityMeta>
      </LeftContainer>
      <RightContainer>
        <Button
          type="primary"
          shape="round"
          onClick={() => onClickUpdate(id)}>
          Actualizar
        </Button>
      </RightContainer>
    </MainContainer>
  </Card>;