import { WorkTableArea } from "../../types";

export const worktableAreas: {[key in WorkTableArea]: string } = {
  UPV: "UPV",
  DIRECTION: "Dirección",
  SECURITY_AND_JUSTICE: "Mesa de Seguridad y Justicia en Ciudad Juárez",
  CITIZEN_LINKING_UNIT: "Unidad de Vinculación Ciudadana",
  CITIZEN_OBSERVATORY: "Observatorio Ciudadano",
  ORG_DEVELOPMENT_AND_INTERNAL_STRENGTHENING: `Desarrollo Organizacional y
    Fortalecimiento Interno`,
  ADMINISTRATION: "Administración",
  OTHER: "Otro"
};
