import React from "react";
import { Form, Button, Input, Modal as ANTModal, Typography, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { Modal } from "../../atoms";
import { GoalIndicatorType, GoalIndicators, Goal, } from '../../../types'
import { FormSection } from "..";
import { RequestReviewContextOwnProps } from "../../RequestReviewContext";

export interface ToRemoveGoal {
  qualitativeIndicators: string[];
  quantitativeIndicators: string[];
}

interface GoalFormOwnProps {
  reviewState: Partial<RequestReviewContextOwnProps>;
  reviewable?: boolean;
  approval?: boolean;
  busy: boolean;
  specificObjectiveId?: string;
  goal?: Partial<Goal>;
  addGoal: (specificObjectiveId: string, data: any, closeFormCb: () => void) => void;
  saveGoal: (goalId: string, data: any, toRemove: ToRemoveGoal, closeFormCb: () => void) => void;
  visible: boolean;
  onClose: () => void;
}

type GoalFormProps = GoalFormOwnProps & FormComponentProps;

interface GoalFormState {
  quantitativeIndicators: GoalIndicators<"QUANTITATIVE">;
  quantitativeIndicatorsToRemove: string[];
  qualitativeIndicators: GoalIndicators<"QUALITATIVE">;
  qualitativeIndicatorsToRemove: string[];
}

const indicatorStateKey: { [key in GoalIndicatorType]: string } = {
  QUANTITATIVE: "quantitativeIndicators",
  QUALITATIVE: "qualitativeIndicators"
};

class GoalForm extends React.Component<GoalFormProps, GoalFormState> {
  public state: GoalFormState = {
    quantitativeIndicators: {},
    quantitativeIndicatorsToRemove: [],
    qualitativeIndicators: {},
    qualitativeIndicatorsToRemove: []
  };

  public componentDidUpdate(prevProps: GoalFormProps) {
    const { goal, visible } = this.props;
    if (!prevProps.visible && visible && goal) {
      this.setState({
        qualitativeIndicators: goal.qualitativeIndicators || {},
        quantitativeIndicators: goal.quantitativeIndicators || {}
      });
    }
  }

  private handleOnClose = () => {
    const { onClose, form } = this.props;
    form.resetFields();
    this.setState({ quantitativeIndicators: {}, qualitativeIndicators: {} });
    onClose();
  }

  private handleOnSave = () => {
    const {
      quantitativeIndicators,
      quantitativeIndicatorsToRemove,
      qualitativeIndicators,
      qualitativeIndicatorsToRemove
    } = this.state;
    const { form, specificObjectiveId, goal, addGoal, saveGoal } = this.props;
    const quantitativeIndicatorsKeys = Object.keys(quantitativeIndicators);
    const qualitativeIndicatorsKeys = Object.keys(qualitativeIndicators);
    const empty = {
      quantitativeIndicators: quantitativeIndicatorsKeys.length === 0,
      qualitativeIndicators: qualitativeIndicatorsKeys.length === 0
    };

    if (empty.quantitativeIndicators || empty.qualitativeIndicators) {
      return ANTModal.error({
        title: "Campos faltantes",
        content: (
          <ul>
            {empty.quantitativeIndicators && <li>Agrega al menos un indicador cuantitativo.</li>}
            {empty.qualitativeIndicators && <li>Agrega al menos un indicador cualitativo.</li>}
          </ul>
        )
      });
    }

    form.validateFields((err: any, values: any) => {
      if (!err && specificObjectiveId) {
        addGoal(
          specificObjectiveId,
          {
            description: values.goals._.description,
            qualitativeIndicators: values.goals._.qualitativeIndicators,
            quantitativeIndicators: values.goals._.quantitativeIndicators,
          },
          this.handleOnClose
        );
      } else if (!err && goal) {
        saveGoal(
          goal.id!,
          {
            description: values.goals[goal.id!].description,
            qualitativeIndicators: values.goals[goal.id!].qualitativeIndicators,
            quantitativeIndicators: values.goals[goal.id!].quantitativeIndicators,
          },
          {
            quantitativeIndicators: quantitativeIndicatorsToRemove,
            qualitativeIndicators: qualitativeIndicatorsToRemove
          },
          this.handleOnClose
        );
      }
    });

    return;
  }

  private addIndicator = (type: GoalIndicatorType, indicatorId: string = uuid()) =>
    this.setState((state) => ({
      ...state,
      [indicatorStateKey[type]]: {
        ...state[indicatorStateKey[type]],
        [indicatorId]: {
          id: indicatorId
        }
      }
    }))

  private removeIndicator = (type: GoalIndicatorType, indicatorId: string) => {
    const { qualitativeIndicatorsToRemove, quantitativeIndicatorsToRemove } = this.state;
    const { [indicatorId]: removed, ...indicators } = this.state[indicatorStateKey[type]];
    const toRemove = type === "QUALITATIVE"
      ? { qualitativeIndicatorsToRemove: [...qualitativeIndicatorsToRemove, indicatorId] }
      : { quantitativeIndicatorsToRemove: [...quantitativeIndicatorsToRemove, indicatorId] };

    return this.setState((state) => ({ ...state, [indicatorStateKey[type]]: indicators, ...toRemove }));
  }

  private renderIndicatorRows = <T extends GoalIndicatorType>(
    type: GoalIndicatorType,
    indicators: GoalIndicators<T> = {}
  ) => {
    const { goal = {}, reviewable, approval, busy, form: { getFieldDecorator } } = this.props;
    const indicatorIds = Object.keys(indicators);
    const indicatorsKey = `goals.${goal.id || "_"}.${indicatorStateKey[type]}`;

    return indicatorIds.map((indicatorId) => {
      return (
        <div style={{ display: "flex" }}>
          <Row gutter={10} style={{ flex: 1 }}>
            <Col span={12}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${indicatorsKey}.${indicatorId}.description`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, describa el indicador."
                    }
                  ],
                  initialValue: get(goal, `${indicatorStateKey[type]}.${indicatorId}.description`, undefined)
                })!(
                  <Input placeholder="Descripción" disabled={busy || reviewable || approval} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${indicatorsKey}.${indicatorId}.verificationMethod`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingrese el medio de verificación."
                    }
                  ],
                  initialValue: get(goal, `${indicatorStateKey[type]}.${indicatorId}.verificationMethod`, undefined)
                })!(
                  <Input placeholder="Medio de verificación" disabled={busy || reviewable || approval} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <div style={{ marginTop: 4, marginLeft: 10 }}>
            {!reviewable && !approval && (
              <Button
                icon="close"
                type="link"
                onClick={() => this.removeIndicator(type, indicatorId)}
                disabled={busy}
              />
            )}
          </div>
        </div>
      );
    });
  }

  public render() {
    const { quantitativeIndicators, qualitativeIndicators } = this.state;
    const { form, visible, reviewable, goal = {}, approval, busy } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        width={1020}
        title="Nueva Meta"
        footer={
          !(reviewable || approval) ? ([
            <Button shape="round" loading={busy} type="primary" onClick={this.handleOnSave}>Guardar</Button>,
            <Button shape="round" disabled={busy} onClick={this.handleOnClose}>Cancelar</Button>
          ]) : ([
            <Button shape="round" onClick={this.handleOnClose}>Cerrar</Button>
          ])
        }
        onCancel={!busy ? this.handleOnClose : undefined}
        visible={visible}
        closable
      >
        <Form>
          <FormSection>
            <Typography.Paragraph style={{ fontSize: 14 }} strong>Descripción</Typography.Paragraph>
            {getFieldDecorator(`goals.${goal.id || "_"}.description`, {
              rules: [
                {
                  required: true,
                  message: "Por favor, describe la meta."
                }
              ],
              initialValue: goal.description
            })!(
              <Input.TextArea
                placeholder="Redacción de la meta"
                rows={3}
                style={{ width: "100%" }}
                disabled={busy || reviewable || approval}
              />
            )}
          </FormSection>
          <FormSection>
            <Typography.Paragraph style={{ fontSize: 14 }} strong>Indicadores cuantitativos</Typography.Paragraph>
            {this.renderIndicatorRows(
              "QUANTITATIVE",
              quantitativeIndicators
            )}
            {!reviewable && !approval && (
              <Button
                icon="plus"
                type="dashed"
                onClick={() => this.addIndicator("QUANTITATIVE")}
                disabled={busy}
                block
              >
                Agregar indicador
              </Button>
            )}
          </FormSection>
          <FormSection>
            <Typography.Paragraph style={{ fontSize: 14 }} strong>Indicadores cualitativos</Typography.Paragraph>
            {this.renderIndicatorRows(
              "QUALITATIVE",
              qualitativeIndicators
            )}
            {!reviewable && !approval && (
              <Button
                icon="plus"
                type="dashed"
                onClick={() => this.addIndicator("QUALITATIVE")}
                disabled={busy}
                block
              >
                Agregar indicador
              </Button>
            )}
          </FormSection>
        </Form>
      </Modal>
    );
  }
}

const WrappedGoalForm = Form.create<GoalFormProps>({ name: "goalForm" })(GoalForm);

export default WrappedGoalForm;
