import gql from "graphql-tag";

const UpdateUserStatus = gql`
  mutation UpdateUserStatus($id: ID!, $status: String!) {
    updateUser(id: $id, status: $status) {
      id
      status
    }
  }
`;

export default UpdateUserStatus;
