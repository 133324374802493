import React from "react";
import { Button } from "antd";

export const SaveButton: React.FC<{onSaveMeeting: any, isBusy?: boolean}> = ({
    onSaveMeeting,
    isBusy = false
  }) => {

    return (
      <Button
        loading={isBusy}
        style={{ float: "left" }}
        shape="round"
        htmlType="submit"
        type="primary"
        onClick={onSaveMeeting}>
        Guardar
      </Button>
    );
};
