import React from "react";
import { Button } from "antd";
import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router";

import { IPreRequest } from "../../../../../types";
import { SectionCard } from "../../../../organisms/ImplementerLayout";
import {
  StyledContainer,
  StyledFormContainer,
  CircleIcon,
  InfoText,
  InfoLabel,
  StyledRow,
  StyledTableContainer
} from ".";

interface IInfoTableOwnProps {
  preRequest: Partial<IPreRequest>;
}

type IInfoTableProps = IInfoTableOwnProps & RouteComponentProps;

const InfoTable: React.FC<IInfoTableProps> = (props) => {
  const {
    projectName = "",
    estimatedBudget = 0,
    createdAt = 0
  } = props.preRequest;

  const budget = parseFloat((estimatedBudget)
    .toString());
  const createdAtString = createdAt.toString();
  const date = moment(parseInt(createdAtString, 10))
    .format("DD/MM/YYYY HH:mm");

  const goToPreRequestsList = () => {
    const { history } = props;
    history.push("../"); // go to the pre-requests list
  };

  return (
    <SectionCard>
      <StyledContainer>
        <StyledFormContainer>
          <CircleIcon />
          <h1>Pre-solicitud enviada</h1>
          <span>El comité de FICOSEC lo analizará</span>
          <StyledTableContainer>
            <StyledRow>
              <InfoLabel>Pre-solicitud:</InfoLabel>
              <InfoText>{projectName}</InfoText>
            </StyledRow>
            <StyledRow>
              <InfoLabel>Fecha de envío:</InfoLabel>
              <InfoText>{date}</InfoText>
            </StyledRow>
            <StyledRow>
              <InfoLabel span={12}>Presupuesto estimado:</InfoLabel>
              <InfoText span={12}>$ {Number(budget).toLocaleString()}</InfoText>
            </StyledRow>
          </StyledTableContainer>
          <Button
            onClick={goToPreRequestsList}
            type="primary"
            shape="round">
            Entendido
          </Button>
        </StyledFormContainer>
      </StyledContainer>
    </SectionCard>
  );
};

export default withRouter(InfoTable);
