import React from "react";
import { Popover as AntPopover } from "antd";
import { PopoverProps as AntPopoverProps } from "antd/lib/popover";

import styles from "./styles.less";

interface PopoverOwnProps {
  title?: string;
  content: any;
  trigger?: string;
  placement?: string;
}

export type PopoverProps = PopoverOwnProps & AntPopoverProps;

const Popover: React.FC<PopoverProps> = ({
  title,
  content,
  trigger,
  placement,
  ...props
}) => (
  <AntPopover
    {...props}
    placement={placement}
    content={content}
    trigger={trigger}
    className={styles.addStyle}
    autoAdjustOverflow={false}
  >
  </AntPopover>
);

export default Popover;
