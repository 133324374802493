import React from "react";
import { Query } from "react-apollo";
import { RouteComponentProps, Redirect } from "react-router";

import { MemorandumTemplate } from "../../templates/memorandums";
import { WorkTableMeeting } from "../../../gql/queries";
import { ErrorTemplate } from "../../templates";
import { WorktableMeetingStatus } from "../../../types";

type MemorandumPageProps = RouteComponentProps<{ id: string }>;

class MemorandumPage extends React.Component<MemorandumPageProps> {
  public render() {
    const { match } = this.props;
    return (
      <Query query={WorkTableMeeting} variables={{ id: match.params.id }}>
        {({ loading, data, error }: { loading: boolean, data: any, error?: any }) => {
          if (error) {
            // TODO: report to bugsnag
            return <ErrorTemplate message="Hubo un problema al cargar la minuta." />;
          }

          if (data?.worktableMeeting?.status === WorktableMeetingStatus.OPEN) {
            const worktableId = data.worktableMeeting.worktable?.id;
            return <Redirect to={`/mesas-de-trabajo/${worktableId}/reunion/${match.params.id}`} />;
          }

          return <MemorandumTemplate loading={loading} data={data.worktableMeeting} />;
        }}
      </Query>
    );
  }
}

export default MemorandumPage;
