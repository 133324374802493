import gql from "graphql-tag";

const ScheduleMeeting = gql`
  mutation ScheduleMeeting(
    $id: ID!
    $requestIds: [String!]!
    $name: String!
    $date: String!
    $time: String!
    $place: String!
    $judgeEmails: [String!]!
    $guestEmails: [String!]!
  ) {
    createMeeting(
      id: $id
      requestIds: $requestIds
      name: $name
      date: $date
      time: $time
      place: $place
      judgeEmails: $judgeEmails
      guestEmails: $guestEmails
    ) {
      id
    }

    updateRequest(id: $id, status: "MEETING_SCHEDULED") {
      id
    }
  }
`;

export default ScheduleMeeting;
