import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { getCustomFieldDecorator } from "../helpers";
import { IActivity, ActivityType, IEvent } from "../../../types";
import { SubActivitySection } from ".";

interface IActivitySectionProps {
  form?: any;
  activityIndex: number;
  activity: IActivity;
  onChange: (type: ActivityType, event: IEvent, index: number) => void;
  onAddActivity: (type: ActivityType, index: number) => void;
  onRemoveActivity: (type: ActivityType, index: number) => void;
}

const ActivitySection: React.FC<IActivitySectionProps> = ({
  activityIndex,
  form,
  activity,
  onAddActivity,
  onRemoveActivity,
  onChange
}) => {

  const { getFieldDecorator } = form;

  const renderSubActivities = () => {
    const { subActivities = [] } = activity;
    return subActivities.map((ac, i) => (
      <SubActivitySection
        form={form}
        onChange={onChange}
        key={ac.id}
        parentActivityIndex={activityIndex}
        onRemoveActivity={onRemoveActivity}
        activityIndex={i}
        subActivity={ac} />
    ));
  };

  const onAddSubActivity = () => {
    onAddActivity("subActivity", activityIndex);
  };

  const removeActivity = () => {
    onRemoveActivity("activity", activityIndex);
  };

  const onChanging = (event: IEvent) => {
    onChange("activity", event, activityIndex);
  };

  return (
    <div style={{ marginBottom: 15 }}>
      <Row gutter={10}>
        <Col span={22}>
          <Form.Item>
            {getFieldDecorator(
                `activity_${activity.id}`,
                getCustomFieldDecorator("activity", activity.title)
              )!(
              <Input
                onChange={onChanging}
                placeholder="Actividad"
                size="large"
                disabled={false} />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button
            onClick={removeActivity}
            size="large"
            type="default"
            icon="delete" />
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={22}>
          {renderSubActivities()}
          <Row>
            <Button
              onClick={onAddSubActivity}
              type="dashed"
              style={{ fontWeight: "bold" }}>
              + Agregar Sub actividad
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ActivitySection;
