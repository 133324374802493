import React from "react";
import { Supply } from '../../../../types/Request'
import { getSuppliesWithSubTotals } from "../../../../utils";
import {
  GeneralSummaryTable,
  PerYearSummaryTable,
  TotalsSummaryTable
} from "./components";

interface MonthlyBudgetTableProps {
  supplies: Array<Partial<Supply>>;
}

class MonthlyBudgetTable extends React.Component<MonthlyBudgetTableProps> {
  public render() {
    const { supplies } = this.props;
    const suppliesWithSubtotals = getSuppliesWithSubTotals(supplies);

    return (
      <>
        <GeneralSummaryTable supplies={suppliesWithSubtotals} />
        <PerYearSummaryTable supplies={suppliesWithSubtotals} />
        <TotalsSummaryTable supplies={suppliesWithSubtotals} />
      </>
    );
  }
}

export default MonthlyBudgetTable;
