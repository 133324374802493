import React from "react";
import { Row, Col, Button } from "antd";

interface TopControlsProps {
  onSaveChanges: any;
  isBusy?: boolean;
}

export const TopControls = (props: TopControlsProps) => {
  return (
    <Row gutter={16}>
      <Col span={12}>
        <span>
          Completa estos datos acerca de tu implementadora para
          poder hacer una solicitud completa a partir de esta
          pre-solicitud
        </span>
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          flexDirection: "row-reverse"
        }}>
        <Button
          onClick={props.onSaveChanges}
          loading={props.isBusy}
          id="submitImplementerProfileTopButton"
          shape="round"
          type="primary"
          size="default">
          Actualizar perfil
        </Button>
      </Col>
    </Row>
  )
};
