import gql from "graphql-tag";

// Simple create user mutation
// Due to a limitation of json-graphql-server, we can't create more than
// one record at a time.
const InviteUsers = gql`
  mutation InviteUsers($id: ID!, $email: String!, $status: String!, $role: String!) {
    createUser(id: $id, email: $email, status: $status, role: $role) {
      id
      email
      status
      role
    }
  }
`;

export default InviteUsers;
