import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { acceptedProject } from "../../../../gql/queries/projects"
import { client } from "../../../../gql/apollo";
import { Content } from "../../../../components/organisms/Layout";
import { Header, MonitoringList } from "./components";

const style = {
  width: "700pt",
  margin: "auto",
  marginTop: "10pt"
};

export const ProjectMonitoringDetailTemplate = (props: any) => {
  const { projectId } = useParams();
  const { data, loading, error } = useQuery(acceptedProject, {
    variables: { id: projectId },
    client
  } as any);

  if (error) {
    message.error("Ocurrió un problema al cargar el proyecto.");
    console.error(error);
  }

  const monitoringEntries = data?.project?.monitoringEntries.sort((a: any, b: any) =>
    a.date < b.date ? -1 : 1);

  return (
    <Content style={style}>
      <Header
        project={data?.project}
        loading={loading} />
      <MonitoringList
        monitorings={monitoringEntries}
        projectClosed={data?.project?.closed} />
    </Content>
  );
};
