import { isNullOrEmpty } from "../../../../../../utils/validations";

export const getCustomFieldDecorator = (key: string = "", initialValue: any) => {
  if (!isNullOrEmpty(fieldDecorators[key])) {
    fieldDecorators[key].initialValue = initialValue;
    return fieldDecorators[key];
  }
  return undefined;
};

const fieldDecorators = {
  activity: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa la actividad."
      }
    ],
    initialValue: ""
  },
  subActivity: {
    rules: [
      {
        required: true,
        message: "Por favor, ingresa la sub actividad."
      }
    ],
    initialValue: ""
  }
};
