import { IAssumption } from "../../../../types";
import { Modal } from "antd";

interface IShowErrorModaArgs {
  title: string;
  content: string;
  okText: string;
}

interface IAssumptionNode {
  [id: string]: Partial<IAssumption>;
}

export const validateAssumptionsTable = (assumptions: IAssumptionNode) => {
  const assumptionsIds = Object.keys(assumptions);
  if (assumptionsIds.length === 0) {
    showErrorModal({
      title: "Supuestos",
      content: "Por favor, agrega al menos un supuesto.",
      okText: "Ok"
    });
    return false;
  }

  if (hasEmptyValues(assumptions, assumptionsIds)) {
    showErrorModal({
      title: "Supuestos",
      content: "Verifica que todos los campos estén rellenados.",
      okText: "Ok"
    });
  }

  return true;
};

const hasEmptyValues = (assumptions: IAssumptionNode, assumptionsIds: string[]) => {
  return assumptionsIds.some((assumptionId) => (
    !assumptions[assumptionId].identification ||
    !assumptions[assumptionId].impact ||
    !assumptions[assumptionId].actions
  ));
};

const showErrorModal = async (args: IShowErrorModaArgs) => {
  const { title, content, okText } = args;
  Modal.error({
    title,
    content,
    okText,
  });
};
