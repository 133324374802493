import gql from "graphql-tag";

const ApproveRequest = gql`
  mutation ApproveRequest($id: String!) {
    updateRequest(
      data: {
        id: $id,
        status: "APPROVED"
      }) {
      id
    }
  }
`;

export default ApproveRequest;
