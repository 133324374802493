import React, { useContext } from "react";
import { MeetingFormContext } from "../../../MeetingFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";

export const FolioInput = (props: any) => {
  const meetingFormContext = useContext(MeetingFormContext);
  const { form } = meetingFormContext;
  const { getFieldDecorator } = form;

  return (
    <Form.Item>
      {getFieldDecorator("folio", {
        rules: [
          {
            required: true,
            message: "Por favor, ingresa el folio de la reunión."
          }
        ],
        initialValue: ""
      })(
        <Input
          id="folio"
          name="folio"
          type="number"
          placeholder="Folio de la reunión" />
      )}
    </Form.Item>
  );
};
