import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { ErrorTemplate } from "../../templates";
import { PendingRequestsTemplate } from "../../templates/requests";
import { Layout } from "../../organisms";
import { PageHeader, Content, SectionCard } from "../../organisms/Layout";
import { PendingRequests } from "../../../gql/queries";
import { client } from "../../../gql/apollo";

export interface IPaginationSignature {
  pagination: PaginationConfig;
  filters: Partial<Record<"id", string[]>>;
  sorter: SorterResult<{ id: string }>;
};

export const sortDirectionMap = {
  descend: "DESC",
  ascend: "ASC"
};

const ReviewalPage: React.FC = () => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { loading, data, error, refetch } = useQuery(PendingRequests, { client, variables: { draft: false } } as any);

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);

  if (error) {
    // TODO: report to bugsnag
    return <ErrorTemplate message="Hubo un problema al cargar las solicitudes." />;
  }

  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<Record<"id", string[]>>,
    sorter: SorterResult<{ id: string }>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <Layout noPadding>
      <PageHeader title="Solicitudes" />
      <Content>
        <SectionCard>
          <PendingRequestsTemplate
            loading={loading}
            onPaginationChange={handleOnPaginationChange}
            requests={data?.requests} />
        </SectionCard>
      </Content>
    </Layout>
  );
};

export default ReviewalPage;
