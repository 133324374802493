import React from "react";
import { Form, Input, Row, Col, Icon } from "antd";
import { FormSection } from "../../../../molecules";
import {
  RequestArea,
  RequestType,
  ImplementerFields
} from "../../../../../types/Request";
import { FormComponentProps } from "antd/lib/form";

interface IDirectivesBoardOwnProps {
  requestArea?: RequestArea;
  requestType?: RequestType;
  fields?: Partial<ImplementerFields>;
  reviewable?: boolean;
  approval?: boolean;
}

type DirectivesBoardProps = IDirectivesBoardOwnProps & FormComponentProps;

export class DirectivesBoardSection extends React.Component<DirectivesBoardProps> {

  public constructor(props: DirectivesBoardProps) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      fields
    } = this.props;

    getFieldDecorator("directorsBoardMembers", { initialValue: fields?.directorsBoardMembers });
    const directorsBoardMembers = getFieldValue("directorsBoardMembers");

    return (
      <FormSection title="Consejo directivo">
        {Object.keys(directorsBoardMembers).map(this.renderDirectives)}
      </FormSection>);
  }

  private renderDirectives = (directiveId: string, idx: number) => {
    const { form, fields, reviewable, approval } = this.props;
    const { getFieldDecorator } = form;
    const directorsBoardMembers = fields?.directorsBoardMembers;

    if (directorsBoardMembers === undefined) {
      return null;
    }

    const directiveName = directorsBoardMembers[directiveId]?.directiveName;
    const directivePosition = directorsBoardMembers[directiveId]?.directivePosition;

    return (
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item>
            {getFieldDecorator(`directiveName.${directiveId}`, {
              rules: [
                {
                  required: true,
                  message: "Por favor, ingrese el nombre del directivo."
                }
              ],
              initialValue: directiveName
            })!(
              <Input placeholder="Nombre" size="large" disabled={reviewable || approval} />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item>
            {getFieldDecorator(`directivePosition.${directiveId}`, {
              rules: [
                {
                  required: true,
                  message: "Por favor, ingrese el cargo del directivo."
                }
              ],
              initialValue: directivePosition
            })!(
              <Input placeholder="Cargo" size="large" disabled={reviewable || approval} />
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
