import gql from "graphql-tag";

const WorkTableMemorandums = gql`
  query Memorandums {
    allMemorandums {
      id
      folio
      type
      startDate
      addressedTo
      subject
    }
  }
`;

export default WorkTableMemorandums;
