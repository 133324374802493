import React, { useState } from "react";
import { Button } from "antd";
import { ModalProps } from "antd/lib/modal";
import { Modal } from "../../../../../atoms";
import { Request } from "../../../../../../types";
import SigningDateForm from "./SigningDateForm";

interface SigningDateModaOwnProps {
  request?: Partial<Request>;
  visible: boolean;
}

type SigningDateModalProps = ModalProps & SigningDateModaOwnProps;

interface SigningDateModalState {
  busy: boolean;
}

export const SigningDateModal = (props: SigningDateModalProps) => {
  const [state, setState] = useState<SigningDateModalState>({
    busy: false
  });

  const toggleBusy = (busy = false)  =>
    setState((prevState) => ({
      ...prevState,
      busy
    }));

  return (
    <Modal
      destroyOnClose
      title="Agendar fecha de firma"
      visible={props.visible}
      onCancel={props.onCancel}
      footer={[
        <Button
          loading={state.busy}
          form="signingDate"
          htmlType="submit"
          shape="round"
          type="primary">
          Guardar fecha
        </Button>,
        <Button
          shape="round"
          type="link"
          onClick={props.onCancel}>
          Cancelar
        </Button>
      ]}>
      <SigningDateForm
        afterSavingSuccess={props.onCancel}
        toggleBusy={toggleBusy}
        project={props.request?.project}
        visible={props.visible} />
    </Modal>
  );
};
