import React from "react";
import { Form, Input, Select, Row, Col, Upload, Button, Checkbox } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { FormSection } from "../../../molecules";
import {
  ImplementerFields,
  RequestArea,
  RequestType
} from "../../../../types/Request";
import {
  InstitutionalBackgroundSection,
  FiscalDataSection,
  DirectivesBoardSection,
  CreationDocumentSection
} from "./components";
import { governmentLevels, implementerTypes } from "../../../../utils/maps";
import { GovernmentLevel, ImplementerType } from "../../../../types";

interface ImplementerFormOwnProps {
  requestArea: RequestArea;
  requestType: RequestType;
  fields: Partial<ImplementerFields>;
  reviewable?: boolean;
  approval?: boolean;
}

type ImplementerFormProps = ImplementerFormOwnProps & FormComponentProps;

class ImplementerForm extends React.Component<ImplementerFormProps> {
  public render() {
    const {
      form,
      requestArea,
      fields,
      reviewable,
      approval,
      requestType
    } = this.props;
    const { getFieldDecorator } = form;
    let dependencyData;

    if (requestArea !== "UPV") {
      dependencyData = <FormSection title="Datos de dependencia">
        <Form.Item>
          {getFieldDecorator("type", {
            rules: [
              {
                required: true,
                message: "Por favor, selecciona el tipo de implementadora."
              }
            ],
            initialValue: fields?.type
          })!(
            <Select
              placeholder="Tipo de implementadora"
              size="large"
              disabled={reviewable || approval}>
              {Object.keys(implementerTypes).map(
                (type: string, i) =>
                  <Select.Option key={type}>
                    {implementerTypes[type as ImplementerType]}
                  </Select.Option>
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("governmentLevel", {
            rules: [
              {
                required: true,
                message: "Por favor, selecciona un nivel de gobierno."
              }
            ],
            initialValue: fields?.governmentLevel
          })!(
            <Select placeholder="Nivel de gobierno" size="large" disabled={reviewable || approval}>
              {Object.keys(governmentLevels).map(
                (level: string, i) =>
                  <Select.Option key={level}>
                    {governmentLevels[level as GovernmentLevel]}
                  </Select.Option>
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("governmentDependency", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la dependecia de gobierno."
              }
            ],
            initialValue: fields?.governmentDependency
          })!(
            <Input placeholder="Dependencia del gobierno" size="large" disabled={reviewable || approval} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("coordination", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la coordinación o departamento."
              }
            ],
            initialValue: fields?.coordination
          })!(
            <Input
              placeholder="Coordinación o departamento"
              size="large"
              disabled={reviewable || approval}
            />
          )}
        </Form.Item>
        {!(requestType === "GOVERNMENT_REQUEST" ||
          requestType === "CONSULTANCY_REQUEST") ?
          <Form.Item>
            {(getFieldDecorator("area", {
              rules: [
                {
                  required: true,
                  message: "Por favor, ingresa el área de dependecia que implementa."
                }
              ],
              initialValue: fields?.area
            })!(
              <Input
                placeholder="Área de dependencia que implementa"
                size="large"
                disabled={reviewable || approval} />
            ))}
          </Form.Item> : null}
        <Form.Item>
          {getFieldDecorator("institutionResponsibleName", {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa el nombre del titular de la institución."
              }
            ],
            initialValue: fields?.institutionResponsibleName
          })!(
            <Input
              placeholder="Nombre del titular de la institución"
              size="large"
              disabled={reviewable || approval}
            />
          )}
        </Form.Item>
      </FormSection>;
    }

    return (
      <Row style={{ padding: 40, marginTop: 74, marginBottom: 74 }}>
        <Col span={8}>
          <Form layout="vertical">
            {dependencyData}
            {/* Antecedentes institucionales */}
            {!(requestType === "GOVERNMENT_REQUEST"
              || requestType === "CONSULTANCY_REQUEST") ?
              <InstitutionalBackgroundSection
                requestArea={requestArea}
                fields={fields}
                reviewable={reviewable}
                approval={approval}
                requestType={requestType}
                form={form} /> : null}
            <FormSection title="Contacto responsable de la solicitud">
              <Form.Item>
                {getFieldDecorator("contactName", {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingresa el nombre del contacto"
                    }
                  ],
                  initialValue: fields?.contactName
                })!(
                  <Input placeholder="Nombre" size="large" disabled={reviewable || approval} />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("contactCharge", {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingresa el puesto del contacto."
                    }
                  ],
                  initialValue: fields?.contactCharge
                })!(
                  <Input placeholder="Puesto" size="large" disabled={reviewable || approval} />
                )}
              </Form.Item>
              {(requestType === "GOVERNMENT_REQUEST"
                || requestType === "IMPLEMENTER_REQUEST") ?
                <Form.Item>
                  {getFieldDecorator("contactDependency", {
                    rules: [
                      {
                        required: true,
                        message: `Por favor, ingresa la dependencia a la
                                que pertenece el contacto.`
                      }
                    ],
                    initialValue: fields?.contactDependency
                  })!(
                    <Input
                      placeholder="Dependencia a la que pertenece"
                      size="large"
                      disabled={reviewable || approval} />
                  )}
                </Form.Item> : null}
              <Form.Item>
                {getFieldDecorator("contactPhoneNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingresa el teléfono del contacto."
                    }
                  ],
                  initialValue: fields?.contactPhoneNumber
                })!(
                  <Input placeholder="Teléfono" size="large" disabled={reviewable || approval} />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("contactEmail", {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingresa el correo electrónico del contacto."
                    }
                  ],
                  initialValue: fields?.contactEmail
                })!(
                  <Input placeholder="Correo electrónico" size="large" disabled={reviewable || approval} />
                )}
              </Form.Item>
            </FormSection>
            {!(requestType === "GOVERNMENT_REQUEST") ?
              <FiscalDataSection
                requestArea={requestArea}
                fields={fields}
                reviewable={reviewable}
                approval={approval}
                requestType={requestType}
                form={form} />
              : null
            }
            <FormSection title="Dirección fiscal">
              <Form.Item>
                {getFieldDecorator("street", {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingresa la calle."
                    }
                  ],
                  initialValue: fields?.street
                })!(
                  <Input placeholder="Calle" size="large" disabled={reviewable || approval} />
                )}
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator("exteriorNumber", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, ingresa el número."
                        }
                      ],
                      initialValue: fields?.exteriorNumber
                    })!(
                      <Input placeholder="Número" size="large" disabled={reviewable || approval} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator("interiorNumber", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, ingresa el número interior."
                        }
                      ],
                      initialValue: fields?.interiorNumber
                    })!(
                      <Input placeholder="Número interior" size="large" disabled={reviewable || approval} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator("colony", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, ingresa la colonia."
                        }
                      ],
                      initialValue: fields?.colony
                    })!(
                      <Input placeholder="Colonia" size="large" disabled={reviewable || approval} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator("zipCode", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, ingresa el código postal."
                        }
                      ],
                      initialValue: fields?.zipCode
                    })!(
                      <Input placeholder="Código postal" size="large" disabled={reviewable || approval} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator("city", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, ingresa la ciudad."
                        }
                      ],
                      initialValue: fields?.city
                    })!(
                      <Input placeholder="Ciudad" size="large" disabled={reviewable || approval} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator("state", {
                      rules: [
                        {
                          required: true,
                          message: "Por favor, ingresa el estado."
                        }
                      ],
                      initialValue: fields?.state
                    })!(
                      <Input placeholder="Estado" size="large" disabled={reviewable || approval} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </FormSection>
            {!(reviewable || approval) && fields?.personType === "MORAL" && (
              <FormSection title="Acta constitutiva">
                <Form.Item>
                  {getFieldDecorator("constitutiveAct", {
                    rules: [
                      {
                        required: true,
                        message: "Por favor, adjunta el archivo de acta constitutiva."
                      }
                    ],
                    initialValue: fields?.constitutiveAct
                  })!(
                    <Upload>
                      <Button shape="round" icon="plus">Adjuntar acta constitutiva</Button>
                    </Upload>
                  )}
                </Form.Item>
              </FormSection>
            )}
            {!(requestType === "CONSULTANCY_REQUEST") ?
              <CreationDocumentSection
                requestArea={requestArea}
                fields={fields}
                reviewable={reviewable}
                approval={approval}
                requestType={requestType}
                form={form} />
              : null}
            {requestArea === "UPV" && (
              <FormSection title="Documento de donataria autorizada">
                <Form.Item>
                  {getFieldDecorator("authorizedDonor", {
                    valuePropName: "checked",
                    rules: [
                      {
                        required: true,
                        message: "Por favor, selecciona si es donataria autorizada o no."
                      }
                    ],
                    initialValue: fields?.authorizedDonor
                  })!(
                    <Checkbox>¿Es donataria autorizada?</Checkbox>
                  )}
                </Form.Item>
                {!(reviewable || approval) && !!fields?.authorizedDonor && (
                  <Form.Item>
                    {getFieldDecorator("authorizedDonorDoc", {
                      rules: [
                        {
                          required: !!Number(fields?.authorizedDonor),
                          message: "Por favor, adjunta el archivo de donataria autorizada."
                        }
                      ],
                      initialValue: fields?.authorizedDonorDoc
                    })!(
                      <Upload>
                        <Button shape="round" icon="plus">Adjuntar documento de donataria autorizada</Button>
                      </Upload>
                    )}
                  </Form.Item>
                )}
              </FormSection>
            )}
            {!(requestType === "GOVERNMENT_REQUEST"
              || requestType === "CONSULTANCY_REQUEST") ?
              <DirectivesBoardSection
                requestArea={requestArea}
                fields={fields}
                reviewable={reviewable}
                approval={approval}
                requestType={requestType}
                form={form} /> : null}
          </Form>
        </Col>
      </Row>
    );
  }
}

const onFieldsChange = (props: any, changedFields: any) => {
  const field = Object.values(changedFields)[0] as { name: string, value: string };
  const actualField = Array.isArray(field)
    ? field[0]
    : field.name === "directives"
      ? field
      : typeof Object.values(field)[0] === "object"
        ? Object.values(field)[0]
        : field;

  if (!props.reviewable) {
    props.onChange(actualField.name, actualField.value);
  }
};

const WrappedImplementerForm = Form.create({ name: "implementer", onFieldsChange })(ImplementerForm);

export default WrappedImplementerForm;
