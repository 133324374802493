export const measurementUnit = {
  PIECE: "Pieza",
  ELEMENT: "Elemento",
  SERVICE_UNIT: "Unidad de Servicio",
  ACTIVITY: "Actividad",
  KILOGRAM: "Kilogramo",
  WORK: "Trabajo",
  RATE: "Tarifa",
  METER: "Metro",
  BULK: "Paquete a granel",
  BASE_BOX: "Caja base",
  KIT: "Kit",
  SET: "Conjunto",
  LITER: "Litro",
  BOX: "Caja",
  MONTH: "Mes",
  HOUR: "Hora",
  SQUARE_METER: "Metro cuadrado",
  HARDWARE: "Equipos",
  MILIGRAM: "Miligramo",
  PACKAGE: "Paquete",
  VARIED: "Variedad",
  GRAM: "Gramo",
  PAIR: "Par",
  DOZEN: "Docenas de piezas",
  UNIT: "Unidad",
  DAY: "Día",
  LOT: "Lote",
  GROUP: "Grupos",
  MILILITER: "Mililitro",
  TRIP: "Viaje"
};
