import { IPreRequest } from "../../../../../../types";
import { client } from "../../../../../../gql/apollo";
import { AprovePreRequest, RejectPreRequest } from "../../../../../../gql/mutations";
import { PreRequests, PreRequest } from "../../../../../../gql/queries";

export const handleFilterOption = (input, option) => {
  const childrenString: string = (option?.props?.children) || "";

  const matchingOption = childrenString
    .toLowerCase()
    .indexOf((input as string)
      .toLowerCase()) >= 0;
  return matchingOption;
};

export const aprovePreRequest = async (id: string, preRequest: Partial<IPreRequest>) => {
  await client.mutate({
    mutation: AprovePreRequest,
    variables: {
      id,
      area: preRequest.area,
      type: preRequest.type,
      status: "APPROVED"
    },
    refetchQueries: [
      { query: PreRequests },
      {
        query: PreRequest,
        variables: { id }
      }
    ]
  });
};

export const rejectPreRequest = async (id: string, annotation: string) => {
  await client.mutate({
    mutation: RejectPreRequest,
    variables: {
      id,
      annotation
    },
    refetchQueries: [
      { query: PreRequests },
      {
        query: PreRequest,
        variables: {
          id,
          annotation
        }
      }
    ]
  });
};
