import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { AccountTemplate, ProfileTemplate, ZoneTemplate } from "../../templates/implementer-onboarding";
import { PersonType, GovtLevel, ImplementerType } from "../../../types";

export type InputChangeSignature =
  (step: ImplementerOnboardingStep, field: string, value: string) => void;

export type ImplementerOnboardingPageRouteProps = Pick<RouteComponentProps<{ step: string }>, "match">;

type ImplementerOnboardingPageProps = ImplementerOnboardingPageRouteProps & RouteComponentProps;

export type ImplementerZone =
  | "NORTH"
  | "CENTER";

type ImplementerOnboardingStep =
  | "ACCOUNT"
  | "PROFILE"
  | "ZONE";

export interface ImplementerAccountState {
  implementerName: string;
  emailAddress: string;
  password: string;
  isBusy?: boolean;
}

export interface ImplementerProfileState {
  type: ImplementerType;
  govtLevel: GovtLevel;
  govtDependency: string;
  area: string;
  coordination: string;
  beneficiaries: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  personType: PersonType;
  RFC: string;
  businessName: string;
  streetAddress: string;
  streetNumber: string;
  city: string;
  state: string;
  neighborhood: string;
  zipCode: string;
}

interface ImplementerOnboardingPageState {
  account: Partial<ImplementerAccountState>;
  profile: Partial<ImplementerProfileState>;
  zone?: ImplementerZone;
}

class ImplementerOnboardingPage extends React.Component<
  ImplementerOnboardingPageProps,
  ImplementerOnboardingPageState
  > {
  public state: ImplementerOnboardingPageState = {
    account: {},
    profile: {},
    zone: undefined
  };

  private steps = {
    0: "cuenta",
    1: "perfil",
    2: "zona"
  };

  private stepMap: { [step in ImplementerOnboardingStep]: string } = {
    ACCOUNT: "cuenta",
    PROFILE: "perfil",
    ZONE: "zona"
  };

  private reverseStepMap: { [step: string]: ImplementerOnboardingStep } = {
    cuenta: "ACCOUNT",
    perfil: "PROFILE",
    zona: "ZONE"
  };

  public componentDidMount() {
    const { history, match } = this.props;
    if (match.params.step !== "cuenta") {
      return history.push("/implementadora/crear-cuenta/cuenta");
    }
  }

  public componentDidUpdate(prevProps: ImplementerOnboardingPageProps) {
    const { history, match } = this.props;

    if (prevProps.match.params.step !== match.params.step) {
      const stateKey = this.reverseStepMap[match.params.step];
      if (stateKey === "PROFILE") {
        const accountValues = Object.values(this.state.account);
        if (accountValues.some((value) => !value)) {
          return history.push("/implementadora/crear-cuenta/cuenta");
        }
      } else if (stateKey === "ZONE") {
        const profileValues = Object.values(this.state.profile);
        if (profileValues.some((value) => !value)) {
          return history.push("/implementadora/crear-cuenta/perfil");
        }
      }
    }
  }

  public render() {
    const { match } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          marginTop: 70,
          backgroundColor: "rgba(142, 29, 65, 0.7)",
          overflowX: "scroll"
        }}
      >
        {this.renderTemplate(match.params.step)}
      </div>
    );
  }

  private renderTemplate = (currentStep: string) => {
    const { account, profile, zone } = this.state;

    if (currentStep === this.stepMap.ACCOUNT) {
      return (
        <AccountTemplate
          state={account}
          onInputChange={this.inputChange}
          onNextStep={this.nextStep}
        />
      );
    } else if (currentStep === this.stepMap.PROFILE) {
      return (
        <ProfileTemplate
          state={profile}
          implementerName={account.implementerName}
          onInputChange={this.inputChange}
          onNextStep={this.nextStep}
        />
      );
    } else if (currentStep === this.stepMap.ZONE) {
      return (
        <ZoneTemplate
          state={zone}
          onZoneSelect={this.zoneSelect}
          onNextStep={this.nextStep}
        />
      );
    }
  }

  private nextStep = (currentStep: number) =>
    this.props.history.push(`/implementadora/crear-cuenta/${this.steps[currentStep + 1]}`)

  private zoneSelect = (zone: ImplementerZone) =>
    this.setState({ zone })

  private inputChange = (step: ImplementerOnboardingStep, field: string, value: string) =>
    this.setState((state) => ({
      ...state,
      [step.toLowerCase()]: {
        ...state[step.toLowerCase()],
        [field]: value
      }
    }))
}

export default withRouter(ImplementerOnboardingPage);
