import React from "react";
import {
  Modal,
  Form,
  Typography,
  Input,
  Select,
  Button
} from "antd";
import { FormSection } from "../../molecules";
import { FormComponentProps } from "antd/lib/form";
import { RequestReviewContextOwnProps } from "../../RequestReviewContext";
import { SpecificObjective } from "../../../types";
import { v4 as uuid } from "uuid";
import { deliverablePeriodicityMap } from "../../../utils/maps";

interface SpecificObjectiveModalOwnProps {
  reviewState?: Partial<RequestReviewContextOwnProps>;
  reviewable?: boolean;
  approval?: boolean;
  specificObjective?: Partial<SpecificObjective>;
  saveSpecificObjective: (
    data: SpecificObjective,
    existingId: string | undefined,
    closeModalCb: () => void
  ) => void;
  visible?: boolean;
  busy?: boolean;
  onClose: () => void;
}

type SpecificObjectiveModalProps = SpecificObjectiveModalOwnProps & FormComponentProps;

interface SpecificObjectiveModalState {
  specificObjective: Partial<SpecificObjective>;
}

class SpecificObjectiveModal extends React.Component<
  SpecificObjectiveModalProps,
  SpecificObjectiveModalState
  > {
  public state: SpecificObjectiveModalState = {
    specificObjective: {
      id: uuid(),
      summary: "",
      description: "",
      goals: [],
      deliverable: {
        id: uuid(),
        title: "",
        description: "",
        periodicity: undefined
      }
    }
  };

  public constructor(props: SpecificObjectiveModalProps) {
    super(props);
  }

  public render() {
    const { reviewable, approval, visible = false, busy = false, form } = this.props;
    const { getFieldDecorator } = form;

    let { specificObjective } = this.props;

    if (specificObjective === undefined) {
      specificObjective = {};
    }

    return (
      <Modal
        onCancel={this.handleOnClose}
        visible={visible}
        title="Objetivo específico"
        footer={
          !(reviewable || approval) ? ([
            <Button
              loading={busy}
              shape="round"
              type="primary"
              onClick={this.onSaveSpecificObjective}>
              Guardar
            </Button>,
            <Button
              disabled={busy}
              shape="round"
              onClick={this.handleOnClose}>
              Cancelar
            </Button>
          ]) : ([
            <Button
              shape="round"
              onClick={this.handleOnClose}>
              Cerrar
            </Button>
          ])
        }>
        <Form>
          <FormSection>
            <Typography.Paragraph
              style={{ fontSize: 14 }}
              strong>
              Descripción
            </Typography.Paragraph>
            <Form.Item>
              {getFieldDecorator(
                `specificObjective.summary`,
                {
                  rules: [
                    {
                      required: true,
                      message: `Por favor, agrega la descripción
                      del objetivo específico.`
                    }
                  ],
                  initialValue: specificObjective.summary || ""
                })!(
                  <Input.TextArea
                    placeholder="Descripción del objetivo específico"
                    rows={3}
                    style={{ width: "100%" }}
                    disabled={busy || reviewable || approval} />
                )}
            </Form.Item>
          </FormSection>
          <FormSection>
            <Typography.Paragraph
              style={{ fontSize: 14 }}
              strong>
              Entregable
            </Typography.Paragraph>
            <p>
              Define el entregable que harás para la correcta medición
              de los resultados del proyecto
            </p>
            <Form.Item>
              {getFieldDecorator(
                `specificObjective.deliverable.title`,
                {
                  rules: [
                    {
                      required: true,
                      message: `Por favor, agrega el título
                      del entregable.`
                    }
                  ],
                  initialValue: specificObjective
                    ?.deliverable
                    ?.title || ""
                })!(
                  <Input
                    placeholder="Título del entregable"
                    style={{ width: "100%" }}
                    disabled={busy || reviewable || approval} />
                )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(
                `specificObjective.deliverable.description`,
                {
                  rules: [
                    {
                      required: true,
                      message: `Por favor, agrega la descripción
                      del entregable.`
                    }
                  ],
                  initialValue: specificObjective
                    ?.deliverable
                    ?.description || ""
                })!(
                  <Input.TextArea
                    placeholder="Descripción del entregable"
                    rows={3}
                    style={{ width: "100%" }}
                    disabled={busy || reviewable || approval} />
                )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(
                `specificObjective.deliverable.periodicity`,
                {
                  rules: [
                    {
                      required: true,
                      message: `Por favor, especifica la periodicidad
                      del entregable`
                    }
                  ],
                  initialValue: specificObjective
                    ?.deliverable
                    ?.periodicity
                })!(
                  <Select placeholder="Periodicidad" disabled={busy || reviewable || approval}>
                    {Object.keys(deliverablePeriodicityMap).map((key) =>
                      <Select.Option value={key}>
                        {deliverablePeriodicityMap[key]}
                      </Select.Option>
                    )}
                  </Select>
                )}
            </Form.Item>
          </FormSection>
        </Form>
      </Modal>
    );
  }

  private onSaveSpecificObjective = () => {
    const { form } = this.props;
    form.validateFieldsAndScroll(this.onValidateFields);
  }

  private handleOnClose = () => {
    const { busy, onClose, form } = this.props;
    if (!busy) {
      form.resetFields();
      onClose();
    }
  }

  private onValidateFields = (err: any, values: { specificObjective: Partial<SpecificObjective> }) => {
    const {
      saveSpecificObjective,
      specificObjective: selectedSpecificObjective
    } = this.props;
    const { specificObjective } = values;
    const { deliverable } = specificObjective;

    if (!err) {
      saveSpecificObjective(
        {
          summary: specificObjective?.summary || "",
          goals: selectedSpecificObjective?.goals || [],
          description: specificObjective?.description || "",
          activities: [],
          deliverable: {
            description: deliverable?.description || "",
            title: deliverable?.title || "",
            periodicity: deliverable?.periodicity
          }
        },
        selectedSpecificObjective?.id,
        this.handleOnClose
      );
    }
  }
}

const WrappedSpecificObjectiveModal = Form
  .create<SpecificObjectiveModalProps>({
    name: "specificObjectiveForm"
  })(SpecificObjectiveModal);

export default WrappedSpecificObjectiveModal;
