import React from "react";
import { Button } from "antd";
import styles from "./styles.less";
import { RequestStatus } from "../../../types";

interface RequestReviewControlsProps {
  requestStatus: RequestStatus;
  onApprove: () => void;
  onReject: () => void;
  onRequestCorrection?: () => void;
}

const RequestReviewControls: React.FC<RequestReviewControlsProps> = ({
  requestStatus,
  onApprove,
  onReject,
  onRequestCorrection
}) => {
  const buttonDisabled = onRequestCorrection && requestStatus === RequestStatus.APPROVED
    || requestStatus === RequestStatus.FINISHED
    || requestStatus === RequestStatus.PENDING_CORRECTION
    || requestStatus === RequestStatus.REJECTED;

  return (
    <>
      <Button
        className={styles.controlButton}
        shape="round"
        type="primary"
        disabled={buttonDisabled}
        onClick={onApprove}>
        Aprobar
    </Button>
      <Button
        className={styles.controlButton}
        shape="round"
        disabled={buttonDisabled}
        onClick={onReject}>
        Cerrar/Rechazar
      </Button>
      {onRequestCorrection && (
        <Button
          onClick={onRequestCorrection}
          className={styles.controlButton}
          disabled={buttonDisabled}
          shape="round">
          Solicitar correción
        </Button>
      )}
    </>
  );
};

export default RequestReviewControls;
