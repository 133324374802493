import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ConfigProvider } from "antd";
import moment from "moment";
import esES from "antd/es/locale/es_ES";
import "moment/locale/es";
import { ErrorBoundary } from "./bugsnag";
import Pages from "./components/pages";
import { RequestReviewPageRouteProps } from "./components/pages/requests";
import * as firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { firebaseConfig } from "./utils/firebaseConfig";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { client } from "./gql/apollo";
import { ApolloProvider } from "react-apollo";

moment.locale("es");

class App extends React.Component {
  public render() {
    /* TODO: Add a FallbackComponent*/
    // @ts-ignore
    // @ts-ignore
    return (
      <ErrorBoundary>
        <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
          <ApolloProvider client={client}>
            <ConfigProvider locale={esES}>
              <Router>
                <Switch>
                  {/* Auth routes */}
                  <Route
                    exact
                    path="/iniciar-sesion"
                    component={Pages.Auth.SignInPage} />
                  <Route
                    exact
                    path="/crear-cuenta"
                    component={Pages.Auth.CreateAccountPage} />

                  <Route
                    exact
                    path="/"
                    component={() => <Redirect to="/iniciar-sesion" />} />
                  {/* Users routes */}
                  <ProtectedRoute
                    path="/usuarios"
                    component={Pages.Users.UsersPage}
                    allowedRoles={["ADMIN"]} />
                  {/* Requests routes */}
                  <ProtectedRoute
                    exact
                    path="/presolicitudes"
                    component={Pages.PreRequests.PreRequestsList}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/presolicitudes/:preRequestId"
                    component={Pages.PreRequests.PreRequestDetail}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/solicitudes/revision"
                    component={Pages.Requests.ReviewalPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/solicitudes/aprobacion"
                    component={Pages.Requests.ApprovalPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    path="/solicitudes/aprobacion/:reqId/:currentStep?"
                    component={(props: RequestReviewPageRouteProps) =>
                      <Pages.Requests.RequestReviewPage {...props} approval />}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/solicitudes/convenio"
                    component={Pages.Requests.AgreementPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    path="/solicitudes/nueva/:currentStep?"
                    component={Pages.Requests.NewRequestPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    path="/solicitudes/:reqId/:currentStep?"
                    component={Pages.Requests.RequestReviewPage}
                    allowedRoles={["ADMIN"]} />
                  {/* Projects routes */}
                  <ProtectedRoute
                    exact
                    path="/proyectos"
                    component={() => <Redirect to="/proyectos/monitoreo/en-monitoreo" />}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/proyectos/monitoreo"
                    component={() => <Redirect to="/proyectos/monitoreo/en-monitoreo" />}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/proyectos/monitoreo/:status"
                    component={Pages.Projects.MonitoringPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/proyectos/:projectId"
                    component={Pages.Projects.ProjectMonitoringDetailPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/proyectos/:projectId/monitoreo/:monitoringEntry"
                    component={Pages.Projects.ProjectMonitoringPage}
                    allowedRoles={["ADMIN"]} />

                  {/* Work tables routes */}
                  <ProtectedRoute
                    exact
                    path="/mesas-de-trabajo"
                    component={Pages.WorkTables.WorkTablesPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    path="/mesas-de-trabajo/nueva/:currentStep?"
                    component={Pages.WorkTables.CreateWorkTablePage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    path="/mesas-de-trabajo/:id"
                    component={Pages.WorkTables.WorkTablePage}
                    allowedRoles={["ADMIN"]} />
                  {/* Agreements routes */}
                  <ProtectedRoute
                    exact
                    path="/acuerdos"
                    component={Pages.Agreements.Agreements}
                    allowedRoles={["ADMIN"]} />
                  {/* Memorandums routes */}
                  <ProtectedRoute
                    exact
                    path="/minutas"
                    component={Pages.Memorandums.MemorandumsPage}
                    allowedRoles={["ADMIN"]} />
                  <ProtectedRoute
                    exact
                    path="/minutas/:id"
                    component={Pages.Memorandums.MemorandumPage}
                    allowedRoles={["ADMIN"]} />
                  {
                    /**
                     * IMPLEMENTER USERS
                     */
                  }
                  <ProtectedRoute
                    exact
                    path="/implementadora/presolicitudes"
                    component={Pages.ImplementerOnboarding.PreRequestsList}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/solicitudes"
                    component={Pages.ImplementerOnboarding.RequestsList}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/solicitudes/:requestId"
                    component={Pages.ImplementerOnboarding.NewRequestPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/solicitudes/detalles/:requestId"
                    component={Pages.Requests.RequestDetailsPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/solicitudes/:requestId/:step"
                    component={Pages.ImplementerOnboarding.NewRequestPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/presolicitudes/crear"
                    component={Pages.ImplementerOnboarding.CreatePreRequestPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/presolicitudes/:newPreRequestId/confirmacion"
                    component={Pages.ImplementerOnboarding.PreRequestConfirmationPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/presolicitudes/:preRequestId"
                    component={Pages.ImplementerOnboarding.PreRequestDetailsPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/perfil/:action"
                    component={Pages.ImplementerProfile.ProfilePage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/proyectos"
                    component={Pages.Projects.ProjectsListPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/proyectos/:projectId"
                    component={Pages.Projects.ProjectMonitoringDetailPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <ProtectedRoute
                    exact
                    path="/implementadora/proyectos/:projectId/monitoreo/:monitoringEntry"
                    component={Pages.Projects.ProjectMonitoringPage}
                    allowedRoles={["IMPLEMENTER"]} />
                  <Route component={Pages.NotFoundPage} />
                </Switch>
              </Router>
            </ConfigProvider>
          </ApolloProvider>
        </FirebaseAuthProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
