import React, { useState, useRef } from "react";
import { message } from "antd";
import { withRouter, RouteComponentProps } from "react-router";
import { FormProps } from "antd/lib/form";
import { useMutation } from "@apollo/react-hooks";
import { Content } from "../../atoms";
import { ProjectForm } from "../forms";
import { RequestFooter } from "../../organisms";
import { NewRequestContextProps, NewRequestMethods } from "../../NewRequestContext";
import { ReviewableProps } from "../../../types/Request";
import { client } from "../../../gql/apollo";
import { UpdateProject } from "../../../gql/mutations/projects";
import { disabledRequestInput } from '../../../utils'

type ProjectInfoProps = ReviewableProps & NewRequestContextProps & RouteComponentProps;

export const ProjectInfo: React.FC<ProjectInfoProps> = ({
  state: { projectId, projectInfo, status, draft, checkFields, reviews },
  methods = {} as NewRequestMethods,
  history,
  reviewable,
  approval,
}) => {
  const formRef = useRef<{ props: FormProps }>();

  const [busy, setBusy] = useState(false);
  const [updateProject] = useMutation<any>(UpdateProject, { client } as any);
  const { saveProjectInfoInput } = methods;
  const disableByStatus: boolean = disabledRequestInput(status, draft);

  const handleOnClickNext = () => {
    setBusy(true);
    const { form } = formRef!.current!.props;

    form?.validateFields(async (err: any, values: any) => {
      if (err) {
        return;
      }

      try {
        await updateProject({
          variables: {
            id: projectId,
            ...values
          }
        })

        setBusy(false);
        return history.push("ficha");
      } catch (err) {
        // TODO: Report to bugsnag
        message.error("Ocurrió un problema al guardar la información del proyecto");
        setBusy(false);
      }
    });
  }

  return (
    <Content style={{ marginLeft: 245 }}>
      <ProjectForm
        checkFields={checkFields}
        disabled={busy || reviewable || approval || disableByStatus}
        reviewable={reviewable}
        approval={approval}
        fields={projectInfo}
        onChange={saveProjectInfoInput}
        wrappedComponentRef={formRef}
        reviews={reviews}
      />
      {reviewable || !approval && !disableByStatus && (
        <RequestFooter nextProps={{ onClick: handleOnClickNext, loading: busy }} />
      )}
    </Content>
  );

};

export default withRouter(ProjectInfo);
