import { UpdateMeeting } from "../../../../gql/mutations";
import { WorkTable } from "../../../../gql/queries";
import { IWorktableMeeting } from "../../../../types";
import { client } from "../../../../gql/apollo";

export const updateWorktableMeeting = async (
  worktableMeetingId: string,
  meetingData: IWorktableMeeting,
  worktableId: string
) => {
  await client.mutate({
    mutation: UpdateMeeting,
    variables: {
      ...meetingData,
      worktableMeetingId
    },
    refetchQueries: [{
      query: WorkTable,
      variables: {
        id: worktableId
      }
    }]
  });
};
