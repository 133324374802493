import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { SpinProps } from "antd/lib/spin";

type LoadingScreenProps = SpinProps;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ ...spinProps }) =>
  <Wrapper>
    <Spin {...spinProps} />
  </Wrapper>
