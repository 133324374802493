import React from "react";
import { Dropdown, Avatar, Menu, Icon, Button } from "antd";
import firebase from "firebase";

import { StyledAvatarContainer } from "./styled-components";
import { RoleConsumer } from "../../../../RoleConsumer";
import { UserRole } from "../../../../../types";

const signOut = async () => {
  await firebase.auth().signOut();
  window.location.assign("/");
};

const menu = (userRole?: UserRole) => (
  <Menu>
    {userRole === UserRole.IMPLEMENTER && (
      <Menu.Item
        key="1"
        onClick={() => {
          window.location.assign("/implementadora/perfil/editar");
        }}>
        <Icon type="user" />
      Perfil
      </Menu.Item>
    )}
    <Menu.Item key="2" onClick={signOut}>
      <Icon type="logout" />
      Cerrar sesión
    </Menu.Item>
  </Menu>
);

export const UserDropdown: React.FC = (props: any) => {
  return (
    <RoleConsumer>
      {({ user, claims }) => (
        <StyledAvatarContainer>
          {props.extra || null}
          <a>
            <Dropdown overlay={menu(claims?.role)}>
              <Button type="link">{user.email}</Button>
            </Dropdown>
          </a>
        </StyledAvatarContainer>
      )}
    </RoleConsumer>
  );
};
