import React, { Context } from "react";
import { DefaultContextValue } from "./types";

export const ImplementerProfileFormContext: Context<DefaultContextValue> = React.createContext({
  form: undefined,
  onFieldsChange: undefined,
  implementerProfile: undefined,
  isBusy: false,
  isLoadingData: false
} as any);
