import React, { useContext } from "react";
import { Table } from "antd";

import columns from "./columns"
import { MonitoringTemplateContext } from "../../MonitoringTemplateContext";
import { getClosedProjects } from "./helpers";

const ConcludedTable = () => {
  const monitoringTemplateContext = useContext(MonitoringTemplateContext);
  const onPaginationChange = monitoringTemplateContext?.onPaginationChange;
  const projects = monitoringTemplateContext?.data?.concludedProjects;

  const closedProjects = getClosedProjects(projects?.items || []);

  return (
    <Table
      loading={monitoringTemplateContext?.loading}
      pagination={{ total: projects?.count }}
      columns={columns}
      onChange={onPaginationChange}
      dataSource={closedProjects} />
  );
}

export default ConcludedTable;
