import React from "react";

export const renderIndicatorsList = (indicators: any[] = []) => {
  return (
    <ul>
      {indicators
        .map((indicator: any = {}) =>
          <li>
            {indicator?.description} - {indicator?.verificationMethod}
          </li>
        )}
    </ul>
  );
};
