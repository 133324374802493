import { CreatePreRequest } from "../../../../gql/mutations";
import { IPreRequest } from "../../../../types/PreRequest";
import { client } from "../../../../gql/apollo";

export const createPreRequest = async (data: Partial<IPreRequest>) => {
  const result = await client.mutate({
    mutation: CreatePreRequest,
    variables: { ...data }
    // @todo Add refetch query to get all pre requests of the logged implementer
  });
  return result;
};
