import React, { useState } from "react";
import { Descriptions, Skeleton, Button, message } from "antd";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import { Upload } from "../../../../../atoms";
import {
  PageHeader
} from "../../../../../organisms/Layout";
import { Project, UserRole, Attachment } from "../../../../../../types";
import { SectionCard } from "../../../../../organisms/UserLayout";
import { formatToCurrency } from "../../../../../../utils/currency";
import { RoleConsumer } from "../../../../../RoleConsumer";
import { ToggleProject, AttachClosingDocument } from "../../../../../../gql/mutations";
import { client } from "../../../../../../gql/apollo";
import { acceptedProject } from "../../../../../../gql/queries";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

interface HeaderProps {
  project?: Partial<Project>;
  loading?: boolean;
}

const getBreadCrumbRoutes = (projectName = "") => ([
  {
    path: "/proyectos/monitoreo/en-monitoreo",
    breadcrumbName: "Proyectos"
  },
  {
    path: "#",
    breadcrumbName: projectName
  }
]);

const renderExtra = (
  project: Partial<Project>,
  onToggleProject: () => Promise<void>,
  onAttachClosingDocument: (attachment: Attachment) => Promise<void>,
  busy: boolean
) => {
  const { monitoringEntries, closingDocument } = project;
  const allEntriesClosed = monitoringEntries?.every((entry) => entry.closed);
  const label = project.closed
    ? "Abrir proyecto"
    : "Cerrar proyecto";

  const onUploadDone = (file: UploadChangeParam<UploadFile<any>>) => {
    onAttachClosingDocument({
      ref: file.file.response?.imageUrl,
      fileName: file.file.name
    });
  };

  if (!project.closed && closingDocument === null) {
    return (
      <Upload
        showUploadList={false}
        disabled={!allEntriesClosed}
        onDone={onUploadDone}>
        <Button
          loading={busy}
          icon="file"
          type="primary"
          shape="round"
          disabled={!allEntriesClosed}>
          Adjuntar documento de cierre
        </Button>
      </Upload>
    );
  }

  return (
    <>
      <Button
        loading={busy}
        type="primary"
        shape="round"
        disabled={!allEntriesClosed}
        onClick={onToggleProject}>
        {label}
      </Button>
      <Button
        icon="download"
        type="primary"
        shape="circle"
        onClick={() => window.open(project.closingDocument?.ref, "_blank")} />
    </>
  );
};

export const Header = (props: HeaderProps) => {
  const {
    project = {},
    loading = false
  } = props;

  const projectName = project?.name
    || project?.request?.preRequest?.projectName
    || "N/A";
  const implementerName =
    project?.request?.preRequest?.implementer?.displayName || "N/A";
  const estimatedBudget = formatToCurrency(
    project?.request?.preRequest?.estimatedBudget || 0);
  const requestId = project?.request?.id

  if (loading) {
    return (
      <SectionCard>
        <Skeleton loading={loading} active />
      </SectionCard>
    );
  }

  const [attachClosingDocument] = useMutation(AttachClosingDocument, { variables: { id: project.id }, client } as any);
  const [toggleProject] = useMutation(ToggleProject, { variables: { id: project.id }, client } as any);
  const [busy, setBusy] = useState(false);

  const onAttachClosingDocument = async (attachment: Attachment) => {
    try {
      setBusy(true);
      await attachClosingDocument({
        variables: {
          attachment
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: acceptedProject,
          variables: {
            id: project.id
          }
        }]
      });
    } catch (error) {
      // TODO: Report to bugsnag
      console.error(error);
      message.error("Ocurrió un problema al adjuntar el documento de cierre.");
    } finally {
      setBusy(false);
    }
  };

  const onToggleProject = async () => {
    try {
      setBusy(true);
      await toggleProject({
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: acceptedProject,
          variables: {
            id: project.id
          }
        }]
      });
    } catch (error) {
      // TODO: Report to bugsnag
      console.error(error);
      message.error("Ocurrió un problema al actualizar el proyecto.");
    } finally {
      setBusy(false);
    }
  };

  return (
    <RoleConsumer>
      {({ claims }) => (
        <PageHeader
          title={projectName}
          breadcrumb={{
            routes: getBreadCrumbRoutes(projectName),
            itemRender: (route: any) => (
              <Link to={route.path}>{route.breadcrumbName}</Link>)
          }}
          extra={claims?.role === UserRole.ADMIN && renderExtra(project, onToggleProject, onAttachClosingDocument, busy)}
          style={{ margin: "0pt -10pt 10pt -10pt" }}>
          <Link
            to={`../solicitudes/${requestId}/${claims?.role === UserRole.IMPLEMENTER ? "capacidad-financiera" : "implementadora"}`}
            type="link"
            style={{ display: "block", marginBottom: "1rem" }}>
            Ver solicitud completa
          </Link>
          <Descriptions column={1}>
            <Descriptions.Item label="Implementadora">
              {implementerName}
            </Descriptions.Item>
            <Descriptions.Item label="Solicitado">
              {estimatedBudget} MXN
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}
    </RoleConsumer>
  );
};
