import gql from "graphql-tag";

const Users = gql`
  query Users {
    allUsers {
      id
      email
      status
      role
      Profile {
        id
        name
      }
    }
  }
`;

export default Users;
