import React, { useContext } from "react";
import { MeetingFormContext } from "../../../MeetingFormContext";
import Form from "antd/lib/form/Form";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";

export const DateInput = (props: any) => {
  const meetingFormContext = useContext(MeetingFormContext);
  const { form, worktableMeeting, onInputChange } = meetingFormContext;
  const { getFieldDecorator } = form;

  const onChange = (value: Moment | null, dateString: string) => {
    onInputChange({
      key: "date",
      value: value?.toISOString()
    });
  };

  return (
    <Form.Item
      label="Fecha de la reunión">
      {getFieldDecorator("date", {
        rules: [
          {
            required: true,
            message: "Por favor, ingresa la fecha de la reunión."
          }
        ],
        valuePropName: "defaultValue",
        initialValue: worktableMeeting?.date !== undefined ? moment(worktableMeeting?.date) : undefined
      })(
        <DatePicker
          id="date"
          name="date"
          onChange={onChange} />
      )}
    </Form.Item>
  );
};
