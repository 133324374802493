import gql from "graphql-tag";

export const UpdateProject = gql`
  mutation UpdateProject(
    $id: String!
    $name: String
    $place: String
    $estimatedDuration: Int
    $responsibleName: String
    $responsibleEmailAddress: String
    $preventionLevel: [PreventionLevel!]
    $preventionScope: [PreventionScope!]
    $problem: String
    $description: String
    $beneficiariesProfile: String
    $substantiation: String
    $methodology: String
    $startDate: DateTime
    $endDate: DateTime
    $generalObjective: String
    $impactIndicator: String
    $agreement: AgreementInput
  ) {
    updateProject(
      data: {
        id: $id
        name: $name
        place: $place
        estimatedDuration: $estimatedDuration
        responsibleName: $responsibleName
        responsibleEmailAddress: $responsibleEmailAddress
        preventionLevel: $preventionLevel
        preventionScope: $preventionScope
        problem: $problem
        description: $description
        beneficiariesProfile: $beneficiariesProfile
        substantiation: $substantiation
        methodology: $methodology
        startDate: $startDate
        endDate: $endDate
        generalObjective: $generalObjective
        impactIndicator: $impactIndicator
        agreement: $agreement
      }
    ) {
      id
      name
      place
      estimatedDuration
      responsibleName
      responsibleEmailAddress
      preventionLevel
      preventionScope
      problem
      description
      beneficiariesProfile
      substantiation
      methodology
      startDate
      endDate
      generalObjective
      impactIndicator
      agreement {
        id
      }
    }
  }
`;
