import gql from "graphql-tag";

export const CreateImplementerAccount = gql`
mutation CreateImplementerAccount(
  $name: String!
  $email: String!
  $password: String!
) {
  createImplementerAccount(data:{
    name:  $name,
    email:  $email,
    password: $password
  }) {
    id
    uid
    displayName
    email
    role
  }
}`;
