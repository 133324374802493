import React from "react";
import { Col, Row } from "antd";
import { getGoalIndicators, renderIndicatorsList } from "./helpers";
import { CenteredCol, GoalActivitiesColumn } from ".";
import style from "./style.less";

export const SpecificObjectiveGoalsRows = (props: any) => {

  const { goals: specificObjectiveGoals = [] } = props.specificObjective;
  const { data = {} } = props;
  const { goals = {}, activities = {} } = data;

  return (
    <>
      {specificObjectiveGoals.map((goalId: string, indexG: number) => {
        const goal = goals[goalId] || {};
        const goalActivities = goal?.activities || [];
      
        const isLastGoal = specificObjectiveGoals.length === indexG + 1;
      
        const indicators: any = {
          quantitative: [],
          qualitative: []
        } = getGoalIndicators(goalActivities, activities);
      
        return (
          <Row
            className={style.Goal_row}
            type="flex"
            style={{
              display: "flex",
              alignItems: "stretch",
              borderBottom: isLastGoal ? "none" : "solid 1px #ddd"
            }}>
            <CenteredCol span={4}>
              {goal?.description}
            </CenteredCol>
            <CenteredCol
              span={6}
              style={{
                alignItems: "flex-start",
                borderLeft: "solid 1px #ddd"
              }}>
              {renderIndicatorsList(indicators?.quantitative)}
            </CenteredCol>
            <CenteredCol
              span={6}
              style={{
                alignItems: "flex-start",
                borderLeft: "solid 1px #ddd"
              }}>
              {renderIndicatorsList(indicators?.qualitative)}
            </CenteredCol>
            <Col
              span={8}
              style={{ borderLeft: "solid 1px #ddd" }}>
              <GoalActivitiesColumn
                goalActivities={goalActivities}
                activities={activities} />
            </Col>
          </Row>);
      })}
    </>
  );
};
