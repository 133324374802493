import { ImplementerProfileFormState } from "../types";

export const initialState: ImplementerProfileFormState = {
  isBusy: false,
  isLoadingData: true,
  implementerProfile: {
    directorsBoardMembers: [],
    documents: [],
    documentsToCreate: [],
    documentsToDelete: []
  },
  members: {
    toConnect: [],
    toDisconnect: [],
    connected: []
  }
};
