import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Skeleton, Card, Icon, Button, Modal, message } from "antd";

import { IPreRequest, PreRequestStatus } from "../../../types";
import { SectionCard } from "../../organisms/UserLayout";
import { StyledSpan, stylesMap, deletePreRequest } from "./helpers";

interface PreSubmissionStatusCardOwnProps {
  loading: boolean;
  preRequest: IPreRequest;
  showDeleteOption?: boolean;
}

type PreSubmissionStatusCardProps =
  & PreSubmissionStatusCardOwnProps
  & RouteComponentProps;

const CheckIcon = <Icon
  type="check-circle"
  theme="twoTone"
  twoToneColor="#52C41A"
  style={{ fontSize: "30px" }} />;

const RejectedIcon = <Icon
  type="close-circle"
  theme="twoTone"
  twoToneColor="#f5222d"
  style={{ fontSize: "30px" }} />;

const InProgressIcon = <Icon
  type="clock-circle"
  theme="twoTone"
  twoToneColor="#fa8c16"
  style={{ fontSize: "30px" }} />;

const iconsMap: { [status in PreRequestStatus]: any } = {
  "IN_PROGRESS": InProgressIcon,
  "REJECTED": RejectedIcon,
  "APPROVED": CheckIcon
};

const PreSubmissionStatusCard: React.FC<PreSubmissionStatusCardProps> = (props) => {
  const { loading, preRequest, showDeleteOption = false } = props;

  const getIcon = () => {
    const { status } = preRequest;
    return iconsMap[status];
  };

  const getTitleLabel = () => {
    const { status } = preRequest;
    const titleLabels: { [status in PreRequestStatus]: any } = {
      "IN_PROGRESS": "En revisión",
      "REJECTED": "Rechazada",
      "APPROVED": "Pre-solicitud aprobada"
    }
    return titleLabels[status];
  };

  const getDescriptionLabel = () => {
    const { status } = preRequest;
    if (status === "APPROVED") {
      return "Tu pre-solicitud ha sido aprobada";
    } else if (status === "REJECTED") {
      return (
        <>
          <StyledSpan>Motivo:</StyledSpan>
          <p style={{ display: "inline-block" }}>
            {preRequest.annotation}
          </p>
        </>
      );
    } else {
      return "Tu pre-solicitud está siendo analizada";
    }
  };

  const getCardStyle = () => {
    return stylesMap[preRequest.status];
  }

  const handleOnDelete = () => {
    Modal.confirm({
      title: "Eliminar pre-solicitud",
      content: "¿Deseas eliminar esta pre-solicitud? Esta acción no es reversible.",
      okText: "Eliminar",
      onOk: async () => {
        try {
          const { preRequest } = props;
          await deletePreRequest(preRequest.id);
          message.success("Pre-solicitud eliminada.");
          const { history } = props;
          history.goBack();
        } catch (error) {
          return message.error("Ocurrió un error.");
        }
      }
    });
  };

  return (
    <SectionCard style={getCardStyle()}>
      <Skeleton
        avatar
        loading={loading}>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between"
          }}>
          <Card.Meta
            avatar={getIcon()}
            title={getTitleLabel()}
            description={getDescriptionLabel()} />
          { showDeleteOption ?
            <Button
              type="primary"
              shape="round"
              icon="delete"
              onClick={handleOnDelete}>
              Eliminar
            </Button>
            : null }
        </div>
      </Skeleton>
    </SectionCard>
  );
};


export default withRouter(PreSubmissionStatusCard);
