import * as Auth from "./auth";
import * as Requests from "./requests";
import * as Projects from "./projects";
import * as WorkTables from "./workTables";
import * as Users from "./users";
import * as Agreements from "./agreements";
import * as Memorandums from "./memorandums";
import * as ImplementerOnboarding from "./implementer-onboarding";
import * as  PreRequests from "./pre-requests";
import * as ImplementerProfile from "./implementer-profile";
import { NotFoundPage } from "./NotFoundPage";

export default {
  Auth,
  Requests,
  Projects,
  WorkTables,
  Users,
  Agreements,
  Memorandums,
  ImplementerOnboarding,
  PreRequests,
  ImplementerProfile,
  NotFoundPage
};
