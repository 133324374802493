import React, { Component } from "react";
import { Select } from "antd";

const institutionLevelMap: { [key: string]: string } = {
  FEDERAL: "Federal",
  STATE: "Estatal",
  MUNICIPAL: "Municipal"
};

interface IInstitutionTypeSelectProps {
  onChange: any;
  name: string;
  defaultValue?: any;
}

export class InstitutionTypeSelect extends Component<IInstitutionTypeSelectProps> {

  public constructor(props: IInstitutionTypeSelectProps) {
    super(props);
  }

  public render() {
    const { onChange, name, defaultValue } = this.props;

    return (
      <Select
        defaultValue={defaultValue}
        size="large"
        showSearch
        placeholder="Nivel de institución"
        optionFilterProp="children"
        onChange={(value: any) => {
          onChange({ target: { value, name } });
        }}>
        {this.renderOptions()}
      </Select>
    );
  }

  private renderOptions = () => {
    return Object.keys(institutionLevelMap).map((levelKey) => (
      <Select.Option key={levelKey} value={levelKey}>
        {institutionLevelMap[levelKey]}
      </Select.Option>
    ));
  }
}
