import React from "react";
import { Collapse, Form, Input, DatePicker, Button, Icon, Spin, Tooltip } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { Moment, default as moment } from "moment";
import styles from "./styles.less";
import { ColorCodedPanel, EditableText, Popover } from '../../atoms'
import {
  GeneralObjective,
  SpecificObjective,
  Goal,
  Activity,
  Supply,
  SustainabilityArgument
} from "../../../types";
import { ReviewControls, CommentButton } from "../../molecules";
import { RequestReviewConsumer, RequestReviewContextOwnProps } from "../../RequestReviewContext";
import {
  impactIndicatorInfo,
  methodologyInfo,
  objectiveGeneralInfo
} from '../../templates/implementer-onboarding/TooltipHelpers'

interface ProjectFileFormState {
  activePanels: {
    specificObjectives: string[];
    goals: string[];
  };
}

interface ProjectFileFormOwnProps {
  busyAddingGoal?: boolean;
  busyArgument: {
    id?: string;
    action?: "delete" | "add" | "save";
  };
  reviewable?: boolean;
  approval?: boolean;
  disabled?: boolean;
  onAddGoal: (specificObjectiveId: string) => void;
  onGoalDetail: (specificObjectiveId: string) => void;
  onAddActivity: (goalId: string) => void;
  onActivityDetail: (activityId: string) => void;
  fields: {
    projectMethodology?: string;
    projectDuration?: string[] | number[];
    generalObjective: Partial<GeneralObjective>;
    impactIndicator?: string;
    specificObjectives: {
      [id: string]: Partial<SpecificObjective>;
    };
    goals: {
      [id: string]: Partial<Goal>;
    };
    activities: {
      [id: string]: Partial<Activity>;
    };
    supplies: {
      [id: string]: Partial<Supply>;
    };
    sustainabilityArguments: {
      [id: string]: Partial<SustainabilityArgument>;
    };
  };
  methods: {
    saveGeneralObjectiveSummary: (value: string) => void;
    saveActivityInput: (activityId: string, field: string, value: string) => void;
    saveSupplyInput: (supplyId: string, field: string, value: string) => void;
    saveProjectMethodology: (methodology: string) => void;
    saveProjectDuration: (duration: string[]) => void;
    saveImpactIndicator: (indicator: string) => void;
    addSpecificObjective: (
      specificObjective?: Partial<SpecificObjective>
    ) => void;
    removeSpecificObjective: (objectiveId: string) => void;
    removeGoal: (goalId: string) => void;
    removeActivity: (activityId: string) => void;
    addSustainabilityArgument: () => void;
    saveSustainabilityArgument: (argumentId: string, argument: string) => void;
    removeSustainabilityArgument: (argumentId: string) => void;
  };
  checkFields: boolean;
  reviews: any;
}

type ProjectFileFormProps = ProjectFileFormOwnProps & FormComponentProps;

class ProjectFileTree extends React.Component<ProjectFileFormProps, ProjectFileFormState> {
  public state = {
    activePanels: {
      specificObjectives: [],
      goals: []
    }
  };

  public componentDidMount() {
    const {
      fields: {
        generalObjective,
        specificObjectives
      }
    } = this.props;

    const specificObjectiveIds: string[] = [];
    const goalIds: string[] = [];

    generalObjective.specificObjectives!.map((specificObjectiveId) => {
      specificObjectiveIds.push(`specificObjectives.${specificObjectiveId}`);
      if (specificObjectives[specificObjectiveId]) {
        specificObjectives[specificObjectiveId].goals!.map((goalId) => {
          goalIds.push(`goals.${goalId}`);
        });
      }
    });

    this.setState({
      activePanels: {
        specificObjectives: specificObjectiveIds,
        goals: goalIds,
      }
    });
  }

  public componentDidUpdate(prevProps: ProjectFileFormProps, prevState: ProjectFileFormState) {
    const {
      fields: {
        generalObjective,
        specificObjectives
      }
    } = this.props;

    const specificObjectiveIds: string[] = [];
    const goalIds: string[] = [];

    generalObjective.specificObjectives!.map((specificObjectiveId) => {
      specificObjectiveIds.push(`specificObjectives.${specificObjectiveId}`);
      if (specificObjectives[specificObjectiveId]) {
        specificObjectives[specificObjectiveId].goals!.map((goalId) => {
          goalIds.push(`goals.${goalId}`);
        });
      }
    });

    if (
      specificObjectiveIds.length !== prevState.activePanels.specificObjectives.length ||
      goalIds.length !== prevState.activePanels.goals.length
    ) {
      this.setState({
        activePanels: {
          specificObjectives: specificObjectiveIds,
          goals: goalIds,
        }
      });
    }
  }

  public render() {
    const { activePanels } = this.state;
    const {
      form: { getFieldDecorator },
      fields,
      methods,
      busyAddingGoal,
      busyArgument,
      reviewable,
      approval,
      disabled = false,
      onAddGoal,
      onGoalDetail,
      onAddActivity,
      checkFields,
      onActivityDetail
    } = this.props;

    return (
      <RequestReviewConsumer>
        {({ requestState, reviewState }) => (
          <Form>
            <div className={styles.box} id="projectMethodology">
              <span>Metodología</span>
              <div className={styles.boxWrapper}>
                <Form.Item validateStatus={checkFields ? fields.projectMethodology ? "" : "error" : "" }>
                  <Popover
                    placement="right"
                    content={methodologyInfo}
                    trigger="click">
                    {getFieldDecorator("projectMethodology", {
                      trigger: "onBlur",
                      valuePropName: "defaultValue",
                      initialValue: fields.projectMethodology
                    })!(
                      <Input
                        placeholder="Describe la metodología a usar para este proyecto"
                        disabled={reviewable || approval || disabled}
                      />
                    )}
                  </Popover>
                </Form.Item>
                {!reviewable && (<CommentButton field="projectMethodology" size="default" disabled={disabled}/>)}
                {reviewable && this.renderReviewControls("projectMethodology", reviewState)}
              </div>
            </div>
            <div
              className={styles.box}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <span>Duración del proyecto</span>
              <div className={styles.boxWrapper}>
                <Tooltip
                  title={`La duración del proyecto es usada para calcular los meses en los que
                  se repartirán las actividades a realizar y el presupuesto a distribuir.`}>
                  <Form.Item validateStatus={checkFields ? fields.projectDuration?.length ? "" : "error" : "" }>
                    {getFieldDecorator("projectDuration", {
                      valuePropName: "defaultValue",
                      initialValue: [
                        fields.projectDuration![0] && moment(fields.projectDuration![0]),
                        fields.projectDuration![1] && moment(fields.projectDuration![1])
                      ]
                    })!(
                      <DatePicker.RangePicker disabled={reviewable || approval || disabled} />
                    )}
                  </Form.Item>
                </Tooltip>
                {!reviewable && (<CommentButton field="projectDuration" size="default" disabled={disabled}/>)}
                {reviewable && this.renderReviewControls("projectDuration", reviewState)}
              </div>
            </div>
            <Collapse bordered={false} className={styles.projectFileTree} activeKey="impactIndicator">
              <Collapse.Panel
                key="generalObjective"
                className="root"
                extra={reviewable ? (
                  this.renderReviewControls("generalObjective", reviewState)
                ) : (
                    <CommentButton field="generalObjective" size="default" disabled={disabled}/>
                  )}
                header={
                  <Popover
                    placement="right"
                    content={objectiveGeneralInfo}
                    trigger="click">
                    <ColorCodedPanel
                      reviewable={reviewable}
                      type="GENERAL_OBJECTIVE"
                      id="generalObjective"
                    >
                      <EditableText
                        checkFields={checkFields}
                        editable={!(reviewable || approval || disabled)}
                        defaultValue={fields.generalObjective.summary}
                        placeholder="Describe el objetivo general"
                        onSave={(value) => methods.saveGeneralObjectiveSummary(value)}
                      />
                    </ColorCodedPanel>
                  </Popover>
                }
                showArrow={false}
              />
              <Collapse.Panel
                key="impactIndicator"
                className="root"
                extra={reviewable ? (
                  this.renderReviewControls("impactIndicator", reviewState)
                ) : (
                    <CommentButton field="impactIndicator" size="default" disabled={disabled}/>
                  )}
                header={
                  <Popover
                    placement="right"
                    content={impactIndicatorInfo}
                    trigger="click">
                    <ColorCodedPanel
                      reviewable={reviewable}
                      type="IMPACT_INDICATOR"
                      id="specificObjectives">
                      <EditableText
                        checkFields={checkFields}
                        editable={!(reviewable || approval || disabled)}
                        defaultValue={fields.impactIndicator}
                        placeholder="Describe el indicador de impacto"
                        onSave={(value) => methods.saveImpactIndicator(value)}/>
                    </ColorCodedPanel>
                  </Popover>
                }
                showArrow={false}
              >
                <Collapse
                  bordered={false}
                  className={styles.projectFileTree}
                  style={{ marginTop: 26, marginBottom: 14 }}
                  activeKey={activePanels.specificObjectives}
                >
                  {fields.generalObjective.specificObjectives!.map((specificObjectiveId) => {
                    const specificObjective = fields.specificObjectives[specificObjectiveId];
                    if (specificObjective) {
                      return (
                        <Collapse.Panel
                          extra={
                            approval
                              ? null
                              : reviewable
                                ? this.renderReviewControls(`specificObjectives.${specificObjectiveId}`, reviewState)
                                : (
                                  <Button.Group>
                                    <Button
                                      icon="delete"
                                      disabled={disabled}
                                      onClick={() => methods.removeSpecificObjective(specificObjectiveId)}
                                    />
                                    {!reviewable && (<CommentButton field={`specificObjectives.${specificObjectiveId}`} size="default" disabled={disabled}/>)}
                                  </Button.Group>
                                )
                          }
                          key={`specificObjectives.${specificObjectiveId}`}
                          header={
                            <ColorCodedPanel
                              reviewable={reviewable}
                              onClick={() => {
                                methods.addSpecificObjective(specificObjective);
                              }}
                              type="SPECIFIC_OBJECTIVE"
                              id={`specificObjectives.${specificObjectiveId}`}>
                              {specificObjective.summary}
                            </ColorCodedPanel>
                          }
                          showArrow={false}
                        >
                          <Collapse
                            bordered={false}
                            className={styles.projectFileTree}
                            activeKey={activePanels.goals}
                          >
                            {specificObjective.goals!.map((goalId) => {
                              const goal = fields.goals[goalId];
                              if (goal) {
                                return (
                                  <Collapse.Panel
                                    extra={
                                      approval
                                        ? null
                                        : reviewable
                                          ? this.renderReviewControls(`goals.${goalId}`, reviewState)
                                          : (
                                            <Button.Group>
                                              <Button
                                                icon="delete"
                                                disabled={disabled}
                                                onClick={() => methods.removeGoal(goalId)}
                                              />
                                              {!reviewable && (<CommentButton field={`goals.${goalId}`} size="default" disabled={disabled}/>)}
                                            </Button.Group>
                                          )
                                    }
                                    key={`goals.${goalId}`}
                                    header={
                                      <ColorCodedPanel
                                        reviewable={reviewable}
                                        type="GOAL"
                                        id={`goals.${goalId}`}
                                        onClick={() => onGoalDetail(goalId)}
                                      >
                                        {goal.description}
                                      </ColorCodedPanel>
                                    }
                                    showArrow={false}
                                  >
                                    <Collapse
                                      bordered={false}
                                      className={styles.projectFileTree}
                                    >
                                      {goal.activities!.map((activityId) => {
                                        const activity = fields.activities[activityId];
                                        if (activity) {
                                          return (
                                            <Collapse.Panel
                                              extra={
                                                approval
                                                  ? null
                                                  : reviewable
                                                    ? this.renderReviewControls(`activities.${activityId}`, reviewState)
                                                    : (
                                                      <Button.Group>
                                                        <Button
                                                          icon="delete"
                                                          disabled={disabled}
                                                          onClick={() => methods.removeActivity(activityId)}
                                                        />
                                                        {!reviewable && (<CommentButton field={`activities.${activityId}`} size="default" disabled={disabled}/>)}
                                                      </Button.Group>
                                                    )
                                              }
                                              key={`activities.${activityId}`}
                                              header={
                                                <ColorCodedPanel
                                                  reviewable={reviewable}
                                                  type="ACTIVITY"
                                                  onClick={() => onActivityDetail(activityId)}
                                                >
                                                  {activity.description}
                                                </ColorCodedPanel>
                                              }
                                              showArrow={false}
                                            />
                                          );
                                        }

                                        return null;
                                      })}
                                      {!(reviewable || approval || disabled) && (
                                        <Collapse.Panel
                                          key="activities.add"
                                          header={
                                            <ColorCodedPanel
                                              type="ACTIVITY_BUTTON"
                                              onClick={() => onAddActivity(goalId)}
                                            />
                                          }
                                          showArrow={false}
                                        />
                                      )}
                                    </Collapse>
                                  </Collapse.Panel>
                                );
                              }

                              return null;
                            })}
                            {!(reviewable || approval || disabled) && (
                              <Collapse.Panel
                                key="goals.add"
                                header={
                                  <ColorCodedPanel
                                    busy={busyAddingGoal}
                                    type="GOAL_BUTTON"
                                    onClick={() => onAddGoal(specificObjectiveId)}
                                  />
                                }
                                showArrow={false}
                              />
                            )}
                          </Collapse>
                        </Collapse.Panel>
                      );
                    }

                    return null;
                  })}
                  {!(reviewable || approval || disabled) && (
                    <Collapse.Panel
                      key="specificObjectives.add"
                      header={
                        <ColorCodedPanel
                          type="SPECIFIC_OBJECTIVE_BUTTON"
                          onClick={() => {
                            methods.addSpecificObjective();
                          }}
                        />
                      }
                      showArrow={false}
                    />
                  )}
                </Collapse>
              </Collapse.Panel>
            </Collapse>
            <div className={styles.box} id="sustainabilityArguments">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Sostenibilidad</span>
                {!(reviewable || approval) && (
                  <Button
                    type="link"
                    icon="plus-circle"
                    loading={busyArgument.action === "add"}
                    onClick={methods.addSustainabilityArgument}
                    disabled={disabled}
                  >
                    Agregar argumento de sustentabilidad
                  </Button>
                )}
              </div>
              {Object.keys(fields.sustainabilityArguments).map((sustainabilityArgumentId) => {
                return (
                  <div className={styles.boxWrapper}>
                    <Form.Item
                      key={sustainabilityArgumentId}
                      validateStatus={checkFields ? fields.sustainabilityArguments[sustainabilityArgumentId].argument ? "" : "error" : "" }>
                      {getFieldDecorator(`sustainabilityArguments.${sustainabilityArgumentId}`, {
                        trigger: "onBlur",
                        valuePropName: "defaultValue",
                        initialValue: fields.sustainabilityArguments[sustainabilityArgumentId].argument
                      })!(
                        <Input
                          placeholder="Escribe un argumento que asegura la sustentabilidad del proyecto a largo plazo"
                          disabled={(busyArgument.action === "save" && busyArgument.id === sustainabilityArgumentId) || reviewable || approval || disabled}
                          addonAfter={
                            (busyArgument.action === "delete"
                              && busyArgument.id === sustainabilityArgumentId)
                              ? <Spin spinning />
                              : !approval && (
                                <Icon
                                  type="delete"
                                  style={disabled ? {backgroundColor: "#f5f5f5", color: "rgba(0, 0, 0, 0.25)"} : {}}
                                  onClick={() => !disabled && methods.removeSustainabilityArgument(sustainabilityArgumentId)} />
                            )
                          }
                        />
                      )}
                    </Form.Item>
                    {reviewable ? (
                      this.renderReviewControls(`sustainabilityArguments.${sustainabilityArgumentId}`, reviewState)
                    ) : (
                        <CommentButton field={`sustainabilityArguments.${sustainabilityArgumentId}`} size="default" disabled={disabled}/>
                      )}
                  </div>
                );
              })}
            </div>
          </Form>
        )}
      </RequestReviewConsumer>
    );
  }

  private renderReviewControls = (fieldId: string, reviewState: Partial<RequestReviewContextOwnProps>) => {
    const { reviews } = this.props
    return (
      <div className={styles.reviewControlsWrapper}>
        <ReviewControls
          fieldId={fieldId}
          reviews={reviews}
          comment={((reviewState.state || {})[fieldId] || { comment: undefined }).comment}
          approved={((reviewState.state || {})[fieldId] || { approved: undefined }).approved}
          onReset={(reviewState.methods || { onReset: () => undefined }).onReset}
          onApprove={(reviewState.methods || { onApprove: () => undefined }).onApprove}
          onReject={(reviewState.methods || { onReject: () => undefined }).onReject}
          onSaveComment={(reviewState.methods || { onSaveComment: () => undefined }).onSaveComment}
          onDeleteComment={(reviewState.methods || { onDeleteComment: () => undefined }).onDeleteComment}
          relative
          buttonProps={{ size: "default" }}
        />
      </div>
    )
  }
}

const onFieldsChange = (props: ProjectFileFormProps, changedFields: any) => {
  if (changedFields.sustainabilityArguments && !props.reviewable) {
    const argumentId = Object.keys(changedFields.sustainabilityArguments)[0];
    const { value } = changedFields.sustainabilityArguments[argumentId];
    return props.methods.saveSustainabilityArgument(argumentId, value);
  }

  if (changedFields.projectMethodology && !props.reviewable) {
    const { value } = changedFields.projectMethodology;
    return props.methods.saveProjectMethodology(value);
  }

  if (changedFields.projectDuration && !props.reviewable) {
    const { value } = changedFields.projectDuration;
    return props.methods.saveProjectDuration(value);
  }

  if (changedFields.impactIndicator && !props.reviewable) {
    const { value } = changedFields.impactIndicator;
    return props.methods.saveImpactIndicator(value);
  }
};

const WrappedProjectFileTree = Form.create({ name: "projectFileTree", onFieldsChange })(ProjectFileTree);

export default WrappedProjectFileTree;
