import React, { useState, useContext } from "react";
import { Modal, Button, Icon, message } from "antd";
import { Upload } from "../../../../atoms";
import { Project, Agreement } from "../../../../../types";
import { AgreementsTemplateContext } from "../AgreementsTemplateContext";
import { FileToUpload, ContractModalProps, ContractModalState } from "../types";

export const ContractModal = (props: ContractModalProps) => {
  const [state, setState] = useState<ContractModalState>({
    uploadedAttachment: undefined,
    saving: false
  });
  const { request = {} } = props;
  const { contract } = request?.project?.agreement || {};  
  const readOnly: boolean = !!contract;

  const {
    updateProject,
    refetch
  } = useContext(AgreementsTemplateContext) as any;

  const handleAfterUpload = (info: any) => {
    const {
      uid = "",
      name: fileName = "",
      response = {}
    } = info?.file || {};

    const fileData = {
      uid,
      fileName,
      url: response.imageUrl
    };

    setState((state) => ({
      ...state,
      uploadedAttachment: fileData
    }));
  };

  const addAgreementContract = async (file: FileToUpload) => {
    const { url, fileName } = file;
    const project: Partial<Project> | undefined = request?.project;
    const agreement: Partial<Agreement> = { ...project?.agreement };
    agreement.contract = {
      ...agreement.contract,
      ref: url,
      fileName
    };

    const { startDate, endDate, ...updatedProject}: Partial<Project> = {
      ...project,
      agreement
    };

    try {
      toggleSaving(true);
      await updateProject({
        variables: { ...updatedProject }
      });
      message.success("Contrato guardado.")
      handleOnCancel();
      refetch();
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error.");
    } finally {
      toggleSaving(false);
    }
  };

  const handleOnCancel = () => {
    setState((state) => ({
      ...state,
      uploadedAttachment: undefined
    }));
    props.onClose();
  };

  const toggleSaving = (saving = false)  =>
    setState((prevState) => ({
      ...prevState,
      saving
    }));

  const handleOk = () => {
    const { uploadedAttachment } = state;
    if (uploadedAttachment) {
      addAgreementContract(uploadedAttachment);
    } else {
      message.warn("No hay archivos seleccionados");
    }
  };

  return (
    <Modal
      destroyOnClose
      confirmLoading={state.saving}
      onCancel={handleOnCancel}
      onOk={readOnly ? handleOnCancel : handleOk}
      okText="Guardar"
      cancelText="Cancelar"
      title="Contrato"
      {...props}>
      { readOnly ?
          <Button
            href={contract?.ref}
            target="_blank"
            type="primary"
            shape="round">
            <Icon type="download" /> {contract?.fileName}
          </Button>
        : (
          <Upload
            onDone={handleAfterUpload}
            onError={handleAfterUpload}>
            <Button>
              <Icon type="upload" /> Selecciona un archivo
            </Button>
          </Upload>
        ) }
    </Modal>
  );
};
