import React, { useState } from "react";
import { Form, Typography, Input, DatePicker, Row, Col, TimePicker, Radio, InputNumber, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";

import { FormSection } from "../../../../molecules";
import moment from "moment";

interface IFieldVisitFormOwnProps {
  readonly?: boolean;
  fields: any;
  onSubmit: (fieldValues: any, setBusy: (busy: boolean) => void) => void;
}

type FieldVisitFormProps = IFieldVisitFormOwnProps & FormComponentProps;

const FormContainer = styled.div`
  width: 50%;
  max-width: 960px;
  padding: 20px;
  background-color: #ffffff;
`;

const CustomParagraph = styled(Typography.Paragraph)`
  font-size: 14px;
`;

export const RawFieldVisitForm: React.FC<FieldVisitFormProps> = ({ readonly, fields, onSubmit, form }) => {
  const [busy, setBusy] = useState(false);
  const { getFieldDecorator, getFieldValue, validateFields } = form;

  const getFieldDisabled = (field: string) => {
    const localFieldValue = getFieldValue(field);
    const remoteFieldValue = fields?.[field];

    const isMissing = (value: any) =>
      (value === undefined || value === null)

    if (isMissing(localFieldValue) && isMissing(remoteFieldValue)) {
      return true;
    }

    if (!isMissing(localFieldValue) && isMissing(remoteFieldValue)) {
      return !!localFieldValue;
    }

    if (isMissing(localFieldValue) && !isMissing(remoteFieldValue)) {
      return !!remoteFieldValue;
    }

    if (!isMissing(localFieldValue) && !isMissing(remoteFieldValue)) {
      return !!localFieldValue;
    }

    return true;
  }

  const ficosecLogosReasonDisabled = getFieldDisabled("ficosecLogos");
  const initialPlanningReasonDisabled = getFieldDisabled("initialPlanning");
  const organizationEvidenceTypeDisabled = getFieldDisabled("organizationEvidence");

  const handleOnSave = () => {
    setBusy(true);
    validateFields((errors, values) => {
      if (errors) {
        setBusy(false);
        return;
      }

      onSubmit(values, setBusy);
    });
  };

  return (
    <FormContainer>
      <Form>
        <FormSection title="Información general">
          <CustomParagraph strong>Nombre de la actividad</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("activityName", {
              initialValue: fields?.activityName
            })(
              <Input
                placeholder="Ej. Visita de campo 23"
                disabled={readonly} />
            )}
          </Form.Item>
          <CustomParagraph strong>Objetivo de la actividad</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("activityObjective", {
              initialValue: fields?.activityObjective
            })(
              <Input.TextArea
                placeholder="Describe la actividad"
                disabled={readonly} />
            )}
          </Form.Item>
          <CustomParagraph strong>¿Quién reporta?</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("reporterName", {
              initialValue: fields?.reporterName
            })(
              <Input
                placeholder="Nombre"
                disabled={readonly} />
            )}
          </Form.Item>
          <CustomParagraph strong>Lugar de visita</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("visitPlace", {
              initialValue: fields?.visitPlace
            })(
              <Input disabled={readonly} />
            )}
          </Form.Item>
          <CustomParagraph strong>Fecha</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("visitDate", {
              initialValue: fields?.visitDate ? moment(fields?.visitDate) : undefined
            })(
              <DatePicker
                style={{ width: "100%" }}
                disabled={readonly} />
            )}
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CustomParagraph strong>Hora de inicio</CustomParagraph>
              <Form.Item>
                {getFieldDecorator("visitStartTime", {
                  initialValue: fields?.visitStartTime ? moment(fields?.visitStartTime) : undefined
                })(
                  <TimePicker
                    style={{ width: "100%" }}
                    placeholder="Seleccionar hora"
                    disabled={readonly} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <CustomParagraph strong>Hora de término</CustomParagraph>
              <Form.Item>
                {getFieldDecorator("visitEndTime", {
                  initialValue: fields?.visitEndTime ? moment(fields?.visitEndTime) : undefined
                })(
                  <TimePicker
                    style={{ width: "100%" }}
                    placeholder="Seleccionar hora"
                    disabled={readonly} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <CustomParagraph strong>Contenido de la actividad</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("activityContent", {
              initialValue: fields?.activityContent
            })(
              <Input.TextArea
                placeholder="Describe"
                disabled={readonly} />
            )}
          </Form.Item>
        </FormSection>
        <FormSection title="Metas / Resultados de la actividad">
          <CustomParagraph strong>¿Cuenta con lista de asistencia?</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("attendanceList", {
              initialValue: fields?.attendanceList,
              valuePropName: "value"
            })(
              <Radio.Group disabled={readonly}>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <CustomParagraph strong>¿Los materiales son presentados con logos de FICOSEC?</CustomParagraph>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator("ficosecLogos", {
                  initialValue: fields?.ficosecLogos,
                  valuePropName: "value"
                })(
                  <Radio.Group disabled={readonly}>
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {!ficosecLogosReasonDisabled &&
                  getFieldDecorator("ficosecLogosReason", {
                    initialValue: fields?.ficosecLogosReason,
                    rules: [
                      {
                        required: true,
                        message: "Indica el motivo"
                      }
                    ]
                  })(
                    <Input
                      placeholder="Motivo"
                      disabled={readonly} />
                  )}
              </Form.Item>
            </Col>
          </Row>
          <CustomParagraph strong>¿La actividad fue realizada en un lugar planeado?</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("activityPlace", {
              initialValue: fields?.activityPlace,
              valuePropName: "value"
            })(
              <Radio.Group disabled={readonly}>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <CustomParagraph strong>¿Corresponde la actividad con la planeación inicial?</CustomParagraph>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator("initialPlanning", {
                  initialValue: fields?.initialPlanning,
                  valuePropName: "value"
                })(
                  <Radio.Group disabled={readonly}>
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {!initialPlanningReasonDisabled &&
                  getFieldDecorator("initialPlanningReason", {
                    initialValue: fields?.initialPlanningReason,
                    rules: [
                      {
                        required: true,
                        message: "Indica el motivo"
                      }
                    ]
                  })(
                    <Input
                      placeholder="¿Por qué?"
                      disabled={readonly} />
                  )}
              </Form.Item>
            </Col>
          </Row>
          <CustomParagraph strong>¿Por parte de la organización se registró alguna evidencia?</CustomParagraph>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator("organizationEvidence", {
                  initialValue: fields?.organizationEvidence,
                  valuePropName: "value"
                })(
                  <Radio.Group disabled={readonly}>
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {!organizationEvidenceTypeDisabled &&
                  getFieldDecorator("organizationEvidenceType", {
                    initialValue: fields?.organizationEvidenceType,
                    rules: [
                      {
                        required: true,
                        message: "Indica el tipo de evidencia"
                      }
                    ]
                  })(
                    <Input
                      placeholder="¿De qué tipo?"
                      disabled={readonly} />
                  )}
              </Form.Item>
            </Col>
          </Row>
          <CustomParagraph strong>Describe los materiales utilizados en la actividad</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("suppliesUsed", {
              initialValue: fields?.suppliesUsed
            })(
              <Input.TextArea
                placeholder="Describe"
                disabled={readonly} />
            )}
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <CustomParagraph strong>Beneficiarios meta</CustomParagraph>
              <Form.Item>
                {getFieldDecorator("goalBeneficiaries", {
                  initialValue: fields?.goalBeneficiaries
                })(
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    disabled={readonly} />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <CustomParagraph strong>Beneficiarios reales</CustomParagraph>
              <Form.Item>
                {getFieldDecorator("actualBeneficiaries", {
                  initialValue: fields?.actualBeneficiaries
                })(
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    disabled={readonly} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <CustomParagraph strong>Observaciones</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("observations", {
              initialValue: fields?.observations
            })(
              <Input.TextArea
                placeholder="Describe las observaciones encontradas"
                disabled={readonly} />
            )}
          </Form.Item>
          <CustomParagraph strong>Obstáculos / Retos</CustomParagraph>
          <Form.Item>
            {getFieldDecorator("obstacles", {
              initialValue: fields?.obstacles
            })(
              <Input.TextArea
                placeholder="Describe los obstáculos o retos a superar"
                disabled={readonly} />
            )}
          </Form.Item>
        </FormSection>
      </Form>
      {!readonly && (
        <Button
          loading={busy}
          onClick={handleOnSave}
          type="primary"
          shape="round">
          Guardar
        </Button>
      )}
    </FormContainer>
  )
};

export const FieldVisitForm = Form.create<FieldVisitFormProps>()(RawFieldVisitForm);
