import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, message, Modal } from 'antd'
import styled from "styled-components";
import { useParams, useHistory } from "react-router";
import { ReviewableProps } from "../../../types/Request";
import { Content } from "../../atoms";
import { RisksTable, AssumptionsTable } from "../misc";
import { RequestFooter } from "../../organisms";
import { NewRequestContextProps, NewRequestMethods } from "../../NewRequestContext";
import { validateRisksTable, validateAssumptionsTable, uniqErrors, uniqListErrors } from "./helpers";
import { client } from "../../../gql/apollo";
import { UpdateRiskManagement } from "../../../gql/mutations/projects";
import { SubmitRequest } from "../../../gql/mutations";
import { IAssumption, Risk } from "../../../types";
import { requestSchema } from "../../../utils/validation/request.schema";
import { Notifier } from './components'
import { disabledRequestInput } from '../../../utils'

const TableContainer = styled.div`
  margin-top: 20pt;
`;

const RiskManagement: React.FC<ReviewableProps & NewRequestContextProps> = ({
  state,
  methods = {} as NewRequestMethods,
  reviewable,
  approval,
}) => {
  const { projectId, riskManagement: risks, assumptionManagement: assumptions, checkFields, reviews } = state;
  const history = useHistory();
  const { requestId } = useParams();

  const [updateRiskManagement] = useMutation<any>(UpdateRiskManagement, { client } as any);
  const [updateRequest] = useMutation<any>(SubmitRequest, { client } as any);

  const [risksIdsToCreate, setRisksIdsToCreate] = useState<string[]>([]);
  const [risksIdsToUpdate, setRisksIdsToUpdate] = useState<string[]>([]);
  const [risksIdsToDelete, setRisksIdsToDelete] = useState<string[]>([]);

  const [assumptionsIdsToCreate, setAssumptionsIdsToCreate] = useState<string[]>([]);
  const [assumptionsIdsToUpdate, setAssumptionsIdsToUpdate] = useState<string[]>([]);
  const [assumptionsIdsToDelete, setAssumptionsIdsToDelete] = useState<string[]>([]);

  const [busy, setBusy] = useState(false);

  const disableByStatus: boolean = disabledRequestInput(state.status, state.draft);

  const {
    addRisk,
    saveRiskInput,
    removeRisk,
    addEmptyAssumption,
    saveAssumptionInput,
    removeAssumption,
    checkFields: onCheckFields
  } = methods;

  const onAddRisk = () => {
    const risk = addRisk() as Risk;
    setRisksIdsToCreate([...risksIdsToCreate, risk.id]);
  };

  const onRemoveRisk = (riskId: string) => {
    removeRisk(riskId);
    setRisksIdsToDelete([...risksIdsToDelete, riskId]);
  };

  const onSaveRiskInput = (riskId: string, field: string, value: React.ReactText) => {
    saveRiskInput(riskId, field, value);

    if (risksIdsToCreate.includes(riskId) || risksIdsToUpdate.includes(riskId)) {
      return;
    }

    setRisksIdsToUpdate([...risksIdsToUpdate, riskId]);
  };

  // @todo verify with @Ongol what is happening here
  /*
  const onSaveRiskInput = (riskId: string, field: string, value: React.ReactText) => {
    saveRiskInput(riskId, field, value);

    if (risksIdsToCreate.includes(riskId)) {
      return;
    }

    // @todo review if this works propperly
    setRisksIdsToDelete([...risksIdsToDelete, riskId]);
  }*/

  const onAddAssumption = () => {
    const assumption = addEmptyAssumption() as IAssumption;
    setAssumptionsIdsToCreate([...assumptionsIdsToCreate, assumption.id]);
  };

  const onRemoveAssumption = (assumptionId: string) => {
    removeAssumption(assumptionId);
    setAssumptionsIdsToDelete([...assumptionsIdsToDelete, assumptionId]);
  };

  const onSaveAssumptionInput = (assumptionId: string, field: string, value: React.ReactText) => {
    saveAssumptionInput(assumptionId, field, value);

    if (assumptionsIdsToCreate.includes(assumptionId) || assumptionsIdsToUpdate.includes(assumptionId)) {
      return;
    }

    setAssumptionsIdsToUpdate([...assumptionsIdsToUpdate, assumptionId]);
  }

  const onOnClickNext = async () => {
    setBusy(true);
    saveRiskManagement();
  };

  const onClickSend = async () => {
    setBusy(true);
    validateRequest();
  };

  const saveRiskManagement = async () => {
    const risksToCreate = risksIdsToCreate
      .map((riskId) => risks[riskId])
      .filter((risk) => !!risk);
    const risksToUpdate = risksIdsToUpdate.map((riskId) => risks[riskId]);

    const assumptionsToCreate = assumptionsIdsToCreate
      .map((assumptionId) => assumptions[assumptionId])
      .filter((assumption) => !!assumption);
    const assumptionsToUpdate = assumptionsIdsToUpdate.map((assumptionId) => assumptions[assumptionId]);

    const isRisksTableValid = validateRisksTable(risks);
    const isAssumptionsTableValid = validateAssumptionsTable(assumptions);

    if (isRisksTableValid && isAssumptionsTableValid) {
      try {
        await updateRiskManagement({
          variables: {
            projectId,
            risksToCreate,
            risksToUpdate,
            risksToDelete: risksIdsToDelete,
            assumptionsToCreate,
            assumptionsToUpdate,
            assumptionsToDelete: assumptionsIdsToDelete
          }
        });
      } catch (error) {
        // TODO: Report to bugsnag
        message.error("Ocurrió un problema al guardar los riesgos y supuestos.");
      } finally {
        setBusy(false);
      }
    }
  };

  const notifierValidateRequest = async (errors: [string]) => {
    const uniqErr = uniqErrors(errors);
    const listError = uniqListErrors(uniqErr);
    return <Notifier listError={listError}/>;
  };

  const validateRequest = async () => {
    try {
      await requestSchema.validate(state, { abortEarly: false });
      await updateRequest({ variables: { id: requestId } });
      history.push("/implementadora/solicitudes");
    } catch (err) {
      // TODO: Report to bugsnag
      // TODO: Automatically save unsaved steps.
      const content = await notifierValidateRequest(err?.errors);
      onCheckFields && onCheckFields()
      Modal.error({
        title: "Verifica los datos",
        content,
        okText: "Entendido"
      });
    } finally {
      setBusy(false);
    }
  };

  return (
    <Content style={{ marginLeft: 245 }}>
      <div style={{ marginTop: 74, marginBottom: 74, padding: "20px 30px" }}>
        <TableContainer>
          <RisksTable
            reviews={reviews}
            checkFields={checkFields}
            disabled={busy || reviewable || approval || disableByStatus}
            reviewable={reviewable}
            approval={approval}
            risks={Object.values(risks)}
            onChange={onSaveRiskInput}
            onRemoveRisk={onRemoveRisk} />
          {!(reviewable || approval) && (
            <Button
              disabled={busy || disableByStatus}
              shape="round"
              icon="plus"
              onClick={onAddRisk}>
              Agregar riesgo
            </Button>
          )}
        </TableContainer>
        <TableContainer>
          <AssumptionsTable
            reviews={reviews}
            checkFields={checkFields}
            disabled={busy || reviewable || approval || disableByStatus}
            reviewable={reviewable}
            approval={approval}
            assumptions={Object.values(assumptions || {})}
            onChange={onSaveAssumptionInput}
            onRemoveAssumption={onRemoveAssumption}
            onAddAssumption={onAddAssumption} />
        </TableContainer>
      </div>
      {reviewable || !approval && !disableByStatus && (
        <RequestFooter
          nextProps={{ onClick: onOnClickNext, loading: busy }}
          sendProps={{ onClick: onClickSend, loading: busy }}
          lastStep />
      )}
    </Content>
  );
}

export default RiskManagement;
