export const getBreadCrumbRoutes = (projectName = "", folio = '', projectID = '') => ([
  {
    path: "../presolicitudes",
    breadcrumbName: "Pre-solicitudes"
  },
  {
    path: "#",
    breadcrumbName: projectName
  },
  {
    path: `../proyectos/${projectID}`,
    breadcrumbName: `Número de proyecto: ${folio}`
  }
]);
