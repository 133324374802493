import React from "react";
import { Spin } from "antd";

const Loader: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}>
      <Spin />
    </div>
  );
};

export default Loader;
