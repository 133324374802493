export type UserStatus =
  | "PENDING_CONFIRMATION"
  | "ACTIVE"
  | "INACTIVE";

export type UserRole =
  | "ADMIN"
  | "IMPLEMENTER"
  | "LEGAL_AREA"
  | "TECHNICAL_AREA";

export const UserRole: { [key in UserRole]: UserRole } = {
  ADMIN: "ADMIN",
  IMPLEMENTER: "IMPLEMENTER",
  LEGAL_AREA: "LEGAL_AREA",
  TECHNICAL_AREA: "TECHNICAL_AREA"
};

export interface User {
  id: string;
  email: string;
  status: UserStatus;
  role: UserRole;
  password?: string;
  Profile: {
    id: string;
    name: string;
  };
}
