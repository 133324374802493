import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { PendingRequests } from "../../../../gql/queries";
import { RequestsTemplate } from "../../../templates/implementer-onboarding";
import Layout, { PageHeader, Content } from "../../../organisms/Layout";
import { client } from "../../../../gql/apollo";
import { IPaginationSignature, sortDirectionMap } from "../../requests/ReviewalPage";

export default (): any => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { loading, data, error, refetch } = useQuery(PendingRequests, { client } as any);

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);

  if (error) {
    // @todo report to bugsnag
    return message.error("Ocurrió un problema al cargar las solicitudes");
  }

  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<Record<"id", string[]>>,
    sorter: SorterResult<{ id: string }>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <Layout noPadding>
      <PageHeader
        ghost={false}
        extra={<SearchBar />}
        title="Solicitudes" />
      <Content>
        <RequestsTemplate
          loading={loading}
          onPaginationChange={handleOnPaginationChange}
          requests={data?.requests} />
      </Content>
    </Layout>
  );
}

const SearchBar = () => {
  return (<Input.Search
    placeholder="Buscar"
    style={{ width: 400 }} />);
};
