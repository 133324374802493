import React, { useContext } from "react";
import { Descriptions, Table } from "antd";

import { SectionCard } from "../../../../organisms/ImplementerLayout";
import RequestDetailsTemplateContext from "../RequestDetailsTemplateContext";
import { SpecificObjective } from "../../../../../types";

export const ProjectSummarySection = () => {
  const { request = {} } = useContext(RequestDetailsTemplateContext);
  const { specificObjectives = [] as SpecificObjective[] } = request?.project || {};

  return (
    <SectionCard title="Ficha del proyecto">
      <Descriptions
        layout="horizontal"
        column={1}>
        <Descriptions.Item
          label="Objetivo general">
          {request?.project?.generalObjective}
        </Descriptions.Item>
      </Descriptions>
      <Table
        pagination={false}
        dataSource={specificObjectives}>
        <Table.Column
         key="id"
         title="Objetivos específicos"
         dataIndex="description" />
      </Table>
    </SectionCard>
  );
};
