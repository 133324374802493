import React from "react";
import { Footer } from "../../atoms";
import { Button } from "antd";

interface RequestMemorandumFooterProps {
  onClickNext: () => void;
  onClickCancel: () => void;
  nextDisabled?: boolean;
  btnText?: string;
}

const RequestMemorandumFooter: React.FC<RequestMemorandumFooterProps> =
({ onClickNext, onClickCancel, nextDisabled, btnText }) =>
  <Footer>
    <Button
      onClick={onClickNext}
      shape="round"
      type="primary"
      disabled={nextDisabled}
    >
      { btnText }
    </Button>
    <Button onClick={onClickCancel} type="link">Cancelar</Button>
  </Footer>;

export default RequestMemorandumFooter;
