import { uniqBy, uniq } from 'lodash'

export const stepsTitles: object = {
  financialCapability: { step: 1, title: "Capacidad financiera gestora" },
  projectInfo: { step: 2, title: "Información general del proyecto" },
  HRProfiles: { step: 6, title: "Perfil del Recurso Humano" },
  projectMethodology: { step: 3, title: "Ficha del proyecto" },
  projectDuration: { step: 3, title: "Ficha del proyecto" },
  sustainabilityArguments: { step: 3, title: "Ficha del proyecto" },
  generalObjective: { step: 3, title: "Ficha del proyecto" },
  impactIndicator: { step: 3, title: "Ficha del proyecto" },
  specificObjectives: { step: 3, title: "Ficha del proyecto" },
  goals: { step: 3, title: "Ficha del proyecto" },
  activities: { step: 3, title: "Ficha del proyecto" },
  supplies: { step: 4, title: "Presupuesto" },
  assumptionManagement: { step: 7, title: "Supuestos y gestión de riesgos" },
  riskManagement: { step: 7, title: "Supuestos y gestión de riesgos" }
};

export const uniqErrors = (errors: string[]) => {
  return uniq(errors.map(err => err.split(/[. []/)[0]));
}

export const uniqListErrors = (uniqErrors: string[]) => {
  return uniqBy(uniqErrors.map(el => {
    return { step: stepsTitles[el].step, title: stepsTitles[el].title };
  }).sort((a, b) => a.step - b.step), "step");
}
