import gql from "graphql-tag";

export const acceptedProjects = gql`
  query acceptedProjects ($status: RequestStatus!) {
    acceptedProjects( data: { status: $status }) {
      id
      name
      startDate
      endDate
      request {
        id
        status
        revisions {
          id
        }
        preRequest {
          id
          projectName
        }
      }
      agreement {
        id
        closed
        scheduledSigningDate
        contract {
          id
          fileName
          ref
        }
      }
    }
  }
`;
