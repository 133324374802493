import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { getCustomFieldDecorator } from "../helpers";
import { IFormMembersProps } from "../types";
import { InstitutionTypeSelect } from ".";
import { IInstitution } from "../../../types";

const MemberForm: React.FC<IFormMembersProps> = ({
  form,
  member,
  index,
  onChange,
  fieldDecoratorPrefix,
  onRemoveSelectedMember,
  showInstitutionLevels = false
}) => {
  const { getFieldDecorator } = form;
  const onRemoveMember = () => onRemoveSelectedMember(index);
  const onInputChange = (event: any) => onChange(index, event);

  const onChangeInstitutionField = (keyName: string, value: any) => {
    const { institution } = member;
    const updatedInstitution: IInstitution = institution !== undefined ?
      { ...institution } : { name: "" };
    updatedInstitution[keyName] = value;

    onInputChange({
      target: {
        name: "institution",
        value: updatedInstitution
      }
    });
  };

  return (
    <Row gutter={10} style={{ minWidth: "100%" }}>
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator(
              `${fieldDecoratorPrefix}Name_${member.id}`,
              getCustomFieldDecorator("memberName", member?.fullName)
            )!(
            <Input
              name="fullName"
              onChange={onInputChange}
              placeholder="Nombre completo"
              size="large"
              disabled={false} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator(
              `${fieldDecoratorPrefix}Institution_${member.id}`,
              getCustomFieldDecorator("institutionName", member?.institution?.name)
            )!(
            <Input
              onChange={({ target: { value } }) => {
                onChangeInstitutionField("name", value);
              }}
              name="institutionName"
              placeholder="Institución"
              size="large"
              disabled={false} />)}
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item>
          {getFieldDecorator(
              `${fieldDecoratorPrefix}Charge_${member.id}`,
              getCustomFieldDecorator("memberCharge", member.charge)
            )!(
            <Input
              onChange={onInputChange}
              name="charge"
              placeholder="Cargo"
              size="large"
              disabled={false} />)}
        </Form.Item>
      </Col>
      {showInstitutionLevels &&
        <Col span={3}>
          <Form.Item>
            {getFieldDecorator(
                `${fieldDecoratorPrefix}InstitutionLevel_${member.id}`,
                getCustomFieldDecorator("institutionLevel", member?.institution?.level)
              )!(
                <InstitutionTypeSelect
                  defaultValue={member?.institution?.level}
                  name="institutionLevel"
                  onChange={(event: any) => {
                    const { value } = event?.target;
                    onChangeInstitutionField("level", value);
                  }} />)}
          </Form.Item>
        </Col>}
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator(
              `${fieldDecoratorPrefix}MemberPhone_${member.id}`,
              getCustomFieldDecorator("memberPhone", member?.phone)
            )!(
            <Input
              onChange={onInputChange}
              name="phone"
              placeholder="Teléfono"
              size="large"
              disabled={false} />)}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator(
              `${fieldDecoratorPrefix}MemberEmail_${member.id}`,
              getCustomFieldDecorator(
                "memberEmail",
                member?.email,
                [{ type: "email", message: "Ingresa un correo válido" }])
            )!(
            <Input
              type="email"
              onChange={async (event) => {
                onInputChange(event);
                await form.validateFields();
              }}
              name="email"
              placeholder="Correo electrónico"
              size="large"
              disabled={false} />)}
        </Form.Item>
      </Col>
      <Col span={2}>
        <Button
        size="large"
        type="default"
        icon="delete"
        onClick={onRemoveMember} />
      </Col>
    </Row>
  );
};

export default MemberForm;
