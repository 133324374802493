import React from "react";
import { format } from "date-fns";
import ESLocale from "date-fns/locale/es";
import { Link } from "react-router-dom";

import styles from "./styles.less";

export type MonitoringStatus =
  | "en-monitoreo"
  | "concluidos";

interface MonitoringTabProps {
  currentTab: MonitoringStatus;
}

const MonitoringTab: React.FC<MonitoringTabProps> = ({ currentTab = "en-monitoreo" }) => {
  const activeClassName = [styles.tab, styles.active].join(" ");
  const className = (status: MonitoringStatus) => currentTab === status ? activeClassName : styles.tab;

  return (
    <div className={styles.container}>
      <div className={styles.monthContainer}>
        {format(Date.now(), "MMMM yyyy", { locale: ESLocale })}
      </div>
      <div className={styles.tabContainer}>
        <Link to="en-monitoreo">
          <div className={className("en-monitoreo")}>En monitoreo</div>
        </Link>
        <Link to="concluidos">
          <div className={className("concluidos")}>Concluidos</div>
        </Link>
      </div>
    </div>
  );
};

export default MonitoringTab;
