import React, { useContext } from "react";
import { Row, Col, Skeleton } from "antd";

import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import {
  ContactNameInput,
  ContactChargeInput,
  ContactInstitutionInput,
  ContactPhoneNumberInput,
  ContactEmailInput
} from './inputs';

export const ResponsibleContactSection = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const { isLoadingData, implementerProfile } = implementerAccountContext;

  if (isLoadingData) {
    return <Skeleton loading={true} />;
  }

  return (
    <FormSection title="CONTACTO RESPONSABLE DE LA SOLICITUD">
      <Row gutter={16}>
        <Col span={12}>
          <ContactNameInput />
        </Col>
        <Col span={12}>
          <ContactChargeInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          {implementerProfile.type !== "CIVIL_ASSOCIATION"
            && <ContactInstitutionInput />
          }
        </Col>
        <Col span={12}>
          <ContactPhoneNumberInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ContactEmailInput />
        </Col>
        <Col span={12}>
        </Col>
      </Row>
    </FormSection>
  );
};
