import styled from "styled-components";
import { PreRequestStatus } from "../../../types";
import { DeletePreRequest } from "../../../gql/mutations";
import { PreRequests } from "../../../gql/queries";
import { client } from "../../../gql/apollo";

export const StyledSpan = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 5pt;
`;

export const stylesMap: { [status in PreRequestStatus]: any } = {
  "APPROVED": {
    border: "solid 1px #52C41A",
    backgroundColor: "#EAF5DC"
  },
  "REJECTED": {
    border: "solid 1px #ffa39e",
    backgroundColor: "#fff1f0"
  },
  "IN_PROGRESS": {
    border: "solid 1px #ffd591",
    backgroundColor: "#fff7e6"
  }
};

export const deletePreRequest = async (id?: string ) => {
  await client.mutate({
    mutation: DeletePreRequest,
    variables: { id },
    refetchQueries: [{ query: PreRequests }]
  });
};
