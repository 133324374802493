import React from "react";

import styles from "./styles.less";

interface LabeledProps {
  label: string;
  layout?: "vertical" | "horizontal";
}

const Labeled: React.FC<LabeledProps> = ({ label, layout = "horizontal", children }) =>
  <div className={[styles.container, layout === "vertical" ? styles.vertical : undefined].join(" ")}>
    <div className={styles.label}>{label}</div>
    <div className={styles.contentContainer}>{children}</div>
  </div>;

export default Labeled;
