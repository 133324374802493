import React from "react";
import { InputNumber, Input } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import styled from "styled-components";

import { ColumnContainer, ColumnElement } from ".";
import { Beneficiary, ActivityBeneficiaryAttendance, BeneficiaryType } from "../../../../../types";
import { beneficiaryTypesMap } from "../../../../../utils/maps";

interface IBeneficiaryRowProps {
  busy: boolean;
  data?: any;
  beneficiary: Beneficiary;
  form: WrappedFormUtils<any>
}

const RowContainer = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const attendanceMap: { [key in ActivityBeneficiaryAttendance]: string } = {
  UNIQUE: "Única",
  REGULAR: "Regular"
};

export const BeneficiaryRow: React.FC<IBeneficiaryRowProps> = ({
  busy,
  data,
  beneficiary,
  form
}) => {
  const getBeneficiaryType = (type: BeneficiaryType[]) => {
    const typeLabel = beneficiaryTypesMap[type[0]];

    if (typeLabel !== undefined && typeof typeLabel !== "string") {
      return typeLabel[type[1]];
    }

    return typeLabel;
  };

  return (
    <RowContainer>
      <ColumnContainer key={beneficiary.id}>
        <ColumnElement title="Valor meta">
          {beneficiary.goalValue}
        </ColumnElement>
        <ColumnElement title="Tipo">
          {getBeneficiaryType(beneficiary.type)}
        </ColumnElement>
        <ColumnElement title="Descripción">
          {beneficiary.description}
        </ColumnElement>
        <ColumnElement title="Asistencia">
          {attendanceMap[beneficiary.attendance]}
        </ColumnElement>
        {form.getFieldDecorator(`beneficiaries.${beneficiary.id}.id`, {
          initialValue: data?.id
        })(
          <Input type="hidden" />
        )}
        <ColumnElement title="Conseguidos">
          {form.getFieldDecorator(`beneficiaries.${beneficiary.id}.obtained`, {
            initialValue: data?.obtained || 0
          })(
            <InputNumber
              disabled={busy}
              min={0}
              size="small"
              style={{ width: "100%" }} />
          )}
        </ColumnElement>
      </ColumnContainer>
    </RowContainer>
  );
};