import React from "react";
import { Menu } from "antd";
import { LayoutProps } from "antd/lib/layout";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

import {
  StyledHeader,
  StyledLogoContainer,
  StyledMenu,
  StyledAvatarContainer,
  imageStyle,
  UserDropdown
} from "./components";
import { menuItemsMap, sectionsMap, sectionMatcher } from "./helpers";
import { UserRole } from "../../../../types";
const ficosecLogo = require("../../../../assets/ficosec_logo.svg");

interface NavbarOwnProps {
  userRole?: UserRole;
  simple?: boolean;
  extra?: React.ReactNode;
  activeMenu?: string;
}

type NavbarProps =
  NavbarOwnProps &
  RouteComponentProps &
  LayoutProps;

class Navbar extends React.Component<NavbarProps> {
  public render() {
    const { location, userRole } = this.props;
    const { pathname = "" } = location;
    const menuItems = (userRole && menuItemsMap[userRole]) || [];

    const renderMenuItems = (item: any) => {
      if (item.items) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={
              <Link style={{color: "inherit"}} to={item.path}>{item.label}</Link>
            }>
            {item.items.map(renderMenuItems)}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item key={item.key}>
          <Link to={item.path}>{item.label}</Link>
        </Menu.Item>
      );
    };

    const section = sectionMatcher(pathname) || "presolicitudes";

    return (
      <>
        <StyledHeader>
          <StyledLogoContainer>
            <img src={ficosecLogo} style={imageStyle} />
          </StyledLogoContainer>
          <StyledMenu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[sectionsMap[section]]}>
            {menuItems.map(renderMenuItems)}
            <StyledAvatarContainer>
              {this.props.extra || null}
              <UserDropdown />
            </StyledAvatarContainer>
          </StyledMenu>
        </StyledHeader>
      </>
    );
  }
}

export default withRouter(Navbar);
