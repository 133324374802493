import React from "react";
import { Table } from "antd";

import styles from "../styles.less";
import { SectionCard } from "../../../../organisms/UserLayout";
import { totalsTableColumns } from "../helpers";
import { IProcessedSupplyItem } from "../types";

interface ITotalsSummaryTableProps {
  supplies: IProcessedSupplyItem[];
}

export const TotalsSummaryTable: React.FC<ITotalsSummaryTableProps> = (props) => {
  const { supplies = [] } = props;
  return (
    <SectionCard>
      <Table
        scroll={{ x: true }}
        pagination={false}
        className={`${styles.table} ${styles.AllSuppliesTable}`}
        columns={totalsTableColumns}
        dataSource={supplies} />
    </SectionCard>
  );
};
