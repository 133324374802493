import React, { useContext } from "react";
import { Select, Form } from "antd";
import { ClasificationFormContext } from "./ClasificationFormContext";
import { handleFilterOption } from "./helpers";

const areasMap = {
  UPV: "UPV",
  UFI: "UFI"
};

const InstancesDropdown: React.FC<{ disabled?: boolean }> = (props) => {
  const clasificationFormContext = useContext(ClasificationFormContext);
  const { form, onFieldsChange, preRequest } = clasificationFormContext;
  const { getFieldDecorator } = form;
  const { area, status } = preRequest;

  const handleOnChange = (value) => {
    onFieldsChange({ key: "area", value });
  };

  return (
    <Form.Item>
      {getFieldDecorator("area", {
          rules: [
            {
              required: true,
              message: "Por favor, selecciona la instancia."
            }
          ],
          // area could be null
          initialValue: !!area ? area : undefined
        })(
          <Select
            disabled={status === "APPROVED" || props.disabled}
            showSearch
            style={{ width: 200 }}
            placeholder="Seleccionar instancia"
            optionFilterProp="children"
            onChange={handleOnChange}
            filterOption={handleFilterOption}>
            {Object.keys(areasMap)
              .map((a) => (
                  <Select.Option value={a} key={a}>
                    {areasMap[a]}
                  </Select.Option>)
                )}
          </Select>
        )}
    </Form.Item>
  );
};

export default InstancesDropdown;
