import React from "react";
import { Form, Button, Upload } from "antd";
import { FormSection } from "../../../../molecules";
import {
  RequestArea,
  RequestType,
  ImplementerFields
} from "../../../../../types/Request";
import { FormComponentProps } from "antd/lib/form";

interface ICreationDocumentSectionOwnProps {
  requestArea?: RequestArea;
  requestType?: RequestType;
  fields?: Partial<ImplementerFields>;
  reviewable?: boolean;
  approval?: boolean;
}

type CreationDocumentSectionProps =
  & ICreationDocumentSectionOwnProps
  & FormComponentProps;

export class CreationDocumentSection extends React.Component<CreationDocumentSectionProps> {

  public constructor(props: CreationDocumentSectionProps) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator },
      fields,
      reviewable,
      approval
    } = this.props;

    if (reviewable || approval) {
      return null;
    }

    return (
      <FormSection title="Documento de creación (Institución de gobierno) o Ley">
        <Form.Item>
          {getFieldDecorator("creationDocument", {
            rules: [
              {
                required: true,
                message: "Por favor, adjunta el documento de creación."
              }
            ],
            initialValue: fields?.creationDocument
          })!(
            <Upload>
              <Button shape="round" icon="plus">
                Adjuntar documento de creación (Institución de gobierno) o Ley
              </Button>
            </Upload>
          )}
        </Form.Item>
      </FormSection>);
  }
}
