import React, { useContext } from "react";
import { Select, Form } from "antd";
import { ClasificationFormContext } from "./ClasificationFormContext";
import { handleFilterOption } from "./helpers";
import { requestTypes } from "../../../../../../utils/maps/request";

const CategoriesDropdown: React.FC<{ disabled?: boolean }> = (props) => {
  const clasificationFormContext = useContext(ClasificationFormContext);
  const { form, onFieldsChange, preRequest } = clasificationFormContext;
  const { getFieldDecorator } = form;
  const { type, status } = preRequest;

  const handleOnChange = (value) => {
    onFieldsChange({ key: "type", value });
  };

  return (
    <Form.Item>
      {getFieldDecorator("type", {
          rules: [
            {
              required: true,
              message: "Por favor, ingresa la categoría."
            }
          ],
          // type could be null
          initialValue: !!type ? type : undefined
        })(
          <Select
            disabled={status === "APPROVED" || props.disabled}
            showSearch
            style={{ width: 300 }}
            placeholder="Categoría de solicitud"
            optionFilterProp="children"
            onChange={handleOnChange}
            filterOption={handleFilterOption}>
            {Object.keys(requestTypes)
              .map((t) => (
                  <Select.Option value={t} key={t}>
                    {requestTypes[t]}
                  </Select.Option>)
                )}
          </Select>
        )}
    </Form.Item>
  );
};

export default CategoriesDropdown;
