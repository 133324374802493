import React, { useContext } from "react";
import { Form, Row, Col, Input, DatePicker, TimePicker, message } from "antd";
import moment from "moment";
import { FormSection } from "../../../../../molecules";
import { SigningDateFormProps } from "./types";
import { Project } from "../../../../../../types";
import { AgreementsTemplateContext } from "../../AgreementsTemplateContext";

const SigningDateForm = ({ project = {}, ...props }: SigningDateFormProps) => {

  const { updateProject, refetch } = useContext(AgreementsTemplateContext) as any;

  const saveProjectAgreement = async (updatedProject: Partial<Project>) => {
    try {
      props.toggleBusy(true);
      const p = await updateProject({
        variables: { ...updatedProject }
      });
      message.success("Fecha de firma agendada.")
      props.afterSavingSuccess(p);
      refetch();
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error");
    } finally {
      props.toggleBusy(false);
    }
  };

  const handleOnSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const { form } = props;
    const { agreement } = project;

    form.validateFields((errors: any, values: any) => {
      if (errors) {
        return;
      }

      return saveProjectAgreement({
        ...project,
        agreement: {
          ...agreement,
          signingPlace: values?.signingPlace,
          signingTime: moment(values?.signingTime).format("HHmm"),
          scheduledSigningDate:
            moment(values?.scheduledSigningDate).toISOString()
        }
      });
    });
  }

  const { form: { getFieldDecorator } } = props;
  const { agreement = {} } = project;

  const initDateValue = agreement?.scheduledSigningDate ?
    moment(agreement.scheduledSigningDate) : null;
  const initSigningTime = agreement?.signingTime ?
    moment(agreement?.signingTime, "HHmm") : null;
  const initSigningPlace = agreement?.signingPlace || undefined;


  return (
    <Form
      id="signingDate"
      layout="vertical"
      onSubmit={handleOnSubmit}>
      <FormSection>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              {
                getFieldDecorator("scheduledSigningDate", {
                  initialValue: initDateValue,
                  rules: [
                    {
                      required: true,
                      message: "Por favor, selecciona una fecha"
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Fecha"
                    size="large"
                    style={{ width: "100%" }} />
                )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator("signingTime", {
                initialValue: initSigningTime,
                rules: [
                  {
                    required: true,
                    message: "Por favor, selecciona una hora"
                  }
                ]
              })(
                <TimePicker
                  placeholder="Hora"
                  size="large"
                  use12Hours
                  format="hh:mm A"
                  minuteStep={10}
                  defaultOpenValue={moment("00:00:00", "HH:mm")}
                  style={{ width: "100%" }} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator("signingPlace", {
                initialValue: initSigningPlace,
                rules: [
                  {
                    required: true,
                    message: "Por favor, especifica el lugar"
                  }
                ]
              })(
                <Input placeholder="Lugar" size="large" />
              )}
            </Form.Item>
          </Col>
        </Row>
      </FormSection>
    </Form>
  );
}

export default Form.create<SigningDateFormProps>({ name: "signingDate" })(SigningDateForm);
