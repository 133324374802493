import gql from "graphql-tag";

export const RequestCorrection = gql`
  mutation RequestCorrection (
    $requestId: ID!
    $fields: [FieldReviewInput!]!
  ) {
    requestCorrection (
      data: {
        requestId: $requestId
        fields: $fields
      }) {
      id
    }
  }
`;
