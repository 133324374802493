import gql from "graphql-tag";

export const SupplyBudgetComments = gql`
  query SupplyBudgetComments ($supplyBudgetId: String!) {
    supplyBudgetComments(supplyBudgetId: $supplyBudgetId) {
      id
      comment
      createdAt
    }
  }
`;
