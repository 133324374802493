import React, { useState } from "react";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { AuthEmission } from "@react-firebase/auth/dist/types";

import { UserRole } from "../types";

export interface IRoleConsumerChildrenProps {
  claims?: ITokenClaims;
}

export interface IRoleConsumerProps {
  children: (props:
    & IRoleConsumerChildrenProps
    & AuthEmission
  ) => React.ReactElement;
}

export interface ITokenClaims {
  user_id: string;
  name: string;
  role: UserRole;
}

export const RoleConsumer: React.FC<IRoleConsumerProps> = ({ children }) => {
  const [claims, setClaims] = useState<ITokenClaims>();
  return (
    <FirebaseAuthConsumer>
      {(props) => {
        if (!claims) {
          props.user?.getIdTokenResult()
            .then((result: any) => setClaims(result.claims));
        }

        return children({ claims, ...props });
      }}
    </FirebaseAuthConsumer>
  );
};
