import React, { useState } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button, Icon, message, Modal } from "antd";
import { RoleConsumer } from "../../../../RoleConsumer";
import { getBreadCrumbRoutes, createDraftRequest } from "../helpers";
import { CustomPageHeader } from "../../../../organisms/ImplementerLayout";
import { IPreRequest } from "../../../../../types";
import { QueryResult, Query } from "react-apollo";
import { GetAccount } from "../../../../../gql/queries";

const { confirm } = Modal;

interface IDetailsHeaderOwnProps {
  preRequestData: Partial<IPreRequest>;
}

type IDetailsHeaderProps = IDetailsHeaderOwnProps & RouteComponentProps;

const DetailsHeader: React.FC<IDetailsHeaderProps> = ({ preRequestData, history }) => {
  const [busy, setBusy] = useState(false);
  const { id = '', projectName = "", folio = "", request = {} } = preRequestData;

  const handleFillRequest = async (profileIsValid: boolean) => {
    setBusy(true);
    try {
      if (profileIsValid) {
        await createDraftRequest(id);
        setBusy(false);
        history.replace("/implementadora/solicitudes");
      } else {
        setBusy(false);
        confirm({
          okText: "Completar perfil",
          title: "Los datos del perfil están incompletos",
          icon: <Icon type="info-circle" />,
          content: <p>
            El perfil de la implementadora está incompleto. Es necesario
            ingresar la información faltante para proceder.
          </p>,
          onOk() {
            history.push("/implementadora/perfil/editar");
          }
        });
      }
    } catch (error) {
      message.error("Ha ocurrido un error.");
      setBusy(false);
    }
  };

  const onGoBack = () => {
    window.history.back();
  };

  const renderCreateButton = (profileIsValid: boolean) => {
    if (preRequestData.request || preRequestData.status !== "APPROVED") {
      return undefined;
    }

    return (
      <Button
        loading={busy}
        type="primary"
        shape="round"
        onClick={() => handleFillRequest(profileIsValid)}>
        Llenar solicitud
        <Icon type="right-circle" />
      </Button>
    );
  };

  return (
    <RoleConsumer>
      {({ claims }) => (
        <Query
          query={GetAccount}
          variables={{ uid: claims?.user_id }}>
          {({ data, loading, error }: QueryResult) => {
            if (loading) {
              return null;
            }

            if (error) {
              message.error("Ocurrió un error al verificar el perfil");
              return null;
            }

            const { implementerProfile } = data?.account;
            const profileIsValid = !(implementerProfile === null);
            return (
              <CustomPageHeader
                breadcrumb={{
                  routes: getBreadCrumbRoutes(projectName, folio, request?.project?.id),
                  itemRender: (route) => (
                    <Link to={route.path}>{route.breadcrumbName}</Link>)
                }}
                title={projectName}
                ghost={false}
                extra={renderCreateButton(profileIsValid)}
                onBack={onGoBack}>
              </CustomPageHeader>
            );
          }}
        </Query>
      )}
    </RoleConsumer>
  );
};

export default withRouter(DetailsHeader);
