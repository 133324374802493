import React from "react";
import { Button } from "antd";

export const CancelButton: React.FC<{onClose: any}> = ({ onClose }) => {
  return (
    <Button
      style={{ float: "left" }}
      shape="round"
      onClick={onClose}>
        Cancelar
    </Button>
  );
};
