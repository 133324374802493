import gql from "graphql-tag";

export const requestsWithUnclosedAgreement = gql`
  query RequestsWithUnclosedAgreement(
    $skip: Int
    $sortField: String
    $sortDirection: String
  ) {
    requestsWithUnclosedAgreement(
      skip: $skip
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        preRequest {
          id
          area
          type
          status
          annotation
          projectName
          estimatedBudget
          projectDescription
          organizationDescription
        }
        project {
          id
          name
          startDate
          endDate
          estimatedDuration
          description
        	place
          agreement {
            id
            closed
            scheduledSigningDate
            signingTime
            signingPlace
            contract {
              id
              ref
              fileName
            }
          }
        }
        revisions {
          id
          board
          status
          annotations
        }
      }
      count
    }
  }
`;
