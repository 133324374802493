import React from "react";

import { CreateWorkTableTemplate } from "../../templates/workTables";

interface IUsersQueryResult {
  allUsers?: any[];
}

interface IQueryResult {
  loading: boolean;
  data?: IUsersQueryResult;
  error?: any;
  refetch?: any;
}

class CreateWorkTablePage extends React.Component {
  public render() {
    return <CreateWorkTableTemplate />;
  }
}

export default CreateWorkTablePage;
