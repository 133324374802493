import { UpdateImplementerProfile } from "../../../../gql/mutations";
import { GetAccount } from "../../../../gql/queries";
import { ImplementerProfile } from "../../../../types";
import { client } from "../../../../gql/apollo";

interface Args {
  implementerAccountUid: string;
  data: Partial<ImplementerProfile>;
}

export const updateImplementerProfile = async (args: Args) => {
  const { implementerAccountUid = "" } = args;

  const result = await client.mutate({
    mutation: UpdateImplementerProfile,
    variables: {
      ...args.data,
      implementerAccountUid
    },
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: GetAccount,
      variables: { uid: implementerAccountUid }
    }]
  });
  return result;
};
