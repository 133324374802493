import styled from "styled-components";
import { Row } from "antd";

export { CircleIcon } from "./CircleIcon";
export { default as InfoTable} from "./InfoTable";
export { default as InfoLabel } from "./InfoLabel";
export { default as InfoText } from "./InfoText";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 80pt;
  margin-bottom: 300pt;
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500pt;
  padding: 10pt;
`;

export const StyledRow = styled(Row)`
  margin-top: 5pt;
`;

export const StyledTableContainer = styled.div`
  width: 260pt;
  margin-top: 20pt;
  margin-bottom: 20pt;
`;
