import gql from "graphql-tag";

const RequestsForAgreement = gql`
  query RequestsForAgreement {
    allRequests { # TODO: Define status for requests for agreement
      id
      name
      zone
      city
      date
      area
      type
      status
      Agreement {
        id
        date
        time
        place
        contract
      }
    }
  }
`;

export default RequestsForAgreement;
