import React from "react";
import { Menu as ANTMenu } from "antd";
import { ClickParam } from "antd/lib/menu";
import styled from "styled-components";

import { Sidebar, StepIndicator } from "../atoms";
import { Request } from "../templates/requests/ApprovedRequestsTemplate/types";

interface Requests {
  [key: string]: Request[];
}

interface IApprovedRequestsSidebarProps {
  requests: Requests;
  currentStep: RequestApprovalStep;
  onChange: (param: ClickParam) => void;
}

export type RequestApprovalStep =
  | "UNITY"
  | "LOCAL"
  | "DIRECTORS"
  | "TECHNICIANS"
  | "APPROVED";

export const RequestApprovalStep: { [key in RequestApprovalStep]: RequestApprovalStep } = {
  UNITY: "UNITY",
  LOCAL: "LOCAL",
  DIRECTORS: "DIRECTORS",
  TECHNICIANS: "TECHNICIANS",
  APPROVED: "APPROVED"
};

const Menu = styled(ANTMenu)`
  border-right: none !important;
`;

const MenuItemLabel = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 168px;
`;

const steps: { [key in RequestApprovalStep]: string } = {
  UNITY: "Unidad",
  LOCAL: "Consejo local",
  DIRECTORS: "Consejo directivo",
  TECHNICIANS: "Comité técnico",
  APPROVED: "Aplicación aprobada"
};

export const ApprovedRequestsSidebar: React.FC<IApprovedRequestsSidebarProps> = ({ requests = {}, currentStep, onChange }) => (
  <Sidebar>
    <Menu onClick={onChange} defaultSelectedKeys={[currentStep]}>
      {Object.keys(steps).map((step) => (
        <Menu.Item key={step}>
          <MenuItemLabel>
            {steps[step]}
          </MenuItemLabel>
          <StepIndicator label={String(requests[step]?.length || 0)} />
        </Menu.Item>)
      )}
    </Menu>
  </Sidebar>
);

export default ApprovedRequestsSidebar;
