import React from "react";
import { Table } from "antd";
import styled from "styled-components";

import styles from "../styles.less";
import { totalPerYearTableColumns, getTotalsPerYear } from "../helpers";
import { IProcessedSupplyItem } from "../types";

interface IPerYearSummaryTableProps {
  supplies: IProcessedSupplyItem[];
}

const StyledTHead = styled.thead`
  text-align: right;
  padding: 16px;
  font-weight: bold;
`;

const StyledTD = styled.td`
  text-align: right;
  border-left: solid #EEE 1px;
`;

export const PerYearSummaryTable: React.FC<IPerYearSummaryTableProps> = (props) => {
  const { supplies = [] } = props;
  
  const totalsPerYear = getTotalsPerYear(supplies);
  const dataSource = Object.keys(totalsPerYear)
    .map((year: string) => ({
      key: year,
      label: `Total anual ${year}`,
      totalFicosec: totalsPerYear[year].totalFicosec,
      totalCoInvestment: totalsPerYear[year].totalCoInvestment
    }));

  return (
    <Table
      scroll={{ x: true }}
      pagination={false}
      className={styles.table}
      columns={totalPerYearTableColumns as any[]}
      dataSource={dataSource}
      bodyStyle={{
        borderCollapse: "separate",
        borderSpacing: "0 15px"
      }}
      components={{
        table: (props = {}) =>
          <table className={styles.Table__budgetPerYear}>
            {props.children}
          </table>,
        header: {
          wrapper: (props) =>
            <StyledTHead>
              {props.children}
            </StyledTHead>
        },
        body: {
          row: (props) =>
            <tr style={{ padding: 0 }}>
              {props.children}
            </tr>,
          cell: (props) =>
          <StyledTD>
            {props.children}
          </StyledTD>
        }
      }} />
  );
};
