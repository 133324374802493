import React from "react";
import { flatten } from "lodash";
import { getTime, startOfMonth, format } from "date-fns";
import { es } from "date-fns/locale";

import { TableContainer } from "../../../pages/projects";
import { BudgetSummaryTable } from "./components";
import { ASupply, IMonthlyBudget, IBudgetVerification, InvestorType } from "../../../../types";
import { expenseTypes } from "../../../../utils/maps";

interface IBudgetSummaryTabProps {
  loading: boolean;
  supplies: ASupply[];
}

export const BudgetSummaryTab: React.FC<IBudgetSummaryTabProps> = ({ loading, supplies }) => {
  const getSuppliesByMonth = (supplyArray: ASupply[]) => {
    const monthlyBudgets: Array<IMonthlyBudget & { supplyId: string }> = [];
    supplyArray.forEach((supply) =>
      supply.monthlyBudget.forEach((month) => monthlyBudgets.push({ ...month, supplyId: supply.id })));

    const budgetVerifications: Array<IBudgetVerification & { budgetId: string }> = [];
    monthlyBudgets.forEach((budget) =>
      budget.budgetVerification?.forEach((verification) => budgetVerifications.push({ ...verification, budgetId: budget.id })));

    const budgets: { [monthKey: string]: IMonthlyBudget[] } = {};
    monthlyBudgets.forEach((month) => {
      const integerTimestamp = parseInt(month.month as string, 10);
      const date = new Date(integerTimestamp);
      const monthKey = getTime(startOfMonth(date));
      const budget = budgets[monthKey] || [];
      budget.push(month);
      budgets[monthKey] = budget;
    });

    const getBudgeted = (monthlyBudgets: IMonthlyBudget[]) => ({
      ficosec: monthlyBudgets.reduce((acc, curr) => acc + curr.ficosec, 0),
      coinvestor: monthlyBudgets.reduce((acc, curr) => acc + curr.coinvestor, 0)
    });

    const getBudgetVerifications = (budgetVerifications: IBudgetVerification[], type: InvestorType) =>
      budgetVerifications.filter((verification) => verification.type === type);

    const getCombinedVerifications = (budgetVerifications: IBudgetVerification[], type: InvestorType) =>
      ({ consumed: budgetVerifications.reduce((acc, curr) => acc + curr.consumed, 0), type });

    const getVerifications = (monthlyBudgets: IMonthlyBudget[]) => {
      const verifications = flatten(monthlyBudgets.map((monthlyBudget) => monthlyBudget.budgetVerification || []));
      const ficosecVerification =
        getCombinedVerifications(
          getBudgetVerifications(verifications, InvestorType.FICOSEC),
          InvestorType.FICOSEC
        );

      const coinvestorVerification =
        getCombinedVerifications(
          getBudgetVerifications(verifications, InvestorType.COINVESTOR),
          InvestorType.COINVESTOR
        );

      return {
        ficosecVerification,
        coinvestorVerification
      };
    };

    return Object.keys(budgets).map((budget) => {
      const integerTimestamp = parseInt(budget, 10);
      const budgeted = getBudgeted(budgets[budget]);
      const verifications = getVerifications(budgets[budget]);

      return {
        id: budget,
        name: format(integerTimestamp, "MMMM 'de' yyyy", { locale: es }),
        month: budget,
        ficosec: budgeted.ficosec,
        coinvestor: budgeted.coinvestor,
        totalBudgeted: budgeted.ficosec + budgeted.coinvestor,
        totalVerified: verifications.ficosecVerification?.consumed + verifications.coinvestorVerification?.consumed,
        budgetVerification: [
          verifications.ficosecVerification,
          verifications.coinvestorVerification
        ]
      };
    });
  };

  const suppliesByExpenseType = {};
  supplies.forEach((supply) => {
    if (supply.expenseType) {
      const expenseTypeSupplies = suppliesByExpenseType?.[supply.expenseType] || {};
      suppliesByExpenseType[supply.expenseType] = {
        ...expenseTypeSupplies,
        [String(supply.isVerifiable)]: [
          ...expenseTypeSupplies?.[String(supply.isVerifiable)] || [],
          supply
        ]
      };
    }
  });

  const suppliesByVerifiability: Array<{ expenseType: string, children: any[] }> = [];
  Object.keys(suppliesByExpenseType).forEach((expenseType) => {
    Object.keys(suppliesByExpenseType[expenseType]).forEach((supplyVerifiability) => {
      const children = getSuppliesByMonth(suppliesByExpenseType[expenseType][supplyVerifiability]);
      const verifiabilityLabel = supplyVerifiability
        ? "Comprobable"
        : "No comprobable";

      suppliesByVerifiability.push({
        expenseType: `${expenseTypes[expenseType]} / ${verifiabilityLabel}`,
        children
      });
    });
  });

  return (
    <TableContainer>
      <BudgetSummaryTable
        loading={loading}
        dataSource={suppliesByVerifiability}
      />
    </TableContainer>
  );
};
