import React from "react";
import { Icon, Button } from "antd";
import moment from "moment";

interface Args {
  toggleSigningDateModal: any,
  toggleAttachmentModal: any,
  openCloseAgreementModal: (args: { project?: any }) => void;
}

export const getColumns = (args: Args) => [
  {
    title: "Nombre",
    dataIndex: "project.name",
    key: "project.name",
    sorter: true
  },
  {
    title: "Fecha de firma",
    dataIndex: "project.agreement.scheduledSigningDate",
    key: "agreement.scheduledSigningDate",
    render: (signingDate: string, request: any) => {
      const handleToggleSignDateModal = () =>
        args.toggleSigningDateModal(request);

      if (signingDate) {
        return (
          <div>
            <Icon type="calendar" style={{ marginRight: 10 }} />
            {moment(signingDate).format("DD/MM/YYYY")} @ {moment(request?.project?.agreement?.signingTime, "HHmm").format("hh:mm A")}
            <Button
              type="link"
              onClick={handleToggleSignDateModal}>
              Editar
            </Button>
          </div>
        );
      }

      return (
        <Button
          shape="round"
          onClick={handleToggleSignDateModal}>
          Agendar fecha
        </Button>);
    },
    sorter: true
  },
  {
    title: "Contrato",
    dataIndex: "project.agreement.contract",
    key: "contract",
    render: (contract: any, request: any) => {
      if (contract) {
        return (
          <Button
            onClick={() => args.toggleAttachmentModal(request?.id)}
            shape="round"
            type="primary">
            Ver contrato
          </Button>
        );
      }

      return (
        <Button
          shape="round"
          onClick={() => args.toggleAttachmentModal(request?.id)}>
          Adjuntar contrato
        </Button>);
    }
  },
  {
    render: (request: any) => {
      return (
        <Button
          shape="round"
          disabled={!request?.project?.agreement?.contract}
          onClick={() =>
            args.openCloseAgreementModal({
              project: request?.project || {}
            })}>
          {
            request?.project?.agreement?.closed === true ?
              "Detalle del cierre de convenio"
              : "Cerrar convenio"
          }
        </Button>
      );
    }
  }
];
