import React from "react";
import { Table, Select, Input, Button, Form } from 'antd'

import styles from "./styles.less";
import { Risk, RiskImpact, RiskProbability } from "../../../../types/Request";
import { RequestReviewConsumer, RequestReviewContextOwnProps } from "../../../RequestReviewContext";
import { ReviewControls, CommentButton } from "../../../molecules";

interface RisksTableProps {
  disabled: boolean;
  reviewable: boolean;
  approval: boolean;
  risks: Array<Partial<Risk>>;
  onChange: (riskId: string, field: string, value: string | number) => void;
  onRemoveRisk: (riskId: string) => void;
  checkFields: boolean;
  reviews: any;
}

class RisksTable extends React.Component<RisksTableProps> {
  private riskImpactMap = {
    HIGH: "Alto",
    MEDIUM: "Medio",
    LOW: "Bajo"
  };

  private riskProbabilityMap = {
    HIGH: "Alta",
    MEDIUM: "Media",
    LOW: "Baja"
  };

  public render() {
    const { risks = [], checkFields, reviews } = this.props;
    const dataSource = risks.map((risk) => ({ key: risk?.id, ...risk }));

    return (
      <RequestReviewConsumer>
        {({ reviewState }) => (
          <Table
            pagination={false}
            className={styles.table}
            columns={this.getColumns(reviewState, checkFields, reviews)}
            dataSource={dataSource}
            bordered
          />
        )}
      </RequestReviewConsumer>
    );
  }

  private getColumns = (reviewState: Partial<RequestReviewContextOwnProps>, checkFields: boolean, reviews: any) => ([
    {
      title: "Riesgos",
      align: "left" as "left",
      children: [
        {
          title: "Identificación",
          dataIndex: "identification",
          key: "identification",
          render: (value: string, original: Partial<Risk>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Input
                style={{ width: "100%" }}
                defaultValue={value}
                onBlur={
                  (e: React.FocusEvent<HTMLInputElement>) =>
                    this.props.onChange(original.id!, "identification", e.target.value)
                }
                disabled={this.props.disabled}
              />
            </Form.Item>
          )
        },
        {
          title: "Probabilidad",
          dataIndex: "probability",
          key: "probability",
          render: (value: RiskProbability, original: Partial<Risk>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Select
                placeholder="Selecciona la probabilidad"
                defaultValue={value}
                style={{ width: "100%" }}
                onChange={(v: RiskProbability) =>
                  this.props.onChange(original.id!, "probability", v)}
                disabled={this.props.disabled}>
                {Object.keys(this.riskProbabilityMap).map((probability) => (
                  <Select.Option key={probability}>
                    {this.riskProbabilityMap[probability]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        },
        {
          title: "Impacto",
          dataIndex: "impact",
          key: "impact",
          render: (value: RiskImpact, original: Partial<Risk>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Select
                placeholder="Selecciona el riesgo"
                defaultValue={value}
                style={{ width: "100%" }}
                onChange={(v: RiskImpact) => this.props.onChange(original.id!, "impact", v)}
                disabled={this.props.disabled}
              >
                {Object.keys(this.riskImpactMap).map((risk) => (
                  <Select.Option key={risk}>{this.riskImpactMap[risk]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        }
      ]
    },
    {
      title: "Mitigación",
      align: "left" as "left",
      children: [
        {
          title: "Estrategia de mitigación de riesgo",
          dataIndex: "mitigationStrategy",
          key: "mitigationStrategy",
          render: (value: string, original: Partial<Risk>) => (
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? value ? "" : "error" : "" }>
              <Input
                style={{ width: "100%" }}
                defaultValue={value}
                onBlur={
                  (e: React.FocusEvent<HTMLInputElement>) =>
                    this.props.onChange(original.id!, "mitigationStrategy", e.target.value)
                }
                disabled={this.props.disabled}
              />
            </Form.Item>
          )
        }
      ]
    },
    {
      children: [
        {
          align: "center" as "center",
          render: (_: any, risk: Partial<Risk>) => {
            const { disabled, reviewable, approval } = this.props;
            const fieldId = `risks.${risk.id}`;
            if (approval) {
              return null;
            }

            if (!reviewable) {
              return (
                <Button.Group>
                  <Button
                    icon="delete"
                    disabled={disabled}
                    onClick={() => !disabled && this.props.onRemoveRisk(risk.id!)}
                  />
                  <CommentButton field={fieldId} size="default" disabled={disabled}/>
                </Button.Group>
              );
            } else {
              return (
                <ReviewControls
                  reviews={reviews}
                  fieldId={fieldId}
                  comment={((reviewState.state || {})[fieldId] || { comment: undefined }).comment}
                  approved={((reviewState.state || {})[fieldId] || { approved: undefined }).approved}
                  onReset={(reviewState.methods || { onReset: () => undefined }).onReset}
                  onApprove={(reviewState.methods || { onApprove: () => undefined }).onApprove}
                  onReject={(reviewState.methods || { onReject: () => undefined }).onReject}
                  onSaveComment={(reviewState.methods || { onSaveComment: () => undefined }).onSaveComment}
                  onDeleteComment={(reviewState.methods || { onDeleteComment: () => undefined }).onDeleteComment}
                  relative
                />
              );
            }
          }
        }
      ]
    }
  ])
}

export default RisksTable;
