import React from "react";
import { Form, Input, Select, Row, Col, Button, Icon, Modal, Alert, InputNumber } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { UploadFile, UploadChangeParam } from "antd/lib/upload/interface";
import cleanDeep from "clean-deep";

import { Collapse, Panel, Popover, Upload } from '../../atoms'
import { FormSection } from "../../molecules";
import { HRProfile, Goal } from "../../../types/Request";
import reviewableField from "../../../utils/reviewableField";
import { hrProfileInfo } from "../../../copy.json";

interface HRProfileFormOwnProps {
  fields: {
    [key: string]: Partial<HRProfile>;
  };
  goals: {
    [id: string]: Partial<Goal>;
  };
  methods: {
    addHRProfile: () => void;
    removeHRProfile: (profileId: string) => void;
    saveHRProfileInput: (profileId: string, field: string, value: string) => void;
  };
  checkFields: boolean;
  reviews: any;
}

type HRProfileFormProps = { disabled: boolean; reviewable: boolean; approval: boolean; } & HRProfileFormOwnProps & FormComponentProps;


const rhGoalsInfo = (
  <div>
    <div>
      <h5>Metas</h5>
      <p>Las metas se seleccionan una vez agregadas en la ficha técnica del proyecto</p>
    </div>
  </div>
);

class HRProfileForm extends React.PureComponent<HRProfileFormProps> {
  public render() {
    const { fields, methods, disabled, reviewable, approval } = this.props;
    const { addHRProfile } = methods;

    return (
      <Row style={{ padding: 40, marginTop: 74, marginBottom: 74 }}>
        <Col span={8}>
          <Alert
            style={{ marginBottom: 20 }}
            type="info"
            message={hrProfileInfo}
            showIcon
            closable
          />
          <Collapse defaultActiveKey={["0"]}>
            {Object.keys(fields).map(this.renderProfilePanel)}
          </Collapse>
          {!(reviewable || approval) && (
            <Button
              type="dashed"
              icon="plus"
              size="large"
              onClick={addHRProfile}
              disabled={disabled}
              block>
              Agregar un perfil
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  private renderProfilePanel = (profileId: string, idx: number) => {
    const {
      form: { getFieldDecorator, getFieldsError },
      fields,
      goals,
      disabled,
      reviewable,
      checkFields,
      reviews
    } = this.props;
    const errors = cleanDeep(getFieldsError());

    reviewableField.reviewable = reviewable;
    reviewableField.fieldDecorator = getFieldDecorator;
    reviewableField.disabled = disabled;
    reviewableField.reviews = reviews
    const renderReviewableField = reviewableField.render;

    return (
      <Panel
        key={profileId}
        title={fields[profileId].fullName ? fields[profileId].fullName : `Perfil ${idx + 1}`}
        extra={this.renderPanelExtra(idx, profileId, !!errors[profileId])}
      >
        <Form layout="vertical">
          <FormSection>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].fullName ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.fullName`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].fullName
              })!(
                <Input placeholder="Nombre completo" size="large" disabled={disabled} />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].position ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.position`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].position
              })!(
                <Input placeholder="Puesto" size="large" disabled={disabled} />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].projectGoals ? "" : "error" : "" }>
              <Popover
                placement="right"
                content={rhGoalsInfo}
                trigger="hover"
                overlayStyle={reviewable && ({ display: "none" })}>
                {renderReviewableField(`${profileId}.projectGoals`, {
                  trigger: "onBlur",
                  valuePropName: "defaultValue",
                  initialValue: fields[profileId].projectGoals
                })!(
                  <Select
                    mode="multiple"
                    placeholder="Metas en el proyecto"
                    size="large"
                    disabled={disabled}
                  >
                    {Object.keys(goals).map((goalId) => (
                      <Select.Option key={goalId}>{goals[goalId]?.description}</Select.Option>
                    ))}
                  </Select>
                )}
              </Popover>
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].responsibilities ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.responsibilities`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].responsibilities
              })!(
                <Input.TextArea
                  rows={3}
                  placeholder="Funciones y responsabilidades"
                  disabled={disabled}
                />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].incomeEstimation ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.incomeEstimation`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].incomeEstimation
              })!(
                <Input.TextArea
                  rows={3}
                  placeholder="Ingreso estimado"
                  disabled={disabled}
                />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].weeklyHours ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.weeklyHours`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].weeklyHours
              })!(
                <Input.TextArea
                  rows={3}
                  placeholder="Horas a la semana dedicadas por proyecto"
                  disabled={disabled}
                />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].eduLevel ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.eduLevel`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].eduLevel
              })!(
                <Select placeholder="Nivel educativo" size="large" disabled={disabled}>
                  <Select.Option key="PRIMARY">Primaria</Select.Option>
                  <Select.Option key="SECONDARY">Secundaria</Select.Option>
                  <Select.Option key="HIGH_SCHOOL">Preparatoria</Select.Option>
                  <Select.Option key="PROFESSIONAL">Profesional</Select.Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].specialtyArea ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.specialtyArea`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].specialtyArea
              })!(
                <Input placeholder="Área de especialidad" size="large" disabled={disabled} />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].yearsExp ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.yearsExp`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].yearsExp
              })!(
                <InputNumber
                  min={0}
                  placeholder="Años de experiencia"
                  size="large"
                  style={{ width: "100%" }}
                  disabled={disabled} />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].workExp ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.workExp`, {
                trigger: "onBlur",
                valuePropName: "defaultValue",
                initialValue: fields[profileId].workExp
              })!(
                <Input placeholder="Experiencia laboral" size="large" disabled={disabled} />
              )}
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0 }}
              validateStatus={checkFields ? fields[profileId].resume ? "" : "error" : "" }>
              {renderReviewableField(`${profileId}.resume`, {
                valuePropName: "defaultFileList",
                initialValue: this.getResume(fields[profileId].resume),
                getValueFromEvent: (uploadedFile: UploadChangeParam<UploadFile<any>>) =>
                  uploadedFile.fileList[0] && ({
                    fileName: uploadedFile.fileList[0].name,
                    ref: uploadedFile.fileList[0].response?.imageUrl
                  }),
              })!(
                <Upload>
                  <Button
                    shape="round"
                    icon="plus"
                    disabled={disabled || !!fields[profileId].resume}>
                    Adjuntar CV
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </FormSection>
        </Form>
      </Panel>
    );
  }

  private getResume = (resume: any) =>
    resume && ([{
      uid: resume.id || 0,
      name: resume.fileName,
      url: resume.ref,
    }])

  private renderPanelExtra = (idx: number, profileId: string, hasError: boolean = false) => {
    const { reviewable, approval } = this.props;
    return (
      <>
        {hasError && <Icon type="exclamation-circle" theme="filled" style={{ color: "#8e1d41", marginRight: 10 }} />}
        {!(reviewable || approval) && (<Icon type="delete" onClick={() => !this.props.disabled && this.props.methods.removeHRProfile(profileId)} />)}
      </>
    );
  }
}

const onFieldsChange = (props: HRProfileFormProps, changedFields: any) => {
  const field = (Object.values(changedFields)[0] || {}) as { [field: string]: { name: string, value: string } };
  const fieldName = Object.keys(field)[0];
  const fieldValue = field[fieldName]?.value;
  const profileId = Object.keys(changedFields)[0];

  if (!props.reviewable) {
    props.methods.saveHRProfileInput(profileId, fieldName, fieldValue);
  }
};

const WrappedImplementerForm = Form.create({ name: "hrProfile", onFieldsChange })!(HRProfileForm);

export default WrappedImplementerForm;
