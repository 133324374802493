import React, { Context } from "react";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { PaginatedResult } from "../requests/PendingRequestsTemplate";
import { Project } from "../../../types";

interface IDefaultValue {
  loading?: boolean;
  error?: any;
  data?: {
    openProjects: PaginatedResult<Project>;
    concludedProjects: PaginatedResult<Project>;
  };
  onPaginationChange?: (
    paginationConfig: PaginationConfig,
    filters: Partial<any>,
    sorter: SorterResult<any>
  ) => void;
}

export const MonitoringTemplateContext: Context<IDefaultValue> = React
  .createContext({
    loading: false,
    error: undefined,
    data: undefined,
    onPaginationChange: undefined
  } as any);
