export const isNullOrEmpty = (param: any) => {
  if (param === undefined || param === null) {
    return true;
  } else if (typeof(param) === "string") {
    if (param === "") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
