import React from "react";
import { Layout } from "antd";
import { LayoutProps } from "antd/lib/layout";
import styled from "styled-components";

import CustomHeader from "./CustomHeader";
import { CustomContent } from "./CustomContent";

interface IImplementerOwnLayout {
  extra?: React.ReactNode;
}

type ImplementerLayoutProps =
  & IImplementerOwnLayout
  & LayoutProps;

const MainContainer = styled.div`
  margin-top: 64px;
`;

const ImplementerLayout: React.FC<ImplementerLayoutProps> = (props) => {
  return (
    <Layout style={{ height: "100%" }} {...props}>
      <CustomHeader extra={props.extra} />
      <MainContainer>
        <CustomContent>
          {props.children}
        </CustomContent>
      </MainContainer>
    </Layout>
  );
};

export * from "./SectionCard";
export * from "./CustomPageHeader";
export * from "./CustomContent";
export default ImplementerLayout;
