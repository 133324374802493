import React, { useContext } from "react";
import { Descriptions } from "antd";
import { SectionCard } from "../../../../organisms/ImplementerLayout";
import RequestDetailsTemplateContext from "../RequestDetailsTemplateContext";
import { Request, PreventionScope, PreventionLevel } from "../../../../../types";
import { preventionLevels, preventionScopes } from "../../../../../utils/maps";

export const PreventionLevelsSection = (props: any) => {
  const { request = {} as Request } = useContext(RequestDetailsTemplateContext);
  const { preventionLevel, preventionScope } = request?.project || {};

  return (
    <SectionCard
      title="Nivel de prevención que considera el proyecto">
        <Descriptions column={1}>
        <Descriptions.Item
          label="Nivel de prevención">
          {preventionLevels[preventionLevel as PreventionLevel]}
        </Descriptions.Item>
        <Descriptions.Item
          label="Alcance">
          {preventionScopes[preventionScope as PreventionScope]}
        </Descriptions.Item>
      </Descriptions>
    </SectionCard>
  );
};
