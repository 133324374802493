import React, { useContext } from "react";
import { MeetingFormContext } from "../../../MeetingFormContext";
import Form from "antd/lib/form/Form";
import { Input } from "antd";

export const NameInput = (props: any) => {
  const meetingFormContext = useContext(MeetingFormContext);
  const { form, onInputChange } = meetingFormContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item
      label="Nombre de la reunión">
      {getFieldDecorator("name", {
        rules: [
          {
            required: true,
            message: "Por favor, ingresa el nombre de la reunión."
          }
        ],
        initialValue: meetingFormContext.worktableMeeting?.name
      })(
        <Input
          id="name"
          name="name"
          type="text"
          onChange={(onChange)} />
      )}
    </Form.Item>
  );
};
