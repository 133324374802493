import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { getCustomFieldDecorator } from "../helpers";
import { IActivity } from "../../../types";

interface ISubActivitySectionProps {
  form?: any;
  activityIndex: any;
  subActivity: IActivity;
  parentActivityIndex: any;
  onRemoveActivity: any;
  onChange: any;
}

const SubActivitySection: React.FC<ISubActivitySectionProps> = ({
  activityIndex,
  form,
  parentActivityIndex,
  onRemoveActivity,
  onChange,
  subActivity
}) => {
  const { getFieldDecorator } = form;

  const onRemoveSubActivity = () => {
    onRemoveActivity("subActivity", parentActivityIndex, activityIndex);
  };

  const onChanging = (event: any) => {
    onChange("subActivity", event, parentActivityIndex, activityIndex);
  };

  return (
    <Row gutter={10}>
      <Col span={22}>
        <Form.Item>
          {getFieldDecorator(
              `subActivity_${subActivity.id}`,
              getCustomFieldDecorator("subActivity", subActivity.title)
            )!(
            <Input
              onChange={onChanging}
              placeholder="Sub actividad"
              size="large"
              disabled={false} />)}
        </Form.Item>
      </Col>
      <Col span={2}>
        <Button
          onClick={onRemoveSubActivity}
          size="large"
          type="default"
          icon="delete" />
      </Col>
    </Row>
  );
};

export default SubActivitySection;
