import React from "react";
import { Link as RNLink, withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";
import styles from "./styles.less";
import { Sidebar, StepIndicator } from "../../atoms";
import { NewRequestContextProps } from "../../NewRequestContext";

interface RequestSidebarOwnProps {
  currentStep: number;
  stepsToOmit?: string[];
  stepsToSkip?: number; // @note This behavior should be removed
}

type RequestSidebarProps = RequestSidebarOwnProps & Partial<NewRequestContextProps> & RouteComponentProps;

const MenuItem = styled(Menu.Item)`
  display: flex;
  height: auto !important;
`;

const Link = styled(RNLink)`
  display: flex !important;
  align-items: center;
`;

const ItemLabel = styled.div`
  display: inline-block;
  width: 168px;
  line-height: initial;
  white-space: pre-wrap;
  padding: 16px 0px;
`;

export const RequestSidebar: React.FC<RequestSidebarProps> = ({ state, currentStep = 0, history, stepsToSkip = 0 }) => {
  const activities = Object.keys(state?.activities || {});

  return (
    <Sidebar>
      <Sidebar.Heading>Nueva solicitud</Sidebar.Heading>
      <Menu selectedKeys={[String(currentStep)]} className={styles.menu}>
        <MenuItem key="0">
          <StepIndicator label={(1 - stepsToSkip).toString()} checked={state!.checked.financialCapability} />
          <Link to="capacidad-financiera">
            <ItemLabel>Capacidad financiera gestoras</ItemLabel>
          </Link>
        </MenuItem>
        <MenuItem key="1">
          <StepIndicator label={(2 - stepsToSkip).toString()} checked={state!.checked.projectInfo} />
          <Link to="proyecto">
            <ItemLabel>Información general del proyecto</ItemLabel>
          </Link>
        </MenuItem>
        <MenuItem key="2">
          <StepIndicator label={(3 - stepsToSkip).toString()} checked={state!.checked.projectFile} />
          <Link to="ficha">
            <ItemLabel>Ficha del proyecto</ItemLabel>
          </Link>
        </MenuItem>
        <MenuItem key="3">
          <StepIndicator label={(4 - stepsToSkip).toString()} checked={state!.checked.budget} />
          <Link to="presupuesto">
            <ItemLabel>Presupuesto</ItemLabel>
          </Link>
        </MenuItem>
        <MenuItem key="4" disabled={activities.length === 0}>
          <StepIndicator label={(5 - stepsToSkip).toString()} />
          <Link to="cronograma">
            <ItemLabel>Cronograma</ItemLabel>
          </Link>
        </MenuItem>
        <MenuItem key="5">
          <StepIndicator label={(6 - stepsToSkip).toString()} checked={state!.checked.HRProfiles} />
          <Link to="recursos-humanos">
            <ItemLabel>Perfil del Recurso Humano</ItemLabel>
          </Link>
        </MenuItem>
        <MenuItem key="6">
          <StepIndicator label={(7 - stepsToSkip).toString()} checked={state!.checked.riskManagement} />
          <Link to="riesgos">
            <ItemLabel>Supuestos y gestión de riesgos</ItemLabel>
          </Link>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default withRouter(RequestSidebar);
