import React, { useContext } from "react";
import { Input } from "antd";
import Form from "antd/lib/form/Form";
import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";

export const GovernmentDependencyInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item label="Dependencia de gobierno">
      {getFieldDecorator("governmentDependency", {
        rules: [
          {
            required: true,
            message: "Por favor, selecciona la depencencia de gobierno."
          }
        ],
        initialValue: implementerProfile?.governmentDependency
      })(
        <Input
          disabled={isBusy}
          id="governmentDependency"
          name="governmentDependency"
          placeholder="Dependencia de gobierno"
          onChange={onChange}
          size="large" />
      )}
    </Form.Item>
  );
};
