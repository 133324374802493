import gql from "graphql-tag";

export const acceptedProject = gql`
  query project ($id: String!) {
    project(id: $id) {
      id
      name
      closed
      closingDocument {
        fileName
        ref
      }
      monitoringEntries {
        id
        date
        closed
      }
      startDate
      endDate
      request {
        id
        status
        revisions {
          id
        }
        preRequest {
          id
          projectName
          estimatedBudget
          type
          implementer {
            id
            displayName
          }
        }
      }
    }
  }
`;
