import gql from "graphql-tag";

const SendRequest = gql`
  mutation SendRequest($id: String!) {
    updateRequest(data: {
      id: $id,
      draft: false
    }) {
      id
    }
  }
`;

export default SendRequest;
