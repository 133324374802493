import styled from "styled-components";
import { Col } from "antd";

export const CenteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
