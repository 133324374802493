import { RequestStatus, RequestType } from "../../types/Request";

export const requestTypes: { [type in RequestType]: string } = {
  FICOSEC_INVITATION: "Por invitación de FICOSEC",
  IMPLEMENTER_REQUEST: "Solicitud de la implementadora",
  FICOSEC_ANNOUNCEMENT: "Convocatoria FICOSEC",
  EXTERNAL_ANNOUNCEMENT: "Convocatoria externa",
  GOVERNMENT_REQUEST: "Solicitud de gobierno",
  SUPPORT_ACTIVITIES: "Actividades de apoyo a las líneas estratégicas",
  CONSULTANCY_REQUEST: "Solicitud por consultoría"
};

export const requestTypesLetters: { [letter: string]: RequestType } = {
  A: requestTypes.FICOSEC_INVITATION as RequestType,
  B: requestTypes.IMPLEMENTER_REQUEST as RequestType,
  C: requestTypes.FICOSEC_ANNOUNCEMENT as RequestType,
  D: requestTypes.EXTERNAL_ANNOUNCEMENT as RequestType,
  E: requestTypes.GOVERNMENT_REQUEST as RequestType,
  F: requestTypes.SUPPORT_ACTIVITIES as RequestType,
  G: requestTypes.CONSULTANCY_REQUEST as RequestType
};

export const requestTypesList: { [key: string]: RequestType } = {
  A: "FICOSEC_INVITATION",
  B: "IMPLEMENTER_REQUEST",
  C: "FICOSEC_ANNOUNCEMENT",
  D: "EXTERNAL_ANNOUNCEMENT",
  E: "GOVERNMENT_REQUEST",
  F: "SUPPORT_ACTIVITIES",
  G: "CONSULTANCY_REQUEST"
};

export const requestStatuses: { [status in RequestStatus]: string } = {
  PENDING_CORRECTION: "Corrección pendiente",
  IN_PROGRESS: "Por revisar",
  APPROVED: "Aprobada",
  REJECTED: "Rechazada",
  FINISHED: "Finalizada"
};
