import gql from "graphql-tag";

export const UpdateSustainabilityArgument = gql`
  mutation UpdateSustainabilityArgument(
    $id: String!
    $argument: String
  ) {
    updateSustainabilityArgument(
      data: {
        id: $id
        argument: $argument
      }
    ) {
      id
      argument
    }
  }
`;
