import React from "react";
import { Descriptions } from "antd";
import { GoalsDetailsTable } from ".";
import { deliverablePeriodicityMap } from "../../../../../utils/maps";
import { DeliverablePeriodicity } from "../../../../../types";


interface SpecificObjectiveInfoProps {
  specificObjective: any;
  data: any;
}

export const SpecificObjectiveInfo = (props: SpecificObjectiveInfoProps) => {
  const { specificObjective = {}, data = {} } = props;
  const { deliverable = {} } = specificObjective;
  const periodicity = deliverablePeriodicityMap[deliverable?.periodicity]
    .toLowerCase();

  return (
    <div
      style={{
        marginBottom: "50pt",
      }}>
      <Descriptions
        column={1}
        layout="vertical"
        title="Objetivo específico">
        <Descriptions.Item label="Descripción">
          {specificObjective.summary}
        </Descriptions.Item>
        <Descriptions.Item label="Entregable">
          <p style={{ fontWeight: "bold" }}>
            {deliverable.title}
          </p>
          <p>{deliverable.description}</p>
          <p style={{ color: "#900" }}>
            Entrega {periodicity}
          </p>
        </Descriptions.Item>
      </Descriptions>
      <GoalsDetailsTable
        data={data}
        specificObjective={specificObjective} />
    </div>
  );
};
