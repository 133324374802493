import React from "react";
import { FormSection } from "../../../../molecules";
import { Form } from "antd";
import {
  NameInput,
  AreaInput,
  WorktableInput,
  SubjectInput,
  PlaceInput,
  DateInput,
  ObjectiveInput
} from "./inputs";

const MeetingForm: React.FC<{}> = (props) => {
  return (
    <Form>
      <FormSection>
        <NameInput />
        <AreaInput />
        <WorktableInput />
        <SubjectInput />
        <PlaceInput />
        <DateInput />
        <ObjectiveInput />
      </FormSection>
    </Form>
  );
};

export default MeetingForm;
