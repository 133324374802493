import React from "react";

interface ErrorTemplateProps {
  message?: string;
}

const containerStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
};

const ErrorTemplate: React.FC<ErrorTemplateProps> = ({ message }) => (
  <div style={containerStyle}>{message}</div>
);

ErrorTemplate.defaultProps = {
  message: "Ocurrió un error, por favor recarga la página."
};

export default ErrorTemplate;
