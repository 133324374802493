import gql from "graphql-tag";

export const UpdateGoal = gql`
  mutation UpdateGoal(
    $id: String!
    $description: String
    $qualitativeIndicators: [CreateGoalIndicatorInput!]
    $qualitativeIndicatorsToRemove: [String!]
    $quantitativeIndicators: [CreateGoalIndicatorInput!]
    $quantitativeIndicatorsToRemove: [String!]
  ) {
    updateGoal(
      data: {
        id: $id
        description: $description
        qualitativeIndicators: $qualitativeIndicators
        qualitativeIndicatorsToRemove: $qualitativeIndicatorsToRemove
        quantitativeIndicators: $quantitativeIndicators
        quantitativeIndicatorsToRemove: $quantitativeIndicatorsToRemove
      }
    ) {
      id
      description
    }
  }
`;
