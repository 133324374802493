import gql from "graphql-tag";

export const CreateDraftRequest = gql`
  mutation CreateDraftRequest ($preRequestId: String!) {
    createDraftRequest(preRequestId: $preRequestId){
      id
      status
      draft
    }
  }
`;
