import React from "react";
import { Row, Col } from "antd";
import { withRouter, RouteComponentProps } from "react-router";

import { Content, Card } from "../../atoms";
import { RequestFooter } from "../../organisms";
import { NewRequestContextProps } from "../../NewRequestContext";
import { RequestArea, RequestType as Request } from "../../../types/Request";
import { requestTypesLetters, requestTypesList } from "../../../utils/maps/request";
import request_types_upv from "../../../request_types_upv.json";
import request_types_ufi from "../../../request_types_ufi.json";

const requestTypeUPV = "upv";
const requestTypeUFI = "ufi";

export class RequestType extends React.Component<NewRequestContextProps & RouteComponentProps> {
  public render() {
    const { state: { requestType } } = this.props;

    return (
      <Content style={{ marginLeft: 245 }}>
        <div style={{ padding: 40, marginTop: 74, marginBottom: 74 }}>
          <Row style={{ marginBottom: 40 }}>
            <h3 style={{ fontSize: 18, marginBottom: 20 }}>Selecciona el área de la solicitud</h3>
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  active={requestType.area === "UFI" ? true : false}
                  title="UFI"
                  info={request_types_ufi[requestTypeUFI]}
                  description="Unidad de fortalecimiento institucional"
                  onClick={() => this.onChangeArea("UFI")}
                />
              </Col>
              <Col span={6}>
                <Card
                  active={requestType.area === "UPV" ? true : false}
                  title="UPV"
                  info={request_types_upv[requestTypeUPV]}
                  description="Unidad de prevención de violencia"
                  onClick={() => this.onChangeArea("UPV")}
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <h3 style={{ fontSize: 18, marginBottom: 20 }}>
              Selecciona el tipo de solicitud
            </h3>
            <Row
              gutter={16}
              type="flex"
              justify="start"
              style={{ marginBottom: 20 }}>
              {this.renderRequestTypeCards()}
            </Row>
          </Row>
        </div>
        <RequestFooter
          nextProps={{
            onClick: this.handleOnClickNext,
            disabled: !requestType.area || !requestType.request
          }}
        />
      </Content>
    );
  }

  public onChangeArea = (area: RequestArea) => {
    const { state: { requestType }, methods } = this.props;
    const { setRequestType, setChecked } = methods;

    if (area === requestType.area) {
      return;
    }

    if (
      area === "UFI" &&
      (requestType.request === requestTypesLetters.C || requestType.request === requestTypesLetters.D)
    ) {
      this.setChecked(false);
      setRequestType({ area, request: undefined });
    } else {
      setRequestType({ area, request: requestType.request });
    }
  }

  public onChangeRequest = (request: Request) => {
    const { state: { requestType }, methods } = this.props;
    const { setRequestType } = methods;

    if (request === requestType.request) {
      return;
    }

    if (requestType.area === "UFI" && (request === requestTypesLetters.C || request === requestTypesLetters.D)) {
      this.setChecked(false);
      setRequestType({ area: requestType.area, request: undefined });
    } else {
      this.setChecked(true);
      setRequestType({ area: requestType.area, request });
    }
  }

  private renderRequestTypeCards = () => {
    const { state: { requestType } } = this.props;
    const cards = [];
    for (const letter in requestTypesList) {
      if (letter !== undefined) {
        cards.push(
          <Col span={6} style={{ marginBottom: "10px" }}>
            <Card
              key={letter}
              disabled={!requestType.area}
              active={(requestType.request === requestTypesList[letter])}
              title={`Tipo ${letter}`}
              description={requestTypesLetters[letter]}
              onClick={() => this.onChangeRequest(requestTypesList[letter])} />
          </Col>);
      }
    }
    return cards;
  }

  private setChecked = (value: boolean) =>
    this.props.methods.setChecked("requestType", value)

  private handleOnClickNext = () => {
    const { history } = this.props;
    return history.push("implementadora");
  }
}

export default withRouter(RequestType);
