import React from "react";
import styled from "styled-components";

import { ExpenseType } from "../../../../../types";
import { expenseTypes } from  "../../../../../utils/maps";
import { formatCurrency } from "../helpers";

const RightAlignedDiv = styled.div`
  text-align: right;
`;

export const generalSummaryTableColumns = [
  {
    key: "expenseType",
    title: "Tipo de gasto",
    dataIndex: "expenseType",
    render: (expenseType: ExpenseType) =>
      ({
        children:
          <div style={{ fontWeight: "bold" }}>
            {expenseTypes[expenseType]}
          </div>
      })
  },
  {
    title: "Total FICOSEC",
    dataIndex: "ficosecSubTotal",
    render: (ficosecSubTotal: number) => (
      <RightAlignedDiv>
        {formatCurrency(ficosecSubTotal)}
      </RightAlignedDiv>
    )
  },
  {
    title: "% FICOSEC",
    dataIndex: "ficosecSubTotalPct",
    render: (ficosecSubTotalPct: number) => (
      <RightAlignedDiv>
        {`${ficosecSubTotalPct.toFixed(2)} %`}
      </RightAlignedDiv>
    )
  },
  {
    title: "Total Co-inversión",
    dataIndex: "coInvestmentSubTotal",
    render: (coInvestmentSubTotal: number) => (
      <RightAlignedDiv>
        {formatCurrency(coInvestmentSubTotal)}
      </RightAlignedDiv>
    )
  },
  {
    title: "% Co-inversión",
    dataIndex: "coInvestmentSubTotalPct",
    render: (coInvestmentSubTotalPct: number) => (
      <RightAlignedDiv>
        {`${coInvestmentSubTotalPct.toFixed(2)} %`}
      </RightAlignedDiv>
    )
  },
  {
    title: "Total",
    dataIndex: "ficosecSubTotalPct",
    render: (ficosecSubTotalPct: number, supply: any) => {
      if (!supply.ficosecSubTotal || !supply.coInvestmentSubTotal) {
        return (
          <RightAlignedDiv>
            {formatCurrency(0)}
          </RightAlignedDiv>
          );
      }

      const total = supply.ficosecSubTotal + supply.coInvestmentSubTotal;
      return (
        <RightAlignedDiv>
          {formatCurrency(total)}
        </RightAlignedDiv>
      );
    }
  }
];
