import {
  SpecificObjective,
  ActivityBeneficiary,
  Agreement,
  Request
} from ".";
import { Attachment } from "./Attachment";

// @todo Verify the type of the fields with any type
export interface Project {
  id: string;
  assumptions: any[];
  beneficiariesProfile: any[];
  description: string;
  endDate: any;
  estimatedDuration: any;
  generalObjective: any;
  humanResources: any[];
  impactIndicator: null
  implementerProjects: []
  methodology: string;
  name: string;
  place: string;
  preventionLevel: PreventionLevel;
  preventionScope: PreventionScope;
  problem: null
  responsibleEmailAddress: string;
  responsibleName: string;
  risks: any[];
  specificObjectives: SpecificObjective[];
  startDate: any;
  substantiation: string;
  supplies: any[];
  sustainabilityArguments: any[];
  agreement?: Partial<Agreement>;
  request?: Partial<Request>;
  monitoringEntries: IProjectMonitoringEntry[];
  closed: boolean;
  closingDocument?: Attachment;
}

export interface IProjectMonitoringEntry {
  id: string;
  date: string;
  closed: boolean;
}

export type PreventionLevel =
  | "NA"
  | "PRIMARY"
  | "SECONDARY"
  | "TERTIARY";

export type PreventionScope =
  | "NA"
  | "PSYCHOSOCIAL"
  | "COMMUNITY"
  | "SOCIAL"
  | "SITUATIONAL";

// This interfaces are used by the Request Review components

export interface ActivityBeneficiaries {
  [beneficiaryId: string]: Partial<ActivityBeneficiary>;
}

export interface NormalizedBeneficiary {
  beneficiaries: Partial<ActivityBeneficiaries>[]
}

export type InvestorType =
  | "FICOSEC"
  | "COINVESTOR";

export const InvestorType: { [key in InvestorType]: InvestorType } = {
  FICOSEC: "FICOSEC",
  COINVESTOR: "COINVESTOR"
};
