import React from "react";
import { v4 as uuidv4 } from "uuid";
import { isEqual, setWith } from "lodash/fp";
import intersection from "lodash/intersection";
import { differenceInCalendarMonths } from "date-fns";
import { Query, QueryResult } from "react-apollo";
import { Modal } from "antd";
import { normalize } from "normalizr";
import {
  NewRequestContextState,
  RequestArea,
  RequestType,
  Activity,
  SpecificObjective,
  IMinistering,
  FinancialCapabilityProject,
  Risk,
  Attachment,
  IAssumption,
  HRProfile,
  Goal,
  Supply,
  SustainabilityArgument
} from "../types";
import { Request } from "../gql/queries";
import { project as projectSchema } from "../utils/normalizer";
import { LoadingScreen } from "./atoms";
import { Redirect } from "react-router-dom";

export interface MutationResult<T> {
  entity: Partial<T>;
  save: () => void;
}

export interface NewRequestMethods {
  setRequestType: (requestType: { area?: RequestArea, request?: RequestType }) => void;
  saveImplementerInput: (field: string, value: string) => void;
  addHRProfile: () => Partial<HRProfile>;
  removeHRProfile: (profileId: string) => void;
  saveHRProfileInput: (profileId: string, field: string, value: string) => void;
  addFinancialCapabilityProject: () => Partial<FinancialCapabilityProject>;
  removeFinancialCapabilityProject: (projectId: string) => void;
  saveFinancialCapabilityInput: (entityId: string, field: string, value?: string | number) => void;
  saveProjectInfoInput: (field: string, value: string) => void;
  saveGeneralObjectiveSummary: (value: string) => MutationResult<string>;
  saveSpecificObjective: (specificObjective: SpecificObjective) => void;
  saveGoalSummary: (goalId: string, data: any) => MutationResult<Goal>;
  saveActivityInput: (path: string, field: string, value: string) => void;
  saveSupplyInput: (supplyId: string, field: string, value?: string | number) => void;
  saveProjectMethodology: (methodology: string) => MutationResult<string>;
  saveProjectDuration: (projectDuration: string[]) => MutationResult<string[]>;
  saveImpactIndicator: (indicator: string) => MutationResult<string>;
  addSpecificObjective: (
    specificObjective: Omit<SpecificObjective, "id">,
    specificObjectiveId?: string
  ) => MutationResult<SpecificObjective>;
  removeSpecificObjective: (objectiveId: string) => MutationResult<SpecificObjective>;
  addGoal: (objectiveId: string, data: any) => MutationResult<Goal>;
  removeGoal: (goalId: string) => MutationResult<Goal>;
  addActivity: (goalId: string, data: Omit<Activity, "id" | "goalId" | "supplies">) => MutationResult<Activity>;
  saveActivity: (activityId: string, data: Omit<Activity, "id" | "goalId" | "supplies">) => MutationResult<Activity>;
  removeActivity: (activityId: string) => MutationResult<Activity>;
  toggleActivityMonth: (activityId: string, month: number) => MutationResult<Activity>;
  addSupply: () => Supply;
  saveSupplyBudget: (supplyId: string, month: number, budget: number) => void;
  removeSupply: (supplyId: string) => void;
  addSustainabilityArgument: () => MutationResult<SustainabilityArgument>;
  saveSustainabilityArgument: (argumentId: string, argument: string) => MutationResult<SustainabilityArgument>;
  removeSustainabilityArgument: (argumentId: string) => MutationResult<SustainabilityArgument>;
  addRisk: () => Partial<Risk>;
  saveRiskInput: (riskId: string, field: string, value: string | number) => void;
  removeRisk: (riskId: string) => void;
  addEmptyAssumption: () => Partial<IAssumption>;
  saveAssumptionInput: (assumptionId: string, field: string, value: any) => void;
  removeAssumption: (assumptionId: string) => void;
  setChecked: (step: string, value: boolean) => void;
  addMinistering: (ministering: IMinistering) => void;
  removeMinistering: (ministeringId: string) => void;
  addEquipmentBill: (supplyId: string) => void;
  checkFields: () => void;
}

export interface NewRequestContextOwnProps {
  state: NewRequestContextState;
  methods: NewRequestMethods;
}

export type NewRequestContextProps = NewRequestContextOwnProps;

export const NewRequestContext = React.createContext<Partial<NewRequestContextProps>>({});

export const NewRequestConsumer = NewRequestContext.Consumer;

export class NewRequestProvider extends React.PureComponent<{ requestId?: string }, NewRequestContextState> {
  public static initialState: NewRequestContextState = {
    requestType: {},
    implementer: {
      directorsBoardMembers: {
        0: {}
      },
    },
    HRProfilesToCreate: [],
    HRProfilesToUpdate: [],
    HRProfilesToDelete: [],
    HRProfiles: {},
    projectsToCreate: [],
    projectsToUpdate: [],
    projectsToDelete: [],
    financialCapability: {},
    projectInfo: {},
    projectDuration: [],
    generalObjective: {
      specificObjectives: []
    },
    specificObjectives: {},
    goals: {},
    activities: {},
    supplies: {},
    sustainabilityArguments: {},
    riskManagement: {},
    assumptionManagement: {},
    checked: {
      requestType: false,
      implementer: false,
      HRProfiles: false,
      financialCapability: false,
      projectInfo: false,
      projectFile: false,
      budget: false,
      riskManagement: false,
      assumptionManagement: false
    },
    ministerings: [],
    reviews: [],
    _original: {},
    status: "",
    draft: false,
    checkFields: false // This field is enabled when sending the request for review
  };

  public state = { ...NewRequestProvider.initialState };

  public componentDidUpdate(_: any, prevState: NewRequestContextState) {
    if (!isEqual(prevState.projectDuration, this.state.projectDuration)) {
      const { projectDuration, activities } = this.state;
      const monthsToRemove: number[] = [];
      const newActivities = { ...activities };

      const monthsSinceEpoch = {
        startDate: Math.abs(differenceInCalendarMonths(new Date(-1).setUTCMonth(0), parseInt(projectDuration[0] as string, 10))),
        endDate: Math.abs(differenceInCalendarMonths(new Date(-1).setUTCMonth(0), parseInt(projectDuration[1] as string, 10)))
      };

      const prevMonthsSinceEpoch = {
        startDate: Math.abs(differenceInCalendarMonths(new Date(-1).setUTCMonth(0), parseInt(prevState.projectDuration[0] as string, 10))),
        endDate: Math.abs(differenceInCalendarMonths(new Date(-1).setUTCMonth(0), parseInt(prevState.projectDuration[1] as string, 10)))
      };

      const startDateDiff = prevMonthsSinceEpoch.startDate - monthsSinceEpoch.startDate;
      const endDateDiff = prevMonthsSinceEpoch.endDate - monthsSinceEpoch.endDate;

      if (startDateDiff < 0) {
        const months = new Array(Math.abs(startDateDiff))
          .fill(0)
          .map((__, idx) => monthsSinceEpoch.startDate + startDateDiff + idx);
        monthsToRemove.push(...months);
      }

      if (endDateDiff > 0) {
        const months = new Array(Math.abs(endDateDiff))
          .fill(0)
          .map((__, idx) => monthsSinceEpoch.endDate + endDateDiff - idx);
        monthsToRemove.push(...months);
      }

      if (monthsToRemove.length > 0) {
        Object.keys(activities).map((activityId) => {
          const activity = activities[activityId];
          const monthsIntersection = intersection(activity.months!, monthsToRemove);

          if (monthsIntersection.length > 0) {
            const months = activity.months!.filter((month) => monthsIntersection.indexOf(month) < 0);
            newActivities[activityId] = {
              ...newActivities[activityId],
              months
            };
          }
        });
      }

      this.setState({ activities: newActivities });
    }
  }

  public render() {
    const { children, requestId = "" } = this.props;

    const value = {
      state: this.state,
      methods: {
        setRequestType: this.setRequestType,
        saveImplementerInput: this.saveImplementerInput,
        addHRProfile: this.addHRProfile,
        removeHRProfile: this.removeHRProfile,
        saveHRProfileInput: this.saveHRProfileInput,
        addFinancialCapabilityProject: this.addFinancialCapabilityProject,
        removeFinancialCapabilityProject: this.removeFinancialCapabilityProject,
        saveFinancialCapabilityInput: this.saveFinancialCapabilityInput,
        saveProjectInfoInput: this.saveProjectInfoInput,
        saveGeneralObjectiveSummary: this.saveGeneralObjectiveSummary,
        saveSpecificObjective: this.saveSpecificObjective,
        saveGoalSummary: this.saveGoalSummary,
        saveActivityInput: this.saveActivityInput,
        saveSupplyInput: this.saveSupplyInput,
        saveProjectMethodology: this.saveProjectMethodology,
        saveProjectDuration: this.saveProjectDuration,
        saveImpactIndicator: this.saveImpactIndicator,
        addSpecificObjective: this.addSpecificObjective,
        removeSpecificObjective: this.removeSpecificObjective,
        addGoal: this.addGoal,
        removeGoal: this.removeGoal,
        addActivity: this.addActivity,
        saveActivity: this.saveActivity,
        removeActivity: this.removeActivity,
        toggleActivityMonth: this.toggleActivityMonth,
        addSupply: this.addSupply,
        saveSupplyBudget: this.saveSupplyBudget,
        removeSupply: this.removeSupply,
        addSustainabilityArgument: this.addSustainabilityArgument,
        saveSustainabilityArgument: this.saveSustainabilityArgument,
        removeSustainabilityArgument: this.removeSustainabilityArgument,
        addRisk: this.addRisk,
        saveRiskInput: this.saveRiskInput,
        removeRisk: this.removeRisk,
        addEmptyAssumption: this.addEmptyAssumption,
        saveAssumptionInput: this.saveAssumptionInput,
        removeAssumption: this.removeAssumption,
        setChecked: this.setChecked,
        addMinistering: this.addMinistering,
        removeMinistering: this.removeMinistering,
        addEquipmentBill: this.addEquipmentBill,
        checkFields: this.checkFields
      }
    };

    return (
      <Query
        query={Request}
        variables={{ id: requestId }}
        onCompleted={(data: any) => this.mergeStates(data?.request)}
        fetchPolicy="network-only">
        {({ loading, error, data }: QueryResult) => {
          if (error) {
            // TODO: Report to bugsnag
            if (error.graphQLErrors.some((error: any) => error.message === "REQUEST_NOT_FOUND_FOR_USER")) {
              Modal.error({
                title: "No pudimos encontrar la solicitud.",
                okText: "Entendido",
                cancelButtonProps: { hidden: true }
              });
              return <Redirect to="/implementadora/solicitudes" />;
            }

            Modal.error({
              title: "No pudimos cargar la solicitud",
              content: "Ocurrió un problema al cargar la solicitud, puedes intentar recargando la página.",
              okText: "Recargar",
              onOk: () => location.reload(),
              cancelButtonProps: { hidden: true }
            });
          }

          if (loading) {
            return (
              <LoadingScreen
                tip="Cargando solicitud"
                size="large"
                spinning />
            );
          }

          return (
            <NewRequestContext.Provider value={value as any}>
              {children}
            </NewRequestContext.Provider>
          );
        }}
      </Query>
    );
  }

  private mergeStates = (request: any) => {
    const project = request?.project || {};
    const generalObjective = {
      specificObjectives: project?.specificObjectives
        ?.map((specificObjective: Partial<SpecificObjective>) => specificObjective.id),
      summary: project?.generalObjective
    };

    const { entities } = normalize(project, projectSchema);

    this.setState((state) => ({
      ...state,
      projectId: project.id,
      generalObjective,
      ...entities,
      projectInfo: {
        name: project.name,
        place: project.place,
        estimatedDuration: project.estimatedDuration,
        responsibleName: project.responsibleName,
        responsibleEmailAddress: project.responsibleEmailAddress,
        preventionLevel: project.preventionLevel,
        preventionScope: project.preventionScope,
        problem: project.problem,
        description: project.description,
        beneficiariesProfile: project.beneficiariesProfile,
        substantiation: project.substantiation
      },
      projectMethodology: project.methodology,
      projectDuration: [project.startDate, project.endDate],
      impactIndicator: project.impactIndicator,
      implementer: request?.preRequest?.implementer?.implementerProfile,
      revisions: request?.revisions,
      reviews: request?.reviews,
      _original: request,
      status: request.status,
      draft: request.draft
    }));
  }

  private setRequestType = ({ area, request }: { area?: RequestArea, request?: RequestType }) =>
    this.setState({ requestType: { area, request } })

  private saveImplementerInput = (field: string, value: string) => {
    if (field.indexOf("directive") > -1) {
      if (field !== "directorsBoardMembers") {
        const splitField = field.split(".");
        const actualField = splitField[0];
        const directiveId = splitField[1];

        this.setState((state) => ({
          implementer: {
            ...state.implementer,
            directorsBoardMembers: {
              ...state.implementer.directorsBoardMembers,
              [directiveId]: {
                ...((state.implementer.directorsBoardMembers || {})[directiveId] || {
                  directiveName: undefined,
                  directivePosition: undefined
                }),
                [actualField]: value
              }
            }
          }
        }));
      }
    }

    this.setState((state) => ({ implementer: { ...state.implementer, [field]: value } }));
  }

  private addHRProfile = (): Partial<HRProfile> => {
    const supplyId = uuidv4();
    const HRProfileId = uuidv4();

    const HRProfile = {
      id: HRProfileId,
      fullName: undefined,
      professionalExp: undefined,
      position: undefined,
      projectGoals: [],
      responsibilities: undefined,
      eduLevel: undefined,
      specialtyArea: undefined,
      workExp: undefined,
    };

    this.setState((state) => ({
      supplies: {
        ...state.supplies,
        [supplyId]: {
          id: supplyId,
          HRProfileId,
          name: "",
          count: 1,
          unitCost: 0,
          monthlyBudget: {},
          expenseType: "HR"
        }
      },
      HRProfilesToCreate: [...state.HRProfilesToCreate, HRProfileId],
      HRProfiles: {
        ...state.HRProfiles,
        [HRProfileId]: HRProfile
      }
    }));

    return HRProfile;
  }

  private removeHRProfile = (profileId: string) => {
    const { HRProfiles, supplies } = this.state;
    const { [profileId]: removed, ...newHRProfiles } = HRProfiles;
    const supplyIds = Object.keys(supplies);
    const newSupplies = {};

    supplyIds.map((supplyId) => {
      if (supplies[supplyId].HRProfileId !== profileId) {
        newSupplies[supplyId] = supplies[supplyId];
      }
    });

    this.setState((state) =>
      ({
        supplies: newSupplies,
        HRProfilesToDelete: [...state.HRProfilesToDelete, profileId],
        HRProfiles: newHRProfiles
      }));
  }

  private saveHRProfileInput = (profileId: string, field: string, value: string) => {
    if (this.state.HRProfilesToCreate.includes(profileId) || this.state.HRProfilesToUpdate.includes(profileId)) {
      this.setState((state) => ({
        HRProfiles: {
          ...state.HRProfiles,
          [profileId]: {
            ...state.HRProfiles[profileId],
            [field]: value
          }
        }
      }));
    } else {
      this.setState((state) => ({
        HRProfilesToUpdate: [...state.HRProfilesToUpdate, profileId],
        HRProfiles: {
          ...state.HRProfiles,
          [profileId]: {
            ...state.HRProfiles[profileId],
            [field]: value
          }
        }
      }));
    }
  }

  private addFinancialCapabilityProject = () => {
    const projectId = uuidv4();
    const project: Partial<FinancialCapabilityProject> = {
      id: projectId,
      financialInstitution: undefined,
      amount: undefined,
      pctFinanced: undefined,
      projectName: undefined,
      projectYear: undefined,
      budgetType: undefined,
      projectObjective: undefined
    };

    this.setState((state) => ({
      projectsToCreate: [...state.projectsToCreate, projectId],
      financialCapability: {
        ...state.financialCapability,
        [projectId]: project
      }
    }));

    return project;
  }

  private removeFinancialCapabilityProject = (projectId: string) => {
    const { [projectId]: removed, ...newFinancialCapabilityProjects } = this.state.financialCapability;
    this.setState((state) => ({
      projectsToDelete: [...state.projectsToDelete, projectId],
      financialCapability: newFinancialCapabilityProjects
    }));
  }

  private saveFinancialCapabilityInput = (
    entityId: string,
    field: string,
    value?: string | number
  ) => {
    if (this.state.projectsToCreate.includes(entityId) || this.state.projectsToUpdate.includes(entityId)) {
      this.setState((state) => ({
        financialCapability: {
          ...state.financialCapability,
          [entityId]: {
            ...state.financialCapability[entityId],
            [field]: value
          }
        }
      }));
    } else {
      this.setState((state) => ({
        projectsToUpdate: [...state.projectsToUpdate, entityId],
        financialCapability: {
          ...state.financialCapability,
          [entityId]: {
            ...state.financialCapability[entityId],
            [field]: value
          }
        }
      }));
    }
  }

  private saveProjectInfoInput = (field: string, value: string) =>
    this.setState((state) => ({ projectInfo: { ...state.projectInfo, [field]: value } }))

  private saveGeneralObjectiveSummary = (value: string) => ({
    entity: value,
    save: () =>
      this.setState((state) => ({ generalObjective: { ...state.generalObjective, summary: value } }))
  })

  private saveGoalSummary = (goalId: string, data: any) => {
    const { [goalId]: goalToUpdate } = this.state.goals;
    const qualitativeIndicators = Object.keys(data.qualitativeIndicators).map(indicatorId => data.qualitativeIndicators[indicatorId]);
    const quantitativeIndicators = Object.keys(data.quantitativeIndicators).map(indicatorId => data.quantitativeIndicators[indicatorId]);

    const goal = {
      ...goalToUpdate,
      description: data.description,
      qualitativeIndicators,
      quantitativeIndicators,
    };

    return {
      entity: goal,
      save: () =>
        this.setState((state) => ({
          goals: {
            ...state.goals as any,
            [goalId]: goal
          }
        }))
    };
  }

  private saveActivityInput = (activityId: string, field: string, value: string) =>
    this.setState((state) => ({
      activities: {
        ...state.activities,
        [activityId]: {
          ...(state.activities[activityId] || {} as any),
          [field]: value
        }
      }
    }))

  private saveSupplyInput = (supplyId: string, field: string, value?: string | number | Attachment[]) => {
    const original = this.state.supplies[supplyId];
    const newSupply = setWith(Object, field, value)(original);

    this.setState((state) => ({
      supplies: {
        ...state.supplies,
        [supplyId]: newSupply
      }
    }));
  }

  private saveProjectMethodology = (methodology: string) => ({
    entity: methodology,
    save: () =>
      this.setState({ projectMethodology: methodology })
  })

  private saveProjectDuration = (projectDuration: string[]) => ({
    entity: projectDuration,
    save: () => this.setState({ projectDuration })
  })

  private saveImpactIndicator = (indicator: string) => ({
    entity: indicator,
    save: () => this.setState({ impactIndicator: indicator })
  })

  private saveSpecificObjective = (specificObjective: SpecificObjective) => {
    const { id, goals, deliverable, summary } = specificObjective;
    if (id !== undefined) {
      const { specificObjectives } = this.state;
      const alreadySavedObjective = specificObjectives[id] !== undefined ?
        specificObjectives[id] : {};

      this.setState((state) => ({
        specificObjectives: {
          ...state.specificObjectives,
          [specificObjective.id as string]: {
            ...alreadySavedObjective,
            id,
            summary,
            deliverable,
            goals
          }
        }
      }));
    }
  }

  private addSpecificObjective = (
    specificObjective: SpecificObjective,
    specificObjectiveId?: string
  ): MutationResult<SpecificObjective> => {
    if (specificObjectiveId !== undefined) {
      specificObjective.id = specificObjectiveId;
      return {
        entity: specificObjective,
        save: () => this.saveSpecificObjective(specificObjective as SpecificObjective)
      };
    }

    const id = uuidv4();
    const { deliverable } = specificObjective;
    const newSpecificObjective = {
      id,
      summary: specificObjective.summary,
      deliverableDescription: deliverable?.description,
      deliverablePeriodicity: deliverable?.periodicity,
      deliverableTitle: deliverable?.title,
      description: specificObjective.summary,
      deliverable: {
        id: uuidv4(),
        title: deliverable?.title,
        description: deliverable?.description,
        periodicity: deliverable?.periodicity
      },
      goals: []
    };

    return {
      entity: newSpecificObjective,
      save: () => {
        this.setState((state) => ({
          generalObjective: {
            ...state.generalObjective,
            specificObjectives: [...state.generalObjective.specificObjectives!, id]
          },
          specificObjectives: {
            ...state.specificObjectives,
            [id]: newSpecificObjective
          }
        }));
      }
    };
  }

  private removeSpecificObjective = (objectiveId: string) => {
    const { generalObjective, specificObjectives, goals, activities, supplies } = this.state;
    const { [objectiveId]: removed, ...newSpecificObjectives } = specificObjectives;

    const newSpecificObjectiveIds =
      generalObjective
        .specificObjectives!
        .filter((specialObjectiveId) => specialObjectiveId !== objectiveId);

    // Filter out goal to remove.
    const goalValues = Object.values(goals);
    const childrenGoals = goalValues.filter((goal) => goal.specificObjectiveId !== objectiveId);
    const goalIds = childrenGoals.map((goal) => goal.id);

    // Build an object with new goals
    const newGoals = {};
    childrenGoals.map((goal) => goal && (newGoals[goal.id!] = goal));

    // Filter out activities that aren't in the goals that aren't removed
    const activityValues = Object.values(activities);
    const childrenActivities = activityValues.filter((activity) => goalIds.includes(activity.goalId));
    const activityIds = childrenActivities.map((activity) => activity.id);

    // Build an object with new activities
    const newActivities = {};
    childrenActivities.map((activity) => activity && (newActivities[activity.id!] = activity));

    // Remove supplies
    const supplyValues = Object.values(supplies);
    const childrenSupplies = supplyValues.filter((supply) => activityIds.includes(supply.activityId));

    // Build an object with new supplies
    const newSupplies = {};
    childrenSupplies.map((supply) => supply && (newSupplies[supply.id!] = supply));

    return {
      entity: removed,
      save: () => {
        this.setState((state) => ({
          ...state,
          generalObjective: {
            ...state.generalObjective,
            specificObjectives: newSpecificObjectiveIds
          },
          specificObjectives: newSpecificObjectives,
          goals: newGoals,
          activities: newActivities,
          supplies: newSupplies
        }));
      }
    };
  }

  private addGoal = (objectiveId: string, data: any) => {
    const id = uuidv4();
    const qualitativeIndicators = Object.keys(data.qualitativeIndicators).map(indicatorId => ({
      id: indicatorId, ...data.qualitativeIndicators[indicatorId]
    }));

    const quantitativeIndicators = Object.keys(data.quantitativeIndicators).map(indicatorId => ({
      id: indicatorId, ...data.quantitativeIndicators[indicatorId]
    }));

    const goal = {
      id,
      specificObjectiveId: objectiveId,
      description: data.description,
      qualitativeIndicators,
      quantitativeIndicators,
      activities: []
    };

    return {
      entity: goal,
      save: () =>
        this.setState((state) => ({
          specificObjectives: {
            ...state.specificObjectives,
            [objectiveId]: {
              ...(state.specificObjectives[objectiveId] || { goals: [] }),
              goals: [...(state.specificObjectives[objectiveId] || { goals: [] }).goals!, id]
            }
          },
          goals: {
            ...state.goals as any,
            [id]: goal
          }
        }))
    }
  }

  private removeGoal = (goalId: string) => {
    const { specificObjectives, goals, activities, HRProfiles, supplies } = this.state;
    const { [goalId]: removedGoal, ...newGoals } = goals;

    // Filter out goal to remove from parent objective.
    const objectiveValues = Object.values(specificObjectives);
    const parentObjective = objectiveValues.findIndex((objective) => objective.id === removedGoal.specificObjectiveId);
    const newParentObjectiveGoals = objectiveValues[parentObjective].goals!.filter((goal) => goal !== goalId);

    const newHRProfiles = { ...HRProfiles };
    Object.keys(newHRProfiles).map((profileId) => {
      newHRProfiles[profileId].projectGoals =
        newHRProfiles[profileId].projectGoals?.filter((goal) => goal !== goalId) || [];
    });

    const newSpecificObjectives = {};
    objectiveValues.map((objective) => newSpecificObjectives[objective.id!] = objective);
    newSpecificObjectives[objectiveValues[parentObjective].id!] = {
      ...newSpecificObjectives[objectiveValues[parentObjective].id!],
      goals: newParentObjectiveGoals
    };

    // Filter out activities that aren't in the goals that aren't removed
    const activityValues = Object.values(activities);
    const childrenActivities =
      activityValues.filter((activity) => activity && activity.goalId !== goalId);
    const activityIds = childrenActivities.map((activity) => activity.id);

    // Build an object with new activities
    const newActivities = {};
    childrenActivities.map((activity) => activity && (newActivities[activity.id!] = activity));

    // Remove supplies
    const supplyValues = Object.values(supplies);
    const childrenSupplies = supplyValues.filter((supply) => activityIds.includes(supply.activityId));

    // Build an object with new supplies
    const newSupplies = {};
    childrenSupplies.map((supply) => supply && (newSupplies[supply.id!] = supply));

    return {
      entity: removedGoal,
      save: () =>
        this.setState((state) => ({
          ...state,
          HRProfiles: newHRProfiles,
          specificObjectives: newSpecificObjectives,
          goals: newGoals,
          activities: newActivities,
          supplies: newSupplies
        }))
    };
  }

  private addActivity = (goalId: string, data: Omit<Activity, "id" | "goalId" | "supplies">) => {
    const id = uuidv4();

    const beneficiaries =  Object.keys(data.beneficiaries).map(beneficiaryId => ({
      id: beneficiaryId, ...data.beneficiaries[beneficiaryId]
    }));

    const activity = {
      id,
      goalId,
      beneficiaries,
      description: data.description,
      months: data.months
    };

    return {
      entity: activity,
      save: () =>
        this.setState((state) => ({
          goals: {
            ...state.goals,
            [goalId]: {
              ...(state.goals[goalId] || { activities: [] }),
              activities: [...(state.goals[goalId] || { activities: [] }).activities!, id]
            }
          },
          activities: {
            ...state.activities as any,
            [id]: activity
          }
        }))
    };
  }

  private saveActivity = (activityId: string, data: Omit<Activity, "id" | "goalId" | "supplies">) => {
    const { activities } = this.state;
    const beneficiaries = Object.keys(data.beneficiaries).map(beneficiaryId => data.beneficiaries[beneficiaryId]);

    const activity = {
      ...activities[activityId],
      beneficiaries,
      description: data.description,
      months: data.months
    };

    return {
      entity: activity,
      save: () =>
        this.setState((state) => ({
          activities: {
            ...state.activities,
            [activityId]: activity as any
          }
        }))
    }
  }

  private removeActivity = (activityId: string) => {
    const { goals, activities, supplies } = this.state;
    const { [activityId]: removedActivity, ...newActivities } = activities;

    // Filter out goal to remove from parent objective.
    const goalValues = Object.values(goals);
    const parentGoal = goalValues.findIndex((goal) => goal.id === removedActivity.goalId);
    const newParentActivities = goalValues[parentGoal].activities!.filter((activity) => activity !== activityId);

    const newGoals = {};
    goalValues.map((goal) => newGoals[goal.id!] = goal);
    newGoals[goalValues[parentGoal].id!] = {
      ...newGoals[goalValues[parentGoal].id!],
      activities: newParentActivities
    };

    // Remove supplies
    const supplyValues = Object.values(supplies);
    const childrenSupplies = supplyValues.filter((supply) => supply.activityId !== activityId);

    // Build an object with new supplies
    const newSupplies = {};
    childrenSupplies.map((supply) => supply && (newSupplies[supply.id!] = supply));

    return {
      entity: removedActivity,
      save: () =>
        this.setState((state) => ({
          ...state,
          goals: newGoals,
          activities: newActivities,
          supplies: newSupplies
        }))
    };
  }

  private toggleActivityMonth = (activityId: string, monthToToggle: number) => {
    const { activities } = this.state;
    const activity = { ...activities[activityId] };

    if (activity.months!.includes(monthToToggle)) {
      activity.months = activity.months!.filter((month) => month !== monthToToggle);
    } else {
      activity.months = [...activity.months!, monthToToggle];
    }

    return {
      entity: activity,
      save: () =>
        this.setState((state) => ({
          ...state,
          activities: {
            ...state.activities,
            [activityId]: {
              ...state.activities[activityId],
              ...activity
            }
          }
        }))
    };
  }

  private addSupply = () => {
    const id = uuidv4();
    const supply: Supply = {
      id,
      name: undefined,
      expenseType: undefined,
      isVerifiable: false,
      measurementUnit: undefined,
      count: 1,
      unitCost: 0,
      monthlyBudget: {}
    };

    this.setState((state) => ({
      supplies: {
        ...state.supplies,
        [id]: supply
      }
    }));

    return supply;
  }

  private saveSupplyBudget = (supplyId: string, month: number, budget: number) => null
  // this.setState((state) => ({
  //   supplies: {
  //     ...state.supplies,
  //     [supplyId]: {
  //       ...state.supplies[supplyId],
  //       monthlyBudget: {
  //         ...state.supplies[supplyId].monthlyBudget,
  //         [month]: budget > 0 ? budget : 0
  //       }
  //     }
  //   }
  // }))

  private removeSupply = (supplyId: string) => {
    const { activities, supplies } = this.state;
    const { [supplyId]: removedSupply, ...newSupplies } = supplies;

    // Filter out supply to remove from parent activity.
    if (removedSupply.activityId) {
      const activityValues = Object.values(activities);
      const parentActivity = activityValues.findIndex((activity) => activity.id === removedSupply.activityId);
      const newParentSupplies = activityValues[parentActivity].supplies?.filter((supply) => supply !== supplyId);

      const newActivities = {};
      activityValues.map((activity) => newActivities[activity.id!] = activity);
      newActivities[activityValues[parentActivity].id!] = {
        ...newActivities[activityValues[parentActivity].id!],
        supplies: newParentSupplies
      };

      this.setState((state) => ({
        ...state,
        activities: newActivities,
        supplies: newSupplies
      }));
    } else {
      this.setState((state) => ({
        ...state,
        supplies: newSupplies
      }));
    }
  }

  private addEquipmentBill = (supplyId: string) => {
    // @todo Load the selected supply to the state
  }

  private addSustainabilityArgument = () => {
    const id = uuidv4();
    const argument = {
      id,
      argument: undefined
    };

    return {
      entity: argument,
      save: () =>
        this.setState((state) => ({
          sustainabilityArguments: {
            ...state.sustainabilityArguments,
            [id]: argument
          }
        }))
    };
  }

  private saveSustainabilityArgument = (argumentId: string, argument: string) => {
    const entity = {
      id: argumentId,
      argument
    };

    return {
      entity,
      save: () =>
        this.setState((state) => ({
          sustainabilityArguments: {
            ...state.sustainabilityArguments,
            [argumentId]: {
              ...(state.sustainabilityArguments[argumentId] || {}),
              argument
            }
          }
        }))
    };
  }

  private removeSustainabilityArgument = (argumentId: string) => {
    const { [argumentId]: removed, ...sustainabilityArguments } = this.state.sustainabilityArguments;

    return {
      entity: removed,
      save: () =>
        this.setState({ sustainabilityArguments })
    };
  }

  private addRisk = () => {
    const id = uuidv4();
    const risk = {
      id,
      identification: undefined,
      probability: undefined,
      impact: undefined,
      mitigationStrategy: undefined
    };

    this.setState((state) => ({
      riskManagement: {
        ...state.riskManagement,
        [id]: risk
      }
    }));

    return risk;
  }

  private saveRiskInput = (riskId: string, field: string, value: string | number) =>
    this.setState((state) => ({
      riskManagement: {
        ...state.riskManagement,
        [riskId]: {
          ...(state.riskManagement[riskId] || {}),
          [field]: value
        }
      }
    }))

  private removeRisk = (riskId: string) => {
    const { riskManagement } = this.state;
    const { [riskId]: removed, ...newState } = riskManagement;
    this.setState({ riskManagement: newState });
  }

  private addEmptyAssumption = () => {
    const id = uuidv4();
    const assumption = {
      id,
      identification: undefined,
      impact: undefined,
      actions: undefined
    };

    this.setState((state) => ({
      assumptionManagement: {
        ...state.assumptionManagement,
        [id]: assumption
      }
    }));

    return assumption;
  }

  private saveAssumptionInput = (assumptionId: string, field: string, value: any) =>
    this.setState((state) => ({
      assumptionManagement: {
        ...state.assumptionManagement,
        [assumptionId]: {
          ...(state.assumptionManagement[assumptionId] || {}),
          [field]: value
        }
      }
    }))

  private removeAssumption = (assumptionId: string) => {
    const { assumptionManagement } = this.state;
    const { [assumptionId]: removed, ...newState } = assumptionManagement;
    this.setState({ assumptionManagement: newState });
  }

  private setChecked = (step: string, value: boolean) =>
    this.setState((state) => ({ checked: { ...state.checked, [step]: value } }))

  private addMinistering = (ministering: IMinistering) => {
    const { ministerings = [] } = this.state;
    const ministeringsUpdated = Array.from(ministerings);
    ministeringsUpdated.push(ministering);
    this.setState({ ministerings: ministeringsUpdated });
  }

  private removeMinistering = (ministeringId: string) => {
    const { ministerings = [] } = this.state;
    const updatedMinistering = ministerings
      .filter((m) => m.id !== ministeringId);
    this.setState({ ministerings: updatedMinistering });
  }

  private checkFields = () => {
    this.setState((state) => ({
      ...state,
      checkFields: true
    }))
  }
}
