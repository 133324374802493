import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";

import { ImplementerPreRequests } from "../../../../gql/queries";
import { PreRequestsTemplate } from "../../../templates/implementer-onboarding";
import { RoleConsumer } from "../../../RoleConsumer";
import { ExtraButtons } from "../RequestsList/components/ExtraButtons";
import Layout, { PageHeader, Content } from "../../../organisms/Layout";
import { client } from "../../../../gql/apollo";
import { IPaginationSignature, sortDirectionMap } from "../../requests/ReviewalPage";
import { PaginationConfig, SorterResult } from "antd/lib/table";

export default () => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { data, error, loading, refetch } = useQuery(ImplementerPreRequests, { client } as any);

  if (error) {
    return <p>Error....</p>;
    // @todo report to bugsnag
  }

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);


  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<Record<"id", string[]>>,
    sorter: SorterResult<{ id: string }>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <RoleConsumer>
      {({ claims }) => {
        return (
          <Layout
            noPadding
            extra={<ExtraButtons />}>
            <PageHeader
              ghost={false}
              title="Pre-solicitudes" />
            <Content>
              <PreRequestsTemplate
                loading={loading}
                onPaginationChange={handleOnPaginationChange}
                preRequests={data?.implementerPreRequests} />
            </Content>
          </Layout>
        );
      }}
    </RoleConsumer>
  );
}
