import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";

const HeadingCol = styled(Col)`
  padding: 10px;
  padding-left: 0px;
  font-weight: bold;
`;

export const TableHeader = (props: any) => {
  return (
    <Row style={{ minWidth: "1600px" }}>
      <HeadingCol span={4}>
        Metas
      </HeadingCol>
      <HeadingCol span={6}>
        Indicadores cualitativos
      </HeadingCol>
      <HeadingCol span={6}>
        Indicadores cuantitativos
      </HeadingCol>
      <HeadingCol span={4}>
        Actividades
      </HeadingCol>
      <HeadingCol span={4}>
        Beneficiarios
      </HeadingCol>
    </Row>
  );
};
