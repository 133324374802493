import React, { useContext } from "react";
import { Row, Col, Skeleton } from "antd";

import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import {
  PersonTypeInput,
  RFCInput,
  BusinessNameInput,
  SocialBehestInput
} from "./inputs";;


export const FiscalDataSection = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const { implementerProfile, isLoadingData } = implementerAccountContext;

  if (isLoadingData) {
    return <Skeleton loading />;
  }

  if (implementerProfile.type === "GOVERNMENT") {
    return null;
  }

  return (
    <FormSection title="DATOS FISCALES">
      <Row gutter={16}>
        <Col span={12}>
          <PersonTypeInput />
        </Col>
        <Col span={12}>
          <RFCInput />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <BusinessNameInput />
        </Col>
        <Col span={12}>
          <SocialBehestInput />
        </Col>
      </Row>
    </FormSection>
  );
};
