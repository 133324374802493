import React from "react";
import bugsnag from "@bugsnag/browser";
import bugsnagReact from "@bugsnag/plugin-react";

export const bugsnagClient = bugsnag({
  apiKey: process.env.BUGSNAG_API_KEY || "DEV",
  appVersion: "1.0.0", // @todo recover from the env file
  appType: "web_client", // @todo recover from the env fie
  beforeSend: (report) => {
    report.app.releaseStage = "development"; // @todo recover from the env file
  }
});

bugsnagClient.use(bugsnagReact, React);

export const ErrorBoundary = bugsnagClient.getPlugin("react");
