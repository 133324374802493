import gql from "graphql-tag";

export const CreateSpecificObjective = gql`
  mutation CreateSpecificObjective(
    $projectId: String!
    $id: String!
    $description: String!
    $deliverableTitle: String!
    $deliverableDescription: String!
    $deliverablePeriodicity: DeliverablePeriodicity!
  ) {
    createSpecificObjective(
      projectId: $projectId
      data: {
        id: $id
        description: $description
        deliverableTitle: $deliverableTitle
        deliverableDescription: $deliverableDescription
        deliverablePeriodicity: $deliverablePeriodicity
      }
    ) {
      id
      description
      deliverableTitle
      deliverableDescription
      deliverablePeriodicity
    }
  }
`;
