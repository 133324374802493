import React from "react";
import { Table as AntTable, Button } from "antd";
import styled from "styled-components";
import styles from "./styles.less";
import { IAssumption } from "../../../../types";
import { getColumns } from "./helpers";
import { RequestReviewConsumer } from "../../../RequestReviewContext";

const Table = styled(AntTable)``;

interface IAssumptionsTableProps {
  disabled: boolean;
  reviewable: boolean;
  approval: boolean;
  assumptions: Array<Partial<IAssumption>>;
  onChange: (assumptionId: string, field: string, value: any) => void;
  onRemoveAssumption: (assumptionId: string) => void;
  onAddAssumption: any;
  checkFields: boolean;
  reviews: any;
}

export const AssumptionsTable: React.FC<IAssumptionsTableProps> = (props) => {
  const { assumptions = [], checkFields, reviews } = props;
  const dataSource = assumptions
    .map((assumption) => ({ key: assumption?.id, ...assumption }));

  return (
    <>
      <RequestReviewConsumer>
        {({ reviewState }) => (
          <Table
            className={styles.table}
            pagination={false}
            columns={getColumns({
              checkFields,
              reviewState,
              onChange: props.onChange,
              disabled: props.disabled,
              reviewable: props.reviewable,
              approval: props.approval,
              onRemoveAssumption: props.onRemoveAssumption,
              reviews: reviews
            }) as any[]}
            dataSource={dataSource}
            bordered />
        )}
      </RequestReviewConsumer>
      {!(props.reviewable || props.approval) ? (
        <Button
          disabled={props.disabled}
          shape="round"
          icon="plus"
          onClick={props.onAddAssumption}>
          Agregar supuesto
        </Button>
      ) : null}
    </>
  );
};
