import React from "react";
import { Layout, Menu, Icon, Avatar, Dropdown } from "antd";
import styled from "styled-components";
import { LayoutProps } from "antd/lib/layout";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

const { Header } = Layout;
const ficosecLogo = require("../../../assets/ficosec_logo.svg");

const StyledLogoContainer = styled.div`
  height: 100%,
  display: flex;
  align-items: center;
  margin-right: 30pt;
`;

const StyledContainer = styled.div`
  display: inline-block;
  float: left;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  margin: 0pt;
`;

const StyledMenu = styled(Menu)`
  line-height: 63px;
  border: none;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
`;

const StyledHeader = styled(Header)`
  background-color: #fff;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
`;

const StyledAvatarContainer = styled.div`
  float: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const imageStyle = {
  width: "120px",
  height: "31px",
};

interface ICustomHeaderOwnProps {
  simple?: boolean;
  extra?: React.ReactNode;
  activeMenu?: string;
}

type ICustomHeaderProps =
  & ICustomHeaderOwnProps
  & RouteComponentProps
  & LayoutProps;

const menu = (
  <Menu>
    <Menu.Item key="1">
      <Icon type="user" />
      Perfil
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="logout" />
      Cerrar sesión
    </Menu.Item>
  </Menu>
);

class CustomHeader extends React.Component<ICustomHeaderProps> {
  public render() {
    const { location } = this.props;
    const { pathname = "" } = location;
    const urlSection = pathname.split("/").length > 2 ?
      pathname.split("/")[2] : "";

    const sectionsMap = {
      "presolicitudes": "prerequests",
      "solicitudes": "requests",
      "proyectos": "projects",
      "mesas-de-trabajo": "worktables"
    };

    return (
      <>
        <StyledHeader>
          <StyledContainer>
            <StyledLogoContainer>
              <img src={ficosecLogo} style={imageStyle} />
            </StyledLogoContainer>
          </StyledContainer>
          <StyledMenu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[sectionsMap[urlSection]]}>
            <Menu.Item key="prerequests">
              <Link to="/presolicitudes">
                Pre-solicitudes
              </Link>
            </Menu.Item>
            <Menu.Item key="requests">
              <Link to="/solicitudes">
                Solicitudes
              </Link>
            </Menu.Item>
            <Menu.Item key="projects">
              <Link to="/proyectos">
                Proyectos
              </Link>
            </Menu.Item>
            <Menu.Item key="worktables">
              <Link to="/mesas-de-trabajo">
                Mesas de trabajo
              </Link>
            </Menu.Item>
            <StyledAvatarContainer>
              {this.props.extra || null}
              <a style={{ marginLeft: "30pt" }}>
                <Dropdown overlay={menu}>
                  <Avatar src="https://picsum.photos/200" />
                </Dropdown>
              </a>
            </StyledAvatarContainer>
          </StyledMenu>
        </StyledHeader>
     </>
    );
  }
}

export default withRouter(CustomHeader);
