import React from "react";
import { Card, Typography, Row, Col, Button } from "antd";

import { ImplementerZone } from "../../pages/ImplementerOnboardingPage";

interface ZoneTemplateProps {
  state: ImplementerZone;
  onZoneSelect: (zone: ImplementerZone) => void;
  onNextStep: (currentStep: number) => void;
}

interface CardProps {
  active?: boolean;
  title: string;
  description: string;
  onClick: () => void;
}

class ZoneTemplate extends React.Component<ZoneTemplateProps> {
  public render() {
    const { state, onZoneSelect, onNextStep } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          top: 100,
          left: "50%",
          transform: "translateX(-50%)"
        }}
      >
        <Card style={{ textAlign: "center", padding: "0px 20px", marginBottom: 100, width: 550 }}>
          <div style={{ marginBottom: 25 }}>
            <Typography.Title level={3}>Zona de implementación</Typography.Title>
          </div>
          <Row style={{ marginBottom: 25 }} gutter={20} type="flex">
            <Col span={12}>
              <this.Card
                active={state === "NORTH"}
                title="Zona norte"
                description="Proyectos ejecutados en: Ciudad Juarez y Nuevo Casas Grandes"
                onClick={() => onZoneSelect("NORTH")}
              />
            </Col>
            <Col span={12}>
              <this.Card
                active={state === "CENTER"}
                title="Zona centro sur"
                description="Proyectos ejecutados en: Chihuahua, Cuauhtémoc, Delicias, Parral"
                onClick={() => onZoneSelect("CENTER")}
              />
            </Col>
          </Row>
          <Button
            style={{ backgroundColor: "#8e1d1d", borderColor: "#8e1d1d" }}
            shape="round"
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => alert("to be implemented")}
          >
            Guardar
          </Button>
        </Card>
      </div>
    );
  }

  private Card: React.FC<CardProps> = ({ active = false, title, description, ...props }) => {
    const regularStyle: React.CSSProperties = {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 20,
      height: "100%",
      textAlign: "center",
      color: "#646464",
      border: "1px solid #646464",
      borderRadius: 4
    };

    const activeStyle: React.CSSProperties = {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 20,
      height: "100%",
      textAlign: "center",
      color: "#8e1d1d",
      border: "1px solid #8e1d1d",
      borderRadius: 4
    };

    const icon = active
      ? require("../../../assets/zone_icon_active.svg")
      : require("../../../assets/zone_icon_inactive.svg");

    return (
      <div style={active ? activeStyle : regularStyle} {...props}>
        <div>
          <img src={icon} />
        </div>
        <div style={{ fontSize: 20 }}>{title}</div>
        <div>{description}</div>
      </div>
    );
  }
}

export default ZoneTemplate;
