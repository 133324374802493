import React from "react";
import { Query } from "react-apollo";
import { RouteComponentProps } from "react-router";

import { WorkTableTemplate } from "../../templates/workTables";
import { WorkTable } from "../../../gql/queries";
import { ErrorTemplate } from "../../templates";

type RequestReviewPageProps = Pick<RouteComponentProps<{ id: string }>, "match">;

interface IQueryResult {
  loading: boolean;
  data: any;
  error?: any;
  refetch: any;
}

class WorkTablePage extends React.Component<RequestReviewPageProps> {
  public render() {
    const { match } = this.props;
    return (
      <Query
        query={WorkTable}
        variables={{
          id: match.params.id
          }}>
        {({ loading, data, error, refetch }: IQueryResult) => {
          if (error) {
            // TODO: report to bugsnag
            return (
              <ErrorTemplate
                message="Hubo un problema al cargar la mesa de trabajo." />
              );
          }

          return (
            <WorkTableTemplate
              loading={loading}
              data={data.worktable}
              refetch={refetch} />
            );
        }}
      </Query>
    );
  }
}

export default WorkTablePage;
