import React from "react";
import { Layout } from "antd";
import { LayoutProps } from "antd/lib/layout";

import CustomHeader from "./CustomHeader";
import { CustomContent } from "./CustomContent";

interface IUserOwnLayoutProps {
  extra?: React.ReactNode;
}

type ImplementerLayoutProps =
  & IUserOwnLayoutProps
  & LayoutProps;

const UserLayout: React.FC<ImplementerLayoutProps> = (props) => {
  return (
    <Layout style={{ height: "100%" }} {...props}>
      <CustomHeader extra={props.extra} />
      <CustomContent>
        {props.children}
      </CustomContent>
    </Layout>
  );
};

export * from "./SectionCard";
export * from "./CustomPageHeader";
export default UserLayout;
