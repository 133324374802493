import React from "react";
import { Footer } from "../../../../atoms";
import { Button } from "antd";

interface RequestFooterProps {
  onClickNext: () => void;
  onClickCancel: () => void;
  nextDisabled?: boolean;
  lastStep?: boolean;
}

const RequestFooter: React.FC<RequestFooterProps> =
  ({ onClickNext, onClickCancel, nextDisabled, lastStep = false }) =>
    <Footer>
      <Button
        onClick={onClickNext}
        shape="round"
        type="primary"
        disabled={nextDisabled}>
        {lastStep ? "Iniciar reunión" : "Siguiente"}
      </Button>
      <Button onClick={onClickCancel} type="link">Cancelar</Button>
    </Footer>;

export default RequestFooter;
