import React from "react";
import { Form, InputNumber, Button, message, Spin, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { BeneficiaryRow } from "./BeneficiaryRow";
import { Activity, ActivityIndicatorType, ActivityBeneficiary, ActivityIndicator } from "../../../../../types";
import { IndicatorRow } from "./IndicatorRow";
import { TechnicalMonitoringEntry } from "../../../../../gql/queries";
import { client } from "../../../../../gql/apollo";

interface IRawActivityFormOwnProps {
  busy: boolean;
  activity?: Activity;

  onSubmit: (form: WrappedFormUtils<any>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type RawActivityFormProps = IRawActivityFormOwnProps & FormComponentProps;

interface IColumnElementProps {
  title?: string;
}

interface IActivityBeneficiaryMonitoringEntry {
  id: string;
  activityBeneficiary: ActivityBeneficiary;
  obtained: number;
}

interface IIndicatorRow {
  id: string;
}

const ButtonContainer = styled.div`
  padding-top: 10px;
`;

const SectionContainer = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px 0px;

  &:last-child {
    border-bottom: none;
  }
`;

const SectionTitle = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
`;

export const ColumnContainer = styled.div`
  display: flex;
`; ``

export const Column = styled.div`
  flex: 1;
  margin: 0px 5px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

export const ColumnElement: React.FC<IColumnElementProps> = ({ title, children }) =>
  <Column>
    {title && <div>{title}:</div>}
    <div>{children}</div>
  </Column>

const RawActivityForm: React.FC<RawActivityFormProps> = ({
  busy,
  activity,
  form,
  onSubmit,
  onCancel
}) => {
  const { monitoringEntry } = useParams();
  const { loading, data, error } = useQuery(TechnicalMonitoringEntry, {
    variables: {
      activityId: activity?.id,
      monitoringEntryId: monitoringEntry
    },
    client
  } as any);

  if (error) {
    // TODO: Report to bugsnag.
    console.error(error);
    message.error("Ocurrió un problema al cargar los datos.");
    onCancel?.(null as any);
  }

  if (loading) {
    return <Spin spinning />;
  }

  const quantitativeIndicators = Object.values(activity?.quantitativeIndicators || {});
  const qualitativeIndicators = Object.values(activity?.qualitativeIndicators || {});

  const getBeneficiaryRowData = (beneficiary: ActivityBeneficiary) =>
    (data?.activityBeneficiaryMonitoringEntries as IActivityBeneficiaryMonitoringEntry[])?.find((entry) =>
      entry.activityBeneficiary.id === beneficiary.id);

  const getIndicatorRowData = <T extends ActivityIndicatorType>(indicator: Partial<ActivityIndicator<T>>, type: T) => {
    const keys = {
      [ActivityIndicatorType.QUALITATIVE]: "qxIndicatorMonitoringEntries",
      [ActivityIndicatorType.QUANTITATIVE]: "qnIndicatorMonitoringEntries"
    };

    const parentKeys = {
      [ActivityIndicatorType.QUALITATIVE]: "activityQualitativeIndicator",
      [ActivityIndicatorType.QUANTITATIVE]: "activityQuantitativeIndicator"
    };

    const obj: IIndicatorRow[] = data?.[keys[type]];
    return obj?.find((entry) =>
      entry[parentKeys[type]].id === indicator.id);
  };

  return (
    <Form>
      <SectionContainer>
        <SectionTitle>Descripción</SectionTitle>
        <div>{activity?.description}</div>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>Beneficiarios</SectionTitle>
        {activity?.beneficiaries?.map((beneficiary) => (
          <BeneficiaryRow
            busy={busy}
            data={getBeneficiaryRowData(beneficiary)}
            beneficiary={beneficiary}
            form={form} />
        ))}
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>Actividades</SectionTitle>
        <ColumnContainer>
          {form.getFieldDecorator("activityMonitoringEntryId", {
            initialValue: data.activityMonitoringEntry?.id
          })(
            <Input type="hidden" />
          )}
          <ColumnElement title="Planeadas">
            {form.getFieldDecorator("plannedActivities", {
              initialValue: data.activityMonitoringEntry?.plannedActivities || 0
            })(
              <InputNumber
                disabled={busy}
                min={0}
                size="small"
                style={{ width: "100%" }} />
            )}
          </ColumnElement>
          <ColumnElement title="Hechas">
            {form.getFieldDecorator("activitiesDone", {
              initialValue: data.activityMonitoringEntry?.activitiesDone || 0
            })(
              <InputNumber
                disabled={busy}
                min={0}
                size="small"
                style={{ width: "100%" }} />
            )}
          </ColumnElement>
          <ColumnElement title="Horas invertidas">
            {form.getFieldDecorator("hoursInvested", {
              initialValue: data.activityMonitoringEntry?.hoursInvested || 0
            })(
              <InputNumber
                disabled={busy}
                min={0}
                size="small"
                style={{ width: "100%" }} />
            )}
          </ColumnElement>
        </ColumnContainer>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>Indicadores cuantitativos</SectionTitle>
        {quantitativeIndicators.map((indicator) => (
          <IndicatorRow
            busy={busy}
            data={getIndicatorRowData(indicator, ActivityIndicatorType.QUANTITATIVE)}
            type={ActivityIndicatorType.QUANTITATIVE}
            indicator={indicator}
            form={form} />
        ))}
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>Indicadores cualitativos</SectionTitle>
        {qualitativeIndicators.map((indicator) => (
          <IndicatorRow
            busy={busy}
            data={getIndicatorRowData(indicator, ActivityIndicatorType.QUALITATIVE)}
            type={ActivityIndicatorType.QUALITATIVE}
            indicator={indicator}
            form={form} />
        ))}
      </SectionContainer>
      <ButtonContainer>
        <Button
          loading={busy}
          style={{ marginRight: 10 }}
          type="primary"
          shape="round"
          onClick={() => onSubmit(form)}>
          Guardar
        </Button>
        <Button
          disabled={busy}
          shape="round"
          onClick={onCancel}>
          Cancelar
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export const ActivityForm = Form.create<RawActivityFormProps>()(RawActivityForm);