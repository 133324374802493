import React from "react";
import { Form, message, Button } from "antd";
import { withRouter } from "react-router";
import { Query, QueryResult } from "react-apollo";
import { bugsnagClient } from "../../../bugsnag";
import { FormSection } from "../../molecules";
import { ErrorTemplate } from "../../templates";

import {
  ImplementerProfileFormProps,
  ImplementerProfileFormState,
  DefaultContextValue,
} from "./types";
import { CustomEventArgs, ImplementerProfile, DirectorsBoardMember } from "../../../types";
import { initialState, updateImplementerProfile, createImplementerProfile } from "./helpers";
import { ImplementerProfileFormContext } from "./ImplementerProfileFormContext";
import {
  DependencyDataSection,
  InstitutionalBackgroundSection,
  ResponsibleContactSection,
  FiscalDataSection,
  FiscalAddressSection,
  DocsSection,
  BoardOfDirectorsSection,
  TopControls
} from "./components";
import { GetAccount } from "../../../gql/queries";
import { v4 as uuid } from "uuid";

class ImplementerProfileForm extends React.Component<ImplementerProfileFormProps, ImplementerProfileFormState> {
  public state: ImplementerProfileFormState = {
    ...initialState
  };

  public constructor(props: ImplementerProfileFormProps) {
    super(props);
  }

  public render() {
    const { form } = this.props;
    const { isBusy, isLoadingData } = this.state;
    const providerValue: DefaultContextValue = {
      form,
      implementerProfile: { ...this.state.implementerProfile },
      onFieldsChange: this.onFieldsChange,
      onDeleteField: this.onDeleteField,
      isBusy,
      isLoadingData
    };

    return (
      <Query
        onCompleted={this.handleOnCompleted}
        query={GetAccount}
        variables={{ uid: this.props.implementerAccountId }}>
        {({ error }: QueryResult) => {

          if (error) {
            return <ErrorTemplate />;
          }

          return (
            <ImplementerProfileFormContext.Provider value={providerValue}>
              <TopControls
                isBusy={isBusy}
                onSaveChanges={this.handleSubmit} />
              <div style={{ maxWidth: "500pt", marginTop: "30pt" }}>
                <Form
                  id="presubmissionForm"
                  onSubmit={this.handleSubmit}>
                  <DependencyDataSection />
                  <InstitutionalBackgroundSection />
                  <ResponsibleContactSection />
                  <FiscalDataSection />
                  <FiscalAddressSection />
                  <DocsSection />
                  <BoardOfDirectorsSection
                    onAdd={this.onAddMember}
                    onRemove={this.onRemoveMember}
                    onChangeMember={this.onChangeMember}
                    members={this.state.members} />
                  <FormSection>
                    <Button
                      style={{ float: "right" }}
                      loading={isBusy}
                      id="submitImplementerProfileFormButton"
                      shape="round"
                      type="primary"
                      htmlType="submit"
                      size="default">
                      Actualizar perfil
                    </Button>
                  </FormSection>
                </Form>
              </div>
            </ImplementerProfileFormContext.Provider>
          );
        }}
      </Query>
    );
  }

  private onChangeMember = (member: DirectorsBoardMember) => {
    const { members } = this.state;
    const toConnect = members.toConnect
      .map((m) => m.id === member.id ? member : m);
    const connected = members.connected
      .map((m) => m.id === member.id ? member : m);

    this.setState({
      members: {
        ...members,
        toConnect,
        connected
      }
    });
  }

  private onAddMember = (member: DirectorsBoardMember) => {
    this.setState((prevState) => ({
      members: {
        ...prevState.members,
        toConnect: [
          ...prevState.members.toConnect,
          member
        ]
      }
    }));
  }

  private onRemoveMember = (member: DirectorsBoardMember) => {
    const { members } = this.state;
    const { toDisconnect = [] } = members;

    const toConnect = members.toConnect.filter((m) => m.id !== member.id);
    const connected = members.connected
      .filter((m) => {
        if (m.id !== member.id) {
          return true;
        }
        toDisconnect.push(m);
        return false;
      });

    this.setState({
      members: {
        connected,
        toConnect,
        toDisconnect
      }
    });
  }

  private handleOnCompleted = (data: any) => {
    const implementerProfile: Partial<ImplementerProfile> =
      data?.account?.implementerProfile || {}
    const { directorsBoardMembers = [] } = implementerProfile;
    const membersToConnect: DirectorsBoardMember[] = [];

    if (directorsBoardMembers.length < 1) {
      membersToConnect.push({ id: uuid(), charge: "", name: "" })
    }

    if (implementerProfile.id !== undefined) {
      this.setState((prevState) => ({
        implementerProfile: { ...implementerProfile },
        members: {
          ...prevState.members,
          connected: directorsBoardMembers,
          toConnect: membersToConnect
        }
      }));
    } else {
      this.setState((prevState) => ({
        implementerProfile: { ...implementerProfile },
        members: {
          ...prevState.members,
          toConnect: membersToConnect
        }
      }));
    }
    this.setState({ isLoadingData: false });
  }

  private handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    const { form } = this.props;
    evt.preventDefault();
    form.validateFieldsAndScroll((err) => {
      if (err) {
        return;
      }
      this.saveImplementerProfile();
    });
  }

  private onFieldsChange = (arg: CustomEventArgs) => {
    const updatedImplementer = { ...this.state.implementerProfile };
    updatedImplementer[arg.key] = arg.value;
    this.setState({ implementerProfile: { ...updatedImplementer } });
  }

  private onDeleteField = (arg: CustomEventArgs) => {
    this.setState(prevState => ({
      ...prevState,
      implementerProfile: {
        ...prevState.implementerProfile,
        [arg.key]: [...prevState.implementerProfile[arg.key] || [], arg.value]
      }
    }))
  }

  private toggleIsBusy = () => {
    this.setState((prevState) => ({
      ...prevState,
      isBusy: !prevState.isBusy
    }));
  }

  private saveImplementerProfile = async () => {
    this.toggleIsBusy();
    const { implementerAccountId = "" } = this.props;
    const { implementerProfile, members } = this.state;

    try {
      if (implementerProfile.id === undefined) {
        await createImplementerProfile({
          data: {
            ...implementerProfile,
            directorsBoardMembers: members.toConnect
              .map(({ charge, name }) => ({ name, charge }))
          },
          implementerAccountUid: implementerAccountId
        });
      } else {
        await updateImplementerProfile({
          data: {
            ...implementerProfile,
            directorsBoardMembers: {
              connect: members.toConnect
                .map(({ charge, name }) => ({ charge, name })),
              updateMany: members.connected
                .map(({ charge, name, id }) => ({ charge, name, id })),
              disconnect: members.toDisconnect
                .map(({ id }) => ({ id })),
            }
          },
          implementerAccountUid: implementerAccountId
        } as any);
      }
      message.success("Perfil actualizado.");
    } catch (error) {
      bugsnagClient.notify(error, {
        beforeSend: (report) => {
          report.metaData = { error: JSON.stringify(error) };
          report.errorClass = "Implementer Update Profile";
          report.groupingHash = "Implementer Update Profile";
        }
      });
      message.error("Ocurrió un error.");
    } finally {
      this.toggleIsBusy();
    }
  }
}

const WrappedImplementerProfileForm =
  Form.create<ImplementerProfileFormProps>({
    name: "implementer_profile_form"
  })(ImplementerProfileForm);

export default withRouter(WrappedImplementerProfileForm);
