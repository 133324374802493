import React from "react";
import styled from "styled-components";

export default styled.div`
  z-index: 1;
  position: fixed;
  bottom: 0;
  padding: 20px 16px;
  width: 100%;
  background: #ffffff;
  border-top: solid 1px #e6e6e6;
`;
