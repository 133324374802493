import React from "react";
import { Table as ANTTable } from "antd";
import styled from "styled-components";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import { formatToCurrency } from "../../../../../utils/currency";
import { getVerificationRecord } from "../../../../templates/implementer/ProjectMonitoringTemplate/components/BudgetVerificationModal";
import { InvestorType } from "../../../../../types";

interface IBudgetSummaryTableProps {
  loading: boolean;
  dataSource: Array<{ expenseType: string, children: any[] }>;
}

const Table = styled(ANTTable)`
  & .ant-table-row.ant-table-row-level-1 {
    background-color: #f5f5f5;
  }
`;

const budgetSummaryTableColumns = [
  {
    title: "Tipo de gasto",
    render: (_: any, record: any) =>
      record.expenseType
        ? record.expenseType
        : format(parseInt(record.month, 10), "MMMM 'de' yyyy", { locale: es })
  },
  {
    dataIndex: "ficosec",
    title: "Presupuestado FICOSEC",
    render: (ficosec: number) =>
      ficosec !== undefined && formatToCurrency(ficosec)
  },
  {
    title: "Comprobado FICOSEC",
    render: (_: any, record: any) => {
      if (record.ficosec !== undefined) {
        const verificationRecord = getVerificationRecord(record, InvestorType.FICOSEC);
        return formatToCurrency(verificationRecord?.consumed || 0);
      }

      return null;
    }
  },
  {
    dataIndex: "coinvestor",
    title: "Presupuestado Co-inversión",
    render: (coinvestor: number) =>
      coinvestor !== undefined && formatToCurrency(coinvestor)
  },
  {
    title: "Comprobado Co-inversión",
    render: (_: any, record: any) => {
      if (record.coinvestor !== undefined) {
        const verificationRecord = getVerificationRecord(record, InvestorType.COINVESTOR);
        return formatToCurrency(verificationRecord?.consumed || 0);
      }

      return null;
    }
  },
  {
    title: "Total comprobado",
    render: (_: any, record: any) =>
      record.totalVerified
        ? formatToCurrency(record.totalVerified)
        : null
  },
  {
    title: "Total presupuestado",
    render: (_: any, record: any) =>
      record.totalBudgeted
        ? formatToCurrency(record.totalBudgeted)
        : null
  },
  {
    dataIndex: "difference",
    title: "Diferencia",
    render: (_: any, record: any) =>
      record.totalBudgeted && record.totalVerified
        ? formatToCurrency(record.totalBudgeted - record.totalVerified)
        : null
  },
];

export const BudgetSummaryTable: React.FC<IBudgetSummaryTableProps> = ({ loading, dataSource = [] }) => {
  return (
    <Table
      pagination={false}
      loading={loading}
      columns={budgetSummaryTableColumns}
      dataSource={dataSource}
    />
  );
};
