import React from "react";
import {
  Button,
  Spin,
  Table,
  Card as ANTCard,
  Descriptions,
  PageHeader,
  Skeleton,
  Typography,
  message,
  List,
  Icon
} from "antd";
import { format } from "date-fns";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

import { IWorktableMeeting } from "../../../types/WorktableMeeting";
import { Content, Upload } from "../../atoms";
import { WorktableMemberType, Attachment } from "../../../types";
import { client } from "../../../gql/apollo";
import { UpdateMeeting } from "../../../gql/mutations";
import { WorkTableMeeting } from "../../../gql/queries";
import styled from "styled-components";

interface MemorandumTemplateProps {
  loading: boolean;
  data: IWorktableMeeting;
}

const Card = styled(ANTCard)`
  width: 100%;
  margin: 15px;
`;

class MemorandumTemplate extends React.Component<MemorandumTemplateProps> {
  private membersColumns = [
    {
      title: "No",
      key: "id",
      render: (_: any, __: any, idx: number) => idx + 1
    },
    {
      title: "Nombre",
      dataIndex: "fullName",
    },
    {
      title: "Institución",
      dataIndex: "institution",
    }
  ];

  private agreementsColumns = [
    {
      title: "Acuerdo",
      dataIndex: "description"
    },
    {
      title: "Responsable",
      dataIndex: "responsible",
    }
  ];

  public render() {
    const { loading, data } = this.props;
    const memoDate = format(data?.date, "DD/MM/YY hh:mm aa");

    const membersWithoutPublicOffice = data?.worktable?.members?.filter((member) =>
      member.type === WorktableMemberType.WITHOUT_PUBLIC_OFFICE);

    const membersWithPublicOffice = data?.worktable?.members?.filter((member) =>
      member.type === WorktableMemberType.WITH_PUBLIC_OFFICE);

    const guestMembers = data?.worktable?.members?.filter((member) =>
      member.type === WorktableMemberType.GUEST);

    const routes = [
      {
        path: "/minutas",
        breadcrumbName: "Minutas",
      },
      {
        path: "/minutas/" + data?.id,
        breadcrumbName: data?.name,
      },
    ];

    const getAgendaAttachments = (): Array<UploadFile<any>> =>
      data?.agendaAttachments?.map((attachment, idx) => ({
        uid: attachment.id || String(idx),
        name: attachment.fileName,
        url: attachment.ref,
        size: null,
        type: null
      }));

    return (
      <Content style={{ background: "#ececec" }}>
        <PageHeader
          ghost={false}
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => window.history.back()}
          title={data?.name}
          breadcrumb={{ routes }}
          extra={[
            <Button icon="download" />,
            <Upload onDone={this.attachFile} showUploadList={false}>
              <Button type="primary">
                Adjuntar archivo
                </Button>
            </Upload>,
          ]}
        >
          <Skeleton loading={loading} paragraph={{ rows: 2 }}>
            <Descriptions size="small" column={2}>
              <Descriptions.Item label="Coordinación">{data?.worktable?.coordination}</Descriptions.Item>
              <Descriptions.Item label="Tipo">{data?.subject}</Descriptions.Item>
              <Descriptions.Item label="Fecha">{memoDate}</Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </PageHeader>
        <this.EmptyView loading={loading}>
          <Card title="Integrantes sin cargo Público" bordered={false}>
            <Table
              pagination={false}
              columns={this.membersColumns}
              dataSource={membersWithoutPublicOffice}
            />
          </Card>
          <Card title="Integrantes con cargo Público" bordered={false}>
            <Table
              pagination={false}
              columns={this.membersColumns}
              dataSource={membersWithPublicOffice}
            />
          </Card>
          <Card title="Integrantes invitados" bordered={false}>
            <Table
              pagination={false}
              columns={this.membersColumns}
              dataSource={guestMembers}
            />
          </Card>
          <Card title="Objetivo" bordered={false}>
            <Typography.Paragraph>{data?.objective}</Typography.Paragraph>
          </Card>
          <Card title="Orden del día" bordered={false}>
            <Typography.Paragraph>{data?.agenda}</Typography.Paragraph>
            <Upload
              disabled
              listType="picture"
              defaultFileList={getAgendaAttachments()} />
          </Card>
          <Card title="Acuerdos" bordered={false}>
            <Table
              pagination={false}
              columns={this.agreementsColumns}
              dataSource={data?.agreements}
            />
          </Card>
          <Card title="Documentos adjuntos" bordered={false}>
            <List
              itemLayout="horizontal"
              dataSource={data?.documents}
              renderItem={this.renderAttachment} />
          </Card>
        </this.EmptyView>
      </Content>
    );
  }

  private EmptyView: React.FC<{ loading: boolean }> = ({ loading, children }) => {
    if (loading) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spin />
        </div>
      );
    }

    return <>{children}</>;
  }

  private renderAttachment = (item: Attachment) =>
    <List.Item style={{ cursor: "pointer" }} onClick={() => window.open(item.ref, "_blank")}>
      <Icon type="paper-clip" style={{ marginRight: 10 }} />
      <List.Item.Meta
        title={item.fileName}
      />
    </List.Item>

  private attachFile = async (info: UploadChangeParam<UploadFile<any>>) => {
    const { data } = this.props;
    try {
      await client.mutate({
        mutation: UpdateMeeting,
        variables: {
          worktableMeetingId: data?.id,
          documents: [{ fileName: info.file.name, ref: info.file.response.imageUrl }]
        },
        refetchQueries: [{ query: WorkTableMeeting, variables: { id: data?.id } }]
      });
    } catch (error) {
      // TODO: Notify to bugsnag
      message.error("Hubo un problema al guardar el archivo.");
    }
  }
}

export default MemorandumTemplate;
