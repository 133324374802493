type beneficiaryTypes = 
| "PERCENTAGE"
| "CURRENCY"
| "UNITS";

export const beneficiaryTypesMap: { [key in beneficiaryTypes ]: any} = {
  PERCENTAGE: "Porcentaje",
  CURRENCY: "Moneda",
  UNITS: {
    GOODS: "Artículos",
    CLIENTS: "Clientes",
    MEETINGS: "Reuniones",
    FOLK: "Gente",
    PEOPLE: "Personas",
    PUBLICATIONS: "Publicaciones",
    PROJECTS: "Proyectos",
    ENROLLMENTS: "Inscripciones",
    TESTS: "Pruebas",
    VISITORS: "Visitantes"
  }
};
  