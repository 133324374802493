import gql from "graphql-tag";

export const FieldVisit = gql`
  query FieldVisit ($projectId: String!, $monitoringEntry: String!) {
    fieldVisit(projectId: $projectId, monitoringEntry: $monitoringEntry) {
      id
      activityName
      activityObjective
      reporterName
      visitPlace
      visitDate
      visitStartTime
      visitEndTime
      activityContent
      attendanceList
      ficosecLogos
      ficosecLogosReason
      activityPlace
      initialPlanning
      initialPlanningReason
      organizationEvidence
      organizationEvidenceType
      suppliesUsed
      goalBeneficiaries
      actualBeneficiaries
      observations
      obstacles
    }
  }
`;
