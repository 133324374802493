import React, { useState } from "react";
import { Modal, List, Button, Input, message } from "antd";
import { ModalProps } from "antd/lib/modal";
import { format } from "date-fns";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { SaveSupplyBudgetComment, DeleteSupplyBudgetComment } from "../../gql/mutations/monitoring";
import { client } from "../../gql/apollo";
import { SupplyBudgetComments } from "../../gql/queries";
import { UserRole } from "../../types";

interface IComment {
  id: string;
  comment: string;
  createdAt: string;
}

interface IBudgetCommentsModalOwnProps {
  supplyBudgetId: string;
  role?: string;
}

type BudgetCommentsModalProps = IBudgetCommentsModalOwnProps & ModalProps;

const CommentField = styled(Input.TextArea)`
  margin-bottom: 10px;
`;

const CommentButtonGroup = styled(Button.Group)`
  display: block;
  text-align: right;
`;

export const BudgetCommentsModal: React.FC<BudgetCommentsModalProps> = ({ supplyBudgetId, role, ...modalProps }) => {
  const { loading, data, error } = useQuery(SupplyBudgetComments, { variables: { supplyBudgetId }, client } as any);
  const [saveSupplyBudgetComment] = useMutation(SaveSupplyBudgetComment, { client } as any);
  const [deleteSupplyBudgetComment] = useMutation(DeleteSupplyBudgetComment, { client } as any);

  if (error) {
    // TODO: Report to bugsnag
    console.error(error);
    message.error("Ocurrió un problema la cargar los comentarios");
  }

  const [currentComment, setCurrentComment] = useState<string>();
  const [commenting, setCommenting] = useState(false);
  const [editing, setEditing] = useState<string>();
  const [busy, setBusy] = useState(false);

  const onAddComment = () => {
    setCommenting(true);
    setEditing(undefined);
    setCurrentComment(undefined);
  };

  const onSaveComment = async () => {
    if (!currentComment) {
      return;
    }

    try {
      setBusy(true);
      await saveSupplyBudgetComment({
        variables: {
          id: editing,
          supplyBudgetId,
          comment: currentComment
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: SupplyBudgetComments,
          variables: { supplyBudgetId }
        }]
      });

      setCommenting(false);
      setEditing(undefined);
      setCurrentComment(undefined);
    } catch (error) {
      // TODO: Report to bugsnag
      console.error(error);
      message.error("Ocurrió un problema al guardar el comentario.");
    } finally {
      setBusy(false);
    }
  };

  const deleteComment = async (id: string) => {
    try {
      await deleteSupplyBudgetComment({
        variables: { id },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: SupplyBudgetComments,
          variables: { supplyBudgetId }
        }]
      });
    } catch (error) {
      // TODO: Report to bugsnag
      console.error(error);
      message.error("Ocurrió un problema al eliminar el comentario.");
    }
  };

  const onCancelComment = () => {
    setCommenting(false);
    setEditing(undefined);
    setCurrentComment(undefined);
  };

  const onEditComment = (item: IComment) => {
    setCommenting(false);
    setEditing(item.id);
    setCurrentComment(item.comment);
  };

  const onDeleteComment = (id: string) => {
    return Modal.confirm({
      title: "Borrar comentario",
      content: "Esta acción es permanente.",
      okText: "Borrar",
      cancelText: "Cancelar",
      onOk: () => deleteComment(id)
    });
  };

  const listItemExtra = (item: IComment) => (
    <Button.Group>
      <Button
        icon="edit"
        disabled={busy}
        onClick={() => onEditComment(item)} />
      <Button
        icon="delete"
        disabled={busy}
        onClick={() => onDeleteComment(item.id)} />
    </Button.Group>
  );

  const renderListItem = (item: IComment) => {
    const date = format(parseInt(item.createdAt, 10), "dd/MM/yyyy");

    if (item.id === editing) {
      return renderCommentField(item.comment);
    }

    return (
      <List.Item extra={role === UserRole.ADMIN && listItemExtra(item)}>
        <List.Item.Meta
          title={item.comment}
          description={date}
        />
      </List.Item>
    );
  };

  const renderCommentField = (comment?: string) => (
    <>
      <CommentField
        defaultValue={comment}
        onBlur={({ target }) => setCurrentComment(target.value)} />
      <CommentButtonGroup>
        <Button
          icon="plus"
          type="primary"
          loading={busy}
          onClick={onSaveComment}>
          Guardar comentario
        </Button>
        <Button
          icon="close"
          type="primary"
          disabled={busy}
          onClick={onCancelComment}>
          Cancelar
        </Button>
      </CommentButtonGroup>
    </>
  );

  const footer = (
    <>
      {commenting
        ? renderCommentField()
        : (
          <Button
            icon="plus"
            type="ghost"
            block
            disabled={busy}
            onClick={onAddComment}>
            Añadir comentario
          </Button>
        )}
    </>
  );

  const sortedComments = data?.supplyBudgetComments?.sort((a: IComment, b: IComment) =>
    a.createdAt < b.createdAt ? -1 : 1);

  return (
    <Modal
      title="Comentarios"
      footer={null}
      {...modalProps}>
      <List
        loading={loading}
        locale={{ emptyText: "No hay comentarios" }}
        dataSource={sortedComments || []}
        renderItem={renderListItem}
        footer={role === UserRole.ADMIN && footer} />
    </Modal>
  )
};
