export const adminMenuItems = [
  {
    key: "prerequests",
    path: "/presolicitudes",
    label: "Pre-solicitudes"
  },
  {
    key: "requests",
    label: "Solicitudes",
    items: [
      {
        key: "requests:review",
        path: "/solicitudes/revision",
        label: "Revisión"
      },
      {
        key: "requests:approved",
        path: "/solicitudes/aprobacion",
        label: "Aprobación"
      },
      {
        key: "requests:agreement",
        path: "/solicitudes/convenio",
        label: "Convenio"
      }
    ]
  },
  {
    key: "projects",
    path: "/proyectos",
    label: "Proyectos"
  },
  {
    key: "worktables",
    path: "/mesas-de-trabajo",
    label: "Mesas de trabajo"
  }
];
