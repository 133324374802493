import React from "react";
import { format } from "date-fns";
import { Button, Tag } from "antd";

import { RequestStatus, RequestType, RequestArea } from "../../../../../types";
import { requestStatuses, requestTypes } from "../../../../../utils/maps";
import { Request } from "../../ApprovedRequestsTemplate/types";

interface Args {
  onReview: (requestId: string) => void;
  onDeleteRequest?: (requestId: string) => void;
}

export const getColumns = ({ onReview, onDeleteRequest }: Args) => ([
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) => format(parseInt(date, 10), "dd/MM/yyyy"),
    sorter: true
  },
  {
    title: "Nombre",
    dataIndex: "project.name",
    key: "name",
    sorter: true
  },
  {
    title: "Área",
    dataIndex: "preRequest.area",
    key: "area",
    render: (area: RequestArea) => area || "Por definir",
    sorter: true
  },
  {
    title: "Tipo",
    dataIndex: "preRequest.type",
    key: "type",
    render: (type: RequestType) => type ? requestTypes[type] : "Por definir",
    sorter: true
  },
  {
    title: "Estatus",
    dataIndex: "reviews.length",
    key: "reviews",
    render: (reviews: number) =>
      <Tag>
        Revisión {reviews + 1}
      </Tag>
  },
  {
    fixed: "right" as "right",
    width: 1,
    render: (_: any, request: { id: string }) => (
      <>
        <Button
          shape="round"
          style={{ marginRight: 10 }}
          onClick={() => onReview(request.id)}>
          Revisar solicitud
        </Button>
        <Button
          onClick={() => onDeleteRequest ?
            onDeleteRequest(request.id)
            : undefined}
          shape="circle"
          icon="delete" />
      </>
    )
  }
]);
