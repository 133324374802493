import React from "react";
import { Tag } from "antd";

import { timestampToReadableDate } from "./";
import { requestStatuses } from "../../../../../utils/maps";
import { RequestStatus } from "../../../../../types";
import { Request } from "../../../requests/ApprovedRequestsTemplate/types";

const tagColorsMap: { [status in RequestStatus]: string } = {
  PENDING_CORRECTION: "blue",
  IN_PROGRESS: "orange",
  REJECTED: "red",
  APPROVED: "green",
  FINISHED: "green"
};

export const requestsTableColumns = [
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (timestamp) => timestampToReadableDate(timestamp as number),
    sorter: true
  },
  {
    title: "Nombre de la solicitud",
    dataIndex: "preRequest.projectName",
    key: "projectName",
    sorter: true
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    render: (status: RequestStatus, request: Request) => (
      !request?.draft ?
        <>
          <Tag color={tagColorsMap[status]}>
            {requestStatuses[status]}
          </Tag>
          <Tag>
            Revisión {request.reviews.length + 1}
          </Tag>
        </>
        : <Tag>Borrador</Tag>
    )
  },
];
