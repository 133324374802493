import gql from "graphql-tag";

const WorkTableMeeting = gql`
  query WorkTableMeeting($id: String!) {
    worktableMeeting(meetingId: $id) {
      id
      folio
      agenda
      agendaAttachments {
        id
        fileName
        ref
      }
      name
      subject
      place
      date
      objective
      status
      worktable {
        id
        area
        coordination
        members {
          id
          type
          fullName
          institution
          position
          institutionLevel
          phoneNumber
          emailAddress
        }
      }
      agreements {
        id
        description
        responsible
        deadline
        status
        documents {
          id
          fileName
          ref
        }
      }
      documents {
        id
        fileName
        ref
      }
    }
  }
`;

export default WorkTableMeeting;
