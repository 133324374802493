import React from "react";
import { Row, Col } from "antd";
import { ScrollableContainer } from "../../../../atoms";
import { TableHeader } from ".";
import { SpecificObjectiveGoalsRows } from ".";

interface GoalDetailsTableProps {
  specificObjective: any;
  data: any;  
}

export const GoalsDetailsTable = (props: GoalDetailsTableProps) => {

  return (
    <ScrollableContainer>
      <Row>
        <TableHeader />
        <Col
          span={24}
          style={{
            marginBottom: "50pt",
            border: "solid 1px #ddd",
            minWidth: "1600px"
          }}>
          <SpecificObjectiveGoalsRows {...props} />
        </Col>
      </Row>
    </ScrollableContainer>
  );
};
