import gql from "graphql-tag";

const WorkTables = gql`
  query worktables(
    $skip: Int
    $sortField: String
    $sortDirection: String
  ) {
    worktables(
      skip: $skip
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      items {
        id
        folio
        logotypeUrl
        name
        area
        subject
        coordination
        technicalSecretary
        type
        description
        status
      }
      count
    }
  }
`;

export default WorkTables;
