import React from "react";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router";
import {
  Typography,
  Modal as AntModal,
  Button,
  Empty,
  Spin,
  Skeleton,
  Table,
  message
} from "antd";
const { confirm } = AntModal;
import moment from "moment";

import { Content, ContentHeader, Sidebar, Labeled } from "../../atoms";
import Layout from "../../organisms/Layout";
import {
  RequestMemorandumFooter,
  WorktableMeetingModal
} from "../../organisms";
import { Memorandum } from "../../../types/Memorandum";
import { client } from "../../../gql/apollo";
import { NewMemorandum } from "../../../gql/mutations/memorandum";
import { DeleteMeeting } from "../../../gql/mutations";
import { worktableAreas } from "../../../utils/maps/workTableAreas";
import { IWorktableMeeting, IWorkTable, WorktableMeetingStatus, UserRole } from "../../../types";
import { WorkTable } from "../../../gql/queries";
import { MeetingDetailTemplate } from "./";
import { Link } from "react-router-dom";

type Action =
  | "CREATE"
  | "EDIT";

interface ICustomEvent {
  key: string;
  value: any;
}

interface WorkTableTemplateOwnProps {
  loading: boolean;
  data: any;
  refetch: any;
}

interface WorkTableTemplateState {
  worktableMeetingModal: {
    visible: boolean;
    action: Action;
    worktableMeeting?: IWorktableMeeting;
  };
  newMemorandum?: Memorandum;
}

type WorkTableTemplateProps =
  & WorkTableTemplateOwnProps
  & RouteComponentProps;

class WorkTableTemplate extends React.Component<WorkTableTemplateProps, WorkTableTemplateState> {
  public state: WorkTableTemplateState = {
    worktableMeetingModal: {
      visible: false,
      action: "CREATE",
      worktableMeeting: undefined
    },
    newMemorandum: undefined
  };

  private meetingsColumns = [
    {
      title: "Folio",
      dataIndex: "folio",
      key: "folio"
    },
    {
      title: "Tema",
      dataIndex: "subject",
      key: "subject"
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (date: string) =>
        moment(date).format("DD/MM/YYYY")
    },
    {
      title: "Minuta",
      render: (row: any) => {
        if (row.status === WorktableMeetingStatus.CLOSED) {
          return <Link onClick={(evt) => evt.stopPropagation()} to={`/minutas/${row.id}`}>Ver minuta</Link>;
        }

        return "––";
      }
    },
    {
      render: (row: any) => (
        <>
          <Button
            style={{ margin: "0 4px" }}
            onClick={(e) => this.onOpenEditMeetingModal(row)}
            icon="edit"
            shape="circle"
            size="large" />
          <Button
            style={{ margin: "0 4px" }}
            onClick={(e) => this.handleOnDeleteMeeting(row)}
            icon="delete"
            shape="circle"
            size="large" />
        </>
      )
    }
  ];

  public render() {
    const { worktableMeetingModal } = this.state;
    const { history, loading, data } = this.props;
    const { meetings } = data || { meetings: [] };
    const {
      action,
      visible,
      worktableMeeting
    } = worktableMeetingModal;

    const worktableData: Partial<IWorkTable> = {
      name: data && data.name,
      area: data && data.area
    };

    return (
      <Layout noPadding>
        <Sidebar>
          <div style={{ padding: 20 }}>
            {this.renderSidebarContents(loading, data)}
          </div>
        </Sidebar>
        <Switch>
          <Route exact path="/mesas-de-trabajo/:worktableId">
            <WorktableMeetingModal
              action={action}
              visible={visible}
              workTableId={data ? data.id : ""}
              worktable={worktableData}
              worktableMeeting={worktableMeeting}
              onClose={this.handleOnCloseMeetingModal}
              onInputChange={this.onMeetingModalInputChange} />
            <ContentHeader
              title="Reuniones"
              subtitle={`Mostrando ${meetings.length}`}
              renderRight={
                <Button
                  onClick={this.openCreateMeetingModal}
                  size="large"
                  shape="circle"
                  icon="plus" />
              }
            />
            <Content style={{ marginLeft: 245, bottom: 0 }}>
              <this.EmptyView loading={loading} empty={meetings.length === 0}>
                <div style={{ marginTop: 69, backgroundColor: "#ffffff" }}>
                  <Table
                    pagination={false}
                    columns={this.meetingsColumns}
                    dataSource={meetings}
                    onRow={(record: any) => ({
                      onClick: () => history.push(`/mesas-de-trabajo/${data.id}/reunion/${record.id}`)
                    })}
                  />
                </div>
              </this.EmptyView>
              <RequestMemorandumFooter
                onClickNext={this.handleOnClickCloseWorkTable}
                onClickCancel={() => undefined}
                nextDisabled={false}
                btnText={"Concluir mesa de trabajo"}
              />
            </Content>
          </Route>
          <Route
            exact
            path="/mesas-de-trabajo/:worktableId/reunion/:meetingId"
            component={MeetingDetailTemplate}
          />
        </Switch>
      </Layout>
    );
  }

  private onMeetingModalInputChange = ({ key, value }: ICustomEvent) => {
    const { worktableMeeting } = this.state.worktableMeetingModal;
    if (worktableMeeting) {
      this.setState((prevState) => {
        worktableMeeting[key] = value;

        return ({
          ...prevState,
          worktableMeetingModal: {
            ...prevState.worktableMeetingModal,
            worktableMeeting
          }
        });
      });
    }
  }

  private openCreateMeetingModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      worktableMeetingModal: {
        ...prevState.worktableMeetingModal,
        visible: !prevState.worktableMeetingModal.visible,
        action: "CREATE"
      }
    }));
  }

  private onOpenEditMeetingModal = (record: any) => {
    this.setState((prevState) => ({
      ...prevState,
      worktableMeetingModal: {
        ...prevState.worktableMeetingModal,
        visible: !prevState.worktableMeetingModal.visible,
        action: "EDIT",
        worktableMeeting: { ...record }
      }
    }));
  }

  private renderSidebarContents = (loading: boolean, data: any = {}) => {
    if (loading) {
      return <Skeleton />;
    }

    if (data === undefined) {
      return <Skeleton />;
    }

    return (
      <>
        <div style={{ marginBottom: 30 }}>
          <span
            style={{ color: "rgba(0,0,0,0.5)", fontSize: 20, fontWeight: 500 }}>
            {data.folio}
          </span>
          <Typography.Title
            level={4}>
            {data.name}
          </Typography.Title>
        </div>
        <this.SidebarItem label="Tipo de reunión">
          {worktableAreas[data.area]}
        </this.SidebarItem>
        <this.SidebarItem label="Coordinación">
          {data.coordination}
        </this.SidebarItem>
      </>
    );
  }

  private SidebarItem: React.FC<{ label: string }> = ({ label, children }) =>
    <div
      style={{
        paddingBottom: 12,
        marginBottom: 12,
        borderBottomColor: "#f5f5f5",
        borderBottomWidth: 1,
        borderBottomStyle: "solid"
      }}
    >
      <Labeled label={label} layout="vertical">{children}</Labeled>
    </div>

  private EmptyView: React.FC<{ loading: boolean, empty: boolean }> = ({ loading, empty, children }) => {
    if (loading) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Spin />
        </div>
      );
    }

    if (empty) {
      return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Empty
            image={require("../../../assets/empty_agreements.png")}
            imageStyle={{ height: 81 }}
            description={
              <div style={{ fontSize: 16, marginTop: 20 }}>
                No hay reuniones
              </div>
            }>
            <Button
              shape="round"
              type="primary"
              onClick={this.openCreateMeetingModal}>Nueva reunión</Button>
          </Empty>
        </div>
      );
    }

    return <>{children}</>;
  }

  private handleOnCloseMeetingModal = () => {
    this.setState({
      worktableMeetingModal: {
        visible: false,
        action: "CREATE",
        worktableMeeting: undefined
      }
    });
    this.props.refetch();
  }

  private handleOnDeleteMeeting = async (record: any) => {
    const { data } = this.props;
    confirm({
      title: "Eliminar reunión",
      content: "¿Estás seguro que deseas eliminar esta reunión? Esta acción no se puede deshacer.",
      okText: "Eliminar",
      okType: "primary",
      okButtonProps: {
        shape: "round"
      },
      cancelText: "No",
      cancelButtonProps: {
        shape: "round"
      },
      onOk() {
        client.mutate({
          mutation: DeleteMeeting,
          variables: { id: record.id },
          refetchQueries: [{ query: WorkTable, variables: { id: data.id } }]
        });
      }
    });
  }

  private handleOnClickCloseWorkTable = () => {
    const { history, data } = this.props;

    NewMemorandum({
      id: data.id,
      folio: data.folio,
      title: data.title,
      addressedTo: data.addressedTo,
      subject: data.subject,
      startDate: data.startDate,
      type: data.type,
      location: data.location,
      workTableAgreements_id: (Math.random() * 10).toString(),
      workTable_id: 1,
      status: "FINISHED"
    })
      .then(() => {
        message.success("Se ha cerrado la mesa de trabajo");
        return history.push("/mesas-de-trabajo");
      })
      .catch(() => {
        return message.error("Ha ocurrido un error");
      });
  }
}

export default withRouter(WorkTableTemplate);
