import {
  PreventionLevel,
  PreventionScope
} from "../../types/Project";

export const preventionLevels: { [key in PreventionLevel]: string } = {
  NA: "N/A",
  PRIMARY: "Primaria",
  SECONDARY: "Secundaria",
  TERTIARY: "Terciaria"
};

export const preventionScopes: { [key in PreventionScope]: string } = {
  NA: "N/A",
  PSYCHOSOCIAL: "Psicosocial",
  COMMUNITY: "Comunitario",
  SOCIAL: "Social",
  SITUATIONAL: "Situacional"
};
