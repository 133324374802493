import React from "react";
import { Layout } from "../../../organisms";
import { PageHeader } from "../../../organisms/Layout";
import { ProjectMonitoringDetailTemplate } from "../../../templates/projects";

export const ProjectMonitoringDetailPage = () => {

  return (
    <Layout noPadding>
      <PageHeader title="Monitoreo de proyecto" />
      <ProjectMonitoringDetailTemplate />
    </Layout>
  );
};
