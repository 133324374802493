import { RequestStatus } from "../types";

export const disabledRequestInput = (status: string, draft: boolean) => {
  if (draft && (status === RequestStatus.IN_PROGRESS || status === RequestStatus.PENDING_CORRECTION)) {
    return false;
  } else if (!draft && (status === RequestStatus.PENDING_CORRECTION)) {
    return false;
  } else {
    return true;
  }
};
