import React, { useContext } from "react";
import { Row, Col, Skeleton } from "antd";

import { ImplementerProfileFormContext } from "../ImplementerProfileFormContext";
import { FormSection } from "../../../molecules";
import {
  AreaInput,
  CoordinationInput,
  GovernmentLevelInput,
  GovernmentDependencyInput,
  ImplementerTypeInput,
  InstitutionResponsibleNameInput
} from "./inputs";


export const DependencyDataSection = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const { implementerProfile, isLoadingData } = implementerAccountContext;

  if (isLoadingData) {
    return <Skeleton loading={true} />;
  }

  return (
    <FormSection title="DATOS DE LA DEPENDENCIA">
        {implementerProfile.type === "CIVIL_ASSOCIATION" ? (
          <Row gutter={16}>
            <Col span={12}>
              <ImplementerTypeInput />
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <ImplementerTypeInput />
              </Col>
              <Col span={12}>
                <GovernmentLevelInput />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <GovernmentDependencyInput />
              </Col>
              <Col span={12}>
                <CoordinationInput />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <InstitutionResponsibleNameInput />
              </Col>
              <Col span={12}>
                {implementerProfile.type === "GOVERNMENT"
                  || implementerProfile.type === "CONSULTANT" ?
                  null
                  : <AreaInput />}
              </Col>
            </Row>
          </>
        )}
    </FormSection>
  );
};
