import React from "react";
import { Layout as AntLayout } from "antd";
import { LayoutProps as AntLayoutProps } from "antd/lib/layout";
import styled from "styled-components";

import Navbar from "./Navbar";
import { ContentContainer } from "./ContentContainer";
import { RoleConsumer } from "../../RoleConsumer";

interface LayoutOwnProps {
  extra?: React.ReactNode;
  noPadding?: boolean;
}

type LayoutProps = LayoutOwnProps & AntLayoutProps;

const BaseLayout = styled(AntLayout)`
  min-height: 100%;
`;

const style = {
  noPadding: {
    padding: 0
  },
  padding: { padding: "10pt" }
}

/**
 * Wrapper that includes a navbar with the userRole options and display
 * the child inside a default formated content. You can disable the default
 * padding with the noPadding prop. The props are the same as the Ant Layout
 * component.
 * @param props
 */
const Layout: React.FC<LayoutProps> = ({ noPadding = false, ...props }: LayoutProps) => {
  return (
    <RoleConsumer>
      {({ claims }) => (
        <BaseLayout {...props}>
          <Navbar extra={props.extra} userRole={claims?.role} />
          <ContentContainer
            style={noPadding ? style.noPadding : style.padding}>
            {props.children}
          </ContentContainer>
        </BaseLayout>
      )}
    </RoleConsumer>
  );
};

export * from "./SectionCard";
export * from "./PageHeader";
export * from "./ContentContainer";
export default Layout;
