import React from "react";
import { Input, Card, Table, InputNumber, Button, Select, Form } from "antd";
import { get } from "lodash";
import { ReviewControls, CommentButton } from '../../molecules'
import { FinancialCapability, FinancialCapabilityProjectBudgetType, } from "../../../types";
import { formatInput, parseInput } from "../../../utils";
import { RequestReviewContextOwnProps } from "../../RequestReviewContext";

interface FinancialCapabilityFormOwnProps {
  reviewState: Partial<RequestReviewContextOwnProps>;
  fields: Partial<FinancialCapability>;
  addProject: () => void;
  removeProject: (projectId: string) => void;
  saveInput: (entityId: string, field: string, value?: string | number) => void;
  reviews: any;
}

type FinancialCapabilityFormProps =
  & { disabled: boolean; reviewable: boolean; approval: boolean; checkFields: boolean }
  & FinancialCapabilityFormOwnProps;

class FinancialCapabilityForm extends React.Component<FinancialCapabilityFormProps> {
  private budgetsTableColumns = [
    {
      key: "year",
      dataIndex: "year",
      title: "Año"
    },
    {
      key: "public.total",
      dataIndex: "public.total",
      title: () => <span style={{ color: "rgba(0, 30, 185, 0.65)" }}>Presupuesto público</span>,
      render: (total: string = "0") =>
        formatInput(total)
    },
    {
      key: "public.financed",
      dataIndex: "public.financed",
      title: () => <span style={{ color: "rgba(0, 30, 185, 0.65)" }}>Financiamiento</span>,
      render: (publicBudgetFinanced: number = 0, record: any) => {
        const pct = publicBudgetFinanced / get(record, "public.total", 1) * 100;
        const round = Math.round(pct * 100) / 100;
        return this.formatPct(String(round));
      }
    },
    {
      key: "private.total",
      dataIndex: "private.total",
      title: () => <span style={{ color: "rgba(11, 189, 125, 0.65)" }}>Presupuesto privado</span>,
      render: (total: string = "0") =>
        formatInput(total)
    },
    {
      key: "private.financed",
      dataIndex: "private.financed",
      title: () => <span style={{ color: "rgba(11, 189, 125, 0.65)" }}>Financiamiento</span>,
      render: (privateBudgetFinanced: number = 0, record: any) => {
        const pct = privateBudgetFinanced / get(record, "private.total", 1) * 100;
        const round = Math.round(pct * 100) / 100;
        return this.formatPct(String(round));
      }
    },
    {
      key: "total",
      dataIndex: "total",
      title: "Total",
      render: (_: any, record: any) => {
        const publicTotal = get(record, "public.total", 0);
        const privateTotal = get(record, "private.total", 0);

        const total = parseFloat(publicTotal) + parseFloat(privateTotal);
        return Intl
          .NumberFormat("es-MX", { style: "currency", currency: "MXN" })
          .format(total);
      }
    }
  ];

  private projectsTableColumn = [
    {
      key: "financialInstitution",
      dataIndex: "financialInstitution",
      title: "Institución financiera",
      render: (financialInstitution: string, record: any) => {
        const { checkFields } = this.props
        const error = checkFields ? financialInstitution ? "" : "error" : ""
        return (
          <Form.Item
            style={{marginBottom: 0}}
            validateStatus={error}>
            <Input
              style={{ width: "100%" }}
              defaultValue={financialInstitution}
              onBlur={(evt) => this.props.saveInput(record.id, "financialInstitution", evt.target.value || undefined)}
              disabled={this.props.disabled}
            />
          </Form.Item>
        )
      }
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Monto",
      render: (amount: string, record: any) => (
        <InputNumber
          style={{ width: "100%" }}
          formatter={formatInput}
          parser={parseInput}
          defaultValue={parseFloat(amount || "0")}
          onChange={(value) => this.props.saveInput(record.id, "amount", value)}
          disabled={this.props.disabled}
        />
      )
    },
    {
      key: "pctFinanced",
      dataIndex: "pctFinanced",
      title: "% de financiamiento",
      render: (pctFinanced: string, record: any) => (
        <InputNumber
          style={{ width: "100%" }}
          min={0}
          max={100}
          formatter={this.formatPct}
          parser={this.parsePct}
          defaultValue={parseFloat(pctFinanced || "0")}
          onBlur={(evt) => this.props.saveInput(record.id, "pctFinanced", parseFloat(evt.target.value.replace("%", "")))}
          disabled={this.props.disabled}
        />
      )
    },
    {
      key: "projectName",
      dataIndex: "projectName",
      title: "Nombre del proyecto",
      render: (projectName: string, record: any) => {
        const { checkFields } = this.props
        const error = checkFields ? projectName ? "" : "error" : ""
        return (
          <Form.Item
            style={{marginBottom: 0}}
            validateStatus={error}>
            <Input
              style={{ width: "100%" }}
              defaultValue={projectName}
              onBlur={(evt) => this.props.saveInput(record.id, "projectName", evt.target.value || undefined)}
              disabled={this.props.disabled}
            />
          </Form.Item>
        )
      }
    },
    {
      key: "projectYear",
      dataIndex: "projectYear",
      title: "Año",
      width: 150,
      render: (projectYear: string, record: any) => {
        const { checkFields } = this.props
        const error = checkFields ? projectYear ? "" : "error" : ""
        return (
          <Form.Item
            style={{marginBottom: 0}}
            validateStatus={error}>
            <Select
              style={{ width: "100%" }}
              placeholder="Año"
              defaultValue={projectYear}
              onChange={(value: string) => this.props.saveInput(record.id, "projectYear", String(value))}
              disabled={this.props.disabled}
            >
              {this.yearRange().map((yr) => (
                <Select.Option value={yr}>
                  {yr}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )
      }
    },
    {
      key: "budgetType",
      dataIndex: "budgetType",
      title: "Presupuesto",
      render: (budgetType: FinancialCapabilityProjectBudgetType, record: any) => {
        const { checkFields } = this.props
        const error = checkFields ? budgetType ? "" : "error" : ""
        return (
          <Form.Item
            style={{marginBottom: 0}}
            validateStatus={error}>
            <Select
              style={{ width: "100%" }}
              placeholder="Presupuesto"
              defaultValue={budgetType}
              onChange={(value: string) => this.props.saveInput(record.id, "budgetType", value)}
              disabled={this.props.disabled}
            >
              <Select.Option value="PUBLIC">Público</Select.Option>
              <Select.Option value="PRIVATE">Privado</Select.Option>
            </Select>
          </Form.Item>
        )
      }
    },
    {
      key: "projectObjective",
      dataIndex: "projectObjective",
      title: "Objetivo general del proyecto",
      render: (projectObjective: string, record: any) => {
        const { checkFields } = this.props
        const error = checkFields ? projectObjective ? "" : "error" : ""
        return (
          <Form.Item
            style={{marginBottom: 0}}
            validateStatus={error}>
            <Input
              style={{ width: "100%" }}
              defaultValue={projectObjective}
              onBlur={(evt) => this.props.saveInput(record.id, "projectObjective", evt.target.value || undefined)}
              disabled={this.props.disabled}
            />
          </Form.Item>
        )
      }
    },
    {
      render: (_: any, record: any) => {
        const { disabled, approval, reviewState, reviewable, reviews } = this.props;
        const fieldId = `financialCapability.${record.id}`;

        if (approval) {
          return null;
        }

        if (reviewable) {
          return (
            <ReviewControls
              fieldId={fieldId}
              reviews={reviews}
              comment={((reviewState.state || {})[fieldId] || { comment: undefined }).comment}
              approved={((reviewState.state || {})[fieldId] || { approved: undefined }).approved}
              onReset={(reviewState.methods || { onReset: () => undefined }).onReset}
              onApprove={(reviewState.methods || { onApprove: () => undefined }).onApprove}
              onReject={(reviewState.methods || { onReject: () => undefined }).onReject}
              onSaveComment={(reviewState.methods || { onSaveComment: () => undefined }).onSaveComment}
              onDeleteComment={(reviewState.methods || { onDeleteComment: () => undefined }).onDeleteComment}
              relative
            />
          );
        }

        return (
          <Button.Group>
            <Button
              icon="delete"
              disabled={disabled}
              onClick={() => this.props.removeProject(record.id)}
            />
            <CommentButton field={fieldId} size="default" disabled={disabled}/>
          </Button.Group>
        );
      }
    }
  ];

  public render() {
    const {
      disabled,
      reviewable,
      approval,
      addProject
    } = this.props;

    return (
      <div style={{ padding: 15, marginTop: 74, marginBottom: 74 }}>
        <Card title="Presupuestos ejercidos" bordered={false} style={{ marginBottom: 10 }}>
          Distribuye el total anual que se gestó en tu implementadora durante los periodos siguientes
          {this.renderBudgetsTable()}
        </Card>
        <Card title="Proyectos de implementadora" bordered={false}>
          Detalla los proyectos que has hecho en los últimos 3 años.
          Especifíca quién te ha financiado, el monto y en qué año se desarrollaron.
          {this.renderProjectsTable()}
          {!reviewable && !approval && (
            <Button
              type="dashed"
              icon="plus"
              disabled={disabled}
              onClick={addProject}
              block
            >
              Agregar proyecto
            </Button>
          )}
        </Card>
      </div>
    );
  }

  private renderBudgetsTable = () =>
    <Table
      style={{ marginTop: 20 }}
      pagination={false}
      columns={this.budgetsTableColumns}
      dataSource={this.getBudgetsData(this.props.fields || {})}
    />

  private getBudgetsData = (data: FinancialCapability | object) => {
    const years = {};
    Object.values(data).map((project) => {
      if (project.budgetType && project.projectYear) {
        const budgetType = project.budgetType.toLowerCase();
        const possibleBudget = get(years, `${project.projectYear}.${budgetType}`, {});
        const financed = (parseFloat(project.amount || "0") / 100) * parseFloat(project.pctFinanced || "0");

        years[project.projectYear] = {
          ...years[project.projectYear],
          year: project.projectYear,
          [budgetType]: {
            total: parseFloat(possibleBudget.total || "0") + parseFloat(project.amount || "0"),
            financed: parseFloat(possibleBudget.financed || "0") + financed
          }
        };
      }
    });

    return Object.values(years).sort((a: any, b: any) => a.year > b.year ? -1 : 0);
  }

  private renderProjectsTable = () =>
    <Table
      style={{ marginTop: 20, marginBottom: 20 }}
      pagination={false}
      columns={this.projectsTableColumn}
      rowKey="id"
      scroll={{ x: "max-content" }}
      dataSource={Object.values(this.props.fields)}
    />

  private formatPct = (value?: string | number) =>
    value ? String(value).replace("%", "").concat("%") : "0%"

  private parsePct = (value?: string) =>
    value ? `${value}%`.replace("%", "") : "0"

  private yearRange = (currentYear: number = new Date().getUTCFullYear()): number[] =>
    Array(currentYear - 1900 + 1)
      .fill(undefined)
      .map((_, idx) => currentYear - idx)
}

export default FinancialCapabilityForm;
