import React, { useContext } from "react";
import { Input, Form } from "antd";

import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";

export const ZipCodeInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item label="Código postal">
      {getFieldDecorator("zipCode", {
        initialValue: implementerProfile?.zipCode
      })(
        <Input
          disabled={isBusy}
          id="zipCode"
          name="zipCode"
          placeholder="Código postal"
          onChange={onChange}
          size="large" />
      )}
    </Form.Item>
  );
};
