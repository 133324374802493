import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import styles from "./styles.less";

export const ExtraButtons: React.FC = () => (
  <Link to="/implementadora/presolicitudes/crear">
    <Button
      className={styles.___clickable}
      type="primary"
      shape="round"
      icon="plus"
      children={"Nueva pre-solicitud"} />
  </Link>);
