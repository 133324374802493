import React from "react";
import styles from "./styles.less";

interface ContentHeaderProps {
  title: string;
  subtitle?: string;
  renderRight?: React.ReactElement;
  style?: object;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ title, subtitle, renderRight, style }) => (
  <div
    className={styles.contentHeader}
    style={style}>
    <div>
      <div
        className={styles.title}>
        {title}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
    {renderRight && (
      <div className={styles.rightArea}>
        {renderRight}
      </div>
    )}
  </div>
);

export default ContentHeader;
