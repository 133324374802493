import React, { useContext } from "react";
import { Input, Select } from "antd";
import Form from "antd/lib/form/Form";
import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";
import { PersonType } from "../../../../../types";
import { selectFilteringHelper } from "../../../../../utils";
import { personTypes } from "../../../../../utils/maps";

export const PersonTypeInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const handleOnChange = (value: PersonType) => {
    onFieldsChange({
      key: "personType",
      value: value
    });
  };

  return (
    <Form.Item label="Tipo de persona">
      {getFieldDecorator("personType", {
        rules: [
          {
            required: true,
            message: "Por favor, selecciona el tipo de persona."
          }
        ],
        initialValue: !!implementerProfile?.personType ?
          implementerProfile?.personType : undefined
      })(
        <Select
          disabled={isBusy}
          size="large"
          showSearch
          style={{ width: "100%" }}
          placeholder="Tipo de persona"
          optionFilterProp="children"
          onChange={handleOnChange}
          filterOption={selectFilteringHelper}>
          {Object.keys(personTypes)
            .map((pt) => (
              <Select.Option
                value={pt}
                key={pt}>
                {personTypes[pt]}
              </Select.Option>
            ))}
        </Select>
      )}
    </Form.Item>
  );
};
