import React from "react";
import { Form, Button, Input, Select, Modal as ANTModal, Typography, Row, Col, Cascader, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { differenceInCalendarMonths } from "date-fns";
import { Moment } from "moment";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { Modal } from "../../atoms";
import months from "../../../utils/maps/months";
import { Activity, ActivityBeneficiaries } from "../../../types";
import { FormSection } from "..";
import { RequestReviewContextOwnProps } from "../../RequestReviewContext";

export interface ToRemove {
  beneficiaries: string[];
}

interface ActivityFormOwnProps {
  reviewState: Partial<RequestReviewContextOwnProps>;
  reviewable?: boolean;
  approval?: boolean;
  busy: boolean;
  goalId?: string;
  activity?: Partial<Activity>;
  addActivity: (goalId: string, data: Omit<Activity, "id" | "goalId" | "supplies">, closeFormCb: () => void) => void;
  saveActivity: (activityId: string, data: Omit<Activity, "id" | "goalId" | "supplies">, toRemove: ToRemove, closeFormCb: () => void) => void;
  durationRange: string[] | Moment[] | number[];
  visible: boolean;
  onClose: () => void;
}

type ActivityFormProps = ActivityFormOwnProps & FormComponentProps;

interface ActivityFormState {
  beneficiaries: ActivityBeneficiaries;
  beneficiariesToRemove: string[];
}

class ActivityForm extends React.Component<ActivityFormProps, ActivityFormState> {
  public state: ActivityFormState = {
    beneficiaries: {},
    beneficiariesToRemove: []
  };

  public static beneficiaryTypes = [
    "PERCENTAGE",
    "CURRENCY",
    "UNITS",
  ];

  private ageRanges = [
    { label: "0 a 5 años", value: "0-5" },
    { label: "6 a 11 años", value: "6-11" },
    { label: "12 a 15 años", value: "12-15" },
    { label: "16 a 18 años", value: "16-18" },
    { label: "19 a 29 años", value: "19-29" },
    { label: "30 a 49 años", value: "30-49" },
    { label: "50 a 65 años", value: "50-65" },
    { label: "66 años y más", value: "66+" }
  ]

  private profiles = [
    { label: "Niñas", value: "GIRLS" },
    { label: "Niños", value: "BOYS" },
    { label: "Adolescentes", value: "TEENAGERS" },
    { label: "Jóvenes", value: "YOUNG" },
    { label: "Adultos", value: "ADULTS" },
    { label: "Adultos mayores", value: "ELDERS" },
  ]

  private attendances = [
    { label: "Regular", value: "REGULAR" },
    { label: "Única", value: "UNIQUE" },
    { label: "Ocasional", value: "OCCASIONAL" },
  ]

  private beneficiaryTypeOptions = [
    { value: "GOODS", label: "Artículos", },
    { value: "CLIENTS", label: "Clientes" },
    { value: "MEETINGS", label: "Reuniones" },
    { value: "FOLK", label: "Gente" },
    { value: "PEOPLE", label: "Personas" },
    { value: "PUBLICATIONS", label: "Publicaciones" },
    { value: "PROJECTS", label: "Proyectos" },
    { value: "ENROLLMENTS", label: "Inscripciones" },
    { value: "TESTS", label: "Pruebas" },
    { value: "VISITORS", label: "Visitantes" }
  ];

  public componentDidUpdate(prevProps: ActivityFormProps) {
    const { activity, visible } = this.props;
    if (!prevProps.visible && visible && activity) {
      this.setState({
        beneficiaries: activity.beneficiaries as any || {}
      });
    }
  }

  public render() {
    const { beneficiaries } = this.state;
    const { form, visible, durationRange, activity = {}, reviewable, approval, busy } = this.props;
    const { getFieldDecorator } = form;

    const firstDate = new Date(durationRange[0] as string);
    const secondDate = new Date(durationRange[1] as string);
    const monthsSinceEpoch = Math.abs(differenceInCalendarMonths(new Date(-1).setUTCMonth(0), firstDate));
    const monthDiff = durationRange.length === 2
      ? Math.abs(differenceInCalendarMonths(firstDate, secondDate))
      : undefined;
    const firstYear = firstDate.getFullYear();
    const firstMonth = firstDate.getMonth();
    const monthArr = new Array(monthDiff ? monthDiff + 1 : 0).fill(0);
    monthArr.map((_, idx) => (monthArr[idx] = monthsSinceEpoch + idx));

    return (
      <Modal
        width={1020}
        title="Nueva actividad"
        footer={
          !(reviewable || approval) ? ([
            <Button shape="round" loading={busy} type="primary" onClick={this.handleOnSave}>Guardar</Button>,
            <Button shape="round" disabled={busy} onClick={this.handleOnClose}>Cancelar</Button>
          ]) : ([
            <Button shape="round" onClick={this.handleOnClose}>Cerrar</Button>
          ])
        }
        onCancel={!busy ? this.handleOnClose : undefined}
        visible={visible}
        closable
      >
        <Form>
          <FormSection>
            <Typography.Paragraph style={{ fontSize: 14 }} strong>Beneficiarios</Typography.Paragraph>
            {this.renderBeneficiaryRows(beneficiaries)}
            {!reviewable && !approval && (
              <Button
                icon="plus"
                type="dashed"
                onClick={() => this.addBeneficiary()}
                disabled={busy}
                block
              >
                Agregar beneficiario
              </Button>
            )}
          </FormSection>
          <FormSection>
            <Typography.Paragraph style={{ fontSize: 14 }} strong>Descripción</Typography.Paragraph>
            {getFieldDecorator(`activities.${activity.id || "_"}.description`, {
              rules: [
                {
                  required: true,
                  message: "Por favor, describe la actividad."
                }
              ],
              initialValue: activity.description
            })!(
              <Input.TextArea
                placeholder="Redacción de la actividad"
                rows={3}
                style={{ width: "100%" }}
                disabled={busy || reviewable || approval}
              />
            )}
          </FormSection>
          <FormSection>
            <Typography.Text style={{ fontSize: 14 }} strong>
              Meses en que se realizará la actividad
            </Typography.Text>
            <Typography.Paragraph>
              Esta actividad se verá reflejada en los meses que selecciones en el cronograma.
            </Typography.Paragraph>
            {getFieldDecorator(`activities.${activity.id || "_"}.months`, {
              rules: [
                {
                  required: true,
                  message: "Por favor, selecciona los meses para realizar esta actividad."
                }
              ],
              initialValue: activity.months
            })!(
              <Select
                style={{ minWidth: 240, width: "auto" }}
                mode="multiple"
                placeholder="Seleccionar meses"
                disabled={busy || reviewable || approval}
                showArrow
              >
                {monthArr.map((monthSinceEpoch, monthIdx) => {
                  const month: number = monthIdx + firstMonth;
                  const currentYear = Math.floor(month / 12);
                  const actualMonth = month - (currentYear * 12);
                  const displayValue = `${months[actualMonth]} ${firstYear + currentYear}`;

                  return (
                    <Select.Option value={monthSinceEpoch}>{displayValue}</Select.Option>
                  );
                })}
              </Select>
            )}
          </FormSection>
        </Form>
      </Modal>
    );
  }

  private renderBeneficiaryRows = (beneficiaries: ActivityBeneficiaries = {}) => {
    const { activity = {}, reviewable, approval, busy, form: { getFieldDecorator } } = this.props;
    const beneficiariesIds = Object.keys(beneficiaries);
    const beneficiariesKey = `activities.${activity.id || "_"}.beneficiaries`;

    return beneficiariesIds.map((beneficiaryId) => {
      const rawBeneficiaryType = get(beneficiaries, `${beneficiaryId}.type`, undefined);
      const beneficiaryType = rawBeneficiaryType?.[0] === "OTHER"
        ? rawBeneficiaryType[1]
        : rawBeneficiaryType;

      return (
        <div style={{ display: "flex" }}>
          <Row gutter={10} style={{ flex: 1 }}>
            <Col span={4}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${beneficiariesKey}.${beneficiaryId}.goalValue`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, ingresa el valor meta."
                    }
                  ],
                  initialValue: get(activity, `beneficiaries.${beneficiaryId}.goalValue`, undefined)
                })!(
                  <Input placeholder="Valor meta" disabled={busy || reviewable || approval} />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${beneficiariesKey}.${beneficiaryId}.type`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, selecciona el tipo de beneficiario."
                    }
                  ],
                  initialValue: beneficiaryType
                })!(this.renderBeneficiaryTypeField(beneficiaryId, beneficiariesKey, beneficiaryType))}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${beneficiariesKey}.${beneficiaryId}.profile`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, selecciona el perfil."
                    }
                  ],
                  initialValue: get(activity, `beneficiaries.${beneficiaryId}.profile`, undefined)
                })!(
                  <Select
                    placeholder="Perfil"
                    disabled={busy || reviewable || approval}>
                    {this.profiles.map(profile => (
                      <Select.Option key={profile.value} value={profile.value}>{profile.label}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${beneficiariesKey}.${beneficiaryId}.range`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, selecciona el rango de edad."
                    }
                  ],
                  initialValue: get(activity, `beneficiaries.${beneficiaryId}.range`, undefined)
                })!(
                  <Select
                    placeholder="Edad"
                    disabled={busy || reviewable || approval}
                  >
                    {this.ageRanges.map(age => (
                      <Select.Option key={age.value} value={age.value}>{age.label}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item className="activity-form-item">
                {getFieldDecorator(`${beneficiariesKey}.${beneficiaryId}.attendance`, {
                  rules: [
                    {
                      required: true,
                      message: "Por favor, selecciona la asistencia."
                    }
                  ],
                  initialValue: get(activity, `beneficiaries.${beneficiaryId}.attendance`, undefined)
                })!(
                  <Select
                    placeholder="Asistencia"
                    disabled={busy || reviewable || approval}>
                    {this.attendances.map(attendance => (
                      <Select.Option key={attendance.value} value={attendance.value}>{attendance.label}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <div style={{ marginTop: 4, marginLeft: 10 }}>
            {!reviewable && !approval && (
              <Button
                icon="close"
                type="link"
                onClick={() => this.removeBeneficiary(beneficiaryId)}
                disabled={busy}
              />
            )}
          </div>
        </div>
      );
    });
  }

  private renderBeneficiaryTypeField = (
    beneficiaryId: string,
    beneficiariesKey: string,
    beneficiaryType: string | string[]
  ) => {
    const { reviewable, approval, busy, form: { setFieldsValue } } = this.props;
    const beneficiaryTypeField = typeof beneficiaryType === "string"
      ? "INPUT"
      : "CASCADER";

    if (beneficiaryTypeField === "INPUT") {
      return (
        <Input
          autoFocus
          placeholder="Tipo"
          disabled={busy || reviewable || approval}
          suffix={
            <Icon
              type="close"
              onClick={() => {
                setFieldsValue({
                  [`${beneficiariesKey}.${beneficiaryId}.type`]: undefined
                });

                this.setState((state) => ({
                  ...state,
                  beneficiaries: {
                    ...state.beneficiaries,
                    [beneficiaryId]: {
                      ...state.beneficiaries[beneficiaryId],
                      type: undefined
                    }
                  }
                }));
              }}
            />}
        />
      );
    }

    if (beneficiaryTypeField === "CASCADER") {
      return (
        <Cascader
          options={this.beneficiaryTypeOptions}
          placeholder="Tipo"
          disabled={busy || reviewable || approval}
          onChange={(type) => {
            setFieldsValue({
              [`${beneficiariesKey}.${beneficiaryId}.type`]: ""
            });

            this.setState((state) => ({
              ...state,
              beneficiaries: {
                ...state.beneficiaries,
                [beneficiaryId]: {
                  ...state.beneficiaries[beneficiaryId],
                  type: type[0] === "" ? "" : type
                }
              }
            }));
          }}
        />
      );
    }

    return null;
  }

  private addBeneficiary = (beneficiaryId: string = uuid()) =>
    this.setState((state) => ({
      ...state,
      beneficiaries: {
        ...state.beneficiaries,
        [beneficiaryId]: {
          id: beneficiaryId
        }
      }
    }))

  private removeBeneficiary = (beneficiaryId: string) => {
    const { beneficiaries: beneficiariesFromState, beneficiariesToRemove } = this.state;
    const { [beneficiaryId]: removed, ...beneficiaries } = beneficiariesFromState;
    return this.setState({ beneficiaries, beneficiariesToRemove: [...beneficiariesToRemove, beneficiaryId] });
  }

  private handleOnClose = () => {
    const { onClose, form } = this.props;
    form.resetFields();
    this.setState({ beneficiaries: {} });
    onClose();
  }

  private formatBeneficiaryTypes = (beneficiariesKeys: string[], beneficiaries: ActivityBeneficiaries) => {
    const newBeneficiaries: ActivityBeneficiaries = {};
    beneficiariesKeys.forEach((key) => {
      const rawBeneficiaryType = beneficiaries[key].type;
      const beneficiaryType = typeof rawBeneficiaryType === "string"
        ? ["OTHER", rawBeneficiaryType]
        : rawBeneficiaryType;

      newBeneficiaries[key] = {
        ...beneficiaries[key],
        type: beneficiaryType
      };
    });

    return newBeneficiaries;
  }

  private handleOnSave = () => {
    const {
      beneficiaries,
      beneficiariesToRemove
    } = this.state;
    const { form, goalId, activity, addActivity, saveActivity } = this.props;
    const beneficiariesKeys = Object.keys(beneficiaries);

    const empty = { beneficiaries: beneficiariesKeys.length === 0 };

    if (empty.beneficiaries) {
      return ANTModal.error({
        title: "Campos faltantes",
        content: (
          <ul>
            {empty.beneficiaries && <li>Agrega al menos un beneficiario.</li>}
          </ul>
        )
      });
    }

    form.validateFields((err: any, values: any) => {
      if (!err && goalId) {
        addActivity(
          goalId,
          {
            beneficiaries: this.formatBeneficiaryTypes(beneficiariesKeys, values.activities._.beneficiaries) as any,
            description: values.activities._.description,
            months: values.activities._.months
          },
          this.handleOnClose
        );
      } else if (!err && activity) {
        saveActivity(
          activity.id!,
          {
            beneficiaries:
              this.formatBeneficiaryTypes(beneficiariesKeys, values.activities[activity.id!].beneficiaries) as any,
            description: values.activities[activity.id!].description,
            months: values.activities[activity.id!].months
          },
          {
            beneficiaries: beneficiariesToRemove
          },
          this.handleOnClose
        );
      }
    });

    return;
  }
}

const WrappedActivityForm = Form.create<ActivityFormProps>({ name: "activityForm" })(ActivityForm);

export default WrappedActivityForm;
