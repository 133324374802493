import React, { Context } from "react";
import { IWorktableMeeting, IWorkTable } from "../../../types";
import { WrappedFormUtils } from "antd/lib/form/Form";

interface IDefaultValue {
  worktableMeeting: IWorktableMeeting | undefined;
  form: WrappedFormUtils;
  onInputChange: (args: { key: string, value: any }) => void;
  worktable: Partial<IWorkTable>;
}

export const MeetingFormContext: Context<IDefaultValue> = React.createContext({
  worktableMeeting: undefined,
  form: undefined,
  onInputChange: undefined,
  worktable: undefined
} as any);
