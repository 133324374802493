import React, { useState } from "react";
import { Col, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { v4 as uuid } from "uuid";
import { IActivity, ActivityType } from "../../types";
import { ActivityForm } from "./components";
import { Footer } from "../.";

interface IFormActivitiesOwnProps {
  data?: IActivity[];
  saveNewWorkTable: () => void;
  onCancel: () => void;
  onChange: (
    section: "general" | "members" | "activities",
    event: { target: { name: string, value: any } }) => void;
}

type IFormActivitiesProps = FormComponentProps & IFormActivitiesOwnProps;

const FormActivities: React.FC<IFormActivitiesProps> = (props) => {

  const {
    form,
    saveNewWorkTable,
    onCancel,
    data: activities = [],
    onChange
  } = props;

  const onAddActivity = (
    activityType: ActivityType,
    activityIndex: number = -1
  ) => {
    const updatedActivities = Array.from(activities);
    const activitiesHasIndex = (activities.length - 1) >= (activityIndex);
    const emptyActivity = {
      id: uuid(),
      title: "",
      subActivities: []
    };

    if (activityType === "subActivity") {
      if (!activitiesHasIndex) {
        return;
      }

      if (updatedActivities[activityIndex].subActivities === undefined) {
        updatedActivities[activityIndex].subActivities = [];
      }

      updatedActivities[activityIndex]?.subActivities?.push(emptyActivity);
    } else if (activityType === "activity") {
      updatedActivities.push(emptyActivity);
    }

    onChange(
      "activities", {
      target: {
        name: "activities",
        value: updatedActivities
      }
    });
  };

  const onRemoveActivity = (
    activityType: ActivityType,
    activityIndex: number = -1,
    subActivityIndex: number = -1
  ) => {
    let updatedActivities = Array.from(activities);
    const activitiesHasIndex = (activities.length - 1) >= (activityIndex);
    const emptyActivity = {
      id: uuid(),
      title: "",
      subActivities: []
    };

    if (activityType === "subActivity") {
      if (!activitiesHasIndex) {
        return;
      }
      const filteredSubActivities = updatedActivities[activityIndex]
        .subActivities
        ?.filter((a: IActivity, i: number) => i !== subActivityIndex);
      updatedActivities[activityIndex].subActivities = filteredSubActivities;
    } else if (activityType === "activity") {
      updatedActivities = activities
        .filter((a: IActivity, i: number) => i !== activityIndex);
    }

    onChange(
      "activities", {
      target: {
        name: "activities",
        value: updatedActivities.length > 0 ?
          updatedActivities
          : [emptyActivity]
      }
    });
  };

  const onChanging = (
    activityType: ActivityType,
    event: { target: { value: string, name: string } },
    activityIndex: number = -1,
    subActivityIndex: number = -1
  ) => {
    const activitiesToUpdate: IActivity[] = Array.from(activities);
    let subActivity: IActivity | undefined;

    if (activitiesToUpdate[activityIndex] === undefined) {
      return;
    }

    const selectedActivity = activitiesToUpdate[activityIndex];
    selectedActivity.subActivities = activitiesToUpdate[activityIndex]
      ?.subActivities || [];

    subActivity = selectedActivity
      .subActivities[subActivityIndex];

    switch (activityType) {
      case "activity":
        selectedActivity.title = event.target.value;
        break;
      case "subActivity":
        if (subActivity === undefined) {
          return;
        }
        subActivity.title = event.target.value;
        selectedActivity
          .subActivities[subActivityIndex] = subActivity;
        break;
    }

    onChange(
      "activities", {
      target: {
        name: "activities",
        value: activitiesToUpdate
      }
    });
  };

  const onNext = async () => {
    await form.validateFields();
    saveNewWorkTable();
  };

  return (
    <>
      <div style={{ marginLeft: "380", marginBottom: "580px", padding: 40 }}>
        <Col span={8}>
          <ActivityForm
            form={form}
            activities={activities || []}
            onChange={onChanging}
            onAddActivity={onAddActivity}
            onRemoveActivity={onRemoveActivity} />
        </Col>
      </div>
      <Footer
        onClickCancel={onCancel}
        onClickNext={onNext}
        lastStep={true} />
    </>
  );
};

export default Form.create<IFormActivitiesProps>
  ({ name: "customized_form_controls" })
  (FormActivities);
