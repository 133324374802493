import { UploadFile } from "antd/lib/upload/interface";
import {
  ImplementerType,
  PersonType,
  IPreRequest,
  Project,
  InvestorType
} from "../types";
import { Attachment } from "./Attachment";

export interface Request {
  id: string;
  createdAt: string; // it's a timestamp number but it comes as an string
  draft: boolean;
  responsibleEmailAddress: string;
  responsibleName: string;
  responsiblePhoneNumber: string;
  responsiblePosition: string;
  status: RequestStatus;
  preRequest: IPreRequest;
  project: Project;
  rejectReason?: string;
}

export type RequestArea =
  | "UFI"
  | "UPV";

export type RequestType =
  | "FICOSEC_INVITATION"
  | "IMPLEMENTER_REQUEST"
  | "FICOSEC_ANNOUNCEMENT"
  | "EXTERNAL_ANNOUNCEMENT"
  | "GOVERNMENT_REQUEST"
  | "SUPPORT_ACTIVITIES"
  | "CONSULTANCY_REQUEST";

export type RequestStatus =
  | "PENDING_CORRECTION"
  | "IN_PROGRESS"
  | "APPROVED"
  | "REJECTED"
  | "FINISHED";

export const RequestStatus: { [key in RequestStatus]: RequestStatus } = {
  PENDING_CORRECTION: "PENDING_CORRECTION",
  IN_PROGRESS: "IN_PROGRESS",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  FINISHED: "FINISHED"
};

export type GovtLevel =
  | "FEDERAL"
  | "STATE"
  | "MUNICIPAL";

export interface ImplementerFields {
  type: ImplementerType;
  governmentLevel: GovtLevel;
  governmentDependency: string;
  zone: string;
  implementerZone: string;
  implementerRegions: [];
  implementerName: string;
  implementerMission: string;
  implementerVision: string;
  socialObjective: string;
  street: string;
  exteriorNumber: string;
  interiorNumber: string;
  colony: string;
  zipCode: string;
  city: string;
  state: string;
  department: string;
  coordination: string;
  area: string;
  institutionResponsibleName: string;
  contactName: string;
  contactCharge: string;
  contactDependency: string;
  contactPhoneNumber: string;
  contactEmail: string;
  personType: PersonType;
  RFC: string;
  businessName: string;
  socialBehest: string;
  constitutiveAct: string;
  creationDocument: string;
  authorizedDonor: "0" | "1";
  authorizedDonorDoc?: string;
  directorsBoardMembers: {
    [directiveId: string]: Partial<Directive>;
  };
}

// Check if master and Phd is needed
export type EduLevel =
  | "PRIMARY"
  | "SECONDARY"
  | "HIGH_SCHOOL"
  | "PROFESSIONAL";

export const EduLevel: { [key in EduLevel]: EduLevel } = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  HIGH_SCHOOL: "HIGH_SCHOOL",
  PROFESSIONAL: "PROFESSIONAL"
};

export interface HRProfile {
  id: string;
  fullName: string;
  position: string;
  professionalExp: string;
  projectGoals: string[];
  responsibilities: string;
  incomeEstimation: string;
  weeklyHours: string;
  eduLevel: EduLevel;
  specialtyArea: string;
  yearsExp: string;
  workExp: string;
  resume: UploadFile;
}

export interface Directive {
  directiveName: string;
  directivePosition: string;
}

export interface FinancialCapabilityProject {
  id: string;
  financialInstitution: string;
  amount: number;
  pctFinanced: number;
  projectName: string;
  projectYear: string | number;
  budgetType: FinancialCapabilityProjectBudgetType;
  projectObjective: string;
}

export type FinancialCapabilityProjectBudgetType =
  | "PUBLIC"
  | "PRIVATE";

export const FinancialCapabilityProjectBudgetType: { [key in FinancialCapabilityProjectBudgetType]: FinancialCapabilityProjectBudgetType } = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE"
};

export interface FinancialCapability {
  [id: string]: Partial<FinancialCapabilityProject>;
}

export type StrategicAxis =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5";

export type FicosecsObjective =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5";

export interface ProjectInfo {
  name: string;
  place: string;
  estimatedDuration: number;
  responsibleName: string;
  responsibleEmailAddress: string;
  preventionLevel: [string];
  preventionScope: [string];
  problem: string;
  description: string;
  beneficiariesProfile: string;
  substantiation: string;
}

export type ExpenseType =
  | "HR"
  | "SUPPLIES"
  | "EQUIPMENT"
  | "OPERATIVE_EXPENSES"
  | "ADMINISTRATIVE_EXPENSES"
  | "ADAPTATION_OR_EQUIPMENT";

export const ExpenseType: { [key in ExpenseType]: ExpenseType } = {
  HR: "HR",
  SUPPLIES: "SUPPLIES",
  EQUIPMENT: "EQUIPMENT",
  OPERATIVE_EXPENSES: "OPERATIVE_EXPENSES",
  ADMINISTRATIVE_EXPENSES: "ADMINISTRATIVE_EXPENSES",
  ADAPTATION_OR_EQUIPMENT: "ADAPTATION_OR_EQUIPMENT"
};

export type MeasurementUnit =
  | "PIECE"
  | "ELEMENT"
  | "SERVICE_UNIT"
  | "ACTIVITY"
  | "KILOGRAM"
  | "WORK"
  | "RATE"
  | "METER"
  | "BULK"
  | "BASE_BOX"
  | "KIT"
  | "SET"
  | "LITER"
  | "BOX"
  | "MONTH"
  | "HOUR"
  | "SQUARE_METER"
  | "HARDWARE"
  | "MILLIGRAM"
  | "PACKAGE"
  | "VARIED"
  | "GRAM"
  | "PAIR"
  | "DOZEN"
  | "UNIT"
  | "DAY"
  | "LOT"
  | "GROUP"
  | "MILLILITER"
  | "TRIP";

export const MeasurementUnit: { [key in MeasurementUnit]: MeasurementUnit } = {
  PIECE: "PIECE",
  ELEMENT: "ELEMENT",
  SERVICE_UNIT: "SERVICE_UNIT",
  ACTIVITY: "ACTIVITY",
  KILOGRAM: "KILOGRAM",
  WORK: "WORK",
  RATE: "RATE",
  METER: "METER",
  BULK: "BULK",
  BASE_BOX: "BASE_BOX",
  KIT: "KIT",
  SET: "SET",
  LITER: "LITER",
  BOX: "BOX",
  MONTH: "MONTH",
  HOUR: "HOUR",
  SQUARE_METER: "SQUARE_METER",
  HARDWARE: "HARDWARE",
  MILLIGRAM: "MILLIGRAM",
  PACKAGE: "PACKAGE",
  VARIED: "VARIED",
  GRAM: "GRAM",
  PAIR: "PAIR",
  DOZEN: "DOZEN",
  UNIT: "UNIT",
  DAY: "DAY",
  LOT: "LOT",
  GROUP: "GROUP",
  MILLILITER: "MILLILITER",
  TRIP: "TRIP"
};

export type BeneficiaryType =
  | "PERCENTAGE"
  | "CURRENCY"
  | "UNITS"
  | "GOODS"
  | "CLIENTS"
  | "MEETINGS"
  | "FOLK"
  | "PEOPLE"
  | "PUBLICATIONS"
  | "PROJECTS"
  | "ENROLLMENTS"
  | "TESTS"
  | "VISITORS";

export type BudgetVerificationInvoice = Attachment;

export interface IBudgetVerification {
  id: string;
  type: InvestorType;
  consumed: number;
  invoices: BudgetVerificationInvoice[];
}

export interface IMonthlyBudget {
  id: string;
  month: string | number;
  ficosec: number;
  coinvestor: number;
  budgetVerification?: IBudgetVerification[];
}

export type ASupply = Omit<Supply, "monthlyBudget"> & { monthlyBudget: IMonthlyBudget[], children?: any };

export interface Supply {
  id: string;
  HRProfileId?: string;
  activityId?: string;
  name?: string;
  count: number;
  expenseType?: ExpenseType;
  isVerifiable: boolean;
  unitCost: number;
  measurementUnit?: MeasurementUnit;
  monthlyBudget: {
    [month: number]: Partial<IMonthlyBudget>;
  };
  summary?: string;
  computedTotal?: number;
  equipmentBills?: any[];
}

export type ActivityBeneficiaryType = any; // TODO: Define beneficiary types

export type ActivityBeneficiaryAttendance =
  | "REGULAR"
  | "UNIQUE";

export const ActivityBeneficiaryAttendance: { [key in ActivityBeneficiaryAttendance]: ActivityBeneficiaryAttendance } = {
  REGULAR: "REGULAR",
  UNIQUE: "UNIQUE"
};

export interface ActivityBeneficiary {
  id: string;
  goalValue: string;
  type: ActivityBeneficiaryType;
  description: string;
  attendance: ActivityBeneficiaryAttendance;
}

export interface Beneficiary {
  id: string;
  attendance: ActivityBeneficiaryAttendance;
  description: string;
  goalValue: string;
  type: BeneficiaryType[];
}

export type GoalIndicatorType =
  | "QUANTITATIVE"
  | "QUALITATIVE";

export const GoalIndicatorType: { [type in GoalIndicatorType]: GoalIndicatorType } = {
  QUANTITATIVE: "QUANTITATIVE",
  QUALITATIVE: "QUALITATIVE"
};

export interface GoalIndicator<T extends GoalIndicatorType> {
  id: string;
  description: string;
  verificationMethod: string;
  type: T;
}

export interface GoalIndicators<T extends GoalIndicatorType> {
  [indicatorId: string]: Partial<GoalIndicator<T>>;
}

export interface Activity {
  id: string;
  goalId: string;
  beneficiaries: Beneficiary[];
  description: string;
  months: number[];
  supplies: string[];
}

export interface Goal {
  id: string;
  specificObjectiveId: string;
  description: string;
  quantitativeIndicators: GoalIndicators<"QUANTITATIVE">;
  qualitativeIndicators: GoalIndicators<"QUALITATIVE">;
  activities: string[];
}

export interface SpecificObjective {
  id?: string;
  summary: string;
  goals: string[];
  description: string;
  deliverable: SpecificObjectiveDeliverable;
  activities: Activity[];
}

export interface SpecificObjectiveDeliverable {
  id?: string;
  title: string;
  description: string;
  periodicity?: DeliverablePeriodicity;
}

export type DeliverablePeriodicity =
  | "MONTHLY"
  | "QUARTERLY" // trimestral
  | "BIANNUAL"; // semestral

export const DeliverablePeriodicity: { [key in DeliverablePeriodicity]: DeliverablePeriodicity } = {
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  BIANNUAL: "BIANNUAL"
};

export interface GeneralObjective {
  id: string;
  summary: string;
  specificObjectives: string[];
}

export type RiskProbability =
  | "LOW"
  | "MEDIUM"
  | "HIGH";


export const RiskProbability: { [key in RiskProbability]: RiskProbability } = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW"
};

export type RiskImpact =
  | "HIGH"
  | "MEDIUM"
  | "LOW";

export const RiskImpact: { [key in RiskImpact]: RiskImpact } = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW"
};

export interface Risk {
  id: string;
  identification: string;
  probability: RiskProbability;
  impact: RiskImpact;
  mitigationStrategy: string;
}

export interface SustainabilityArgument {
  id: string;
  argument: string;
}

export interface ReviewableProps {
  reviewable: boolean;
  approval: boolean;
}

/**
 * A ministering is the aprovement of budget for certain specific objective
 * in certain date for a project
 */
export interface IMinistering {
  id: string;
  date: string;
  deliverableId: string;
}

export interface IRequest {
  id: string;
}
