import gql from "graphql-tag";

export const CreateSustainabilityArgument = gql`
  mutation CreateSustainabilityArgument(
    $projectId: String!
    $id: String!
    $argument: String
  ) {
    createSustainabilityArgument(
      projectId: $projectId
      data: {
        id: $id
        argument: $argument
      }
    ) {
      id
      argument
    }
  }
`;
