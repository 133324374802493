import React from "react";
import { Col } from "antd";

const InfoText = (props: any) => {
  return (
    <Col
      span={15}
      style={{ textAlign: "right" }}
      {...props}>
        {props.children}
    </Col>
  );
};

export default InfoText;
