import { IProcessedSupplyItem } from "../types";
import moment from "moment";

type TotalsPerYear = {
  [year: number]: {
    totalFicosec: number,
    totalCoInvestment: number
  }
};

export const getTotalsPerYear = (supplies: IProcessedSupplyItem[] = []) => {
  const totalsPerYear: TotalsPerYear = {};

  supplies.forEach((supply) => {
    const { monthlyBudget = {} } = supply;
    Object.keys(monthlyBudget).forEach((timestamp: string) => {
      const current = monthlyBudget[timestamp];
      const { ficosec = 0, coinvestor = 0 } = current;
      const year = moment(parseInt(timestamp))
        .toDate()
        .getFullYear()
        .toString();

      if (totalsPerYear[year] === undefined) {
        totalsPerYear[year] = { totalFicosec: 0, totalCoInvestment: 0 };
      }
      
      totalsPerYear[year].totalFicosec += ficosec;
      totalsPerYear[year].totalCoInvestment += coinvestor;
    });
  });

  return totalsPerYear;
};
