import gql from "graphql-tag";

export const UpdateSupplies = gql`
  mutation UpdateSupplies(
    $projectId: String!
    $suppliesToCreate: [CreateSupplyInput!]!
    $suppliesToUpdate: [UpdateSupplyInput!]!
    $suppliesToDelete: [String!]!
  ) {
    createSupplies(
      projectId: $projectId
      data: $suppliesToCreate
    ) {
      id
      name
      expenseType
      isVerifiable
      unitCost
      measurementUnit
      count
    }

    updateSupplies(
      data: $suppliesToUpdate
    ) {
      id
      name
      expenseType
      isVerifiable
      unitCost
      measurementUnit
      count
    }

    deleteSupplies(ids: $suppliesToDelete)
  }
`;
