import { WorkTableArea } from "./WorkTableArea";

export interface IWorkTable {
  id: string;
  name: string;
  coordination: string;
  area: WorkTableArea;
  members: IWorktableMember[];
}

export type WorktableMemberType =
  | "WITH_PUBLIC_OFFICE"
  | "WITHOUT_PUBLIC_OFFICE"
  | "GUEST";

export const WorktableMemberType: { [key in WorktableMemberType]: WorktableMemberType } = {
  WITH_PUBLIC_OFFICE: "WITH_PUBLIC_OFFICE",
  WITHOUT_PUBLIC_OFFICE: "WITHOUT_PUBLIC_OFFICE",
  GUEST: "GUEST"
};

export type WorktableMemberInstitutionLevel =
  | "STATE"
  | "FEDERAL"
  | "MUNICIPAL";

export interface IWorktableMember {
  id: string;
  type: WorktableMemberType;
  fullName: string;
  institution: string;
  position: string;
  institutionLevel: WorktableMemberInstitutionLevel;
  phoneNumber: string;
  emailAddress: string;
}
