import React from "react";
import { Button } from "antd";

import styles from "./styles.less";

interface RequestCardProps {
  id: string;
  name: string;
  onRemove: (requestId: string) => void;
}

const RequestCard: React.FC<RequestCardProps> = ({ id, name, onRemove }) =>
  <div className={styles.card}>
    <span>{name}</span>
    <Button shape="circle" icon="delete" onClick={() => onRemove(id)} />
  </div>;

export default RequestCard;
