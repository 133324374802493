import gql from "graphql-tag";

export const GetAccount = gql`
  query GetImplementerProfile ($uid: String!) {
    account(uid: $uid) {
      id
      implementerProfile {
        id
        type
        governmentLevel
        governmentDependency
        coordination
        area
        institutionResponsibleName
        implementerName
        implementerMission
        implementerVision
        socialObjective
        contactName
        contactCharge
        contactInstitution
        contactEmail
        contactPhoneNumber
        personType
        RFC
        businessName
        socialBehest
        street
        exteriorNumber
        interiorNumber
        colony
        zipCode
        city
        state
        directorsBoardMembers {
          id
          name
          charge
        }
        documents {
          id
          fileName
          ref
        }
      }
    }
  }
`;
