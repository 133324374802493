import gql from "graphql-tag";

export const MonitoringEntry = gql`
  query MonitoringEntry ($projectId: String!, $id: String!) {
    monitoringEntry(id: $id) {
      id
      date
      closed
    }

    project(id: $projectId) {
      id
      name
      startDate
      endDate
      supplies {
        id
        name
        expenseType
        isVerifiable
        humanResource {
          id
          fullName
        }
        monthlyBudget {
          id
          ficosec
          coinvestor
          month
          budgetVerification {
            id
            type
            consumed
            invoices {
              id
              ref
              fileName
            }
          }
        }
      }
    }
  }
`;
