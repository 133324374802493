import React from "react";
import { Form, Input, Select } from "antd";
import { FormSection } from "../../../../molecules";
import {
  RequestArea,
  RequestType,
  ImplementerFields
} from "../../../../../types/Request";
import { FormComponentProps } from "antd/lib/form";

interface IFiscalDataSectionOwnProps {
  requestArea?: RequestArea;
  requestType?: RequestType;
  fields?: Partial<ImplementerFields>;
  reviewable?: boolean;
  approval?: boolean;
}

type FiscalDataSectionProps = IFiscalDataSectionOwnProps & FormComponentProps;

export class FiscalDataSection extends React.Component<FiscalDataSectionProps> {

  public constructor(props: FiscalDataSectionProps) {
    super(props);
  }

  public render() {
    const {
      form: { getFieldDecorator },
      fields,
      reviewable,
      approval
    } = this.props;

    return (
      <FormSection title="Datos fiscales">
        <Form.Item>
          {getFieldDecorator("personType", {
            rules: [
              {
                required: true,
                message: "Por favor, selecciona el tipo de persona."
              }
            ],
            initialValue: fields?.personType
          })!(
            <Select placeholder="Tipo de persona" size="large" disabled={reviewable || approval}>
              <Select.Option key="PHYSICAL">Física</Select.Option>
              <Select.Option key="MORAL">Moral</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("RFC", {
            rules: [
              {
                required: true,
                message: "Por favor, ingrese el RFC."
              },
              {
                min: 12,
                message: "Por favor, ingrese un RFC correcto."
              },
              {
                max: 13,
                message: "Por favor, ingrese un RFC correcto."
              }
            ],
            initialValue: fields?.RFC
          })!(
            <Input placeholder="RFC" size="large" disabled={reviewable || approval} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("businessName", {
            rules: [
              {
                required: true,
                message: "Por favor, ingrese la razón social."
              }
            ],
            initialValue: fields?.businessName
          })!(
            <Input placeholder="Razón social" size="large" disabled={reviewable || approval} />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("socialBehest", {
            rules: [
              {
                required: true,
                message: "Por favor, ingrese el objeto social o mandato."
              }
            ],
            initialValue: fields?.socialBehest
          })!(
            <Input placeholder="Objeto social o mandato" size="large" disabled={reviewable || approval} />
          )}
        </Form.Item>
      </FormSection>);
  }
}
