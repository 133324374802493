import gql from "graphql-tag";

export const CreateActivity = gql`
  mutation CreateActivity(
    $goalId: String!
    $id: String!
    $beneficiaries: [CreateActivityBeneficiaryInput!]!
    $description: String!
    $months: [Int!]!
  ) {
    createActivity(
      goalId: $goalId
      data: {
        id: $id
        beneficiaries: $beneficiaries
        description: $description
        months: $months
      }
    ) {
      id
      beneficiaries {
        id
      }
      description
      months
    }
  }
`;
