import React, { useState } from "react";
import { message } from "antd";
import { Redirect, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import firebase from "firebase";

import {
  MainContainer,
  ContentContainer,
  IllustrationContainer,
  FormContainer,
  SignUpForm
} from "./components";
import { bugsnagClient } from "../../../bugsnag";
import { RoleConsumer } from "../../RoleConsumer";
import { getSignInRedirectPath } from "../../ProtectedRoute";
import { CreateImplementerAccount } from "../../../gql/mutations";
import { client } from "../../../gql/apollo";

const ficosecLogo = require("../../../assets/ficosec_logo.svg");

export const CreateAccountTemplate: React.FC = () => {
  const history = useHistory();
  const [busy, setBusy] = useState(false);
  const [createImplementerAccount] = useMutation(CreateImplementerAccount, { client } as any);

  const handleOnSignInAttempt = async (implementerName: string, emailAddress: string, password: string) => {
    try {
      setBusy(true);
      const result = await createImplementerAccount({
        variables: {
          name: implementerName,
          email: emailAddress,
          password
        }
      });

      if (result?.data?.createImplementerAccount !== undefined) {
        await firebase.auth()
          .signInWithEmailAndPassword(emailAddress, password);
        history.push("/implementadora/presolicitudes/crear");
      }
    } catch (error) {
      const graphQLErrors = error?.graphQLErrors;
      if (graphQLErrors) {
        const errorCode = graphQLErrors[0]?.
          extensions?.
          exception?.
          errorInfo?.code || "";

        if (errorCode === "auth/email-already-exists") {
          message.warning("El correo ya se encuentra registrado");
        } else {
          message.error("Ocurrió un error.");
          bugsnagClient.notify(error, {
            beforeSend: (report) => {
              report.metaData = { error: JSON.stringify(error) };
              report.errorClass = "CreateAccount";
              report.groupingHash = report.errorClass;
            }
          });
        }
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <RoleConsumer>
      {({ isSignedIn, claims }) => {
        if (isSignedIn) { // navigate to the desired route
          const query = new URLSearchParams(location.search);
          const defaultRedirectPath = getSignInRedirectPath(claims?.role);
          const redirectPath = query.get("redirect") || defaultRedirectPath;
          return <Redirect to={redirectPath} />;
        }

        return (
          <MainContainer>
            <ContentContainer>
              <FormContainer>
                <img src={ficosecLogo} />
                <h1 style={{ fontSize: "30pt", fontWeight: "bold" }}>Bienvenido</h1>
                <p>
                  FICOSEC es dirigido por empresarios del estado de Chihuaha,
                  apartidista y sin fines de lucrro. La ejecución de proyectos
                  encaminados a su misión se realiza a través de dos Asociaciones
                  Civiles que son Seguridad y Justicia de Ciudad Juárez A. C. y
                  Fundación Ficosec A. C. ubicadas en la Zona Norte (Ciudad Juárez y
                  Nuevo Casas Grandes) y en la Zona Centro y Sur del estado
                  (Chihuahua) Cuahutémoc, Delicias y Parral).
                </p>
                <p>
                  Para poder enviar una pre solicitud de fondos, primero creemos tu
                  cuenta.
                </p>
                <SignUpForm
                  loading={busy}
                  onSignUpAttempt={handleOnSignInAttempt}
                />
              </FormContainer>
            </ContentContainer>
            <IllustrationContainer />
          </MainContainer>
        );
      }}
    </RoleConsumer>
  );
};
