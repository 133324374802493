import React, { useState } from "react";
import { Table } from "antd";
import { Request } from "../../../../types";
import { AgreementTemplateProps, AgreementTemplateState } from "./types";
import { getColumns } from "./columns";
import {
  SigningDateModal,
  CloseAgreementModal,
  ContractModal
} from "./components";

const AgreementTemplate: React.FC<AgreementTemplateProps> = (props) => {
  const [state, setState] = useState<AgreementTemplateState>({
    contractAttachmentModal: {
      visible: false,
      requestId: ""
    },
    signingDateModal: {
      request: undefined,
      visible: false
    },
    closeAgreementModal: {
      project: undefined,
      visible: false
    }
  });

  const { loading, requests, onPaginationChange } = props;
  const { requestId: selectedRequestId } = state.contractAttachmentModal;
  const selectedRequest: Partial<Request> | undefined = requests
    ?.items
    ?.find((r: any) => r.id === selectedRequestId);

  // CLOSING AGREEMENT MODAL
  const openCloseAgreementModal = (args: { project?: any }) =>
    setState((state) => ({
      ...state,
      closeAgreementModal: {
        project: { ...args.project },
        visible: true
      }
    }));

  // CLOSING AGREEMENT MODAL
  const closeCloseAgreementModal = () =>
    setState((state) => ({
      ...state,
      closeAgreementModal: {
        project: undefined,
        visible: false
      }
    }));

  const toggleSigningDateModal = (request?: Partial<Request>) =>
    setState({
      ...state,
      signingDateModal: {
        ...state.signingDateModal,
        request,
        visible: !state.signingDateModal.visible
      }
    });

  const toggleAttachmentModal = (requestId?: string) =>
    setState((prevState) => ({
      ...prevState,
      contractAttachmentModal: {
        visible: !prevState.contractAttachmentModal.visible,
        requestId
      }
    }));

  return (
    <div style={{ padding: "0px 140px", background: "#ffffff" }}>
      <ContractModal
        request={selectedRequest}
        onClose={toggleAttachmentModal}
        visible={state.contractAttachmentModal.visible} />
      <CloseAgreementModal
        onCancel={closeCloseAgreementModal}
        visible={state.closeAgreementModal.visible}
        project={state.closeAgreementModal.project} />
      <SigningDateModal
        onCancel={toggleSigningDateModal as any}
        visible={state.signingDateModal.visible}
        request={state.signingDateModal.request} />
      <Table
        loading={loading}
        pagination={{ total: requests?.count }}
        columns={getColumns({
          openCloseAgreementModal,
          toggleSigningDateModal,
          toggleAttachmentModal
        })}
        onChange={onPaginationChange}
        dataSource={requests?.items || []} />
    </div>
  );
};

export default AgreementTemplate;
