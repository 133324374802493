import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import styles from "./styles.less";
import { Sidebar, StepIndicator } from "../../atoms";
import { NewRequestContextProps } from "../../NewRequestContext";

interface RequestReviewSidebarOwnProps {
  currentStep: number;
}

type RequestReviewSidebarProps = RequestReviewSidebarOwnProps & Partial<NewRequestContextProps>;

export const RequestReviewSidebar: React.FC<RequestReviewSidebarProps> = ({ currentStep = 0 }) => {
  return (
    <Sidebar>
      <Sidebar.Heading>Nueva solicitud</Sidebar.Heading>
      <Menu selectedKeys={[String(currentStep)]} className={styles.menu}>
        <Menu.Item key="0">
          <StepIndicator label="1" />
          <Link to="implementadora">
            <span className={styles.itemLabel}>
              Perfil de la implementadora
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <StepIndicator label="2" />
          <Link to="capacidad-financiera">
            <span className={styles.itemLabel}>
              Capacidad financiera gestora
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <StepIndicator label="3" />
          <Link to="proyecto">
            <span className={styles.itemLabel}>
              Información general del proyecto
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <StepIndicator label="4" />
          <Link to="ficha">
            <span className={styles.itemLabel}>
              Ficha del proyecto
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <StepIndicator label="5" />
          <Link to="presupuesto">
            <span className={styles.itemLabel}>
              Presupuesto
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <StepIndicator label="6" />
          <Link to="cronograma">
            <span className={styles.itemLabel}>
              Cronograma
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <StepIndicator label="7" />
          <Link to="recursos-humanos">
            <span className={styles.itemLabel}>
              Perfil del Recurso Humano
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <StepIndicator label="8" />
          <Link to="riesgos">
            <span className={styles.itemLabel}>
              Supuestos y gestión de riesgos
            </span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sidebar>
  );
};

export default RequestReviewSidebar;
