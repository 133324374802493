import React from "react";
import { Modal } from "antd";
import { withRouter, RouteComponentProps } from "react-router";

import { Content } from "../../atoms";
import { ImplementerForm } from "../forms";
import { RequestFooter } from "../../organisms";
import { NewRequestContextProps } from "../../NewRequestContext";
import { ReviewableProps } from "../../../types/Request";

type ImplementerProfileProps = ReviewableProps & NewRequestContextProps & RouteComponentProps;

export class ImplementerProfile extends React.Component<ImplementerProfileProps> {
  private formRef: any;

  public render() {
    const {
      state: { requestType, implementer },
      reviewable,
      approval
    } = this.props;

    return (
      <Content style={{ marginLeft: 245 }}>
        <ImplementerForm
          reviewable={reviewable}
          approval={approval}
          requestArea={requestType.area}
          requestType={requestType.request}
          fields={implementer}
          onChange={this.onFormInputChange}
          wrappedComponentRef={(formRef: any) => (this.formRef = formRef)} />
        {reviewable || !approval && (
          <RequestFooter nextProps={{ onClick: this.handleOnClickNext }} />
        )}
      </Content>
    );
  }

  private onFormInputChange = (field: string, value: string) => {
    const { methods, reviewable } = this.props;
    if (!reviewable) {
      methods.saveImplementerInput(field, value);
    }
  }

  private handleOnClickNext = () => {
    const { history, methods } = this.props;
    const { setChecked } = methods;
    const { form } = this.formRef.props;

    form.validateFields((err: any, values: any) => {
      const everyValue = Object.values(values);

      if (everyValue.some((value) => !value)) {
        setChecked("implementer", false);
        return Modal.confirm({
          title: "Campos requeridos faltantes.",
          content: "Algunos campos que son requeridos están faltantes, ¿Deseas continuar?",
          okText: "Sí, continuar",
          cancelText: "Cancelar",
          onOk() {
            history.push("recursos-humanos");
          }
        });
      }

      if (err) {
        return setChecked("implementer", false);
      }

      setChecked("implementer", true);
      return history.push("recursos-humanos");
    });
  }
}

export default withRouter(ImplementerProfile);
