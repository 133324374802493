import React from "react";
import { Table as ANTTable } from "antd";
import { TableProps } from "antd/lib/table";
import styled from "styled-components";

import columns from "./columns";
import { Supply, InvestorType } from "../../../../types";

export type BreakdownTableType =
  | "BREAKDOWN"
  | "SUBTOTAL"
  | "TOTAL";

export const BreakdownTableType: { [key in BreakdownTableType]: BreakdownTableType } = {
  BREAKDOWN: "BREAKDOWN",
  SUBTOTAL: "SUBTOTAL",
  TOTAL: "TOTAL"
};

interface IMonitoringBudgetBreakdownTableOwnProps {
  loading: boolean;
  type?: BreakdownTableType;
  onOpenBudgetVerificationModal?: (budgetId: string, type: InvestorType) => void;
}

type MonitoringBudgetBreakdownTableProps = IMonitoringBudgetBreakdownTableOwnProps & TableProps<Partial<Supply>>;

const Table = styled(ANTTable)`
  & .ant-table-row.ant-table-row-level-1 {
    background-color: #f5f5f5;
  }
`;

export const MonitoringBudgetBreakdownTable: React.FC<MonitoringBudgetBreakdownTableProps> = ({
  loading,
  type,
  onOpenBudgetVerificationModal,
  dataSource = []
}) => {
  return (
    <Table
      loading={loading}
      pagination={false}
      columns={columns(type, onOpenBudgetVerificationModal)}
      dataSource={dataSource}
    />
  );
};
