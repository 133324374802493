import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { ProjectsListTemplate } from "../../templates";
import { Projects } from "../../../gql/queries";
import { client } from "../../../gql/apollo";
import { ErrorScreen } from "../../atoms";
import { IPaginationSignature, sortDirectionMap } from "../requests/ReviewalPage";

export const ProjectsListPage: React.FC = () => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { loading, data, error, refetch } = useQuery(Projects, { variables: { closed: true }, client } as any);

  if (error) {
    // TODO: Report to bugsnag
    console.error(error);
    return <ErrorScreen message="Ocurrió un problema al cargar los proyectos" />;
  }

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.field, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);

  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<any>,
    sorter: SorterResult<any>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <ProjectsListTemplate
      loading={loading}
      onPaginationChange={handleOnPaginationChange}
      projects={data?.projects} />
  );
};
