import gql from "graphql-tag";

const WorkTable = gql`
  query WorkTable($id: String!) {
    worktable(id: $id) {
      id
      folio
      logotypeUrl
      name
      area
      subject
      coordination
      technicalSecretary
      type
      description
      status
      meetings {
        id
        folio
        name
        subject
        place
        date
        objective
        status
      }
    }
  }
`;

export default WorkTable;
