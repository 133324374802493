import React from "react";
import { format } from "date-fns";
import { RequestType, RequestStatus } from "../../../../../types";
import { requestTypes, requestStatuses } from "../../../../../utils/maps";
import { Button } from "antd";

interface IRequestColumns {
  pushToRequest: (reqId: string) => void;
}

export const requestColumns = ({ pushToRequest }: IRequestColumns) => [
  {
    title: "Nombre",
    dataIndex: "project.name",
    key: "project.name"
  },
  {
    title: "Localidad",
    dataIndex: "project.place",
    key: "project.place"
  },
  {
    title: "Fecha de inicio",
    dataIndex: "project.startDate",
    key: "project.startDate",
    render: (date: string) => format(new Date(date), "dd/MM/yyyy")
  },
  {
    title: "Fecha de fin",
    dataIndex: "project.endDate",
    key: "project.endDate",
    render: (date: string) => format(new Date(date), "dd/MM/yyyy")
  },
  {
    title: "Tipo",
    dataIndex: "preRequest.type",
    key: "preRequest.type",
    render: (type: RequestType) => requestTypes[type]
  },
  {
    title: "Estatus",
    dataIndex: "status",
    key: "status",
    render: (status: RequestStatus) => requestStatuses[status]
  },
  {
    fixed: "right" as "right",
    width: 1,
    render: (_: any, request: { id: string }) => (
      <Button
        shape="round"
        style={{ marginRight: 10 }}
        onClick={() => pushToRequest(request.id)}
      >
        Ver detalles
      </Button>
    )
  }
];
