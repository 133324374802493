import React from "react";
import ReactDOM from "react-dom";
import { Spin, Icon, DatePicker } from "antd";

DatePicker.defaultProps = {
  ...DatePicker.defaultProps, format: ["DD [de] MMMM [de] YYYY", "x"]
};

DatePicker.RangePicker.defaultProps = {
  ...DatePicker.RangePicker.defaultProps, format: ["DD [de] MMMM [de] YYYY", "x"]
};

Spin.setDefaultIndicator(<Icon type="loading" spin />);

import App from "./App";

const root = document.getElementById("root");
ReactDOM.render(<App />, root);
