import { CreateMeeting } from "../../../../gql/mutations";
import { WorkTable } from "../../../../gql/queries";
import { IWorktableMeeting } from "../../../../types";
import { client } from "../../../../gql/apollo";

export const createWorktableMeeting = async (
  meetingData: IWorktableMeeting,
  worktableId: string) => {
  await client.mutate({
    mutation: CreateMeeting,
    variables: {
      ...meetingData,
      worktableId
    },
    refetchQueries: [{
      query: WorkTable,
      variables: {
        worktableId
      }
    }]
  });
};
