import gql from "graphql-tag";

export const UpdateBudgetVerification = gql`
  mutation UpdateBudgetVerification(
    $id: String
    $supplyBudgetId: String!
    $type: InvestorType!
    $consumed: Float!
    $invoices: [CreateAttachmentInput!]!
  ) {
  updateBudgetVerification(
    data: {
      id: $id
      supplyBudgetId: $supplyBudgetId
      type: $type
      consumed: $consumed
      invoices: $invoices
    }
  ) {
    id
  }
}
`;
