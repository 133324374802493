import React from "react";
import { Query } from "react-apollo";

import { AgreementsTemplate } from "../../templates/agreements";
import { WorkTableAgreements } from "../../../gql/queries";
import { ErrorTemplate } from "../../templates";

class AgreementsPage extends React.Component {
  public render() {
    return (
      <Query query={WorkTableAgreements}>
        {({ loading, data, error }: { loading: boolean, data: any, error?: any }) => {
          if (error) {
            return <ErrorTemplate message="Hubo un problema al cargar los acuerdos." />;
          }

          return <AgreementsTemplate
                   loading={loading} data={data.allWorkTableAgreements} />;
        }}
      </Query>
    );
  }
}

export default AgreementsPage;
