import React, { Component } from "react";

import { PreRequestForm } from "../../../organisms";
import { StyledContainer, StyledFormContainer } from "./components";
import { RoleConsumer } from "../../../RoleConsumer";
import Layout, { Content } from "../../../organisms/Layout";

export default class CreatePreRequestPage extends Component {
  public render = () => {
    return (
      <RoleConsumer>
        {({ claims }) => {
          return (
            <Layout>
              <Content>
                <StyledContainer>
                  <StyledFormContainer>
                    <h2 style={{ fontWeight: "bold" }}>
                      ¡Hola, {claims?.name}!
                    </h2>
                    <p>
                      Es momento de que nos digas un poco sobre tu organización
                      y el proyecto que quieres realizar.
                    </p>
                    <PreRequestForm action="CREATE" />
                  </StyledFormContainer>
                </StyledContainer>
              </Content>
            </Layout>
          );
        }}
      </RoleConsumer>
    );
  }
}
