import React from "react";
import { Button, Table } from "antd";
import { useHistory } from "react-router";

import { requestColumns } from "../helpers";

interface RequestsTableProps {
  loading: boolean;
  total: number;
  requests: any[];
}

export const RequestsTable: React.FC<RequestsTableProps> = ({ loading, total, requests }) => {
  const history = useHistory();

  const pushToRequest = (reqId: string) =>
    history.push(`/solicitudes/aprobacion/${reqId}`);

  return (
    <Table
      loading={loading}
      pagination={{ total }}
      columns={requestColumns({ pushToRequest })}
      rowKey="id"
      dataSource={requests} />
  );
};
