import gql from "graphql-tag";

export const UpdateActivity = gql`
  mutation UpdateActivity(
    $id: String!
    $beneficiaries: [CreateActivityBeneficiaryInput!]
    $beneficiariesToRemove: [String!]
    $description: String
    $months: [Int!]
  ) {
    updateActivity(
      data: {
        id: $id
        beneficiaries: $beneficiaries
        beneficiariesToRemove: $beneficiariesToRemove
        description: $description
        months: $months
      }
    ) {
      id
    }
  }
`;
