export default {
  "fico-green-light": "#6BDA80",
  "fico-green-medium": "#43B964",
  "fico-green-dark": "#1A8B42",
  "fico-red-light": "#DB7481",
  "fico-red-medium": "#B94C65",
  "fico-red-dark": "#8C1F42",
  "fico-azul-light": "#5EDCC6",
  "fico-azul-medium": "#38BAAF",
  "fico-azul-dark": "#148E8D",
  "fico-orange-light": "#F8C26A",
  "fico-orange-medium": "#F2A849",
  "fico-orange-dark": "#EB8221",
};
