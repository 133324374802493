import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/react-hooks'
import { message } from "antd";
import { PaginationConfig, SorterResult } from "antd/lib/table";

import { requestsWithUnclosedAgreement } from "../../../gql/queries";
import { client } from "../../../gql/apollo";
import { ErrorTemplate } from "../../templates";
import { AgreementTemplate } from "../../templates/requests";
import Layout, { PageHeader } from "../../../components/organisms/Layout";
import { IPaginationSignature, sortDirectionMap } from "./ReviewalPage";
import { AgreementsTemplateContext } from '../../templates/requests/AgreementTemplate/AgreementsTemplateContext'
import { UpdateProject } from '../../../gql/mutations/projects'

const AgreementPage: React.FC = () => {
  const [pagination, setPagination] = useState<IPaginationSignature | undefined>();
  const { loading, error, data, refetch } = useQuery(
    requestsWithUnclosedAgreement,
    {
      client
    } as any);

  if (error) {
    return (
      <ErrorTemplate
        message="Hubo un problema al cargar las solicitudes." />
    );
  }

  const [updateProject] = useMutation(
    UpdateProject,
    {
      client,
      refetchQueries: [{ query: requestsWithUnclosedAgreement }],
      awaitRefetchQueries: true
    } as any);

  useEffect(() => {
    const skip = (pagination?.pagination?.pageSize || 10) * ((pagination?.pagination?.current || 1) - 1);
    const sortDirection = pagination?.sorter?.order || "descend";
    refetch({ skip: skip > 0 ? skip - 1 : skip, sortField: pagination?.sorter?.columnKey, sortDirection: sortDirectionMap[sortDirection] });
  }, [pagination]);

  if (error) {
    // @todo report to bugsnag
    return message.error("Ocurrió un problema al cargar las solicitudes");
  }

  const handleOnPaginationChange = (
    paginationConfig: PaginationConfig,
    filters: Partial<Record<"id", string[]>>,
    sorter: SorterResult<{ id: string }>
  ) => setPagination({ pagination: paginationConfig, filters, sorter });

  return (
    <Layout noPadding>
      <PageHeader title="Convenio" ghost={false} />
      <AgreementsTemplateContext.Provider
        value={{ updateProject, refetch }}>
        <AgreementTemplate
          loading={loading}
          onPaginationChange={handleOnPaginationChange}
          requests={(data as any)?.requestsWithUnclosedAgreement} />
      </AgreementsTemplateContext.Provider>
    </Layout>
  );
};

export default AgreementPage;
