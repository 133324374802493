import gql from "graphql-tag";

export const ImplementerPreRequests = gql`
  query GetImplementerRequests(
    $skip: Int
    $sortField: String
    $sortDirection: String
  ) {
    implementerPreRequests(
      skip: $skip
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      items {
        id
        estimatedBudget
        organizationDescription
        projectDescription
        projectName
        createdAt
        status
        request {
          id
        }
      }
      count
    }
  }
`;
