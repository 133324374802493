import React from "react";
import { RouteComponentProps } from "react-router";

import { NewRequestTemplate } from "../../templates/requests";
import { NewRequestProvider } from "../../NewRequestContext";

type NewRequestPageProps = Pick<RouteComponentProps<{ currentStep: string }>, "match">;

class NewRequestPage extends React.Component<NewRequestPageProps> {
  public render() {
    const { match } = this.props;
    return (
      <NewRequestProvider>
        <NewRequestTemplate currentStep={match.params.currentStep} />
      </NewRequestProvider>
    );
  }
}

export default NewRequestPage;
