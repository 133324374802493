import React from "react";
import Input, { InputProps } from "antd/lib/input";

import styles from "./styles.less";

const SimpleInput: React.FC<InputProps> = (props) => (
  <Input className={styles.simpleInput} {...props} />
);

export default SimpleInput;
