import React from "react";
import { PageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import styled from "styled-components";

const StyledPageHeader = styled(PageHeader)`
  margin: -10pt -10pt 10pt -10pt;
  border: 1px solid rgb(235, 237, 240)
`;

export const CustomPageHeader: React.FC<PageHeaderProps> = (props) => (
    <StyledPageHeader {...props} />
  );
