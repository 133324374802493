export const getStepTitle = (currentStep: number, stepsToSkip: number): {
  title: string,
  subtitle: string
} => {
  const totalSteps = 9 - stepsToSkip;

  const stepTitles: {
    [key: number]: {
      title: string,
      subtitle: string
    }
  } = {
    0: {
      title: "Tipo de solicitud",
      subtitle: `Paso 1 de ${totalSteps}`
    },
    1: {
      title: "Perfil de la Implementadora",
      subtitle: `Paso 2 de ${totalSteps}`
    },
    2: {
      title: "Capacidad financiera gestora",
      subtitle: `Paso ${3 - stepsToSkip} de ${totalSteps}`
    },
    3: {
      title: "Información general del proyecto",
      subtitle: `Paso ${4 - stepsToSkip} de ${totalSteps}`
    },
    4: {
      title: "Ficha del proyecto",
      subtitle: `Paso ${5 - stepsToSkip} de ${totalSteps}`
    },
    5: {
      title: "Presupuesto",
      subtitle: `Paso ${6 - stepsToSkip} de ${totalSteps}`
    },
    6: {
      title: "Cronograma",
      subtitle: `Paso ${7 - stepsToSkip} de ${totalSteps}`
    },
    7: {
      title: "Perfil del Recurso Humano",
      subtitle: `Paso ${8 - stepsToSkip} de ${totalSteps}`
    },
    8: {
      title: "Supuestos y gestión de riesgos",
      subtitle: `Paso ${9 - stepsToSkip} de ${totalSteps}`
    }
  };

  const keyExists = Object.keys(stepTitles).some((key) =>
    parseInt(key, 10) === currentStep);

  if (keyExists) {
    return stepTitles[parseInt(currentStep.toString(), 10)];
  } else {
    return { title: "", subtitle: "" };
  }
};
