import React from "react";
import { Route, RouteProps } from "react-router";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import styled from "styled-components";

import { UserRole } from "../types/User";
import { RoleConsumer } from "./RoleConsumer";
import { ErrorScreen, LoadingScreen } from "./atoms";

interface IProtectedRouteProps {
  allowedRoles: UserRole[];
}

type ProtectedRouteProps = IProtectedRouteProps & RouteProps;

export const getSignInRedirectPath = (role?: UserRole) => {
  if (role === UserRole.ADMIN) {
    return "/presolicitudes";
  }

  if (role === UserRole.IMPLEMENTER) {
    return "/implementadora/presolicitudes";
  }

  return "/iniciar-sesion";
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
  ...routeProps
}) => {
  return (
    <RoleConsumer>
      {({ claims, isSignedIn }) => {
        if (isSignedIn === false) {
          return (
            <ErrorScreen
              message="Es necesario que inicies sesión para poder ver esta página"
              redirect={{
                to: "/iniciar-sesion",
                label: "Iniciar sesión"
              }}
            />
          );
        }

        if (!claims?.role) {
          return <LoadingScreen />;
        }

        if (allowedRoles.includes(claims.role)) { // role  permitido
          return <Route {...routeProps} />;
        } else {
          const redirectPath = getSignInRedirectPath(claims.role);
          // @todo Show the corresponding panel according the user role
          return (
            <ErrorScreen
              message="No encontramos la página que estás buscando"
              redirect={{
                to: redirectPath,
                label: "Regresar"
              }}
            />
          );
        }
      }}
    </RoleConsumer>
  );
};
