import gql from "graphql-tag";

export const CreateImplementerProfile = gql`
  mutation CreateImplementerProfile(
    $implementerAccountUid: String!,
    $type: ImplementerType!,
    $governmentLevel: GovernmentLevel,
  	$governmentDependency: String,
    $coordination: String,
    $area: String,
    $institutionResponsibleName: String,
  	$implementerName: String,
  	$implementerMission: String,
    $implementerVision: String,
    $socialObjective: String,
    $contactName: String!,
    $contactCharge: String!,
    $contactInstitution: String,
    $contactEmail: String!,
    $contactPhoneNumber: String!,
  	$personType: PersonType,
  	$RFC: String,
  	$businessName: String,
  	$socialBehest: String,
    $street: String,
    $exteriorNumber: String,
    $interiorNumber: String,
    $colony: String,
    $zipCode: String,
    $city: String,
    $state: String,
    $directorsBoardMembers: [CreateDirectorsBoardMemberInput!]!,
    $documentsToCreate: [CreateAttachmentInput!]!,
  ) {
    createImplementerProfile (
      data: {
        implementerAccountUid: $implementerAccountUid
        type: $type
        governmentLevel: $governmentLevel
        governmentDependency: $governmentDependency
        coordination: $coordination
        area: $area
        institutionResponsibleName: $institutionResponsibleName
        implementerName: $implementerName
        implementerMission: $implementerMission
        implementerVision: $implementerVision
        socialObjective: $socialObjective
        contactName: $contactName
        contactCharge: $contactCharge
        contactInstitution: $contactInstitution
        contactEmail: $contactEmail
        contactPhoneNumber: $contactPhoneNumber
        personType: $personType
        RFC: $RFC
        businessName: $businessName
        socialBehest: $socialBehest
        street: $street
        exteriorNumber: $exteriorNumber
        interiorNumber: $interiorNumber
        colony: $colony
        zipCode: $zipCode
        city: $city
        state: $state
        directorsBoardMembers: $directorsBoardMembers
        documentsToCreate: $documentsToCreate
      }
    ) {
      id
      uid
      implementerProfile {	
        id
        type
        governmentLevel
        governmentDependency
        coordination
        area
        institutionResponsibleName
        implementerName
        implementerMission
        implementerVision
        socialObjective
        contactName
        contactCharge
        contactInstitution
        contactEmail
        contactPhoneNumber
        personType
        RFC
        businessName
        socialBehest
        street
        exteriorNumber
        interiorNumber
        colony
        zipCode
        city
        state
        directorsBoardMembers {
          id
          name
          charge
        }
        documents {
          id
          fileName
          ref
        }
      }
    }
  }
`;
