import React, { useContext } from "react";
import { Input } from "antd";
import Form from "antd/lib/form/Form";
import { ImplementerProfileFormContext } from "../../ImplementerProfileFormContext";

export const SocialBehestInput = (props: any) => {
  const implementerAccountContext = useContext(ImplementerProfileFormContext);
  const {
    form,
    isBusy,
    onFieldsChange,
    implementerProfile
  } = implementerAccountContext;
  const { getFieldDecorator } = form;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldsChange({
      key: event.target.name,
      value: event.target.value
    });
  };

  return (
    <Form.Item label="Objeto social o mandato">
      { getFieldDecorator("socialBehest", {
        rules: [
          {
            required: true,
            message: "Por favor, ingresa objeto social o mandato."
          }
        ],
        initialValue: implementerProfile?.socialBehest
      })(
        <Input
          disabled={isBusy}
          id="socialBehest"
          name="socialBehest"
          placeholder="Objeto social o mandato"
          onChange={onChange}
          size="large" />
      )}
    </Form.Item>
  );
};
