import React from "react";
import { Query } from "react-apollo";

import { MemorandumsTemplate } from "../../templates/memorandums";
import { WorkTableMemorandums } from "../../../gql/queries";
import { ErrorTemplate } from "../../templates";

class MemorandumsPage extends React.Component {
  public render() {
    return (
      <Query query={WorkTableMemorandums}>
        {({ loading, data, error }: { loading: boolean, data: any, error?: any }) => {
          if (error) {
            return <ErrorTemplate message="Hubo un problema al cargar las minutas." />;
          }
          return <MemorandumsTemplate
                  loading={loading} data={data.allMemorandums} />;
        }}
      </Query>
    );
  }
}

export default MemorandumsPage;
