import React from "react";
import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";

class RequestRejectionForm extends React.Component<FormComponentProps> {
  public render() {
    const { form } = this.props;
    return (
      <Form id="requestRejectionForm">
        <Form.Item>
          {form.getFieldDecorator("rejectReason", {
            rules: [
              {
                required: true,
                message: "Por favor, ingrese la razón del rechazo."
              }
            ],
          })(
            <Input.TextArea rows={5} placeholder="Motivo del rechazo" />
          )}
        </Form.Item>
      </Form>
    );
  }
}

const onFieldsChange = (props: any, changedFields: any) =>
  props.onChange(changedFields);

export default Form.create({ name: "requestRejectionForm", onFieldsChange })(RequestRejectionForm);
