import { Attachment } from "./Attachment";

export type AgreementStatus =
  | "IN_PROGRESS"
  | "FINISHED";

export enum AgreementStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED"
}

export const AgreementStatusDict: { [key in AgreementStatus]: string } = {
  IN_PROGRESS: "En proceso",
  FINISHED: "Completado"
};

export interface Agreement {
  id: string;
  description: string;
  agreement: string;
  deadline: string;
  responsible: string;
  documents?: Array<Partial<Attachment>>;
  contract?: Partial<Attachment>;
  status: AgreementStatus;
  scheduledSigningDate?: string;
  signingPlace?: string;
  signingTime?: string;
}
