import gql from "graphql-tag";

const ApprovedRequests = gql`
  query ApprovedRequests {
    allRequests(filter: { status: "APPROVED" }) {
      id
      name
      zone
      city
      date
      area
      type
      status
    }
    allMeetings {
      id
      requestIds
      name
      date
      time
      place
      judgeEmails
      guestEmails
    }
  }
`;

export default ApprovedRequests;
