import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Query, QueryResult } from "react-apollo";
import { Modal } from "antd";
import { RequestDetailsTemplate } from "../../templates/requests";
import { Request } from "../../../types";
import { Request as RequestQuery } from "../../../gql/queries";
import { LoadingScreen } from "../../atoms";
import Layout from "../../organisms/Layout";

interface RequestDetailsPageState {
  request?: Partial<Request>;
}

type RequestDetailsPageProps =
  & RouteComponentProps
  & Pick<RouteComponentProps<{ requestId: string }>, "match">;

class RequestDetailsPage extends React.Component<RequestDetailsPageProps, RequestDetailsPageState> {
  public state: RequestDetailsPageState = {
    request: {}
  }

  public render() {
    const { match } = this.props;
    const { requestId } = match.params;

    return (
      <Layout noPadding>
        <Query
          query={RequestQuery}
          variables={{ id: requestId }}
          onCompleted={this.handleOnCompleted}
          fetchPolicy="network-only">
          {({ loading, error }: QueryResult) => {
            if (error) {
              Modal.error({
                title: "No pudimos cargar la solicitud",
                content: "Ocurrió un problema al cargar la solicitud, puedes intentar recargando la página.",
                okText: "Recargar",
                onOk: () => location.reload(),
                cancelButtonProps: { hidden: true }
              });
            }

            if (loading) {
              return (
                <LoadingScreen
                  tip="Cargando solicitud"
                  size="large"
                  spinning />
              );
            }

            return <RequestDetailsTemplate request={this.state.request} />;
          }}
        </Query>
      </Layout>
    );
  }

  private handleOnCompleted = (data: any) => {
    const { request = {} } = data;
    this.setState({ request: { ...request } });
  }
}

export default withRouter(RequestDetailsPage);
